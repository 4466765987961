import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { Observable } from 'rxjs';
import { OrderService } from 'src/app/services/api/order/order.service';
import { Service } from 'src/app/models/service';
import { ServiceSubscription } from 'src/app/models/service-subscription';
import { VehicleSubscription } from 'src/app/models/vehicle-subscription';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-service-repeat',
  templateUrl: 'service-repeat.html',
  styleUrls: ['./service-repeat.scss'],
})
export class ServiceRepeatComponent implements OnChanges {
  @Input() serviceSubscription: ServiceSubscription;
  @Input() vehicleSubscription: VehicleSubscription;

  selectedFrequency: number;
  frequencyOptions: number[] = [];

  constructor(private loadingAlert: LoadingAlertService, private orderService: OrderService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const subscrioption = this.vehicleSubscription || this.serviceSubscription;
    const defaultFrequency = subscrioption?.frequency;
    this.selectedFrequency = this.selectedFrequency || defaultFrequency;
    this.frequencyOptions = subscrioption?.service.frequencyOptions || [];
  }

  didChange(frequency) {
    this.loadingAlert.showLoader();
    const request: Observable<any> = this.vehicleSubscription
      ? this.orderService.updateVehicleSubscription(this.vehicleSubscription, frequency)
      : this.orderService.updateServiceSubscription(this.serviceSubscription, frequency);

    request.pipe(finalize(() => this.loadingAlert.dismissLoader())).subscribe(
      () => {
        this.loadingAlert.showToastConfirmation('Your preferences have been updated.');
      },
      () => {
        this.loadingAlert.showToastAlert('An error occurred. Please try again.');
      }
    );
  }
}
