import * as moment from 'moment';

import { BaseEntity, MemoizeGetters, Transform, toDate } from './base';

@MemoizeGetters
export class MemberSubscription extends BaseEntity {
  status: 'active' | 'inactive';
  memberSubscriptionTierId: number;

  @Transform(parseInt)
  fee: number;
  initialFreeMonths: number;
  periodDays: number;

  @Transform(toDate)
  createdAt: Date;

  @Transform(toDate)
  datePaidThrough: Date;

  // Computed Properties

  get term() {
    return this.memberSubscriptionTierId === 1 ? 'monthly' : 'annual';
  }

  get active() {
    return this.status === 'active';
  }

  get freeMembershipExpiration(): Date {
    const momentCreatedAt = this.createdAt ? moment(this.createdAt) : moment(new Date());
    return momentCreatedAt.add(30 * this.initialFreeMonths, 'days').toDate();
  }

  get freeDaysRemaining(): number {
    const today = moment();
    const momentPaidThrough = this.datePaidThrough ? moment(this.datePaidThrough) : null;
    const isActive = momentPaidThrough && momentPaidThrough.isAfter(today);
    const hasFreeMonth = this.initialFreeMonths > 0;
    const daysUntilExpiration = moment(this.freeMembershipExpiration).diff(today, 'days');

    if (isActive && hasFreeMonth && daysUntilExpiration > 0) {
      return daysUntilExpiration;
    }
    return 0;
  }
}

export interface MemberSubscriptionCreateParams {
  tier: string;
}
