import { Component, Input } from '@angular/core';
import { ModalService, YshModals } from '../../services/modal/modal.service';

@Component({
  selector: 'ysh-cutoff-disclaimer-modal',
  templateUrl: 'cutoff-disclaimer-modal.html',
  styleUrls: ['./cutoff-disclaimer-modal.scss'],
})
export class CutoffDisclaimerModalComponent {
  static PAGE_NAME = YshModals.CutoffDisclaimerModalComponent;

  @Input() color: string;
  @Input() name: string;
  @Input() cutOff: string;

  constructor(private modalService: ModalService) {}

  dismiss() {
    this.modalService.dismissModal();
  }
}
