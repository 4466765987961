import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  SocialSharingEvent,
  SocialSharingParams,
  SocialSharingService,
} from 'src/app/services/social-sharing/social-sharing.service';
import { take, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { IonSlides } from '@ionic/angular';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Photo } from 'src/app/models/photo';
import { PhotoStoryService } from 'src/app/services/photo-story/photo-story.service';
import { ReferralService } from '../../../services/api/referral/referral.service';
import { Subject } from 'rxjs';
import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-photo-story',
  templateUrl: './photo-story.page.html',
  styleUrls: ['./photo-story.page.scss'],
})
export class PhotoStoryPage implements AfterViewInit, OnDestroy {
  @ViewChild('slides', { static: true }) slides: IonSlides;
  photoStory: Photo[] = [];
  selectedVehicle: Nullable<Vehicle>;
  inviteCode: Nullable<string>;
  viewedPhotos: { [key: string]: string };
  activeIndex = 0;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private loadingAlert: LoadingAlertService,
    private storyService: PhotoStoryService,
    private vehicleService: VehiclesService,
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private referralService: ReferralService,
    private socialSharingService: SocialSharingService,
    private cdr: ChangeDetectorRef
  ) {}

  ionViewWillEnter() {
    this.slides.update();
  }

  // life-cycle hooks
  ngAfterViewInit() {
    this.analytics.trackView('Photo Story Page');
    this.getInviteCode();
    this.slides.update();
    this.slides.lockSwipes(true);
    this.registerSubscriptions();
    this.slides.options = {
      initialSlide: this.activeIndex,
    };
    this.storyService.getViewedPhotos().then((list) => {
      this.viewedPhotos = list || {};
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private registerSubscriptions() {
    this.vehicleService.selectedVehicle$.pipe(takeUntil(this.unsubscribe)).subscribe((vehicle) => {
      this.selectedVehicle = vehicle;
    });

    this.storyService.photoStoryForSelectedVehicle$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((story) => {
        this.photoStory = story;
        this.cdr.detectChanges();
      });
  }

  // Actions

  async didTapSlide($event: CustomEvent) {
    this.markPhotoAsViewed();
    $event.preventDefault();
    $event.stopPropagation();
    if (await this.slides.isEnd()) {
      setTimeout(() => {
        this.saveViewedPhotos();
        this.dismissStory();
      }, 100);
    }
    await this.slides.lockSwipes(false);
    await this.slides.slideNext(0);
    await this.slides.lockSwipes(true);
  }

  markPhotoAsViewed() {
    // mark photo viewed in the list
    if (!this.photoStory[this.activeIndex]) {
      return;
    }
    const uid = this.photoStory[this.activeIndex].uid;
    if (!this.viewedPhotos[uid]) {
      this.viewedPhotos[uid] = 'viewed';
    }
  }

  didTapSetAsVehicleImage() {
    const imageToSet = this.photoStory[this.activeIndex];
    if (!imageToSet || !this.selectedVehicle) {
      return;
    }
    this.vehicleService.updateVehicle(this.selectedVehicle, {
      imageUrl: imageToSet.url,
    });
    this.loadingAlert.showToastConfirmation('Vehicle Image updated');
  }

  didTapShare() {
    const params: SocialSharingParams = {
      message: this.getShareMessage(),
      fileToShare: this.getShareImage(),
      subject: 'I love my Yoshi',
      event: SocialSharingEvent.PhotoStory,
      hasPrefilledMsg: true,
    };
    this.socialSharingService.presentSocialSharingSheet(params);
  }

  didTapDismiss() {
    this.markPhotoAsViewed();
    this.saveViewedPhotos();
    this.dismissStory();
  }

  dismissStory() {
    this.modalService.dismissModal();
  }

  // Data

  private getInviteCode() {
    this.referralService.preferredProgram$.pipe(take(1)).subscribe((program) => {
      this.inviteCode = program?.inviteCode;
    });
  }

  private saveViewedPhotos() {
    if (this.slides && this.photoStory.length) {
      this.storyService.saveViewedPhotos(this.viewedPhotos);
    }
  }

  // Helpers

  private getShareImage(): string | undefined {
    if (this.photoStory[this.activeIndex]) {
      return this.photoStory[this.activeIndex].url;
    }
  }

  private getShareMessage(): string {
    let shareMessage =
      'I got my gas delivered by @startyoshi! They match their prices to the lowest top tier station within 2 miles.';
    if (this.inviteCode && this.inviteCode.length) {
      shareMessage =
        shareMessage +
        ' You can also use my code ' +
        this.inviteCode +
        ' for an additional credit: www.startyoshi.com/redeem';
    }
    return shareMessage;
  }

  async updateActiveIndex() {
    if (!this.slides) {
      return;
    }
    this.activeIndex = await this.slides.getActiveIndex();
  }
}
