import { ApiService } from '../api/api.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Photo } from 'src/app/models/photo';
import { Storage } from '@ionic/storage';
import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesService } from '../api/vehicles/vehicles.service';
import { map } from 'rxjs/operators';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PhotoStoryService {
  hasNewPhotos$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  photoStoryForSelectedVehicle$: BehaviorSubject<Photo[]> = new BehaviorSubject([]);
  selectedVehicle: Nullable<Vehicle>;
  seenPhotosList = {};

  constructor(
    private api: ApiService,
    private vehicleService: VehiclesService,
    private storage: Storage
  ) {
    this.subscribeToSelectedVehicle();
  }

  getViewedPhotos() {
    let viewedPhotosPromise;
    if (this.selectedVehicle) {
      viewedPhotosPromise = this.storage.get(
        PhotoStoryKeys.viewedPhotos + this.selectedVehicle.uid
      );
    } else {
      viewedPhotosPromise = new Promise((resolve, reject) => {
        reject('Selected vehicle is undefined');
      });
    }
    return viewedPhotosPromise;
  }

  private subscribeToSelectedVehicle() {
    this.vehicleService.selectedVehicle$.subscribe((vehicle) => {
      this.selectedVehicle = vehicle;
      if (this.selectedVehicle) {
        this.getPhotoStory();
      } else {
        this.photoStoryForSelectedVehicle$.next([]);
        this.hasNewPhotos$.next(false);
      }
    });
  }

  private getPhotoStory() {
    if (!this.selectedVehicle) {
      return;
    }
    return this.api
      .call({
        method: 'GET',
        url: `/vehicles/${this.selectedVehicle.uid}/photos`,
      })
      .pipe(map((photos) => photos.map((photo) => new Photo(photo))))
      .subscribe((story) => {
        story = this.sortByTakenAt(story);
        this.photoStoryForSelectedVehicle$.next(story || []);
        this.updateNewPhotosStatus();
      });
  }

  private sortByTakenAt(story: Photo[]) {
    // most recent first
    return [...story].sort((a, b) => {
      return moment(a.takenAt).isBefore(b.takenAt) ? 1 : -1;
    });
  }

  saveViewedPhotos(list) {
    if (!this.selectedVehicle) {
      return;
    }
    this.storage
      .set(PhotoStoryKeys.viewedPhotos + this.selectedVehicle.uid, list)
      .then(() => this.updateNewPhotosStatus())
      .catch((error) => console.log('Error saving seenPhotosList ', error));
  }

  private updateNewPhotosStatus() {
    this.getViewedPhotos()
      .then((list) => {
        this.seenPhotosList = list || {};
        const story = this.photoStoryForSelectedVehicle$.value;
        const filter = (photo: Photo) => !this.seenPhotosList[photo.uid];
        const hasNewPhotos = story && story.filter(filter).length > 0;
        this.hasNewPhotos$.next(hasNewPhotos);
      })
      .catch((error) => console.log('Error retrieving seenPhotosList ', error));
  }
}
enum PhotoStoryKeys {
  viewedPhotos = 'viewed-photos-',
}
