import {
  ActionSheetController,
  AlertController,
  LoadingController,
  Platform,
  ToastController,
} from '@ionic/angular';

import { Injectable } from '@angular/core';
import { Toast } from '@ionic-native/toast/ngx';

@Injectable({
  providedIn: 'root',
})
export class LoadingAlertService {
  private loader: Nullable<HTMLIonLoadingElement>;

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private platform: Platform,
    private nativeToast: Toast,
    private actionSheetCtrl: ActionSheetController
  ) {}

  showLoader() {
    this.showLoaderWithText(null);
  }

  async showLoaderWithText(text) {
    if (this.loader) {
      this.loader.dismiss();
    }
    this.loader = await this.loadingCtrl.create();
    await this.loader.present();
  }

  async dismissLoader() {
    if (this.loader) {
      this.loader.dismiss();
      this.loader = null;
    }
  }

  async presentAlert(header, subHeader) {
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      buttons: ['Ok'],
    });
    alert.present();
  }

  showToastAlert(message) {
    this.showToast(message, 'alert');
  }

  showToastConfirmation(message) {
    this.showToast(message, 'confirm');
  }

  async showToast(message, cssClass) {
    const duration = 3000;
    if (this.platform.is('cordova') && this.nativeToast) {
      const color = cssClass === 'confirm' ? '#02D7AB' : '#f53d3d';
      const styles = {
        opacity: 1,
        textSize: 15,
        cornerRadius: 10,
        backgroundColor: color,
      };
      this.nativeToast
        .showWithOptions({
          message,
          position: 'top',
          duration,
          styling: styles,
        })
        .subscribe(() => {});
    } else {
      const toast = await this.toastCtrl.create({
        message,
        position: 'top',
        cssClass,
        duration,
      });
      toast.present();
    }
  }

  async showConfirmationDialog(
    header: string,
    buttonText: string,
    callback: () => void,
    cancelCallback?: () => void
  ) {
    const actionSheet = await this.actionSheetCtrl.create({
      header,
      buttons: [
        {
          text: buttonText,
          handler: () => {
            callback();
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
            if (cancelCallback) {
              cancelCallback();
            }
          },
        },
      ],
    });
    actionSheet.present();
  }
}
