import { CollectionActions, NamedAction } from './actions';
import { CollectionState, createCollectionState } from './collection';

import { BaseEntity } from 'src/app/models/base';
import { Collection } from './state';
import { PageInfo } from 'src/app/services/api/api.service';

/** Factory to create a reducer for a named list . */
export function ReducerForCollection(collection: Collection) {
  return (state, action: NamedAction) => {
    const name = action.collectionName;
    const isInitializationCall = state === undefined;
    if (name !== collection.classId && !isInitializationCall) {
      return state;
    }

    return CollectionReducer(state, action);
  };
}

export function ClearAllMetaReducer(reducer) {
  return (state, action) => {
    return reducer(action.type === CollectionActions.CLEAR_ALL ? undefined : state, action);
  };
}

/**
 * Generic reducer for a list of BaseEntity objects with a populated uid field.
 * @param state - Initial immutable state
 * @param action - Action to perform
 * @returns CollectionState - New state
 */
export function CollectionReducer(
  state = createCollectionState(),
  action: NamedAction
): CollectionState<any> {
  switch (action.type) {
    case CollectionActions.SET: {
      const entities: BaseEntity[] = action.payload;
      const newState = {
        loading: false,
        loaded: true,
        ids: entities.map((entity) => entity.uid),
        total_pages: 0,
        total_result: 0,
        entities: entities.reduce((object, entity) => {
          object[entity.uid] = entity;
          return object;
        }, {}),
      };
      return newState;
    }
    case CollectionActions.SET_WITH_PAGE_INFO: {
      const entities: BaseEntity[] = action.payload.items;
      const pageInfo: PageInfo = action.payload.pageInfo;
      const newState = {
        loading: false,
        loaded: true,
        ids: entities.map((entity) => entity.uid),
        total_pages: pageInfo ? pageInfo.total_pages : 1,
        total_result: pageInfo ? pageInfo.total_entries : entities.length,
        entities: entities.reduce((object, entity) => {
          object[entity.uid] = entity;
          return object;
        }, {}),
      };
      return newState;
    }

    case CollectionActions.APPEND: {
      const entities: BaseEntity[] = action.payload;
      const newEntities = { ...state.entities };
      const newState = {
        ...state,
        loading: false,
        loaded: true,
        ids: state.ids.concat(entities.map((entity) => entity.uid)),
        entities: entities.reduce((object, entity) => {
          object[entity.uid] = entity;
          return object;
        }, newEntities),
      };
      return newState;
    }

    case CollectionActions.ADD: {
      const entity: BaseEntity = action.payload;
      const ids = [...state.ids];
      const entities = { ...state.entities };

      // only add to store if new uid
      if (!ids.includes(action.payload.uid)) {
        ids.push(entity.uid);
        entities[action.payload.uid] = entity;
      }
      return { ...state, ids, entities };
    }
    case CollectionActions.ADD_MANY: {
      const ids = [...state.ids];
      const entities = { ...state.entities };
      // only add to store if new uid
      const newEntities: BaseEntity[] = action.payload.filter(
        (entity) => !ids.includes(entity.uid)
      );
      const newState = {
        ...state,
        loading: false,
        loaded: true,
        ids: ids.concat(newEntities.map((entity) => entity.uid)),
        entities: newEntities.reduce((object, entity) => {
          object[entity.uid] = entity;
          return object;
        }, entities),
      };
      return newState;
    }
    case CollectionActions.INSERT: {
      const entity: BaseEntity = action.payload;
      const ids = [...state.ids];
      const entities = { ...state.entities };
      ids.unshift(entity.uid);
      entities[action.payload.uid] = entity;
      return { ...state, ids, entities };
    }
    case CollectionActions.UPDATE: {
      const entity: BaseEntity = action.payload;
      const entities = { ...state.entities };
      if (entities[action.payload.uid]) {
        entities[action.payload.uid] = entity;
      }
      return { ...state, entities };
    }
    case CollectionActions.UPDATE_MANY: {
      const updatedEntities: BaseEntity[] = action.payload;
      const entities = { ...state.entities };
      updatedEntities.forEach((updatedEntity) => {
        if (entities[updatedEntity.uid]) {
          entities[updatedEntity.uid] = updatedEntity;
        }
      });
      return { ...state, entities };
    }
    case CollectionActions.SET_LOADING: {
      const loading: boolean = action.payload;
      const newState = { ...state };
      newState.loading = loading;
      return newState;
    }
    case CollectionActions.DELETE: {
      const entity: BaseEntity = action.payload;
      const newState = { ...state };
      newState.ids = newState.ids.filter((id) => id !== entity.uid);
      const entities = { ...newState.entities };
      delete entities[entity.uid];
      return { ...newState, entities };
    }
    default:
      return state;
  }
}
