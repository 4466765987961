import { Component, Input, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Day } from 'src/app/models/day';
import { FlowPage } from '../../../flow-director';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Service } from 'src/app/models/service';
import { Shift } from 'src/app/models/shift';
import { UserAddress } from 'src/app/models/user-address';
import moment from 'moment';

export interface TimeWindowSelectorPageProps {
  day: Day;
  service: Service;
  cutoffTime?: number;
  selectedShift?: Shift;
  selectedWindow?: RequestedWindow;
  isModal: boolean;
  onAddressChanged: (address: UserAddress) => void;
}

export interface TimeWindowSelectorPageForm {
  shift?: Shift;
  window?: RequestedWindow;
}

interface SliderLabelInfo {
  lowerLabel: string;
  upperLabel: string;
  lowerx: string;
  upperx: string;
}

@Component({
  selector: 'ysh-time-window-selector',
  templateUrl: './time-window-selector.page.html',
  styleUrls: ['./time-window-selector.page.scss'],
})
export class TimeWindowSelectorPage implements OnInit, FlowPage {
  @Input() onComplete: (route: Shift, requestedWindow: RequestedWindow) => void;
  @Input() onDismiss: () => void;

  props: TimeWindowSelectorPageProps;
  form: TimeWindowSelectorPageForm = {};

  validTimeWindow = true;
  sliderLabelInfo: SliderLabelInfo = {
    lowerLabel: '',
    upperLabel: '',
    lowerx: '0%',
    upperx: '100%',
  };

  constructor(private analytics: AnalyticsService, private modalService: ModalService) {}

  ngOnInit() {
    this.form.shift = this.props.selectedShift;
    this.form.window = this.props.selectedWindow;

    if (!this.form.shift) {
      this.setDefaultShift();
    }
    if (!this.form.window && this.form.shift) {
      this.setDefaultWindowForShift(this.form.shift);
    }

    this.analytics.trackView('TimeWindowSelectorPage');

    // wait to ensure the slider has rendered
    setTimeout(() => this.reflectUserSelectedWindow(), 200);
  }

  didSelectWindow() {
    if (this.onComplete && this.form.shift && this.form.window) {
      this.onComplete(this.form.shift, {
        upper: this.computeValidHour(this.form.window.upper),
        lower: this.computeValidHour(this.form.window.lower),
      });
    }
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didUpdateAddress(address: UserAddress) {
    this.props.onAddressChanged(address);
  }

  sliderValueDidChange() {
    this.reflectUserSelectedWindow();
    this.validateTimeWindow();
  }

  didSelectShift(shift: Shift) {
    if (!shift || shift === this.form.shift) {
      return;
    }
    this.form.shift = shift;
    this.setDefaultWindowForShift(shift);
    this.validateTimeWindow();
    setTimeout(() => this.reflectUserSelectedWindow(), 200);
  }

  // helpers

  setDefaultShift() {
    this.form.shift = this.props.day.defaultShift();
  }

  setDefaultWindowForShift(shift: Shift) {
    this.form.window = {
      lower: shift.startTime.hour,
      upper: this.computeUpperBound(shift.startTime.hour, shift.endTime.hour),
    };
  }

  reflectUserSelectedWindow() {
    if (this.form.window && this.form.shift) {
      const lowerValue = this.form.window.lower;
      const upperValue = this.form.window.upper;
      const min = this.form.shift.startTime.hour;
      const max = this.computeUpperBound(
        this.form.shift.startTime.hour,
        this.form.shift.endTime.hour
      );
      const lowerX = 100 * ((lowerValue - min) / (max - min));
      const upperX = 100 * ((upperValue - min) / (max - min));
      this.sliderLabelInfo.lowerLabel = this.formattedHour(lowerValue);
      this.sliderLabelInfo.upperLabel = this.formattedHour(upperValue);
      this.sliderLabelInfo.lowerx = `${lowerX}%`;
      this.sliderLabelInfo.upperx = `${upperX}%`;
    }
  }

  // helpers
  formattedHour(hour) {
    if (!hour && hour !== 0) {
      return 'N/A';
    }
    return moment(`${this.computeValidHour(hour)}`, ['HH']).format('hA');
  }

  validateTimeWindow() {
    const currentHour = moment().hour();
    const window = this.form.window!;
    const lowerBound = this.props.day.isToday ? Math.max(currentHour, window.lower) : window.lower;
    this.validTimeWindow =
      window.upper - lowerBound >= this.props.day.minimumWindowForShift(this.form.shift!);
  }

  computeUpperBound(lower: number, upper: number) {
    return lower > upper ? upper + 24 : upper;
  }

  computeValidHour(hour: number) {
    return hour >= 24 ? hour - 24 : hour;
  }
}

export interface RequestedWindow {
  upper: number;
  lower: number;
}
