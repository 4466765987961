import 'reflect-metadata';

import * as humps from 'humps';

import { v4 as uuid } from 'uuid';

type TransformerMap = Record<string, (value: any) => any>;

export type BaseEntityType<T extends BaseEntity> = { new (...args: any[]): T; classId: string };

export const TRANSFORMERS_KEY = Symbol('transformers');
export const DERIVERS_KEY = Symbol('derivers');
export const DEFAULTS_KEY = Symbol('default');

/**
 * Base class for Yoshi API models
 */
export abstract class BaseEntity {
  uid: string;
  json: Record<string, unknown>;

  // Helps identify subclasses after minification
  private static _classId: string;
  static get classId(): string {
    if (!this.hasOwnProperty('_classId')) {
      var isMinified = !/BaseEntity/.test(BaseEntity.name);
      this._classId = isMinified ? uuid() : this.name;
    }
    return this._classId;
  }

  constructor(json: Record<string, unknown>) {
    const camelizedJson = humps.camelizeKeys(json);
    this.json = camelizedJson;
    const transformed = this.transformValues(camelizedJson);
    this.assignProperties(transformed);
  }

  private transformValues(json: Record<string, unknown>) {
    const transformed = { ...json };
    const transformers: TransformerMap = Reflect.getMetadata(TRANSFORMERS_KEY, this);
    for (const key in transformers) {
      const value = transformed[key];
      const transformer = transformers[key];
      transformed[key] = value != null ? transformer(value) : null;
    }

    const derivers: TransformerMap = Reflect.getMetadata(DERIVERS_KEY, this);
    for (const key in derivers) {
      const transformer = derivers[key];
      transformed[key] = json && transformer(json);
    }

    return transformed;
  }

  private assignProperties(transformed: any) {
    for (let key in transformed) {
      if (this.isKeyAssignable(key)) {
        this[key] = transformed[key];
      }
    }
    this.assignDefaultValues();
    this.uid = this.uid || uuid();
  }

  private isKeyAssignable(key: string) {
    // Don't overwrite methods or getters
    const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this), key);
    return !descriptor || (!descriptor.get && typeof descriptor.value !== 'function');
  }

  private assignDefaultValues() {
    const defaultValues = Reflect.getMetadata(DEFAULTS_KEY, this);
    for (let key in defaultValues) {
      if (this[key] == null) {
        this[key] = defaultValues[key];
      }
    }
  }
}
