import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

import { ServiceSheetPage } from './service-sheet.page';
import { ServiceSheetPageRoutingModule } from './service-sheet-routing.module';
import { ServicesSharedModule } from '../components/services-components.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ServiceSheetPageRoutingModule,
    ServicesSharedModule,
    ComponentsModule,
    PipesModule,
  ],
  declarations: [ServiceSheetPage],
})
export class ServiceSheetPageModule {}
