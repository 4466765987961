import { BaseEntity, MemoizeGetters } from './base';

@MemoizeGetters
export class FuelType extends BaseEntity {
  fuelType: string;
  fuelGrade?: string;

  get description() {
    return this.fuelGrade ? this.fuelGrade + ' ' + this.fuelType : this.fuelType;
  }

  get shortDescription() {
    return this.fuelGrade || this.fuelType;
  }
}
