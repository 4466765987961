import { ActionSheetController, IonInput, ModalController } from '@ionic/angular';
import { CameraService, ImgSourceType } from 'src/app/services/camera/camera.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Vehicle, VehicleParams } from 'src/app/models/vehicle';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FuelType } from 'src/app/models/fuel-type';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-edit-vehicle',
  templateUrl: './edit-vehicle.page.html',
  styleUrls: ['./edit-vehicle.page.scss'],
})
export class EditVehiclePage implements OnInit {
  readonly DEFAULT_FUEL_GUAGE_THRESHOLD = 2;
  readonly FUEL_GUAGE_UI_SCALE_FACTOR = 5;

  @ViewChild('nicknameInputField') nicknameInputField: IonInput;
  @ViewChild('licenseInputField') licenseInputField: IonInput;
  @ViewChild('vinInputField') vinInputField: IonInput;

  @Input() vehicle: Vehicle;
  @Input() onSave: () => void;
  @Input() modal = false;
  @Input() missingField: string;

  dirty = false;
  vehicleImg: string;

  fuelTypes: FuelType[];
  carMakes = [];
  carModels = [];
  colors = Vehicle.availableColors;

  selectedMake: any;
  selectedModel: any;
  params: VehicleParams = {};

  fuel_gauge_threshold: number;
  // isOnstar = false;
  iconUrl;

  constructor(
    private vehicleService: VehiclesService,
    private loadingAlertCtrl: LoadingAlertService,
    private analytics: AnalyticsService,
    private actionSheetCtrl: ActionSheetController,
    private cameraService: CameraService,
    private modalCtrl: ModalController
  ) {
    this.loadFuelType();
    this.loadMakes();
  }

  ngOnInit() {
    if (this.vehicle.onstar) {
      this.iconUrl = `assets/images/gm-icon-${this.vehicle.vehicleMake.make}@2x.png`;
    } else if (this.vehicle.toyotaConnect) {
      this.iconUrl = 'assets/images/icon-toyota.svg';
    }
    this.setInitialParamsForVehicle(this.vehicle);
    if (this.vehicle.oemConnected) {
      this.fuel_gauge_threshold = this.vehicle.fuelGaugeThreshold
        ? this.vehicle.fuelGaugeThreshold * this.FUEL_GUAGE_UI_SCALE_FACTOR
        : this.DEFAULT_FUEL_GUAGE_THRESHOLD;
    }
    this.loadModelsForMake(this.vehicle.vehicleMake.uid);
    this.analytics.trackView('EditVehiclePage');
    if (this.missingField) {
      setTimeout(() => {
        this.setFocusOnMissingField();
      }, 1500);
    }
  }

  private setFocusOnMissingField() {
    if (this.missingField === 'nickname') {
      this.nicknameInputField.setFocus();
    } else if (this.missingField === 'license') {
      this.licenseInputField.setFocus();
    } else if (this.missingField === 'vin') {
      this.vinInputField.setFocus();
    }
  }

  // Actions

  didTapSave() {
    this.saveVehicle(true);
  }

  didTapDismiss() {
    this.dirty ? this.showUnsavedPrompt() : this.dismiss();
  }

  async didTapAvatar() {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Take Picture',
          handler: () => this.didSelectImgSource(ImgSourceType.Camera),
        },
        {
          text: 'Choose Existing',
          handler: () => this.didSelectImgSource(ImgSourceType.Gallery),
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });
    actionSheet.present();
  }

  didSelectImgSource(option: ImgSourceType) {
    this.cameraService.accessCamera(option, (imgPath) => {
      if (imgPath) {
        this.dirty = true;
        this.vehicleImg = imgPath;
      }
    });
  }

  didChangeNickname() {
    this.dirty = true;
  }

  didSelectColor() {
    this.dirty = true;
  }

  didChangeMake(make) {
    this.selectedMake = make.uid;
    this.loadModelsForMake(make.uid);
  }

  didChangeModel(model) {
    this.selectedModel = model.uid;
    this.dirty = true;
  }

  didChangeFuelType() {
    this.dirty = true;
  }

  didChangeLicense() {
    this.dirty = true;
  }

  didChangeVin() {
    this.dirty = true;
  }

  didChangeFuelGaugeThreshold() {
    this.dirty = true;
  }

  didTapDelete() {
    this.loadingAlertCtrl.showConfirmationDialog(
      'Are you sure you want to delete your vehicle? Deleting this vehicle will also cancel any services scheduled for this vehicle.',
      'Yes, cancel',
      () => this.deleteVehicle()
    );
  }

  // Data

  loadFuelType() {
    this.vehicleService.getFuelsTypes().subscribe(
      (data) => {
        this.fuelTypes = data;
      },
      (error) => {
        console.log('error loading fuel types', error);
      }
    );
  }

  loadMakes() {
    this.vehicleService.getCarMakes().subscribe(
      (data) => {
        this.carMakes = data;
      },
      (error) => {
        console.log('error loading car makes', error);
      }
    );
  }

  loadModelsForMake(makeUID) {
    // this.loadingAlertCtrl.showLoader();
    this.vehicleService
      .getCarModels(makeUID)
      .pipe(
        finalize(() => {
          this.loadingAlertCtrl.dismissLoader();
        })
      )
      .subscribe(
        (data) => {
          this.carModels = data.vehicle_models;
        },
        (error) => {
          console.log('error loading car models', error);
        }
      );
  }

  saveVehicle(dismissOnSave?: boolean) {
    this.loadingAlertCtrl.showLoaderWithText('Saving');
    // REFACTOR: Prevents a 500 server error, remove when possible
    if (!this.params.nickname) {
      delete this.params.nickname;
    }

    if (this.vehicle.oemConnected) {
      this.params.fuelGaugeThreshold = this.fuel_gauge_threshold / this.FUEL_GUAGE_UI_SCALE_FACTOR;
    }

    if (this.vehicleImg) {
      this.saveVehicleImg();
    }

    this.params.vehicleModelUid = this.selectedModel;
    this.vehicleService
      .updateVehicle(this.vehicle, this.params)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(
        (vehicle) => {
          this.vehicle = vehicle;
          this.setInitialParamsForVehicle(vehicle);

          if (this.vehicle.oemConnected) {
            this.loadingAlertCtrl.showToastConfirmation(
              "Thanks for updating your settings. We will alert you when your vehicle's fuel level falls below the tank level you have set."
            );
          } else {
            this.loadingAlertCtrl.showToastConfirmation('Vehicle Updated');
          }

          if (dismissOnSave) {
            this.dismiss();
          }
        },
        (error) => {
          this.loadingAlertCtrl.showToastAlert('Error saving vehicle');
        }
      );
  }

  saveVehicleImg() {
    this.vehicleService.updateVehicleImage(this.vehicle, this.vehicleImg).subscribe(() => {
      this.vehicleService.loadSelectedVehicle();
    });
  }

  deleteVehicle() {
    this.loadingAlertCtrl.showLoaderWithText('Deleting Vehicle');
    this.vehicleService
      .deleteVehicle(this.vehicle)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(
        (data) => {
          // this.viewCtrl.dismiss();
          this.loadingAlertCtrl.showToastConfirmation('Your vehicle has been deleted');
        },
        (error) => {
          this.loadingAlertCtrl.showToastAlert('Error deleting vehicle');
        }
      );
  }

  setInitialParamsForVehicle(vehicle: Vehicle) {
    this.params = {};
    if (this.vehicle.nickname) {
      this.params.nickname = this.vehicle.nickname;
    }
    this.params.license = this.vehicle.license;
    this.params.vin = this.vehicle.vin;
    this.params.color = this.vehicle.color;
    if (this.vehicle.fuelType) {
      this.params.fuelTypeUid = this.vehicle.fuelType.uid;
    }
    this.selectedMake = vehicle.vehicleMake.uid;
    this.selectedModel = this.vehicle.vehicleModel.uid;
  }

  // Validation

  validates(): boolean {
    return !this.params.vin || this.vehicleService.validateVin(this.params.vin);
  }

  // helpers

  dismiss() {
    this.modalCtrl.dismiss();
  }

  async showUnsavedPrompt() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Do you want to save the changes you made to your profile?',
      buttons: [
        {
          text: 'Save Changes',
          handler: () => {
            this.saveVehicle(true);
          },
        },
        {
          text: 'Discard',
          role: 'destructive',
          handler: () => {
            this.dismiss();
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });
    actionSheet.present();
  }
}
