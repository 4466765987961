import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { UpsellFlowControllerPage } from './upsell-flow-controller.page';
import { UpsellFlowControllerPageRoutingModule } from './upsell-flow-controller-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, UpsellFlowControllerPageRoutingModule],
  declarations: [UpsellFlowControllerPage],
})
export class UpsellFlowControllerPageModule {}
