import { Component, Input, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { MembershipService } from 'src/app/services/api/membership/membership.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-membership-discount-offer',
  templateUrl: './membership-discount-offer.page.html',
  styleUrls: ['./membership-discount-offer.page.scss'],
})
export class MembershipDiscountOfferPage implements OnInit, FlowPage {
  @Input() onComplete: (accepted: boolean) => void;
  @Input() onDismiss: () => void;
  discount: number;
  promoApplied: boolean;
  private readonly promoCode = 'MEMBERSHIPSAVEDISCOUNT10';
  constructor(
    private analytics: AnalyticsService,
    private loadingAlertCtrl: LoadingAlertService,
    private membershipService: MembershipService
  ) {
    this.discount = MembershipService.MEMBERSHIP_CANCELLATION_DISCOUNT_VALUE;
  }

  ngOnInit() {
    this.analytics.trackView('MembershipDiscountOfferPage');
  }

  didDeclineOffer() {
    this.onComplete(false);
  }

  didAcceptOffer() {
    this.applyDiscount();
  }

  applyDiscount() {
    this.loadingAlertCtrl.showLoaderWithText('Applying Discount');
    this.membershipService
      .applyMembershipDiscount(this.promoCode)
      .pipe(
        finalize(() => {
          this.loadingAlertCtrl.dismissLoader();
        })
      )
      .subscribe(
        () => {
          this.promoApplied = true;
          this.loadingAlertCtrl.showToastConfirmation('Discount Applied! ');
        },
        (error) => {
          this.promoApplied = false;
          this.loadingAlertCtrl.showToastAlert(`Error applying code: ${error}`);
        }
      );
  }

  didTapFinish() {
    this.onDismiss();
  }

  didTapDismiss() {
    this.onDismiss();
  }
}
