import { BehaviorSubject, Observable } from 'rxjs';
import { LDClient, LDFlagSet, LDFlagValue, LDUser, initialize } from 'launchdarkly-js-client-sdk';

import { EnvironmentService } from '../environment/environment.service';
import { Injectable } from '@angular/core';
import { ServerEnvironment } from './../environment/environment.service';
import { User } from 'src/app/models/user';
import { UserService } from '../api/user/user.service';
import _ from 'underscore';
import { map } from 'rxjs/operators';

export enum Flag {
  AllowApplePay = 'allow-apple-pay',
  AllowDefaultReferrals = 'allow-default-referrals',
  AllowInviteAll = 'allow-invite-all',
  AllowMembershipSkip = 'allow-membership-skip',
  AllowPaymentSkip = 'allow-payment-skip',
  HideOilChange = 'hide-oil-change',
  ShowMembershipSelect = 'show-membership-select',
  ShowSuggestedInvites = 'show-suggested-invites',
  ToyotaConnectAuthFlow = 'toyota-connect-auth-flow',
  ShowWebflowAlternateLayout = 'ShowWebflowAlternateLayout',
}

enum LDEvent {
  Change = 'change',
  Ready = 'ready',
}

const DEFAULT_FLAGS: { [key in Flag]: LDFlagValue } = {
  [Flag.AllowApplePay]: false,
  [Flag.AllowDefaultReferrals]: false,
  [Flag.AllowInviteAll]: false,
  [Flag.AllowMembershipSkip]: false,
  [Flag.AllowPaymentSkip]: true,
  [Flag.HideOilChange]: false,
  [Flag.ShowMembershipSelect]: false,
  [Flag.ToyotaConnectAuthFlow]: false,
  [Flag.ShowSuggestedInvites]: false,
  [Flag.ShowWebflowAlternateLayout]: false,
};

const ANONYMOUS_USER: LDUser = { key: 'anon', anonymous: true };

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  env: ServerEnvironment;

  private ldClient: LDClient;
  private flags$: BehaviorSubject<LDFlagSet> = new BehaviorSubject(DEFAULT_FLAGS);

  constructor(private userService: UserService, private environment: EnvironmentService) {
    this.initialize();
  }

  initialize() {
    this.environment.serverEnvironment$.subscribe((env) => {
      if (this.env === env) {
        return;
      }
      this.env = env;
      if (this.ldClient) {
        this.ldClient.close();
      }
      this.createClient();
    });
  }

  createClient() {
    const currentUser = this.userService.currentUser$.value;
    const ldUser = currentUser ? this.createLDUser(currentUser) : ANONYMOUS_USER;
    this.ldClient = initialize(this.environment.serverVars().launchDarklyKey, ldUser, {
      sendEventsOnlyForVariation: true,
    });
    this.userService.currentUser$.subscribe((user) => this.userDidChange(user));
    this.subscribeToUpdates();
  }

  trackEvent(event: string) {
    this.ldClient.track(event);
  }

  flag$(flag: Flag): Observable<boolean> {
    this.ldClient.variation(flag);
    return this.flags$.pipe(map((flags) => flags[flag]));
  }

  private subscribeToUpdates() {
    this.ldClient.on(LDEvent.Change, (flags: LDFlagSet) => {
      const existingFlags = this.flags$.value;
      for (const key of Object.keys(flags)) {
        existingFlags[key] = flags[key].current;
      }
      this.flags$.next(existingFlags);
    });
    this.ldClient.on(LDEvent.Ready, () => {
      this.setFlags();
    });
  }

  private setFlags() {
    this.flags$.next(this.ldClient.allFlags());
  }

  private userDidChange(user: Nullable<User>) {
    if (this.ldClient) {
      this.ldClient.identify(user ? this.createLDUser(user) : ANONYMOUS_USER);
    }
  }

  private createLDUser(user: User): LDUser {
    const createdAt = user.createdAt && user.createdAt.toISOString();

    return {
      key: user.uid,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      anonymous: false,
      custom: {
        admin: user.admin,
        filler: user.filler,
        provider: user.provider,
        converted: user.converted,
        phone: user.phone,
        createdAt,
      },
    };
  }
}
