import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

interface BackHandler {
  uid: string;
  callback: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class BackButtonService {
  list: BackHandler[] = [];

  constructor() {
    this.init();
  }

  init() {
    if (window.location.protocol !== 'file:') {
      window.onpopstate = () => {
        this.pop();
      };
    }
  }

  add(callback: () => void) {
    const uid = uuid();
    history.pushState(null, '', window.location.toString());
    this.list.push({
      uid,
      callback,
    });
    return uid;
  }

  remove(uid: string) {
    const index = this.list.findIndex((value) => value.uid === uid);
    if (index > -1) {
      this.list.splice(index, 1);
    }
  }

  pop() {
    const lastElement = this.list.pop();
    if (lastElement) {
      lastElement.callback();
    }
  }
}
