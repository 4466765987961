import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

import { ApplePayService } from 'src/app/services/apple-pay/apple-pay.service';
import { CreditCardFlowPage } from 'src/app/pages/flows/payment/credit-card-flow/credit-card-flow.page';
import { Platform } from '@ionic/angular';
import { Service } from 'src/app/models/service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface YshPaymentSheetProps {
  outlineInputStyle?: boolean;
  service?: Nullable<Service>;
}

@Component({
  selector: 'ysh-payment-sheet',
  templateUrl: './ysh-payment-sheet.component.html',
  styleUrls: ['./ysh-payment-sheet.component.scss'],
})
export class YshPaymentSheetComponent implements OnDestroy {
  static PAGE_NAME = YshModals.YshPaymentSheetComponent;
  @Input() onComplete: (data: any) => void;
  @Input() props: YshPaymentSheetProps;

  title = 'Choose a payment method';
  canSkipPayment = false;
  applePayAllowed = false;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private featureFlags: FeatureFlagService,
    protected platform: Platform,
    private applePayService: ApplePayService,
    private modalService: ModalService
  ) {
    this.applePayService
      .readyForApplePay()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((ready) => {
        this.applePayAllowed = ready;
      });

    this.featureFlags
      .flag$(Flag.AllowPaymentSkip)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((flag) => {
        this.canSkipPayment = flag;
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async didTapCreditCard() {
    const modal = await this.modalService.openPage({
      component: CreditCardFlowPage,
      componentProps: {
        hideBackButton: true,
        showInDarkTheme: true,
        showDismissBtn: true,
        outlineInputStyle: this.props.outlineInputStyle,
        service: this.props.service,
      },
    });
    const { data } = await modal.onDidDismiss();
    setTimeout(() => this.didFinish(data), 50); // nested modal requires a timeout to dismiss properly
  }

  async didTapApplePay() {
    const paymentSuccess = await this.applePayService.requestPaymentInfo();
    this.didFinish(paymentSuccess);
  }

  didTapLater() {
    this.didFinish(true);
  }

  onDismiss() {
    this.didFinish(false);
  }

  didFinish(done: boolean) {
    this.modalService.dismissModal(done);
  }
}
