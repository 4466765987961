import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'button[ysh-pill-button]',
  templateUrl: './ysh-pill-button.component.html',
  host: {
    '[attr.disabled]': 'disabled || null',
  },
  styleUrls: ['./ysh-pill-button.component.scss'],
  exportAs: 'yshPillButton',
})
export class YshPillButtonComponent {
  @Input() disabled: boolean;
  @Input() selected = false;
  @Input() class = '';
  @HostBinding('class')
  get hostClasses() {
    return `${this.class} ysh-pill-button ${this.selected ? 'selected' : ''}`;
  }
}
