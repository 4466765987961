import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreditCardFlowPageRoutingModule } from './credit-card-flow-routing.module';

import { CreditCardFlowPage } from './credit-card-flow.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    CreditCardFlowPageRoutingModule,
    ComponentsModule,
    TextMaskModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [CreditCardFlowPage],
})
export class CreditCardFlowPageModule {}
