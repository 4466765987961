import { BaseEntity, BaseEntityType, DERIVERS_KEY } from './base';

import { addMetadataToMap } from './util';

/**
 * BaseEntity property decorator to derive a value from an entity's unserialized data
 * @param  {(value:any)=>any} transformer transform function
 */
export const Derive = (deriver: (json: any) => any) => {
  return <T extends BaseEntity, K extends string>(target: T, key: K) => {
    addMetadataToMap(DERIVERS_KEY, key, deriver, target);
  };
};

/**
 * Transform a polymorphic association (eg source, sourceType) to a specified
 * entity as long as the object type matches the expected type
 * @param  {string} expectedType type to compare against
 * @param  {BaseEntityType<T>} entity BaseEntity to serialize to
 * @param  {string} [objKey='source'] key of unserialized polymorphic object
 * @param  {string} [objTypeKey='sourceType'] key of unserialized polymorphic object type
 * @returns (value) => BaseEntity
 */

export function fromPolymorphicAssociation<T extends BaseEntity>(
  expectedType: string,
  entity: BaseEntityType<T>,
  objKey = 'source',
  objTypeKey: string = 'sourceType'
) {
  return (json): T | null => {
    const obj = json[objKey];
    const objType = json[objTypeKey];
    const isCorrectEntity = objType === expectedType;
    return isCorrectEntity && typeof obj === 'object' ? new entity(obj) : null;
  };
}
