import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { MembershipCardComponent } from './membership-card/membership-card';
import { MembershipBenefitsComponent } from './membership-benefits/membership-benefits';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, ComponentsModule],
  declarations: [MembershipCardComponent, MembershipBenefitsComponent],
  exports: [MembershipCardComponent, MembershipBenefitsComponent],
})
export class MembershipComponentsModule {}
