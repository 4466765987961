import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PhotoStoryPageRoutingModule } from './photo-story-routing.module';
import { PhotoStoryPage } from './photo-story.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PhotoStoryPageRoutingModule,
    ComponentsModule
  ],
  declarations: [PhotoStoryPage]
})
export class PhotoStoryPageModule {}
