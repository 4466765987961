import { Component, Input, OnInit } from '@angular/core';

import { Vehicle } from 'src/app/models/vehicle';
import moment from 'moment';

@Component({
  selector: 'ysh-fuel-status',
  templateUrl: './fuel-status.component.html',
  styleUrls: ['./fuel-status.component.scss'],
})
export class FuelStatusComponent implements OnInit {
  @Input() vehicle: Vehicle;
  constructor() {}
  ngOnInit() {}

  get momentsAgo(): string {
    return this.vehicle?.fuelLevel ? moment(this.vehicle.fuelLevel.createdAt).fromNow() : '';
  }

  get fuelStatus(): string {
    if (this.vehicle?.fuelLevel?.level == null) {
      return 'unknown';
    } else if (this.vehicle.fuelLevel?.level >= 0.75) {
      return 'excellent';
    } else if (this.vehicle.fuelLevel.level >= 0.5 && this.vehicle.fuelLevel.level < 0.75) {
      return 'good';
    } else if (this.vehicle.fuelLevel.level >= 0.25 && this.vehicle.fuelLevel.level < 0.5) {
      return 'warn';
    } else {
      return 'danger';
    }
  }
}
