import * as moment from 'moment';

import { BaseEntity, MemoizeGetters, Transform, toDate, toEntity } from './base';

import { ServiceName } from './service-type';
import { Shift } from './shift';

@MemoizeGetters
export class Route extends BaseEntity {
  minimumTimeWindow: number;
  name: string;
  slotScheduling: boolean;

  @Transform(toDate)
  date: Date;

  @Transform(toEntity(Shift))
  shift: Shift;

  serviceTypes: ServiceName[];

  available(cutoffHour = 24) {
    const currentHour = moment().hour();
    const isToday = moment(this.date).isSame(new Date(), 'day');
    const isPast = moment(this.date).isBefore(new Date(), 'day');
    const endTimeHour =
      this.shift.endTime.hour > this.shift.startTime.hour
        ? this.shift.endTime.hour
        : this.shift.endTime.hour + 24;
    const minWindow = endTimeHour - currentHour > this.minimumTimeWindow;
    const pastCutoff = currentHour >= cutoffHour;
    return !isPast && (!isToday || (minWindow && !pastCutoff));
  }
}
