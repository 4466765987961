import { BaseEntity, MemoizeGetters, Transform, toEntity } from '../base';
import { LoyaltyMultiplier } from './multiplier';
import { LoyaltyPerk } from './perk';
import { LoyaltyReward } from './reward';
import { LoyaltyTier } from './tier';

@MemoizeGetters
export class LoyaltyPolicy extends BaseEntity {
  benefitsPeriod: number;
  earningsPeriod: number;
  maxPointsPerPeriod: number;
  name: string;

  @Transform(toEntity(LoyaltyMultiplier))
  multipliers: LoyaltyMultiplier[];

  @Transform(toEntity(LoyaltyTier))
  tiers: LoyaltyTier[];

  @Transform(toSortedPerks)
  perks: LoyaltyPerk[];

  @Transform(toEntity(LoyaltyReward))
  rewards: LoyaltyReward[];

  get valid(): boolean {
    const unmatchedPerk = this.perks.find((perk) => {
      const matchingTier = this.tiers.find((tier) => tier.name === perk.tier);
      return !matchingTier;
    });
    return !unmatchedPerk;
  }
}

function toSortedPerks(json: any[]): LoyaltyPerk[] {
  return json.map((item) => new LoyaltyPerk(item)).sort((a, b) => a.priority - b.priority);
}
