import { AppRoutes } from 'src/app/routes';
import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthGuard } from './../auth/auth.guard';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private authGuard: AuthGuard, private router: Router) {}
  async canActivate() {
    const authenticated = await this.authGuard.isAuthenticated();
    if (authenticated) {
      this.router.navigate([AppRoutes.Tabs]);
    }
    return !authenticated;
  }
}
