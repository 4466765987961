import * as moment from 'moment';

import { BaseEntity, Default, MemoizeGetters, Transform, toDate, toEntity } from './base';

import { ServiceType } from './service-type';

export enum BenefitTarget {
  DeliveryFee = 'DeliveryFee',
  MemberSubscription = 'MemberSubscription',
  GasPricing = 'GasPricing',
  User = 'User',
}

export enum BenefitMethod {
  ValueDiscount = 'value_discount',
  Credit = 'credit',
  PercentageDiscount = 'percentage_discount',
  FixedPrice = 'fixed_price',
}

@MemoizeGetters
export class Benefit extends BaseEntity {
  readonly DATE_FORMAT = 'M/DD/YY';

  private sourceUser: SourceUser;
  private targetVehicle: TargetVehicle;

  target: BenefitTarget;
  applyMethod: BenefitMethod;
  displayName: string;
  promotionalDetails?: BenefitPromotionalDetails;
  isMembershipTrial: boolean;

  @Default(1)
  numberOfVisits: number;

  @Transform(Number)
  value: number;
  note?: string;
  useLimit?: number;
  benefitsPackageTitle: string;
  active: boolean;

  @Default(0)
  perUseAmount?: number;

  @Transform(toDate)
  expiredAt?: Date;

  @Transform(toEntity(ServiceType))
  serviceType: ServiceType;

  @Transform(toEntity(ServiceType))
  @Default([])
  serviceTypes: ServiceType[];

  // Computed Properties

  get vehicleName(): string {
    return this.targetVehicle && this.targetVehicle.name;
  }

  get vehicleUid(): string {
    return this.targetVehicle && this.targetVehicle.uid;
  }

  get sourceUserName(): string {
    return (this.sourceUser && this.sourceUser.name) || 'Yoshi';
  }

  get sourceUserFirstName(): string {
    return this.sourceUserName && this.sourceUserName.split(' ')[0];
  }

  get limitString(): string | undefined {
    if (!this.perUseAmount) {
      return;
    }

    const formatter = Intl.NumberFormat('en-us', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    });
    const perUseFormatted = formatter.format(this.perUseAmount);
    if (this.applyMethod === BenefitMethod.Credit) {
      if (this.numberOfVisits > 1) {
        return `${perUseFormatted} per visit up to ${this.numberOfVisits} visits`;
      }
    } else {
      return `Total savings limited to ${perUseFormatted}`;
    }
  }

  get finePrints(): string[] {
    const finePrints: string[] = [];
    if (this.limitString) {
      finePrints.push(this.limitString);
    }
    if (this.useLimit) {
      finePrints.push(`Use up to ${this.useLimit} times`);
    }
    if (this.expiredAt) {
      finePrints.push(`Expires ${moment(this.expiredAt).format(this.DATE_FORMAT)}`);
    }
    return finePrints;
  }

  /**
   * Checks if a benefit is a FuelPrice
   * @returns {boolean} true if the benefit is a fuel price
   */
  get isFuelPrice(): boolean {
    return this.target == BenefitTarget.GasPricing;
  }

  get fromYoshi(): boolean {
    return this.sourceUserName == 'Yoshi';
  }

  get daysUntilExpiration(): number {
    return moment(this.expiredAt).diff(moment(), 'days') + 1;
  }

  /**
   *
   * @param {Date} date is to compare against the benefit to check if the benefit is valid or expired. There is also
   *  an assumption that if the expiration date is null, we will always return that the date is valid because a null
   *  expiration date corresponds to no expiration date.
   * @returns {boolean} true if benefit is valid (unexpired) or invalid (expired)
   */
  isValidOn(date: Date): boolean {
    if (date == undefined || this.expiredAt == null) {
      return true;
    }
    let endOfDate = moment(date).endOf('day');
    return moment(this.expiredAt).endOf('day') >= endOfDate;
  }

  /**
   *
   * @param {string} uid - vehicle uid to pass in
   * @returns {boolean} true if you can use this benefit with the uid's vehicle
   */
  isValidWithVehicleUid(uid: string): boolean {
    if (!this.vehicleUid) {
      return true;
    }

    return this.vehicleUid === uid;
  }

  appliable(serviceType: ServiceType): boolean {
    const matchesType = Boolean(this.serviceTypes.find((type) => type.uid === serviceType.uid));
    const matchesTarget = this.target !== BenefitTarget.GasPricing || serviceType.isGas;
    return matchesType && matchesTarget;
  }
}

interface SourceUser {
  name: string;
  company_user: boolean;
}

interface TargetVehicle {
  name: string;
  uid: string;
}

interface BenefitPromotionalDetails {
  withUpcomingGasOrder: string;
  withoutUpcomingGasOrder: string;
}
