import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'ysh-notes-for-filler',
  templateUrl: './notes-for-filler.page.html',
  styleUrls: ['./notes-for-filler.page.scss'],
})
export class NotesForFillerPage implements OnInit {
  @ViewChild('inputNote') inputNote: { setFocus: () => void };
  @Input() isModal: boolean;
  @Input() note = '';
  @Input() showBackBtn = false;
  @Input() showDismissBtn = false;

  readonly MAX_NOTE_LENGTH = 40;
  readonly addNoteTitle = 'For a smooth delivery, add a note:';
  readonly exampleNote: string = '"I am on floor 5"';

  constructor(private analytics: AnalyticsService, private modalService: ModalService) {}

  ngOnInit() {
    this.analytics.trackView('NotesForFillerPage');
    setTimeout(() => {
      this.inputNote.setFocus();
    }, 600);
  }

  didTapAddNote() {
    this.dismissNotesPage();
  }

  didTapDismiss() {
    this.dismissNotesPage();
  }

  dismissNotesPage() {
    if (this.isModal) {
      this.modalService.dismissModal({ note: this.note });
    }
  }
}
