import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeTime',
})
export class RelativeTimePipe implements PipeTransform {
  transform(date: Date | null) {
    const momentDate = moment(date).startOf('day');
    const daysAway = momentDate.diff(moment().startOf('day'), 'days');
    switch (true) {
      case daysAway == 0: {
        return 'Today';
      }
      case daysAway == 1: {
        return 'Tomorrow';
      }
      case daysAway > 1 && daysAway < 7: {
        return momentDate.format('dddd');
      }
      case daysAway >= 7 && daysAway < 26: {
        const numWeeks = Math.round(daysAway / 7);
        if (numWeeks == 1) {
          return `In ${numWeeks} week`;
        }
        return `In ${numWeeks} weeks`;
      }
      case !date: {
        return 'Pending';
      }
      default: {
        return momentDate.fromNow();
      }
    }
  }
}
