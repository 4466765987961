import { Order, OrderCancelReason } from 'src/app/models/order';
import { finalize, flatMap } from 'rxjs/operators';

import { ActionSheetController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { EditFlowControllerPage } from 'src/app/pages/flows/ordering/edit-flow-controller/edit-flow-controller.page';
import { EditModes } from './edit-modes';
import { Injectable } from '@angular/core';
import { LoadingAlertService } from '../../loading-alert/loading-alert.service';
import { ModalService } from '../../modal/modal.service';
import { OrderScheduleService } from '../order-schedule/order-schedule.service';
import { OrderService } from '../../api/order/order.service';
import { ServiceOrder } from 'src/app/models/service-order';
import { ServiceSubscription } from 'src/app/models/service-subscription';
import { TitleForFrequencyPipe } from 'src/app/pipes/title-for-frequency/title-for-frequency';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleSubscription } from 'src/app/models/vehicle-subscription';
import { Visit } from 'src/app/models/visit';
import moment from 'moment';

/***
 * Provides prompts with available update/cancel options for orders and services and composes the correct api calls
 */

@Injectable({
  providedIn: 'root',
})
export class OrderEditService {
  constructor(
    private titleForFrequency: TitleForFrequencyPipe,
    private actionSheetCtrl: ActionSheetController,
    private modalService: ModalService,
    private orderService: OrderService,
    private loadingAlertCtrl: LoadingAlertService,
    private orderSchedule: OrderScheduleService,
    private datePipe: DatePipe
  ) {}

  /***
   * Order Options
   */

  async showEditOptionsForVisit(visit: Visit) {
    let actionSheet: HTMLIonActionSheetElement;
    const buttons: any[] = [
      {
        text: 'Dismiss',
        role: 'cancel',
      },
    ];

    if (!visit.order.service.virtual) {
      buttons.push.apply(buttons, [
        {
          text: `🗓 ${visit.vehicleSubscription?.days?.length > 1 ? 'Change Days' : 'Change Day'}`,
          handler: () => {
            actionSheet.dismiss().then(() => this.didTapReschedule(visit));
            return false;
          },
        },
        {
          text: `${
            visit.order.service.slotScheduling ? '⏰ Change Time Slot' : '⏰ Change Time Window'
          }`,
          handler: () => {
            actionSheet.dismiss().then(() => this.didTapAdjustDeliveryWindowOrSlot(visit));
            return false;
          },
        },
        {
          text: '📍 Adjust Location',
          handler: () => {
            actionSheet.dismiss().then(() => this.didTapAdjustLocation(visit));
            return false;
          },
        },
      ]);
    }

    if (visit.vehicleSubscription) {
      buttons.push({
        text: '⏩ Skip Visit',
        handler: () => {
          actionSheet.dismiss().then(() => this.skipWeeklyVisit(visit.order));
          return false;
        },
      });
    }

    buttons.push({
      text: `🛑 Cancel Visit`,
      role: 'destructive',
      handler: () => {
        actionSheet.dismiss().then(() => this.didTapPause(visit));
        return false;
      },
    });

    actionSheet = await this.actionSheetCtrl.create({
      buttons,
    });
    actionSheet.present();
  }

  private nextOrAllSelector(visit: Visit): Promise<boolean> {
    const needsPrompt = Boolean(visit.vehicleSubscription);
    if (!needsPrompt) {
      return Promise.resolve(true);
    }
    return new Promise<boolean>(async (resolve) => {
      const actionSheet = await this.actionSheetCtrl.create({
        buttons: [
          {
            text: 'Edit next visit only',
            handler: () => {
              actionSheet.dismiss().then(() => resolve(true));
              return false;
            },
          },
          {
            text: 'Edit this visit and future visits',
            handler: () => {
              actionSheet.dismiss().then(() => resolve(false));
              return false;
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
          },
        ],
      });
      actionSheet.present();
    });
  }

  private didTapReschedule(visit: Visit) {
    this.nextOrAllSelector(visit).then((nextOnly) => {
      this.changeVisitDay(visit.order, !nextOnly ? visit.vehicleSubscription : null);
    });
    return true;
  }

  private changeVisitDay(order: Order, subscription: VehicleSubscription | null) {
    this.modalService.openPage({
      component: EditFlowControllerPage,
      animated: false,
      props: {
        order,
        subscription,
        editMode: EditModes.EditDay,
      },
    });
  }

  private didTapAdjustDeliveryWindowOrSlot(visit: Visit) {
    this.nextOrAllSelector(visit).then((nextOnly) => {
      this.changeDeliveryWindowOrSlot(visit.order, !nextOnly ? visit.vehicleSubscription : null);
    });
    return true;
  }

  private changeDeliveryWindowOrSlot(order: Order, subscription?: VehicleSubscription | null) {
    this.modalService.openPage({
      component: EditFlowControllerPage,
      animated: false,
      props: {
        order,
        subscription,
        editMode: order.service.slotScheduling ? EditModes.EditTimeSlot : EditModes.EditTimeWindow,
      },
    });
  }

  private skipWeeklyVisit(order: Order) {
    this.loadingAlertCtrl.showLoader();
    this.orderService
      .cancelOrder(order, OrderCancelReason.SkipNextDelivery)
      .pipe(
        flatMap(() => {
          return this.orderService.getOrders();
        })
      )
      .subscribe(
        () => {
          this.loadingAlertCtrl.dismissLoader();
          this.loadingAlertCtrl.showToastConfirmation('Changed Saved!');
          this.orderService.getServiceOrders();
        },
        () => {
          this.loadingAlertCtrl.dismissLoader();
          this.loadingAlertCtrl.showToastAlert('An error occurred.');
        }
      );
    return true;
  }

  private async didTapPause(visit: Visit) {
    const actionSheet = await this.actionSheetCtrl.create({
      header:
        'Do you want to cancel this visit? This will also cancel any add-on and recurring services',
      buttons: [
        {
          text: 'Yes, cancel.',
          handler: () => this.cancelVisit(visit),
        },
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
    actionSheet.present();
    return true;
  }

  didTapAdjustLocation(visit: Visit) {
    this.modalService.openPage({
      component: EditFlowControllerPage,
      animated: false,
      props: {
        order: visit.order,
        subscription: visit.vehicleSubscription,
        editMode: EditModes.EditLocation,
      },
    });

    return true;
  }

  /***
   * Service Order Options
   */

  async showEditOptionsForService(serviceOrder: ServiceOrder) {
    const assignedVisit = await this.orderSchedule.findVisitForServiceOrder(serviceOrder);
    const nextCompatibleVisit = await this.orderSchedule.findCompatibleVisit(
      serviceOrder.vehicle,
      serviceOrder.service
    );
    const subscription = await this.orderSchedule.findServiceSubscription(
      serviceOrder.vehicle,
      serviceOrder.service.serviceType.name
    );
    const buttons: any[] = [
      {
        text: 'Dismiss',
        role: 'cancel',
      },
    ];

    if (nextCompatibleVisit && assignedVisit !== nextCompatibleVisit) {
      const rescheduleButton = {
        text: '⏪ Reschedule For Next Visit',
        handler: () => this.rescheduleService(serviceOrder, nextCompatibleVisit.order.date),
      };
      buttons.push(rescheduleButton);
    }

    if (subscription) {
      const skipButton = {
        text: '⏩ Skip Until Following ' + this.titleForFrequency.transform(subscription.frequency),
        handler: () => this.skipServiceInterval(serviceOrder, subscription),
      };
      buttons.push(skipButton);
    }

    if (!subscription) {
      const cancelOrderButton = {
        text: '🛑 Cancel Service',
        role: 'destructive',
        handler: () => this.cancelServiceOrder(serviceOrder),
      };
      buttons.push(cancelOrderButton);
    }

    if (subscription) {
      const stopServiceButton = {
        text: '🛑 Stop Service',
        role: 'destructive',
        handler: () => this.cancelServiceSubscription(subscription),
      };
      buttons.push(stopServiceButton);
    }

    const actionSheet = await this.actionSheetCtrl.create({
      buttons,
    });
    actionSheet.present();
  }

  private cancelServiceOrder(serviceOrder: ServiceOrder) {
    this.loadingAlertCtrl.showLoader();
    this.orderService.cancelServiceOrder(serviceOrder).subscribe(
      () => {
        this.loadingAlertCtrl.dismissLoader();
        this.loadingAlertCtrl.showToastConfirmation('Changed Saved!');
        this.orderService.getServiceOrders();
      },
      () => {
        this.loadingAlertCtrl.dismissLoader();
        this.loadingAlertCtrl.showToastAlert('An error occurred.');
      }
    );
  }

  private cancelVisit(visit: Visit) {
    this.loadingAlertCtrl.showLoaderWithText('Please wait...');
    this.orderService
      .cancelVisit(visit)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(() => {
        this.loadingAlertCtrl.showToastConfirmation('Your visit has been cancelled.');
      });
  }

  private cancelServiceSubscription(subscription: ServiceSubscription) {
    this.loadingAlertCtrl.showLoaderWithText('Cancelling');
    this.orderService
      .deleteServiceSubscription(subscription)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe((result) => {
        this.orderService.getServiceSubscriptions();
        this.orderService.getServiceOrders();
      });
  }

  private rescheduleService(serviceOrder: ServiceOrder, date: Date) {
    this.loadingAlertCtrl.showLoaderWithText('Please wait...');
    this.orderService
      .updateServiceOrder(serviceOrder, {
        date: moment(date).format('YYYY-MM-DD'),
      })
      .pipe(
        finalize(() => {
          this.loadingAlertCtrl.dismissLoader();
        })
      )
      .subscribe(() => {
        this.loadingAlertCtrl.showToastConfirmation(`We've rescheduled your service!`);
      });
  }

  private skipServiceInterval(serviceOrder: ServiceOrder, subscription: ServiceSubscription) {
    let date = serviceOrder.date;
    if (!(typeof date === 'object')) {
      date = new Date(date);
    }
    date.setDate(date.getDate() + subscription.frequency);
    this.loadingAlertCtrl.showLoader();
    this.orderService
      .updateServiceOrder(serviceOrder, {
        date: this.datePipe.transform(date, 'yyyy-MM-dd'),
      })
      .subscribe(
        () => {
          this.loadingAlertCtrl.dismissLoader();
          this.loadingAlertCtrl.showToastConfirmation('Changes saved!');

          this.orderService.getServiceSubscriptions();
        },
        () => {
          this.loadingAlertCtrl.dismissLoader();
          this.loadingAlertCtrl.showToastAlert('An error occurred.');
        }
      );
  }
}
