import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'applyPure',
  pure: true,
})
export class ApplyPurePipe implements PipeTransform {
  transform(value: any, fn: Function): any {
    return fn(value);
  }
}
