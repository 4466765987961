import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { WebflowSignupFlowControllerPage } from './webflow-signup-flow-controller.page';
import { WebflowSignupFlowControllerPageRoutingModule } from './webflow-signup-flow-controller-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, WebflowSignupFlowControllerPageRoutingModule],
  declarations: [WebflowSignupFlowControllerPage],
})
export class WebflowSignupFlowControllerPageModule {}
