import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditFlowControllerPageRoutingModule } from './edit-flow-controller-routing.module';

import { EditFlowControllerPage } from './edit-flow-controller.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditFlowControllerPageRoutingModule
  ],
  declarations: [EditFlowControllerPage]
})
export class EditFlowControllerPageModule {}
