import { ApplePay, IOrder, IOrderItem, IPaymentResponse } from '@ionic-native/apple-pay/ngx';
import { FeatureFlagService, Flag } from '../feature-flag/feature-flag.service';
import { Observable, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { LoadingAlertService } from './../loading-alert/loading-alert.service';
import { UserService } from '../api/user/user.service';
import { flatMap } from 'rxjs/operators';

enum ApplePayTransactionStatus {
  Success = 'success',
  Failure = 'failure',
}

@Injectable({
  providedIn: 'root',
})
export class ApplePayService {
  private readonly APPLE_PAY_LINE_ITEMS: IOrderItem[] = [
    {
      label: 'Fuel and Services',
      amount: 0.0,
    },
    {
      label: 'Yoshi, Inc',
      amount: 1.0,
    },
  ];

  constructor(
    private applePay: ApplePay,
    private featureFlags: FeatureFlagService,
    private loadingAlert: LoadingAlertService,
    private userService: UserService
  ) {}

  readyForApplePay(): Observable<boolean> {
    return from(this.applePay.canMakePayments()).pipe(
      flatMap((message) => {
        return this.featureFlags.flag$(Flag.AllowApplePay);
      })
    );
  }

  async requestPaymentInfo(): Promise<boolean> {
    try {
      try {
        await this.applePay.canMakePayments();
      } catch (message) {
        alert(message);
        return false;
      }
      const paymentRequest: IOrder = this.buildPaymentRequest();
      const applePayTransaction = await this.applePay.makePaymentRequest(paymentRequest);
      this.loadingAlert.showLoader();
      await this.saveCardInfo(applePayTransaction);
      await this.loadingAlert.dismissLoader();
      await this.applePay.completeLastTransaction(ApplePayTransactionStatus.Success);
      return true;
    } catch (e) {
      await this.loadingAlert.dismissLoader();
      await this.applePay.completeLastTransaction(ApplePayTransactionStatus.Failure);
      console.error(e);
      alert(e);
      return false;
    }
  }

  private async saveCardInfo(applePayTransaction: IPaymentResponse): Promise<boolean> {
    const stripeToken = (applePayTransaction as any).stripeToken;
    await this.userService.createCreditCard({ token: stripeToken }).toPromise();
    this.userService.getCreditCards();
    return true;
  }

  private buildPaymentRequest(): IOrder {
    return {
      items: this.APPLE_PAY_LINE_ITEMS,
      merchantIdentifier: 'merchant.com.yoshi',
      supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
      merchantCapabilities: ['3ds', 'debit', 'credit'],
      currencyCode: 'USD',
      countryCode: 'US',
      billingAddressRequirement: 'none',
      shippingAddressRequirement: 'none',
      shippingType: 'service',
    };
  }
}
