import { Component, HostBinding, Input } from '@angular/core';

enum YoshiAction {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Destructive = 'destructive',
  Succeeded = 'succeeded',
}

enum YoshiButtonColor {
  Primary = 'primary',
  Dark = 'dark',
}

enum YoshiButtonSize {
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
}

@Component({
  selector: 'button[ysh-button], button[ysh-button-block], button[ysh-button-full]',
  templateUrl: './yoshi-button.html',
  host: {
    '[attr.disabled]': 'disabled || null',
  },
  styleUrls: ['./yoshi-button.scss'],
  exportAs: 'yoshiButton',
})
export class YoshiButtonComponent {
  @Input() disabled: boolean;
  @Input() action: YoshiAction = YoshiAction.Primary;
  @Input() color: YoshiButtonColor = YoshiButtonColor.Primary;
  @Input() size: YoshiButtonSize = YoshiButtonSize.Regular;

  @Input() class = '';
  @HostBinding('class')
  get hostClasses() {
    return `${this.class} ysh-button ${this.size} ${this.color} ${classForAction(this.action)}`;
  }
}

export function classForAction(action: YoshiAction) {
  return `ysh-button-${action}`;
}
