import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppRoutes } from './routes';
import { AuthGuard } from './guards/auth/auth.guard';
import { LoginGuard } from './guards/login/login.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: AppRoutes.Login,
    canActivate: [LoginGuard],
    loadChildren: () => import('./pages/start/start.module').then((m) => m.StartPageModule),
  },
  {
    path: AppRoutes.Landing,
    canActivate: [AuthGuard],
    redirectTo: AppRoutes.Tabs,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.Tabs,
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Webflow,
    loadChildren: () =>
      import('./pages/flows/webflow/webflow-start/webflow-start.module').then((m) => m.WebflowStartPageModule),
  },
  {
    path: '**',
    redirectTo: AppRoutes.Login,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
