import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor() {}

  getYoshiVideo(): Media {
    return {
      type: MediaTypes.Video,
      platform: MediaPlatforms.Youtube,
      srcId: 'GMbCcI_J5Z0',
      thumbnail: 'assets/images/video-cover.png',
      label: 'on the ground',
      title: 'A Big Thank You',
      description: `Yoshi customers thank frontline healthcare workers by donating contactless gas!`,
    };
  }
}

export interface Media {
  type: MediaTypes;
  srcId: string;
  platform: MediaPlatforms;
  thumbnail: string;
  label: string;
  title: string;
  description: string;
}

export enum MediaTypes {
  Video = 'video',
  Article = 'article',
  Blog = 'blog',
}

export enum MediaPlatforms {
  Youtube = 'youtube',
  Medium = 'medium',
}
