import * as moment from 'moment';

import { BaseEntity, MemoizeGetters, Transform, toDate } from './base';

import { ReferralProgramType } from './referral-program';

@MemoizeGetters
export class Referral extends BaseEntity {
  name: string;
  firstName?: string;
  lastName?: string;
  contactInfo: string;
  contactType: ReferralContactType;
  orderPlaced: boolean;
  signedUp: boolean;

  @Transform(toDate)
  sentAt: Date;

  constructor(json, public type: ReferralProgramType) {
    super(json);
  }

  // Computed Properties

  get relativeTime() {
    let momentDate = this.sentAt && moment(this.sentAt);
    return momentDate && momentDate.fromNow();
  }

  get displayName(): string | undefined {
    if (this.name) {
      return this.name;
    } else if (this.firstName) {
      return this.lastName ? this.firstName + ' ' + this.lastName : this.firstName;
    }
  }

  get resendable() {
    const sentDate = moment(this.sentAt);
    const numHoursAgo = moment().diff(sentDate, 'hours');
    return numHoursAgo > 24 && !this.signedUp;
  }
}

export enum ReferralContactType {
  Phone = 'phone',
  Email = 'email',
}

export interface ReferralContact {
  contactInfo: string;
  contactType: ReferralContactType;
}

export interface ReferralCreateParams {
  contacts: ReferralContact[];
  referralProgramUid: string;
}
