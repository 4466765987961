import { BaseEntity, MemoizeGetters, Transform, toDate, toEntity } from './base';
import { Shift, ShiftName, ShiftTime } from './shift';

import { Address } from './address';
import { FuelType } from './fuel-type';
import { Service } from './service';
import { UserAddress } from './user-address';
import { Vehicle } from './vehicle';
import listify from 'listify';
import { titleForFrequency } from '../pipes/title-for-frequency/title-for-frequency';

@MemoizeGetters
export class VehicleSubscription extends BaseEntity {
  frequency: number;
  shiftStartTime: ShiftTime;
  shiftEndTime: ShiftTime;
  shiftWindow: ShiftName;

  @Transform(toDate)
  nextOrderDate?: Date;

  @Transform(toEntity(FuelType))
  fuelType: FuelType;

  @Transform(toEntity(UserAddress))
  userAddress: UserAddress;

  @Transform(toEntity(Address))
  address: Address;

  @Transform(toEntity(Vehicle))
  vehicle: Vehicle;

  @Transform(toEntity(Service))
  service: Service;

  constructor(json: any) {
    super(json);
    // REFACTOR: this is a patch to remap address onto user_address object to keep local model consistent
    // Consider requesting a change the api
    if (this.userAddress) {
      this.userAddress.address = this.address;
    }
    this.frequency = this.frequency === 0 ? 7 : this.frequency;
    this.shiftStartTime = this.shiftStartTime || Shift.defaultStartTime(this.shiftWindow);
    this.shiftEndTime = this.shiftEndTime || Shift.defaultEndTime(this.shiftWindow);
  }

  get days(): string[] {
    return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].filter(
      (day) => this.json[day] == true
    );
  }

  get frequencyString(): string {
    if (this.days.length && !(this.frequency > 7)) {
      return listify(this.days);
    }
    return titleForFrequency(this.frequency);
  }

  private abbreviateDOW(dow: string) {
    let info = {
      sunday: 'Su',
      monday: 'M',
      tuesday: 'Tu',
      wednesday: 'W',
      thursday: 'Th',
      friday: 'F',
      saturday: 'Sa',
    };
    return info[dow];
  }
}

export interface VehicleSubscriptionParams {
  vehicleUid?: string;
  fuelTypeUid?: string;
  shiftWindow?: string;
  shiftStartTime?: number;
  shiftEndTime?: number;
  userAddressUid?: string;
  serviceUid?: string;
  days?: string[];
  lat?: number;
  lng?: number;
  locationName?: string;
  zipCode?: string;
  startDate?: string;
  frequency?: number;
  deliveryLocationDetails?: string;
  deliveryLocationPersistent?: boolean;
}
