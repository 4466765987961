import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { VehicleMakePage } from './vehicle-make.page';
import { VehicleMakePageRoutingModule } from './vehicle-make-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, VehicleMakePageRoutingModule, ComponentsModule],
  declarations: [VehicleMakePage],
})
export class VehicleMakePageModule {}
