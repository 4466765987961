import { BaseEntity, Default, Transform } from './base';

import { GasPrice } from './gas-price';

export enum GasStationSource {
  Opis = 'opis',
  GasBuddy = 'gas_buddy',
}

export class GasStation extends BaseEntity {
  address: string;
  city: string;
  brand: string;
  distance: number;
  lat: string;
  lng: string;
  fromRadius: boolean;
  fromZipCode: boolean;
  zipCode: string;
  lowestRegularPrice: boolean;
  lowestPremiumPrice: boolean;

  @Transform(toGasPriceByTier)
  price: GasPricesByTier;

  @Default(GasStationSource.Opis)
  source: GasStationSource;
}

function toGasPriceByTier(json): GasPricesByTier {
  return {
    regular: json.regular && new GasPrice(json.regular),
    premium: json.premium && new GasPrice(json.premium),
  };
}

export interface GasPricesByTier {
  regular: GasPrice;
  premium: GasPrice;
}
