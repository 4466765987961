import { AddressParams, UserAddress } from 'src/app/models/user-address';
import { ApiService, EmptySubscriber } from '../api.service';
import { AppState, getAll, getCollection } from 'src/state/state';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Service, ServiceQueryParams } from 'src/app/models/service';
import { UserEvent, UserService } from '../user/user.service';
import { filter, finalize, first, map, tap } from 'rxjs/operators';

import { Address } from 'src/app/models/address';
import { CollectionActions } from 'src/state/actions';
import { EventsService } from '../../events/events.service';
import { Faq } from 'src/app/models/faq';
import { GasStation } from 'src/app/models/gas-station';
import { Injectable } from '@angular/core';
import { Order } from 'src/app/models/order';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceName } from 'src/app/models/service-type';
import { SessionService } from '../../session/session.service';
import { Store } from '@ngrx/store';
import { TimeSlot } from 'src/app/models/time-slot';
import { User } from 'src/app/models/user';
import { FaqTag } from 'src/app/pages/services/components/faq/faq';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private static SELECTED_ADDRESS_KEY = 'selected_address';
  userAddresses$: Observable<UserAddress[]>;
  selectedAddress$: BehaviorSubject<Nullable<UserAddress>> = new BehaviorSubject(null);
  serviceAvailable$ = new BehaviorSubject(false);
  services$: Observable<Service[]>;
  groupedServices$: Observable<ServiceGroup[]>;
  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  private _selectedAddress: Nullable<UserAddress>;
  constructor(
    private api: ApiService,
    private session: SessionService,
    private store: Store<AppState>,
    private collectionActions: CollectionActions,
    private events: EventsService,
    private userService: UserService
  ) {
    this.userAddresses$ = store.select((state) => getAll(state, UserAddress));
    this.services$ = store.select((state) => getAll(state, Service));
    this.groupedServices$ = this.services$.pipe(
      map((services) => ServiceGroup.groupServices(services))
    );
    this.isLoading$ = store.select((state) => getCollection(state, UserAddress).loading);
    this.isLoaded$ = store.select((state) => getCollection(state, UserAddress).loaded);
    this.events.subscribe(UserEvent.UserDidLogout, () => {
      this.logOutHandler();
    });
    this.getAvailability();
  }

  logOutHandler() {
    this.setSelectedAddress(null);
  }

  setSelectedAddress(address: Nullable<UserAddress>) {
    const payload = address ? address.json : null;
    const promise = this.session.set(AddressService.SELECTED_ADDRESS_KEY, payload);
    this._selectedAddress = address;
    this.selectedAddress$.next(address);
    return promise;
  }

  loadSelectedAddress() {
    this.session.get(AddressService.SELECTED_ADDRESS_KEY).then(async (data) => {
      if (data) {
        this._selectedAddress = new UserAddress(data);
        this.selectedAddress$.next(this._selectedAddress);
        this.refreshSelectedAdddress(this._selectedAddress);
      } else {
        let userAddresses = await this.userAddresses$.pipe(first()).toPromise();
        this.populateSelectedAddressIfNeeded(userAddresses);
      }
    });
  }

  refreshSelectedAdddress(address: UserAddress) {
    if (address.uid) {
      this.getUserAddress(address.uid).subscribe(
        (refreshedAddress) => this.setSelectedAddress(refreshedAddress),
        async (error) => {
          if (error.code === 404) {
            this.onAddressNotFound(address);
          }
        }
      );
    } else {
      this.setSelectedAddress(null);
    }
  }

  private async onAddressNotFound(address: UserAddress) {
    this.setSelectedAddress(null);
    this.store.dispatch(this.collectionActions.delete(UserAddress, address));
    let userAddresses = await this.userAddresses$.pipe(first()).toPromise();
    this.populateSelectedAddressIfNeeded(userAddresses);
  }

  // Service Availability

  isServiceAvailable(userAddress: Nullable<UserAddress>, user?: Nullable<User>) {
    userAddress = userAddress || this.selectedAddress$.value;
    user = user || this.userService.currentUser$.value;
    if (!userAddress || !user) {
      return false;
    }
    const compound = userAddress.address.compound;
    const preventNewUser = compound?.convertedOnly && !user.converted;
    return userAddress.serviceAvailable && !preventNewUser;
  }

  private getAvailability() {
    return combineLatest([this.userService.currentUser$, this.selectedAddress$])
      .pipe(
        map(([user, userAddress]) => {
          return Boolean(user && userAddress && this.isServiceAvailable(userAddress, user));
        })
      )
      .subscribe((serviceAvailable) => {
        this.serviceAvailable$.next(serviceAvailable);
      });
  }

  // API

  createAddress(params: AddressParams): Observable<Address> {
    params.requestSource = window.location.href;
    const request = this.api
      .call({
        method: 'POST',
        url: `/addresses`,
        body: params,
      })
      .pipe(map((item) => new Address(item)));
    request.subscribe(new EmptySubscriber());
    return request;
  }

  getUserAddresses(pageNumber = 1) {
    this.store.dispatch(this.collectionActions.setLoading(UserAddress, true));
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/user_addresses`,
        params: { page: pageNumber },
        method: 'GET',
      })
      .pipe(
        map((result) => {
          if (!result.error) {
            return result.map((item) => new UserAddress(item));
          } else {
            return [];
          }
        }),
        finalize(() => this.store.dispatch(this.collectionActions.setLoading(UserAddress, false))),
        tap((addresses) => {
          this.populateSelectedAddressIfNeeded(addresses);
          if (pageNumber > 1) {
            this.store.dispatch(this.collectionActions.append(UserAddress, addresses));
          } else {
            this.store.dispatch(this.collectionActions.set(UserAddress, addresses));
          }
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  private populateSelectedAddressIfNeeded(addresses: UserAddress[]) {
    if (!this.selectedAddress$.value && addresses.length) {
      const validAddresses = addresses.filter((address) => this.isServiceAvailable(address));
      const selectedAddress = validAddresses.length ? validAddresses[0] : addresses[0];
      this.setSelectedAddress(selectedAddress);
    }
  }

  getUserAddress(uid: string) {
    const request = this.api
      .call({
        url: `/user_addresses/${uid}`,
        method: 'GET',
      })
      .pipe(
        map((item) => new UserAddress(item)),
        tap((address) => {
          this.store.dispatch(this.collectionActions.update(UserAddress, address));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  getTimeSlots(
    userAddress: UserAddress,
    from: Date | null,
    to: Date | null,
    service: Service
  ): Observable<TimeSlot[]> {
    let params = {
      service_uid: service.uid,
    };

    if (from && to) {
      params['start_date'] = from.toDateString();
      params['end_date'] = to.toDateString();
    }
    const request = this.api
      .call({
        url: `/user_addresses/${encodeURIComponent(userAddress.uid)}/time_slots`,
        params: params,
        method: 'GET',
      })
      .pipe(
        map((dateSlotsCollection) => {
          if (dateSlotsCollection.error) {
            return [];
          }

          return dateSlotsCollection.map((slot) => {
            return new TimeSlot({
              date: slot.date,
              startTime: slot.start_time,
              available: slot.available,
            });
          });
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  getGasStationsWithinRadius(uid) {
    const request = this.api
      .call({
        url: `/user_addresses/${uid}/gas_stations`,
        method: 'GET',
      })
      .pipe(map((result) => result.map((item) => new GasStation(item))));
    return request;
  }

  createUserAddress(params: AddressParams) {
    const request = this.api
      .call({
        method: 'POST',
        url: `/users/${ApiService.USER_UID}/user_addresses`,
        body: params,
      })
      .pipe(
        map((item) => new UserAddress(item)),
        tap((address: UserAddress) => {
          this.store.dispatch(this.collectionActions.add(UserAddress, address));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  deleteUserAddress(address: UserAddress) {
    let request = this.api.call({
      method: 'DELETE',
      url: '/user_addresses/' + address.uid,
    });
    request
      .pipe(
        tap((data) => {
          this.store.dispatch(this.collectionActions.delete(UserAddress, address));
          if (address.uid === this._selectedAddress?.uid) {
            this.setSelectedAddress(null).then(() => {
              this.loadSelectedAddress();
            });
          }
        })
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  getServices(address: Address, params: ServiceQueryParams = {}): Observable<Service[]> {
    this.store.dispatch(this.collectionActions.setLoading(Service, true));
    let request = this.api
      .call({
        url: `/addresses/${address.uid}/services`,
        params,
      })
      .pipe(
        map((data) => data.map((item) => new Service(item))),
        tap((data) => {
          this.store.dispatch(this.collectionActions.set(Service, data));
        }),
        finalize(() => this.store.dispatch(this.collectionActions.setLoading(Service, false)))
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  getFaqs(serviceName: ServiceName, tags?: FaqTag[]) {
    const params = tags ? { tags } : {};
    return this.api
      .call({
        url: `/service_types/${serviceName}/faqs`,
        params
      })
      .pipe(map((data) => data.map((item) => new Faq(item))));
  }
}
