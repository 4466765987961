import { BaseEntity, DEFAULTS_KEY } from './base';
import { addMetadataToMap } from './util';

/**
 * BaseEntity property decorator to provide a default value.
 * Useful as a subclass initializer will overwrite the result of deserialization
 * @param  {any} value
 */
export const Default = (value: any) => {
  return <T extends BaseEntity, K extends string>(target: T, key: K) => {
    addMetadataToMap(DEFAULTS_KEY, key, value, target);
  };
};
