import { Component, Input, OnInit } from '@angular/core';

import { ModalService } from 'src/app/services/modal/modal.service';
import { VehicleRecall } from 'src/app/models/vehicle-recall';

@Component({
  selector: 'ysh-recall-details',
  templateUrl: './recall-details.page.html',
  styleUrls: ['./recall-details.page.scss'],
})
export class RecallDetailsPage {
  @Input() modal = false;
  @Input() recall: VehicleRecall;
  constructor(private modalService: ModalService) {}

  didTapDismiss() {
    this.modalService.dismissModal();
  }
}
