import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { GesturesService, SwipeGesturesMap } from 'src/app/services/gestures/gestures.service';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

import { Gesture } from '@ionic/angular';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ysh-bottom-sheet',
  templateUrl: './ysh-bottom-sheet.component.html',
  styleUrls: ['./ysh-bottom-sheet.component.scss'],
})
export class YshBottomSheetComponent implements OnInit {
  @Output() onDidDismiss: EventEmitter<void> = new EventEmitter();

  ele: HTMLElement;
  gestureListener: Gesture;

  constructor(
    private modalService: ModalService,
    private gesturesService: GesturesService,
    el: ElementRef
  ) {
    this.ele = el.nativeElement;
  }

  ngOnInit() {
    this.gestureListener = this.gesturesService.registerGestureListener(
      this.ele,
      SwipeGesturesMap.Down,
      () => this.didSwipeDown()
    );
  }

  didSwipeDown() {
    this.dismiss();
  }

  didTapOutside() {
    this.dismiss();
  }

  dismiss() {
    this.onDidDismiss.emit();
  }
}
