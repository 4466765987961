import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { TimeSlotSelectorPage } from './time-slot-selector.page';
import { TimeSlotSelectorPageRoutingModule } from './time-slot-selector-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TimeSlotSelectorPageRoutingModule,
    ComponentsModule,
  ],
  declarations: [TimeSlotSelectorPage],
})
export class TimeSlotSelectorPageModule {}
