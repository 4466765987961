import { BaseEntity, Transform, toDate } from './base';

export class DeliveryLocation extends BaseEntity {
  lat: number;
  lng: number;
  locationName: string;
  details?: string;
  persistent: boolean;

  @Transform(toDate)
  updatedAt: Date;
}
