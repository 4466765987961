import { Stripe, loadStripe } from '@stripe/stripe-js';

import { EnvironmentService } from '../environment/environment.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  instance: Stripe | null;
  constructor(private environment: EnvironmentService) {
    this.initializeStripe();
  }

  async initializeStripe() {
    const stripeKey = this.environment.serverVars().stripePublishableKey;
    this.instance = await loadStripe(stripeKey);
  }
}
