import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ServiceDetailPage } from './service-detail.page';
import { ServiceDetailPageRoutingModule } from './service-detail-routing.module';
import { ServicesSharedModule } from '../components/services-components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ServiceDetailPageRoutingModule,
    ServicesSharedModule,
    ComponentsModule,
    PipesModule,
  ],
  declarations: [ServiceDetailPage],
})
export class ServiceDetailPageModule {}
