import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MapService, MapStyle } from 'src/app/services/map/map.service';

import { AddressService } from '../../services/api/address/address.service';
import { AddressesPage } from '../../pages/profile/addresses/addresses.page';
import { ModalService } from '../../services/modal/modal.service';
import { Service } from 'src/app/models/service';
import { Subject } from 'rxjs';
import { UserAddress } from '../../models/user-address';
import mapboxgl from 'mapbox-gl';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ysh-order-flow-header',
  templateUrl: 'order-flow-header.html',
  styleUrls: ['./order-flow-header.scss'],
})
export class OrderFlowHeaderComponent implements OnDestroy {
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBackTapped: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onDismissTapped: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onAddressUpdated: EventEmitter<any> = new EventEmitter();
  @Input() isUpdatingOrder = false;
  @Input() service: Service;
  @Input() hideBackbutton = false;

  text: string;
  map: mapboxgl.Map;
  selectedAddress: Nullable<UserAddress>;
  addressMarker: mapboxgl.Marker;
  initialCoords: mapboxgl.LngLatLike;
  defaultCoords: mapboxgl.LngLatLike = [-121, 37];
  unsubscribe: Subject<void> = new Subject();
  @Input() zoomLevel: number;
  defaultZoomLevel = 13;
  @Input() mapTypeId: google.maps.MapTypeId;
  defaultMapTypeId: google.maps.MapTypeId = google.maps.MapTypeId.ROADMAP;

  constructor(
    private addressService: AddressService,
    private modalService: ModalService,
    private mapService: MapService
  ) {
    this.addressService.selectedAddress$.pipe(takeUntil(this.unsubscribe)).subscribe((address) => {
      this.selectedAddress = address;
      if (this.selectedAddress) {
        this.initialCoords = [this.selectedAddress.address.lng, this.selectedAddress.address.lat];

        if (this.map) {
          this.map.setCenter(this.initialCoords);
        }
        if (this.addressMarker) {
          this.addressMarker.setLngLat(this.initialCoords);
        }
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.initMap(), 0);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  async initMap() {
    const mapOptions: google.maps.MapOptions = {
      zoom: this.zoomLevel || this.defaultZoomLevel,
      mapTypeId: this.mapTypeId || this.defaultMapTypeId,
    };
    const mapContainers = document.getElementsByClassName('map-container');
    if (mapContainers && mapContainers.length) {
      const currentContainer = mapContainers[mapContainers.length - 1];
      this.map = await this.mapService.createMBMap(currentContainer as HTMLElement, {
        zoom: this.zoomLevel || this.defaultZoomLevel,
        center: this.initialCoords,
        style: MapStyle.RoadmapWithNoData,
      });

      this.addressMarker = this.mapService
        .createAddressMarker(this.initialCoords[0], this.initialCoords[1])
        .addTo(this.map);
    }
  }

  didTapBack() {
    this.onBackTapped.emit();
  }

  didTapDismiss() {
    this.onDismissTapped.emit();
  }

  didTapSelectedAddress() {
    this.modalService.openPage({
      component: AddressesPage,
      componentProps: {
        modal: true,
        preventOutOfServiceSelection: true,
        onComplete: (address) => this.onAddressUpdated.emit(address),
      },
    });
  }
}
