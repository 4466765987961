import { AddressConfirmationPage } from './address-confirmation.page';
import { AddressConfirmationPageRoutingModule } from './address-confirmation-routing.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddressConfirmationPageRoutingModule,
    ComponentsModule,
    TextMaskModule,
  ],
  declarations: [AddressConfirmationPage],
})
export class AddressConfirmationPageModule {}
