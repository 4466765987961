import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilKeyChanged, filter, first, map, take, tap } from 'rxjs/operators';

import { AddressService } from '../../api/address/address.service';
import { Injectable } from '@angular/core';
import { Order } from '../../../models/order';
import { OrderService } from '../../api/order/order.service';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceName } from '../../../models/service-type';
import { ServiceOrder } from '../../../models/service-order';
import { ServiceSubscription } from '../../../models/service-subscription';
import { UserAddress } from '../../../models/user-address';
import { Vehicle } from '../../../models/vehicle';
import { VehicleSubscription } from 'src/app/models/vehicle-subscription';
import { VehiclesService } from '../../api/vehicles/vehicles.service';
import { Visit } from 'src/app/models/visit';

/**
 * Provides convenient access to order data
 */

@Injectable({
  providedIn: 'root',
})
export class OrderScheduleService {
  currentAddress$ = new BehaviorSubject<Nullable<UserAddress>>(null);
  availableServices$ = new BehaviorSubject<Service[]>([]);
  availableServiceGroups$ = new BehaviorSubject<ServiceGroup[]>([]);
  featuredService$ = new BehaviorSubject<Nullable<Service>>(null);
  suggestedServices$: Observable<ServiceGroup[]>;
  nextVisitForSelectedVehicle$ = new BehaviorSubject<Nullable<Visit>>(null);

  constructor(
    private orderService: OrderService,
    private vehicleService: VehiclesService,
    private addressService: AddressService
  ) {
    this.syncSelectedAddress();
    this.subscribeToCurrentAddress();
    this.suggestedServices$ = this.suggestedServices();
    this.getAvailableServices();
    this.getAvailableServiceGroups();
    this.getFeaturedService();
    this.fetchServicesOnAddressChange();
    this.getNextVisitForSelectedVehicle();
  }

  // Address

  /**
   * Sync the user-selected address with that of the users next order
   */
  private syncSelectedAddress() {
    let selectedVehicle$ = this.vehicleService.selectedVehicle$;
    let orders$ = this.orderService.upcomingOrders$;
    combineLatest([selectedVehicle$, orders$]).subscribe((value) => {
      const [vehicle, orders] = value;
      const nextOrder = orders.find((order) => order.vehicle.uid === vehicle?.uid);
      const selectedAddress = this.addressService.selectedAddress$.value;
      if (nextOrder && nextOrder.userAddress.uid !== selectedAddress?.uid) {
        this.addressService.setSelectedAddress(nextOrder.userAddress);
      }
    });
  }

  /**
   * Emits the next order's address for the currently selected vehicle, if available, or
   * the users selected default address
   */
  private subscribeToCurrentAddress() {
    let selectedAddress$ = this.addressService.selectedAddress$;
    let selectedVehicle$ = this.vehicleService.selectedVehicle$;
    let orders$ = this.orderService.upcomingOrders$;
    return combineLatest([selectedAddress$, selectedVehicle$, orders$])
      .pipe(
        map((value) => {
          const [address, vehicle, orders] = value;
          const nextOrder = vehicle && orders.find((order) => vehicle.uid === order.vehicle.uid);
          return nextOrder?.userAddress || address;
        })
      )
      .subscribe(this.currentAddress$);
  }

  private fetchServicesOnAddressChange() {
    this.currentAddress$
      .pipe(
        filter((userAddress) => Boolean(userAddress)),
        map((userAddress) => userAddress!),
        distinctUntilKeyChanged('uid')
      )
      .subscribe((userAddress) => {
        if (userAddress?.address) {
          this.addressService.getServices(userAddress.address);
        }
      });
  }

  private getAvailableServices() {
    return combineLatest([this.addressService.services$, this.vehicleService.selectedVehicle$])
      .pipe(
        map(([services, vehicle]) => {
          return vehicle ? services.filter((service) => service.forVehicle(vehicle)) : services;
        }),
        map((services) => {
          const schedulable = services.filter((service) => {
            const needsParent = service.shouldScheduleWith?.length > 0;
            let hasParent = false;
            if (needsParent) {
              hasParent = Boolean(
                services.find((parentService) =>
                  service.shouldScheduleWith.includes(parentService.serviceType.name)
                )
              );
            }
            return !needsParent || hasParent;
          });

          return schedulable;
        })
      )
      .subscribe(this.availableServices$);
  }

  private getAvailableServiceGroups() {
    return this.availableServices$
      .pipe(map((services) => ServiceGroup.groupServices(services)))
      .subscribe(this.availableServiceGroups$);
  }

  private getFeaturedService() {
    return this.availableServices$
      .pipe(map((services) => services.find((service) => service.featured)))
      .subscribe(this.featuredService$);
  }

  private suggestedServices() {
    return combineLatest([
      this.vehicleService.selectedVehicle$,
      this.addressService.groupedServices$,
      this.orderService.serviceOrders$,
    ]).pipe(
      map(([vehicle, services, orders]) => {
        return services
          .filter((service) => {
            if (!vehicle) {
              return false;
            }
            const isSuggested = vehicle.suggestedServices.find((uid) => service.serviceType.uid);
            const hasOrder = orders.find(
              (order) =>
                order.vehicle.uid === vehicle.uid &&
                order.service.serviceType.uid === service.serviceType.uid
            );
            return isSuggested && !hasOrder;
          })
          .filter((service) => {
            return service.basicService.forVehicle(vehicle!);
          });
      })
    );
  }

  private getNextVisitForSelectedVehicle() {
    return combineLatest([this.vehicleService.selectedVehicle$, this.orderService.visits$])
      .pipe(
        map(([vehicle, visits]) => {
          return visits.find((visit) => visit.vehicle.uid === vehicle?.uid);
        })
      )
      .subscribe(this.nextVisitForSelectedVehicle$);
  }

  // Finders

  async findServiceSubscription(
    vehicle: Vehicle,
    serviceName: ServiceName
  ): Promise<Nullable<ServiceSubscription>> {
    const subs = await this.orderService.serviceSubscriptions$.pipe(first()).toPromise();
    return subs.find(
      (sub) => sub.vehicle.uid === vehicle.uid && sub.service.serviceType.name === serviceName
    );
  }

  async findVehicleSubscription(
    vehicle: Vehicle,
    serviceName: ServiceName
  ): Promise<Nullable<VehicleSubscription>> {
    const subs = await this.orderService.vehicleSubscriptions$.pipe(first()).toPromise();
    return subs.find(
      (sub) => sub.vehicle.uid === vehicle.uid && sub.service.serviceType.name === serviceName
    );
  }

  async findNextOrder(vehicle: Vehicle): Promise<Nullable<Order>> {
    const orders = await this.orderService.orders$.pipe(first()).toPromise();
    return orders.find((order) => order.isScheduled && order.vehicle.uid === vehicle.uid);
  }

  async findNextOrderForService(vehicle: Vehicle, service: ServiceName): Promise<Nullable<Order>> {
    const orders = await this.orderService.orders$.pipe(first()).toPromise();
    return orders.find(
      (order) =>
        service === order.service.serviceType.name &&
        order.isScheduled &&
        order.vehicle.uid === vehicle.uid
    );
  }

  async findNextServiceOrder(
    vehicle: Vehicle,
    service: ServiceName
  ): Promise<Nullable<ServiceOrder>> {
    const orders = await this.orderService.serviceOrders$.pipe(first()).toPromise();
    return orders.find(
      (order) =>
        service === order.service.serviceType.name &&
        order.isScheduled &&
        order.vehicle.uid === vehicle.uid
    );
  }

  async findCompatibleVisit(vehicle: Vehicle, service: Service) {
    const visits = await this.orderService.visits$.pipe(first()).toPromise();
    return visits.find(
      (visit) => visit.vehicle.uid === vehicle.uid && visit.order.service.canAccept(service)
    );
  }

  async findVisitForServiceOrder(serviceOrder: ServiceOrder) {
    const visits = await this.orderService.visits$.pipe(first()).toPromise();
    return visits.find((visit) =>
      visit.serviceOrders.find((order) => order.uid === serviceOrder.uid)
    );
  }
}
