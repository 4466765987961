import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PowerUpsPageRoutingModule } from './power-ups-routing.module';

import { PowerUpsPage } from './power-ups.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, PowerUpsPageRoutingModule, ComponentsModule],
  declarations: [PowerUpsPage],
})
export class PowerUpsPageModule {}
