import { Component, Input } from '@angular/core';

import { MemberTier } from '../../../../../models/member-tier';

@Component({
  selector: 'ysh-membership-card',
  templateUrl: 'membership-card.html',
  styleUrls: ['./membership-card.scss'],
})
export class MembershipCardComponent {
  @Input() tier: MemberTier;
  @Input() fee: number;
  @Input() bestValue = false;
  @Input() selected = false;
  @Input() description: string;

  displayFee() {
    return this.fee === null || this.fee === undefined ? this.tier.monthlyFee : this.fee;
  }
}
