/**
 * Interface for a normalized storage of a list of entities
 */
export interface CollectionState<T> {
  ids: string[];
  entities: { [uid: string]: T };
  total_result: number;
  total_pages: number;
  loaded: boolean;
  loading: boolean;
}

/**
 * Creates an empty CollectionState object
 * @returns CollectionState
 */
export function createCollectionState<T>(): CollectionState<T> {
  return {
    ids: [],
    entities: {},
    loading: true,
    loaded: false,
    total_pages: 0,
    total_result: 0,
  };
}
