import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';

import { DollarsAndCentsPipe } from 'src/app/pipes/dollars-and-cents/dollars-and-cents';

@Component({
  selector: 'ysh-service-navbar',
  templateUrl: 'service-navbar.html',
  styleUrls: ['./service-navbar.scss'],
})
export class ServiceNavbarComponent implements OnChanges {
  @Input() service: string;
  @Input() fuelDiscount: number;
  @Input() isSourcedFromUpsellFlow = false;
  @Input() hideBackButton = false;
  @HostBinding('class') classList = '';
  @Output() didTapPriceDetail: EventEmitter<void> = new EventEmitter();
  @Output() didTapBack: EventEmitter<void> = new EventEmitter();

  constructor(private dollarsAndCents: DollarsAndCentsPipe) {}

  ngOnChanges() {
    this.classList = this.service;
  }

  // View Helpers
  showAmountDiscounted() {
    return this.fuelDiscount ? true : false;
  }

  fuelDiscountAmount() {
    return this.dollarsAndCents.transform(Number(this.fuelDiscount));
  }

  didTapPriceDetailIcon() {
    this.didTapPriceDetail.emit();
  }

  onBackTapped() {
    this.didTapBack.emit();
  }
}
