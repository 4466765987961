import { Component, Input, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesPage } from 'src/app/pages/profile/vehicles/vehicles.page';

@Component({
  selector: 'ysh-vehicle-confirmation',
  templateUrl: './vehicle-confirmation.page.html',
  styleUrls: ['./vehicle-confirmation.page.scss'],
})
export class VehicleConfirmationPage implements OnInit, FlowPage {
  @Input() onComplete: (vehicle: Vehicle) => void;
  @Input() onDismiss: () => void;
  @Input() vehicles: Vehicle[];
  @Input() selectedVehicle: Vehicle;

  constructor(private analytics: AnalyticsService, private modalService: ModalService) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('VehicleConfirmationPage');
  }

  // Data
  validates() {
    return this.selectedVehicle !== null;
  }

  // Actions

  didTapVehicleField() {
    this.modalService.openPage({
      component: VehiclesPage,
      componentProps: { modal: true, onComplete: (vehicle) => (this.selectedVehicle = vehicle) },
    });
  }

  didTapContinue() {
    this.onComplete?.(this.selectedVehicle);
  }

  didTapBack() {
    this.onDismiss?.();
  }
}
