import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LoadingAlertService } from '../loading-alert/loading-alert.service';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  constructor(
    private platform: Platform,
    private loadingAlertCtrl: LoadingAlertService,
    private camera: Camera
  ) {}

  accessCamera(sourceType: ImgSourceType, onImgSelected: Function) {
    const cameraOptions: CameraOptions = {
      quality: 90,
      targetHeight: 800,
      targetWidth: 800,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
    };
    if (this.platform.is('cordova')) {
      this.loadingAlertCtrl.showLoader();
      this.platform.ready().then(() =>
        this.camera.getPicture({ ...cameraOptions, sourceType }).then(
          (imageData) => {
            if (onImgSelected) {
              onImgSelected('data:image/jpeg;base64,' + imageData);
            }
            this.loadingAlertCtrl.dismissLoader();
          },
          (err) => {
            this.loadingAlertCtrl.showToastAlert(err);
            this.loadingAlertCtrl.dismissLoader();
          }
        )
      );
    } else {
      this.loadingAlertCtrl.showToastAlert('Camera unavailable');
    }
  }
}
export enum ImgSourceType {
  Gallery = 0,
  Camera = 1,
}
