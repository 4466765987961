import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AddressesPage } from 'src/app/pages/profile/addresses/addresses.page';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UserAddress } from 'src/app/models/user-address';

@Component({
  selector: 'ysh-address-confirmation',
  templateUrl: './address-confirmation.page.html',
  styleUrls: ['./address-confirmation.page.scss'],
})
export class AddressConfirmationPage implements OnInit, FlowPage {
  @Input() onComplete: (address: UserAddress) => void;
  @Input() onDismiss: () => void;
  @Input() addresses: UserAddress[];
  @Input() selectedAddress: UserAddress;

  constructor(private analytics: AnalyticsService, private modalService: ModalService) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('AddressConfirmationPage');
  }

  // Data
  validates() {
    return this.selectedAddress !== null;
  }

  // Actions

  didTapAddressField() {
    this.modalService.openPage({
      component: AddressesPage,
      componentProps: { modal: true, onComplete: (address) => (this.selectedAddress = address) },
    });
  }

  didTapContinue() {
    this.onComplete?.(this.selectedAddress);
  }

  didTapBack() {
    this.onDismiss?.();
  }
}
