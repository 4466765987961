import { BaseEntity, Transform, toEntity } from './base';
import { Service } from './service';
import { ShiftTime } from './shift';
import { UserAddress } from './user-address';
import { Vehicle } from './vehicle';

export class ServiceSubscription extends BaseEntity {
  frequency: number;
  shiftStartTime?: ShiftTime;
  shiftEndTime?: ShiftTime;

  @Transform(toEntity(Vehicle))
  vehicle: Vehicle;

  @Transform(toEntity(UserAddress))
  address?: UserAddress;

  @Transform(toEntity(Service))
  service: Service;
}

export interface ServiceSubscriptionUpdateParams {
  frequency?: number;
  vehicleUid?: string;
  shiftStartTime?: string;
  shiftEndTime?: string;
}
