import {
  BaseEntity,
  Derive,
  MemoizeGetters,
  Transform,
  fromPolymorphicAssociation,
  toDate,
  toEntity,
} from './base';

import { Referral } from './referral';
import { ServiceType } from './service-type';
import { Vehicle } from './vehicle';

@MemoizeGetters
export class Alert extends BaseEntity {
  message?: string;
  scope?: string;

  @Transform(toDate)
  createdAt?: Date;

  @Transform(toDate)
  dismissedAt?: Date;

  @Transform(toDate)
  readAt?: Date;

  @Transform(toDate)
  sentAt?: Date;

  @Transform(toEntity(ServiceType))
  serviceType?: ServiceType;

  @Derive(fromPolymorphicAssociation('Vehicle', Vehicle))
  vehicle: Vehicle;

  @Derive(fromPolymorphicAssociation('Referral', Referral))
  referral: Referral;

  get fromField(): string | null {
    return this.vehicle?.nickname || this.referral?.name;
  }

  get imageUrl(): string | undefined {
    if (this.vehicle) {
      return this.vehicle.vehicleImageUrl;
    } else if (this.referral) {
      return 'assets/images/referral-placeholder.svg';
    }
  }
}

export interface AlertBulkUpdateParams {
  uids: string[];
  read?: boolean;
  dismissed?: boolean;
  resolved?: boolean;
}
