import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ConnectOnStarPageRoutingModule } from './connect-on-star-routing.module';

import { ConnectOnStarPage } from './connect-on-star.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ConnectOnStarPageRoutingModule,
    ComponentsModule,
  ],
  declarations: [ConnectOnStarPage],
})
export class ConnectOnStarPageModule {}
