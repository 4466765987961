import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ysh-empty-state',
  templateUrl: 'empty-state.html',
  styleUrls: ['./empty-state.scss'],
})
export class EmptyStateComponent {
  @Input() imageSource?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() buttonText?: string;
  @Input() bottomText?: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onButtonTap: EventEmitter<any> = new EventEmitter();

  constructor() {}

  didTapButton() {
    if (this.onButtonTap) {
      this.onButtonTap.emit();
    }
  }
}
