import { AddressParams, addressParamsForGooglePlaceData } from 'src/app/models/user-address';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import { IonSearchbar, Platform } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { WebflowVariant, getWebflowVariant } from './webflow-start.variants';
import { finalize, flatMap, map, takeUntil } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { Address } from 'src/app/models/address';
import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Faq } from 'src/app/models/faq';
import { FaqsModalComponent } from 'src/app/components/faqs-modal/faqs-modal';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PlacesAutocompletePage } from 'src/app/pages/misc/places-autocomplete/places-autocomplete.page';
import { QueryParamKey } from 'src/app/navigation.service';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceName } from 'src/app/models/service-type';
import { SimpleMessageModalComponent } from 'src/app/components/simple-message-modal/simple-message-modal';
import { WebflowLoginFlowControllerPage } from '../webflow-login-flow-controller/webflow-login-flow-controller.page';
import { WebflowSignupFlowControllerPage } from '../webflow-signup-flow-controller/webflow-signup-flow-controller.page';

@Component({
  selector: 'ysh-webflow-start-page',
  templateUrl: './webflow-start.page.html',
  styleUrls: [
    './webflow-start.page.scss',
    './webflow-start.page.a.scss',
    './webflow-start.page.b.scss',
  ],
})
export class WebflowStartPage implements OnInit, OnDestroy {
  @ViewChild('searchbarZip') searchbarZip: IonSearchbar;
  @ViewChild('searchbar') searchbar: IonSearchbar;
  googleAutocomplete: google.maps.places.Autocomplete;

  formData: AddressParams = {};
  address: Nullable<Address>;
  serviceGroup: ServiceGroup;
  webflowVariant: WebflowVariant;
  inviteCode?: string | null;
  introText: string | undefined;
  showAltLayout = false;
  featureFlagReady = false;
  layoutVisible = false;
  faq: Faq[] = [];
  zipCode: string;

  constructor(
    public route: ActivatedRoute,
    public platform: Platform,
    private loadingAlerts: LoadingAlertService,
    private keyboardService: KeyboardService,
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private addressService: AddressService,
    private featureFlag: FeatureFlagService
  ) {}

  // life cycle hooks

  ngOnInit() {
    this.webflowVariant = getWebflowVariant(
      this.route.snapshot.data['webflowVariant'],
      this.route.snapshot.data['webflowVariantArea']
    );
    this.analytics.trackView('WebflowStartPage' + this.webflowVariant);
    this.inviteCode = this.route.snapshot.queryParamMap.get(QueryParamKey.InviteCode);
    this.introText =
      this.inviteCode === this.webflowVariant.inviteCode
        ? this.webflowVariant.introTextPromo
        : this.webflowVariant.introText;

    if (this.webflowVariant.faqServiceName) {
      this.getFaqs(this.webflowVariant.faqServiceName);
    }

    if (this.webflowVariant.virtual && this.webflowVariant.defaultLocation) {
      this.formData = this.webflowVariant.defaultLocation;
      this.findVirtualService(this.formData);
    }

    this.configureLayout();
  }

  private unsubscribe: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // layout

  configureLayout() {
    if (this.webflowVariant.layoutAlt) {
      this.featureFlag
        .flag$(Flag.ShowWebflowAlternateLayout)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((flag) => {
          this.showAltLayout = flag;
          this.featureFlagReady = true;
          this.analytics.trackView('WebflowPageLayoutTest' + !flag ? 'A' : 'B');
        });
    } else {
      this.featureFlagReady = true;
    }

    if (this.featureFlagReady) {
      setTimeout(() => {
        this.layoutVisible = true;
      }, 100);
    }
  }

  // actions

  async didTapSearch() {
    this.googleAutocomplete = new google.maps.places.Autocomplete(
      await this.searchbar.getInputElement()
    );
    this.googleAutocomplete.addListener('place_changed', () => {
      const data = this.googleAutocomplete.getPlace();
      this.createDataForGooglePlace(data);
    });
    google.maps.event.clearInstanceListeners(this.searchbar.getInputElement());
    document.querySelector('.pac-container')?.remove();
  }

  didTapSearchZip() {
    const geocoder = new google.maps.Geocoder();
    this.loadingAlerts.showLoader();
    geocoder.geocode({ address: String(this.zipCode) }, (results, status) => {
      this.loadingAlerts.dismissLoader();
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.clearLocationData();
          this.createDataForGooglePlace(results[0]);
          this.didTapDone();
        } else {
          this.loadingAlerts.presentAlert('Failed to get zip code', 'No results found');
        }
      } else {
        this.loadingAlerts.presentAlert(
          'Failed to get zip code',
          'Geocoder failed due to status ' + status
        );
      }
    });
  }

  didTapDone() {
    this.getServices(this.formData).subscribe((serviceGroup) => {
      if (serviceGroup) {
        this.serviceGroup = serviceGroup;
        this.presentSignupFlow();
      } else {
        this.presentNoServicePopOver();
      }
    });
  }

  didTapLogin() {
    this.presentLoginFlow();
  }

  async didTapNewAddress() {
    const modal = await this.modalService.openPage({ component: PlacesAutocompletePage });
    this.keyboardService.close();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.clearLocationData();
      this.createDataForGooglePlace(data);
    }
    setTimeout(() => {
      this.keyboardService.close();
    }, 600);
  }

  didTapFAQS() {
    this.modalService.openPage({
      component: FaqsModalComponent,
      props: {
        faq: this.faq,
      },
    });
  }

  didTapSchedule() {
    this.presentSignupFlow();
  }

  // navigation

  presentLoginFlow() {
    this.modalService.openPage({
      component: WebflowLoginFlowControllerPage,
      componentProps: {
        onDismiss: () => this.modalService.dismissModal(),
      },
      props: {
        serviceNameSearch: this.webflowVariant.serviceNameSearch,
        variant: this.webflowVariant,
      },
    });
  }

  presentSignupFlow() {
    this.modalService.openPage({
      component: WebflowSignupFlowControllerPage,
      componentProps: {
        onDismiss: () => this.modalService.dismissModal(),
      },
      props: {
        serviceGroup: this.serviceGroup,
        address: this.address!,
        addressParams: this.formData,
        webflowVariant: this.webflowVariant,
        didRequestLogin: () => {
          this.modalService.dismissModal();
          this.presentLoginFlow();
        },
      },
    });
  }

  presentNoServicePopOver() {
    const description = this.webflowVariant.virtual
      ? 'Unfortunately, we are not performing virtual inspection in this region.'
      : 'Unfortunately, that address is outside our service area at this time. Please try a different address.';
    this.modalService.open({
      component: SimpleMessageModalComponent,
      componentProps: {
        title: 'Currently Unavailable',
        description,
        actionButtonText: 'Search Again',
      },
    });
  }

  // data

  loadingVirtualServices = false;

  findVirtualService(addressParams: AddressParams) {
    this.loadingVirtualServices = true;
    this.getServices(addressParams, false)
      .pipe(
        finalize(() => {
          this.loadingVirtualServices = false;
        })
      )
      .subscribe((serviceGroup) => {
        if (serviceGroup) {
          this.serviceGroup = serviceGroup;
        }
      });
  }

  createDataForGooglePlace(data) {
    const addressParams = addressParamsForGooglePlaceData(data);
    if (addressParams.zipCode) {
      this.formData = {
        ...this.formData,
        ...addressParams,
      };
    } else {
      this.loadingAlerts.showToastAlert('Please pick a more specific address.');
    }
  }

  getServices(params: AddressParams, showSpinner = true): Observable<Nullable<ServiceGroup>> {
    if (showSpinner) {
      this.loadingAlerts.showLoader();
    }
    return this.addressService.createAddress(params).pipe(
      flatMap((address) => {
        this.address = address;
        return this.addressService.getServices(address, { includeFromGlobalRegion: true });
      }),
      map((services) => {
        const filteredServices = this.filterServices(services);
        return ServiceGroup.groupServices(filteredServices)[0];
      }),
      finalize(() => {
        if (showSpinner) {
          this.loadingAlerts.dismissLoader();
        }
      })
    );
  }

  getFaqs(name: ServiceName) {
    this.addressService.getFaqs(name, this.webflowVariant.faqTags).subscribe((faq) => (this.faq = faq));
  }

  filterServices(services: Service[]): Service[] {
    return services.filter((service) =>
      this.webflowVariant.serviceNameSearch.some((el) => service.name.toLowerCase().includes(el))
    );
  }

  clearLocationData() {
    this.formData = {};
    this.address = null;
  }

  addressValidates() {
    return (
      this.formData?.locationName &&
      this.formData?.lat &&
      this.formData?.lng &&
      this.formData?.zipCode
    );
  }
}
