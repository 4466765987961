import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Vehicle } from '../../models/vehicle';

@Component({
  selector: 'ysh-vehicle-image',
  templateUrl: 'vehicle-image.html',
  styleUrls: ['./vehicle-image.scss'],
})
export class VehicleImageComponent {
  @Input() src: string;
  @Input() selectedVehicle: Vehicle;
  @Output() onTap: EventEmitter<any> = new EventEmitter();
  @Input() displayMode: 'highlighted' | 'grayed';
  @Input() canAddImg = false;
  imageReady: boolean;

  didTapVehicleImage() {
    this.onTap.emit();
  }

  imageDidLoad() {
    this.imageReady = true;
  }

  errorLoadingImage() {
    this.imageReady = false;
  }

  // TODO: Improve this hack for specifying the positioning of a FuelApi image
  isFuelApiImage() {
    return this.src && this.src.includes('fuelapi');
  }
}
