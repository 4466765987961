import { Component, Input } from '@angular/core';

import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-vehicle-license-plate',
  templateUrl: './vehicle-license-plate.page.html',
  styleUrls: ['./vehicle-license-plate.page.scss'],
})
export class VehicleLicensePlatePage {
  @Input() isModal: boolean;
  @Input() allowSkip: boolean = true;
  @Input() selectedVehicle: Vehicle;
  @Input() onComplete: (licensePlate: string) => void;
  @Input() onDismiss: () => void;

  licensePlate = '';

  readonly addLicensePlateTitle: string = 'What is your license plate number?';
  readonly descriptionMsg: string =
    'Entering your plate number helps ensure we fill up the correct vehicle.';
  readonly MAX_LICENSE_LENGTH: number = 9;
  readonly updatingVehicleLoaderText: string = 'Updating Vehicle, please wait..';
  readonly vehicleUpdatedSuccessMsg: string = 'Vehicle Updated';

  constructor(
    private vehicleService: VehiclesService,
    private loadingAlertCtrl: LoadingAlertService
  ) {}

  didTapBack() {
    this.onDismiss?.();
  }

  didTapNext() {
    if (this.selectedVehicle) {
      this.saveLicenseToVehicle();
    } else {
      this.onComplete?.(this.licensePlate);
    }
  }

  saveLicenseToVehicle() {
    this.loadingAlertCtrl.showLoaderWithText(this.updatingVehicleLoaderText);
    this.vehicleService
      .updateVehicle(this.selectedVehicle, { license: this.licensePlate })
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(
        () => {
          this.loadingAlertCtrl.showToastConfirmation(this.vehicleUpdatedSuccessMsg);
          this.onComplete?.(this.licensePlate);
        },
        (error) => {
          this.loadingAlertCtrl.showToastAlert(error);
        }
      );
  }
}
