import { BaseEntity, MemoizeGetters, Transform, toDate } from './base';

@MemoizeGetters
export class Receipt extends BaseEntity {
  amount: string;
  chargeStatus: InvoiceChargeStatus;
  loyaltyPoints: string;

  @Transform(toDate)
  date: Date;
}

export enum InvoiceChargeStatus {
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  NotStarted = 'Not started',
  Waiting = 'Waiting to retry',
}
