import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PlacesAutocompletePageRoutingModule } from './places-autocomplete-routing.module';

import { PlacesAutocompletePage } from './places-autocomplete.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PlacesAutocompletePageRoutingModule
  ],
  declarations: [PlacesAutocompletePage]
})
export class PlacesAutocompletePageModule {}
