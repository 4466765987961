import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Subject } from 'rxjs';
import { Vehicle } from 'src/app/models/vehicle';

@Component({
  selector: 'ysh-vehicle-color',
  templateUrl: './vehicle-color.page.html',
  styleUrls: ['./vehicle-color.page.scss'],
})
export class VehicleColorPage implements OnInit, OnDestroy {
  @Input() onDismiss: () => void;
  @Input() onComplete: (data: string) => void;

  colors = Vehicle.availableColors;
  selectedColor: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private analytics: AnalyticsService) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('VehicleColorPage');
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  didTapDismiss() {
    this.onDismiss?.();
  }

  didSelectColor(color: string) {
    this.selectedColor = color;
    this.onComplete?.(color);
  }
}
