import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotesForFillerPage } from './notes-for-filler.page';

const routes: Routes = [
  {
    path: '',
    component: NotesForFillerPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotesForFillerPageRoutingModule {}
