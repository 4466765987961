import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';

import { IonNav } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PowerUpsPage } from '../power-ups/power-ups.page';
import { ServiceGroup } from 'src/app/models/service-group';
import { Subject } from 'rxjs';

@Component({
  selector: 'ysh-upsell-flow',
  template: '<ion-nav #upsellNav></ion-nav>',
})
export class UpsellFlowControllerPage implements Flow, AfterViewInit, OnDestroy {
  @ViewChild('upsellNav') nav: IonNav;
  @Input() addOns: ServiceGroup[];

  flowDirector: FlowDirector;

  didSkipPowerUps = false;

  private unsubscribe = new Subject<any>();

  constructor(private modalService: ModalService) {}

  // Angular

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.flowDirector = new FlowDirector(this);
  }

  // Flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.didSkipPowerUps && this.addOns?.length) {
      return this.optionsForPowerUpsPage();
    }
  }

  flowDidComplete(): void {
    this.modalService.dismissModal();
  }

  // Params

  optionsForPowerUpsPage(): FlowPageOptions<PowerUpsPage> {
    return {
      page: PowerUpsPage,
      onComplete: () => this.didCompletePowerUpsFlow(),
      pageProps: {
        actionText: 'Skip',
        services: this.addOns,
      },
    };
  }

  didCompletePowerUpsFlow() {
    this.didSkipPowerUps = true;
    this.flowDirector.next();
  }
}
