import * as mapboxgl from 'mapbox-gl';

import { Injectable } from '@angular/core';
import { YoshiMapStyle } from 'src/theme/map-style';

interface MapServiceOptions {
  style: MapStyle;
  zoom: number;
  center: mapboxgl.LngLatLike;
}

export enum MapStyle {
  None = '',
  RoadmapWithNoData = 'mapbox://styles/startyoshi/cl12ofuhh005v14rdvico8ime?optimize=true',
  SatelliteWithData = 'mapbox://styles/startyoshi/cl12htxpv000114lgb32nyf4t',
}
const MAP_BOX_KEY =
  'pk.eyJ1Ijoic3RhcnR5b3NoaSIsImEiOiJjbDEyaGw5aDEwMDVwM2NxZXVkYzluMmJvIn0.hIzcvUSp9Mah2FGdmXt-NQ';
const REMOTE_FEATURE_LAYER = 'site-map-polygons';
const ADDRESS_MARKER_URL = 'assets/images/map-marker-flag.png';

export const LOCAL_FEATURE_LAYER = 'local-features';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor() {
    (mapboxgl as any).accessToken = MAP_BOX_KEY;
  }
  private readonly defaultMapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    styles: YoshiMapStyle,
  };

  createMap(container, options: google.maps.MapOptions): Promise<google.maps.Map> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const map = new google.maps.Map(container, {
          ...options,
          ...this.defaultMapOptions,
        });
        if (map) {
          resolve(map);
          return;
        }
        reject("Couldn't render map");
      });
    });
  }

  createMBMap(container: HTMLElement | string, options: MapServiceOptions) {
    return new mapboxgl.Map({
      container,
      style: options.style,
      center: options.center,
      zoom: options.zoom,
    });
  }

  createAddressMarker(lng: number, lat: number) {
    const markerElement = document.createElement('div');
    markerElement.className = 'marker';
    markerElement.style.height = '64px';
    markerElement.style.width = '64px';
    markerElement.style.backgroundImage = `url(${ADDRESS_MARKER_URL})`;
    return new mapboxgl.Marker(markerElement).setLngLat([lng, lat]).setOffset([0, -32]);
  }

  getFeatures(map: mapboxgl.Map) {
    const layers: string[] = [];
    if (map.getLayer(REMOTE_FEATURE_LAYER)) {
      layers.push(REMOTE_FEATURE_LAYER);
    }
    if (map.getLayer(LOCAL_FEATURE_LAYER)) {
      layers.push(LOCAL_FEATURE_LAYER);
    }
    const mapRef = map.getContainer();
    return map.queryRenderedFeatures([mapRef.offsetWidth / 2, mapRef.offsetHeight / 2], {
      layers,
    });
  }
}
