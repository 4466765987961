import { ApiService, EmptySubscriber } from '../api.service';
import { AppState, getAll } from 'src/state/state';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import { Referral, ReferralContact, ReferralCreateParams } from 'src/app/models/referral';
import { finalize, map, tap } from 'rxjs/operators';

import { CollectionActions } from '../../../../state/actions';
import { Injectable } from '@angular/core';
import { LoyaltyService } from 'src/app/services/api/loyalty/loyalty.service';
import { OrderScheduleService } from '../../scheduling/order-schedule/order-schedule.service';
import { ReferralProgram } from 'src/app/models/referral-program';
import { ReferralProgramType } from '../../../models/referral-program';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  defaultReferrals$: Observable<Referral[]>;
  loyaltyReferrals$: Observable<Referral[]>;
  defaultReferralProgram$: Observable<Nullable<ReferralProgram>>;
  loyaltyReferralProgram$: Observable<Nullable<ReferralProgram>>;
  preferredProgram$: Observable<Nullable<ReferralProgram>>;
  referralPrograms$ = new BehaviorSubject<ReferralProgram[]>([]);

  constructor(
    private api: ApiService,
    private collectionActions: CollectionActions,
    private featureFlags: FeatureFlagService,
    private loyaltyService: LoyaltyService,
    private scheduleHelper: OrderScheduleService,
    private store: Store<AppState>
  ) {
    this.defaultReferralProgram$ = this.getDefaultProgram$();
    this.loyaltyReferralProgram$ = this.getLoyaltyReferralProgram$();
    this.preferredProgram$ = this.getPreferredProgram$();
    this.defaultReferrals$ = store
      .select((state) => getAll(state, Referral))
      .pipe(
        map((referrals) =>
          referrals.filter((referral) => referral.type === ReferralProgramType.Default)
        )
      );
    this.loyaltyReferrals$ = store
      .select((state) => getAll(state, Referral))
      .pipe(
        map((referrals) =>
          referrals.filter((referral) => referral.type === ReferralProgramType.Loyalty)
        )
      );
  }

  // Benefits Policies
  getReferralPrograms() {
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/referral_programs`,
        method: 'GET',
      })
      .pipe(
        map((result) => {
          return result.map((item) => new ReferralProgram(item));
        }),
        tap((result = []) => {
          this.referralPrograms$.next(result);
          return;
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  private getDefaultProgram$() {
    return combineLatest([
      this.referralPrograms$,
      this.featureFlags.flag$(Flag.AllowDefaultReferrals),
      this.scheduleHelper.currentAddress$,
    ]).pipe(
      map(([programs, flagActive, userAddress]) => {
        const defaultReferralProgram = programs.find(
          (program) => program.programType === ReferralProgramType.Default
        );
        return flagActive ? defaultReferralProgram : null;
      })
    );
  }

  private getLoyaltyReferralProgram$() {
    return combineLatest([this.referralPrograms$, this.loyaltyService.hasReferralPerk$]).pipe(
      map(([programs, hasPerk]) => {
        const loyaltyReferralProgram = programs.find(
          (program) => program.programType === ReferralProgramType.Loyalty
        );
        return hasPerk ? loyaltyReferralProgram : null;
      })
    );
  }

  private getPreferredProgram$() {
    return combineLatest([this.defaultReferralProgram$, this.loyaltyReferralProgram$]).pipe(
      map(([defaultReferralProgram, loyaltyReferralProgram]) => {
        return loyaltyReferralProgram?.seats ? loyaltyReferralProgram : defaultReferralProgram;
      })
    );
  }

  getReferrals(program: ReferralProgram) {
    this.store.dispatch(this.collectionActions.setLoading(Referral, true));
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/referrals?referral_program_uid=${program.uid}`,
        method: 'GET',
      })
      .pipe(
        map((result) => {
          if (!result.error) {
            return result.map((item) => new Referral(item, program.programType));
          } else {
            return [];
          }
        }),
        finalize(() => this.store.dispatch(this.collectionActions.setLoading(Referral, false))),
        tap((referrals) => {
          this.store.dispatch(this.collectionActions.set(Referral, referrals));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  createReferral(contacts: ReferralContact[], program: ReferralProgram) {
    const params: ReferralCreateParams = {
      contacts,
      referralProgramUid: program.uid,
    };
    return this.api
      .call({
        url: `/users/${ApiService.USER_UID}/referrals`,
        method: 'POST',
        body: params,
      })
      .pipe(
        map((referrals) =>
          referrals.map((referral) => new Referral(referral, program.programType))
        ),
        tap((referrals) =>
          referrals.forEach((referral) =>
            this.store.dispatch(this.collectionActions.insert(Referral, referral))
          )
        )
      );
  }

  resendReferral(referral: Referral) {
    return this.api.call({
      url: `/users/${ApiService.USER_UID}/resent_referrals`,
      params: {
        referral_uid: referral.uid,
      },
      method: 'POST',
    });
  }
}
