import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfirmCancellationPage } from './confirm-cancellation.page';

const routes: Routes = [
  {
    path: '',
    component: ConfirmCancellationPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfirmCancellationPageRoutingModule {}
