import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ysh-add-service',
  templateUrl: 'add-service.html',
  styleUrls: ['./add-service.scss'],
})
export class AddServiceComponent implements OnInit, OnChanges {
  @Input() added: boolean;
  @Input() price: number;
  @Input() displayMode: 'block' | 'small-full' | 'small' | 'medium' | 'large';
  @Output() didTapAddService: EventEmitter<any> = new EventEmitter();

  action = '';
  serviceName = '';
  constructor() {}

  ngOnInit() {
    this.updateButton();
  }

  ngOnChanges() {
    this.updateButton();
  }

  updateButton() {
    if (this.added) {
      this.action = 'added!';
    } else if (this.displayMode === 'block' || this.displayMode === 'small-full') {
      this.action = 'add service';
    } else {
      this.action = 'add';
    }
  }

  didTap($event) {
    if (!this.added) {
      this.didTapAddService.emit($event);
    }
  }
}
