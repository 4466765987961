import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleForFrequency',
})
export class TitleForFrequencyPipe implements PipeTransform {
  transform(days: number) {
    return titleForFrequency(days);
  }
}

export function titleForFrequency(days: number = 0) {
  let title, unit, period;
  if (!days) {
    title = 'One time';
  } else {
    if (days < 30) {
      unit = 'week';
      period = days / 7;
    } else if (days < 365) {
      unit = 'month';
      period = days / 30;
    } else {
      unit = 'year';
      period = days / 365;
    }
    period = Math.round(period);
    if (period > 1) {
      title = `${period} ${unit}s`;
    } else {
      title = `${unit}`;
    }
  }
  return title;
}
