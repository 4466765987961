import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { VehicleProfilePage } from './vehicle-profile.page';
import { VehicleProfileRoutes } from './routes';

const routes: Routes = [
  {
    path: VehicleProfileRoutes.Root,
    component: VehicleProfilePage,
  },
  {
    path: VehicleProfileRoutes.Recalls,
    loadChildren: () =>
      import('./vehicle-recalls/vehicle-recalls.module').then((m) => m.VehicleRecallsPageModule),
  },
  {
    path: VehicleProfileRoutes.RecallDetail,
    loadChildren: () =>
      import('./recall-details/recall-details.module').then((m) => m.RecallDetailsPageModule),
  },
  {
    path: VehicleProfileRoutes.Mileage,
    loadChildren: () =>
      import('./vehicle-mileage/vehicle-mileage.module').then((m) => m.VehicleMileagePageModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehicleProfilePageRoutingModule {}
