import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ysh-input',
  templateUrl: 'ysh-input.html',
  styleUrls: ['./ysh-input.scss'],
})

export class YshInputComponent {
  @Input() placeholder: string;
  @Input() value: string;
  @Input() readonly: boolean;
  @Input() appearence: 'underline' | 'outline' | 'underline-drop' | 'outline-drop';
  @Output() click: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
