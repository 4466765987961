import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { first, map, takeUntil, tap } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavigationService } from 'src/app/navigation.service';
import { OrderScheduleService } from 'src/app/services/scheduling/order-schedule/order-schedule.service';
import { OrderService } from 'src/app/services/api/order/order.service';
import { ServiceGroup } from 'src/app/models/service-group';
import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-power-ups',
  templateUrl: './power-ups.page.html',
  styleUrls: ['./power-ups.page.scss'],
})
export class PowerUpsPage implements OnInit, OnDestroy {
  @Input() services: ServiceGroup[] = [];
  @Input() actionText = 'Finish';
  @Input() onComplete: () => void;
  @Input() modal = false;

  selectedVehicle: Nullable<Vehicle>;
  serviceMap = {};

  private unsubscribe = new Subject<any>();

  constructor(
    public orderSchedule: OrderScheduleService,
    public vehicleService: VehiclesService,
    private orderService: OrderService,
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private navigationService: NavigationService
  ) {
    this.vehicleService.selectedVehicle$.pipe(takeUntil(this.unsubscribe)).subscribe((vehicle) => {
      this.selectedVehicle = vehicle;
      this.updateScheduledMap();
    });
  }

  private updateScheduledMap() {
    combineLatest([this.orderService.serviceOrders$, this.vehicleService.selectedVehicle$])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([orders, vehicle]) => {
        const serviceMap = {};
        const serviceOrders = orders.filter(
          (order) => order.isScheduled && vehicle?.uid === order.vehicle.uid
        );
        if (serviceOrders.length) {
          this.actionText = 'Next';
        }
        serviceOrders.forEach((order) => {
          serviceMap[order.service.serviceType.name] = true;
        });
        this.serviceMap = serviceMap;
      });
  }

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('PowerUpsPage');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // actions
  didTapFinish() {
    this.onComplete();
  }

  dismiss() {
    this.modal ? this.modalService.dismissModal() : this.navigationService.goBack();
  }
}
