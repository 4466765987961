import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { VehicleYearPage } from './vehicle-year.page';
import { VehicleYearPageRoutingModule } from './vehicle-year-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, VehicleYearPageRoutingModule, ComponentsModule],
  declarations: [VehicleYearPage],
})
export class VehicleYearPageModule {}
