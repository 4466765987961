import { BaseEntity, Transform } from '../base';
import { PerkName } from './perk';
import { TierName } from './tier';

export class LoyaltyReward extends BaseEntity {
  autoRedeem: boolean;
  benefitPackageName: string;
  description: string;
  name: PerkName;
  reward: string;
  tier: TierName;

  @Transform(parseInt)
  price: number;
}
