import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UserLocationPageRoutingModule } from './user-location-routing.module';

import { UserLocationPage } from './user-location.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UserLocationPageRoutingModule,
    ComponentsModule,
  ],
  declarations: [UserLocationPage],
})
export class UserLocationPageModule {}
