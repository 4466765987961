export class TypedFlow {
  flowIndex = 0;
  selectOptions = { title: ' ' };
  typing = false;
  pageSelector = '';
  private skipped = 0;

  advanceToIndex(index) {
    this.flowIndex = index;
    const selector = this.pageSelector + ' .flowItem';
    const flowItems = document.querySelectorAll(selector);
    let i;
    for (i = 0; i < flowItems.length; ++i) {
      flowItems[i].classList.remove('past');
      flowItems[i].classList.remove('active');
      if (i < index) {
        flowItems[i].classList.add('past');
      }
      if (i === index) {
        flowItems[i].classList.add('active');
      }
    }
  }

  start(index = 0) {
    this.advanceToIndex(index);
  }

  next(skip = 0) {
    this.skipped = skip;
    const index = this.flowIndex + 1 + skip;
    this.advanceToIndex(index);
  }

  back() {
    this.advanceToIndex(this.flowIndex - 1 - this.skipped);
    this.skipped = 0;
  }
}
