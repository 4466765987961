import { BehaviorSubject } from 'rxjs';
import { EventsService } from '../events/events.service';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SessionService } from '../session/session.service';
import { environment } from '../../../environments/environment';

export enum ServerEnvironment {
  Beta = 'beta',
  Staging = 'staging',
  Prod = 'prod',
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private static readonly SERVER_ENVIRONMENT_KEY = 'SERVER_ENVIRONMENT';

  serverEnvironment$: BehaviorSubject<ServerEnvironment> = new BehaviorSubject(
    this.defaultServerEnvironment()
  );

  constructor(
    private session: SessionService,
    private events: EventsService,
    private platform: Platform
  ) {
    this.retrieveStoredServerEnvironment();

    // Reset the server environment after login events since local storage may have been cleared
    this.events.subscribe('userDidLogout', () => {
      this.session.set(EnvironmentService.SERVER_ENVIRONMENT_KEY, this.serverEnvironment$.value);
    });
    this.events.subscribe('userDidLogin', () => {
      this.session.set(EnvironmentService.SERVER_ENVIRONMENT_KEY, this.serverEnvironment$.value);
    });
  }

  // Environment Management

  isServerBeta() {
    return this.serverEnvironment$.value === ServerEnvironment.Beta;
  }

  isServerStaging() {
    return this.serverEnvironment$.value === ServerEnvironment.Staging;
  }

  isServerProd() {
    return this.serverEnvironment$.value === ServerEnvironment.Prod;
  }

  serverVars(): ServerVars {
    if (this.isServerBeta()) {
      return betaServer;
    } else if (this.isServerStaging()) {
      return stagingServer;
    } else {
      return prodServer;
    }
  }

  switchServer(env: ServerEnvironment) {
    return this.session.set(EnvironmentService.SERVER_ENVIRONMENT_KEY, env).then(() => {
      this.serverEnvironment$.next(env);
    });
  }

  private defaultServerEnvironment(): ServerEnvironment {
    return this.platform.is('cordova') || environment.production
      ? ServerEnvironment.Prod
      : ServerEnvironment.Beta;
  }

  private retrieveStoredServerEnvironment() {
    return this.session
      .get(EnvironmentService.SERVER_ENVIRONMENT_KEY)
      .then((value?: ServerEnvironment) => {
        this.serverEnvironment$.next(value || this.defaultServerEnvironment());
      });
  }
}

export interface ServerVars {
  readonly apiEndpoint: string;
  readonly websocketUrl: string;
  readonly environmentName: string;
  readonly gmAuthURL: string;
  readonly fbAppId: number;
  readonly shareUrl;
  readonly stripePublishableKey;
  readonly launchDarklyKey;
  readonly toyotaAuthURL: string;
  readonly lexusAuthURL: string;
}

// Environment Management
export const betaServer: ServerVars = {
  apiEndpoint: 'https://api.beta.startyoshi.com/v0',
  shareUrl: 'http://api.beta.startyoshi.com/users/',
  websocketUrl: 'wss://api.beta.startyoshi.com/cable',
  environmentName: 'Beta',
  stripePublishableKey: 'pk_test_HcrdetnFsWge0pK6z1g78ls2',
  gmAuthURL:
    'https://api.gm.com/MIGWeb/consent/consent?response_type=code&client_id=l7xx2r12321312yos233511453235pr469&redirect_uri=yoshi://&scope=ACCOUNT_DATA%20VEHICLE_DATA%20REMOTE_COMMANDS%20DIAGNOSTICS',
  fbAppId: 942555735798943,
  launchDarklyKey: '5f626d5a7dadb80af1f3efe6',
  toyotaAuthURL:
    'https://access.stg.np-sap-tc.com/oauth/authorize?state=open&response_type=code&scope=vehicle_info+lock+unlock+fuel+location&redirect_uri=yoshi://&client_id=54122831-05db-4705-8f37-c05cf2496117',
  lexusAuthURL: '', // Todo - use same url as toyotaAuthURL but with Lexus client_id once received
};

export const stagingServer: ServerVars = {
  apiEndpoint: 'https://api.staging.startyoshi.com/v0',
  shareUrl: 'http://www.staging.startyoshi.com/users/',
  websocketUrl: 'wss://api.staging.startyoshi.com/cable',
  environmentName: 'Staging',
  stripePublishableKey: 'pk_test_HcrdetnFsWge0pK6z1g78ls2',
  gmAuthURL:
    'https://api.gm.com/MIGWeb/consent/consent?response_type=code&client_id=l7xx2r12321312yos233511453235pr469&redirect_uri=yoshi://&scope=ACCOUNT_DATA%20VEHICLE_DATA%20REMOTE_COMMANDS%20DIAGNOSTICS',
  fbAppId: 942555735798943,
  launchDarklyKey: '5f626d5a7dadb80af1f3efe6',
  toyotaAuthURL: '',
  lexusAuthURL: '',
};

export const prodServer: ServerVars = {
  apiEndpoint: 'https://api.startyoshi.com/v0',
  websocketUrl: 'wss://api.startyoshi.com/cable',
  environmentName: 'Production',
  shareUrl: 'http://www.startyoshi.com/users/',
  stripePublishableKey: 'pk_live_TsPI4HEiJ0TNO2Zbhw6iSS12',
  gmAuthURL:
    'https://api.gm.com/MIGWeb/consent/consent?response_type=code&client_id=l7xx2r12321312yos233511453235pr469&redirect_uri=yoshi://&scope=ACCOUNT_DATA%20VEHICLE_DATA%20REMOTE_COMMANDS%20DIAGNOSTICS',
  fbAppId: 942555735798943,
  launchDarklyKey: '5f626d5a7dadb80af1f3efe7',
  toyotaAuthURL:
    'https://access.sap-tc.com/oauth/authorize?state=open&response_type=code&scope=vehicle_info+lock+unlock+fuel+location&redirect_uri=yoshi://&client_id=efef30b5-e1b0-4565-b25d-47e7e11dd5f5',
  lexusAuthURL:
    'https://access.sap-tc.com/oauth/authorize?state=open&response_type=code&scope=vehicle_info+lock+unlock+fuel+location&redirect_uri=yoshi://&client_id=db286b8f-9600-4e50-a81d-9b19763ea24f',
};
