import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { VehicleFlowController } from './vehicle-flow-controller.page';
import { VehicleFlowControllerPageRoutingModule } from './vehicle-flow-controller-routing.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, VehicleFlowControllerPageRoutingModule],
  declarations: [VehicleFlowController],
})
export class VehicleFlowControllerPageModule {}
