import { Component, EventEmitter, Output } from '@angular/core';

import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-vehicle-profile-information',
  templateUrl: './vehicle-profile-information.component.html',
  styleUrls: ['./vehicle-profile-information.component.scss'],
})
export class VehicleProfileInformationComponent {
  @Output() onDidTapEdit: EventEmitter<string> = new EventEmitter();

  vehicle: Vehicle;

  constructor(private vehicleService: VehiclesService) {
    this.vehicleService.selectedVehicle$.subscribe((vehicle: Vehicle) => {
      this.vehicle = vehicle;
    });
  }

  get missingInfo(): string {
    if (!this.vehicle.hasCustomNickname) {
      return 'nickname';
    }
    if (!this.vehicle.license) {
      return 'license';
    }
    if (!this.vehicle.vin) {
      return 'vin';
    }

    return '';
  }

  didTapEdit() {
    this.onDidTapEdit.emit(this.missingInfo);
  }
}
