import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { DaySelectorPage, RepeatFrequencyGas } from '../selectors/day-selector/day-selector.page';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';
import { Observable, combineLatest } from 'rxjs';
import { Order, OrderCancelReason, OrderParams, OrderStatus } from 'src/app/models/order';
import {
  OrderCreateParams,
  TimeWindow,
} from 'src/app/services/scheduling/order-create/order-create.service';
import { PinFlowPage, PinFlowPageForm } from '../pin-flow/pin-flow.page';
import {
  RequestedWindow,
  TimeWindowSelectorPage,
} from '../selectors/time-window-selector/time-window-selector.page';
import { Shift, ShiftName } from 'src/app/models/shift';
import {
  VehicleSubscription,
  VehicleSubscriptionParams,
} from 'src/app/models/vehicle-subscription';
import { finalize, flatMap } from 'rxjs/operators';

import { Day } from 'src/app/models/day';
import { EditModes } from 'src/app/services/scheduling/order-edit/edit-modes';
import { IonNav } from '@ionic/angular';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { LocationPickerResponse } from '../../ordering/pin-flow/location-picker/location-picker';
import { ModalService } from 'src/app/services/modal/modal.service';
import { OrderService } from 'src/app/services/api/order/order.service';
import { Route } from 'src/app/models/route';
import { ServiceName } from 'src/app/models/service-type';
import { TimeSlot } from 'src/app/models/time-slot';
import { TimeSlotSelectorPage } from '../selectors/time-slot-selector/time-slot-selector.page';
import { UserAddress } from 'src/app/models/user-address';
import moment from 'moment';

export interface EditFlowControllerPageProps {
  order: Order;
  subscription: Nullable<VehicleSubscription>;
  editMode: EditModes;
}

interface EditFlowControllerPageForm {
  days: Day[];
  address?: UserAddress;
  timeWindow?: Nullable<TimeWindow>;
  slotStartTime?: Nullable<string>;
  shift?: Nullable<Shift>;
  route?: Nullable<Route>;
  note?: Nullable<string>;
  frequency?: Nullable<number>;
  persistDeliveryLocation: boolean;
  mapInfo?: Nullable<LocationPickerResponse>;
}

@Component({
  selector: 'ysh-edit-flow-controller',
  template: '<ion-nav #editNav [class.animate]="animate" ></ion-nav>',
  styleUrls: ['./edit-flow-controller.page.scss'],
})
export class EditFlowControllerPage implements Flow, AfterViewInit {
  @ViewChild('editNav', { static: true }) nav: IonNav;

  @Input() props: EditFlowControllerPageProps;

  flowDirector: FlowDirector;
  form: EditFlowControllerPageForm;

  addressWasChanged = false;
  completedDaySelection = false;
  completedTimeSelection = false;
  completedPinSelection = false;

  forGasOrder = false;
  animate = true;

  constructor(
    private modalService: ModalService,
    private loadingAlert: LoadingAlertService,
    private orderService: OrderService
  ) {}

  ngAfterViewInit() {
    this.forGasOrder = this.props.order.service.serviceType.name === ServiceName.Gas;
    this.form = this.initForm(this.props);
    this.configureForMode(this.props.editMode);
    setTimeout(() => (this.animate = false), 300);
  }

  private initForm(props: EditFlowControllerPageProps): EditFlowControllerPageForm {
    const days = this.getDays(props.order, props.subscription);
    return {
      days,
      timeWindow: this.getWindow(props.order),
      shift: props.order.shift,
      route: this.getRoute(props.order, days),
      persistDeliveryLocation: props.order.deliveryLocation.persistent,
    };
  }

  private configureForMode(editMode: EditModes) {
    let options: FlowPageOptions<FlowPage>;
    if (editMode === EditModes.EditDay) {
      options = this.optionsForDaySelectorPage();
    } else if (editMode === EditModes.EditTimeWindow) {
      options = this.optionsForTimewindowSelectorPage(true);
    } else if (editMode === EditModes.EditTimeSlot) {
      options = this.optionsForTimeslotSelectorPage(true);
    } else {
      options = this.optionsForPinFlowPage();
    }
    this.flowDirector = new FlowDirector(this, options);
  }

  private getDays(order: Order, subscription: Nullable<VehicleSubscription>) {
    const availableDays = order.userAddress.getDays(undefined, order.service);
    let days: Day[] = [];
    if (subscription?.days?.length) {
      days = availableDays.filter((day) => {
        return subscription.days.indexOf(day.dayOfTheWeek.toLowerCase()) > -1;
      });
    } else {
      const orderDay = moment(order.date).day();
      const availableDay = availableDays.find((day: Day) => day.dayIndex === orderDay);
      days = availableDay ? [availableDay] : [];
    }
    return days;
  }

  private getRoute(order: Order, days: Day[]): Route | undefined {
    return days[0]?.routes.filter((route) => route.shift.uid === order.shift?.uid)[0];
  }

  private getWindow(order: Order) {
    return {
      title: order.shiftWindow,
      startTime: order.shiftStartTime.hour,
      endTime: order.shiftEndTime.hour,
    };
  }

  // Flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    const slotEditMode = this.props.editMode === EditModes.EditTimeSlot;
    const timeEditMode = this.props.editMode === EditModes.EditTimeWindow;
    const pinEditMode = this.props.editMode === EditModes.EditLocation;
    if (!slotEditMode && !timeEditMode && !pinEditMode && !this.completedDaySelection) {
      return this.optionsForDaySelectorPage();
    }
    if (!pinEditMode && !this.completedTimeSelection && this.props.order.service.slotScheduling) {
      return this.optionsForTimeslotSelectorPage();
    }
    if (!pinEditMode && !this.completedTimeSelection && !this.props.order.service.slotScheduling) {
      return this.optionsForTimewindowSelectorPage();
    }
    if (!this.completedPinSelection) {
      return this.optionsForPinFlowPage();
    }
  }

  flowDidComplete(): void {
    this.didFinishEditOrderFlow();
    this.dismiss();
  }

  // Params

  optionsForDaySelectorPage(): FlowPageOptions<DaySelectorPage> {
    return {
      page: DaySelectorPage,
      onComplete: (days) => this.didSelectDays(days),
      onDismiss: () => this.props.editMode === EditModes.EditDay && this.dismiss(),
      props: {
        onAddressChanged: (address) => this.didChangeAddress(address),
        userAddress: this.form.address || this.props.order.userAddress,
        days: this.form.days,
        allowFrequencySelect: Boolean(this.props.subscription),
        editing: true,
        showCutoff: false,
        selectedGasFrequency: this.defaultFrequency(),
        selectedFrequency:
          this.props.subscription?.frequency || this.props.order.service.defaultFrequency,
        service: this.props.order.service,
      },
    };
  }

  private defaultFrequency(): RepeatFrequencyGas {
    if (!this.props.subscription) {
      return RepeatFrequencyGas.OneTime;
    } else if (this.form.days && this.form.days.length === 2) {
      return RepeatFrequencyGas.TwiceWeekly;
    } else {
      return RepeatFrequencyGas.OnceWeekly;
    }
  }

  optionsForTimewindowSelectorPage(isModal = false): FlowPageOptions<TimeWindowSelectorPage> {
    return {
      page: TimeWindowSelectorPage,
      onComplete: (shift: Shift, requestedWindow: { upper: number; lower: number }) => {
        this.didSelectTimeWindow(shift.title, requestedWindow);
      },
      onDismiss: () => this.props.editMode === EditModes.EditTimeWindow && this.dismiss(),
      props: {
        onAddressChanged: (address) => this.didChangeAddress(address),
        day: this.form.days[0],
        selectedShift: this.form.route?.shift,
        selectedWindow: this.form.timeWindow
          ? {
              lower: this.form.timeWindow!.startTime,
              upper: this.form.timeWindow!.endTime,
            }
          : undefined,
        service: this.props.order?.service,
        isModal,
      },
    };
  }

  optionsForTimeslotSelectorPage(isModal = false): FlowPageOptions<TimeSlotSelectorPage> {
    return {
      page: TimeSlotSelectorPage,
      onComplete: (requestedSlot) => this.didSelectTimeSlot(requestedSlot),
      onDismiss: () => (this.form.slotStartTime = null),
      props: {
        onAddressChanged: (address: UserAddress) => this.didChangeAddress(address),
        userAddress: this.props.order.userAddress,
        currentOrder: this.props.order,
        selectedSlotStartTime: this.form.slotStartTime! || this.props.order.slotStartDateTime,
        service: this.props.order?.service,
        day: this.form.days[0],
        isModal: isModal,
      },
    };
  }

  optionsForPinFlowPage(): FlowPageOptions<PinFlowPage> {
    return {
      page: PinFlowPage,
      onComplete: (result) => this.didSelectPinLocation(result),
      onDismiss: () => this.props.editMode === EditModes.EditLocation && this.dismiss(),
      props: {
        onAddressChanged: (address) => this.didChangeAddress(address),
        deliveryLocation: this.form.address ? null : this.props.order.deliveryLocation,
        userAddress: this.form.address || this.props.order.userAddress,
        editing: true,
        service: this.props.order.service,
        forToday: moment(new Date()).isSame(this.props.order.date, 'd'),
        orderClaimed: this.props.order.status === OrderStatus.Claimed,
      },
    };
  }

  dismiss() {
    this.modalService.dismissModal();
  }

  // Actions

  didSelectDays(days: Day[]) {
    this.form.days = days;
    this.form.route = null;
    this.form.shift = null;
    this.form.timeWindow = null;
    this.completedDaySelection = true;
    this.flowDirector.next();
  }

  didSelectTimeWindow(shift: ShiftName, requestedWindow: RequestedWindow) {
    this.form.timeWindow = {
      title: shift,
      startTime: requestedWindow.lower,
      endTime: requestedWindow.upper,
    };
    this.completedTimeSelection = true;
    this.flowDirector.next();
  }

  didSelectTimeSlot(requestedSlot: TimeSlot) {
    this.form.slotStartTime = requestedSlot.startTime;
    this.completedTimeSelection = true;
    this.flowDirector.next();
  }

  didSelectPinLocation(result: PinFlowPageForm) {
    this.form.mapInfo = result.mapInfo;
    this.form.note = result.note;
    this.form.persistDeliveryLocation = result.persistDeliveryLocation || false;
    this.completedPinSelection = true;
    this.flowDirector.next();
  }

  didChangeAddress(address: UserAddress) {
    this.form.address = address;
    this.form.timeWindow = null;
    this.form.shift = null;
    this.addressWasChanged = true;
    this.flowDirector.setRoot(this.optionsForDaySelectorPage());
  }

  didFinishEditOrderFlow() {
    const params: OrderCreateParams = {
      order: this.props.order,
      subscription: this.props.subscription,
      driverNote: this.form.note,
      persistent: this.form.persistDeliveryLocation,
    };

    if (this.form.slotStartTime) {
      params.slotStartDateTime = this.form.slotStartTime;
    }

    if (this.form.timeWindow && this.form.timeWindow?.title !== this.props.order.shiftWindow) {
      params.shift = this.form.timeWindow.title;
    }
    if (
      this.form.timeWindow &&
      this.form.timeWindow.startTime !== this.props.order.shiftStartTime.hour
    ) {
      params.startTime = this.form.timeWindow.startTime;
    }
    if (
      this.form.timeWindow &&
      this.form.timeWindow.endTime !== this.props.order.shiftEndTime.hour
    ) {
      params.endTime = this.form.timeWindow.endTime;
    }

    if (this.addressWasChanged && this.form.address) {
      params.address = this.form.address;
    }
    if (this.completedDaySelection) {
      params.days = this.form.days;
    }
    if (this.form.mapInfo) {
      params.mapInfo = this.form.mapInfo;
    }
    this.updateOrderOrSubscription(params);
  }

  // Data

  async updateOrderOrSubscription(params: OrderCreateParams) {
    if (!params.order && !params.subscription) {
      return;
    }
    let request;
    if (params.days) {
      if (params.subscription) {
        request = this.updateSubscriptionWithNewDays(params);
      } else {
        request = this.rescheduleOrder(params);
      }
    } else {
      request = this.updateSubscriptionAndOrder(params);
    }
    this.loadingAlert.showLoader();
    request.pipe(finalize(() => this.loadingAlert.dismissLoader())).subscribe(
      () => {
        this.loadingAlert.showToastConfirmation('Changes saved!');
        this.orderService.getVehicleSubscriptions();
        this.orderService.getServiceOrders();
      },
      (error) => {
        this.loadingAlert.showToastAlert(error);
      }
    );
  }

  private updateSubscriptionWithNewDays(params: OrderCreateParams) {
    const subParams = this.subscriptionParamsFromSubscription(params);
    return this.orderService.deleteVehicleSubsciption(params.subscription!).pipe(
      flatMap(() => {
        return this.orderService.createVehicleSubscription(subParams);
      }),
      flatMap(() => {
        return this.orderService.getOrders();
      })
    );
  }

  private rescheduleOrder(params: OrderCreateParams): Observable<Order> {
    const orderParams: OrderParams = {};
    orderParams.userAddressUid = params.address?.uid || params.order?.userAddress.uid;
    orderParams.deliveryLocationLat = params.mapInfo
      ? params.mapInfo.coords.lat()
      : params.order.deliveryLocation.lat;
    orderParams.deliveryLocationLng = params.mapInfo
      ? params.mapInfo.coords.lng()
      : params.order.deliveryLocation.lng;

    orderParams.vehicleUid = params.order.vehicle.uid;

    orderParams.shiftWindow = params.shift || params.order.shiftWindow;
    orderParams.shiftStartTime = params.startTime || params.order.shiftStartTime.hour;
    orderParams.shiftEndTime = params.endTime || params.order.shiftEndTime.hour;
    orderParams.deliveryLocationDetails = params.order.deliveryLocation?.details;
    orderParams.deliveryLocationPersistent = params.order.isOrderLocationPersistent;
    orderParams.serviceUid = params.order.service?.uid;

    if (params.days) {
      orderParams.date = moment(params.days[0].nextDate).format('YYYY-MM-DD');
    }

    if (params.slotStartDateTime) {
      orderParams.slotStartDateTime = params.slotStartDateTime;
    }

    return this.orderService
      .cancelOrder(params.order, OrderCancelReason.Rescheduled)
      .pipe(flatMap(() => this.orderService.createOrder(params.order.vehicle, { ...orderParams })));
  }

  private updateSubscriptionAndOrder(params: OrderCreateParams) {
    const requests: Observable<any>[] = [];
    if (params.order) {
      requests.push(this.updateOrder(params));
    }
    if (params.subscription) {
      requests.push(this.updateSubscription(params));
    }
    return combineLatest(requests);
  }

  private updateOrder(params: OrderCreateParams) {
    const orderParams: OrderParams = {};
    if (params.address) {
      orderParams.userAddressUid = params.address.uid;
    }
    if (params.mapInfo) {
      orderParams.deliveryLocationLat = params.mapInfo.coords.lat();
      orderParams.deliveryLocationLng = params.mapInfo.coords.lng();
    }
    orderParams.deliveryLocationPersistent = params.persistent;
    if (params.driverNote !== null) {
      orderParams.deliveryLocationDetails = params.driverNote;
    }
    if (params.shift) {
      orderParams.shiftWindow = params.shift;
    }
    if (params.startTime) {
      orderParams.shiftStartTime = params.startTime;
    }
    if (params.endTime) {
      orderParams.shiftEndTime = params.endTime;
    }
    if (params.slotStartDateTime) {
      orderParams.slotStartDateTime = params.slotStartDateTime;
    }
    return this.orderService.updateOrder(params.order, orderParams);
  }

  private updateSubscription(params: OrderCreateParams) {
    const subParams: VehicleSubscriptionParams = {};
    if (params.address) {
      subParams.userAddressUid = params.address.uid;
    }
    if (params.mapInfo) {
      subParams.lat = params.mapInfo.coords.lat();
      subParams.lng = params.mapInfo.coords.lng();
    }
    subParams.deliveryLocationPersistent = params.persistent;
    if (params.shift) {
      subParams.shiftWindow = params.shift;
    }
    if (params.startTime) {
      subParams.shiftStartTime = params.startTime;
    }
    if (params.endTime) {
      subParams.shiftEndTime = params.endTime;
    }
    return this.orderService.updateVehicleSubscription(params.subscription!, subParams);
  }

  private subscriptionParamsFromSubscription(params: OrderCreateParams): VehicleSubscriptionParams {
    const subscription = params.subscription!;
    const subParams: VehicleSubscriptionParams = {
      vehicleUid: subscription.vehicle.uid,
      fuelTypeUid: subscription.vehicle.fuelType?.uid,
      shiftWindow: subscription.shiftWindow,
      shiftStartTime: subscription.shiftStartTime.hour,
      shiftEndTime: subscription.shiftEndTime.hour,
      userAddressUid: subscription.userAddress.uid,
      days: subscription.days,
      lat: params.order.deliveryLocation.lat,
      lng: params.order.deliveryLocation.lng,
      startDate: moment(params.days![0].nextDate).format('YYYY-MM-DD'),
    };
    if (subscription.service) {
      subParams.serviceUid = subscription.service.uid;
    }
    if (params.address) {
      subParams.userAddressUid = params.address.uid;
    }
    if (params.mapInfo) {
      subParams.lat = params.mapInfo.coords.lat();
      subParams.lng = params.mapInfo.coords.lng();
    }
    if (params.shift) {
      subParams.shiftWindow = params.shift;
    }
    if (params.startTime) {
      subParams.shiftStartTime = params.startTime;
    }
    if (params.endTime) {
      subParams.shiftEndTime = params.endTime;
    }
    if (params.days) {
      subParams.days = params.days.map((day) => day.dayOfTheWeek.toLowerCase());
    }
    return subParams;
  }
}
