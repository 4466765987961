import { Component, OnDestroy } from '@angular/core';
import { TireTread, TireTreadParams } from '../../../../models/tire-tread';
import { Vehicle, VehicleParams } from '../../../../models/vehicle';
import { finalize, flatMap, takeUntil } from 'rxjs/operators';

import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { Subject } from 'rxjs';
import { TirePressure } from '../../../../models/tire-pressure';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import _ from 'underscore';

@Component({
  selector: 'ysh-service-status-tire',
  templateUrl: 'service-status-tire.html',
  styleUrls: ['service-status-tire.scss'],
})
export class ServiceStatusTireComponent implements OnDestroy {
  vehicle: Nullable<Vehicle>;

  pressures: { [key: string]: TirePressure } = {};
  treads: { [key: string]: TireTreadParams } = {};
  lastCheck: Date;
  psiOptions: number[] = [];
  requestedPsiFront: number | undefined = 27;
  requestedPsiRear: number | undefined = 27;
  readonly step: number = 1;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private vehicleService: VehiclesService, private loadingAlert: LoadingAlertService) {
    this.vehicleService.selectedVehicle$.pipe(takeUntil(this.unsubscribe)).subscribe((vehicle) => {
      this.vehicle = vehicle;
      this.requestedPsiFront = this.vehicle?.requestedPsiFront;
      this.requestedPsiRear = this.vehicle?.requestedPsiRear;
    });

    this.populatePsiOptions();
    this.loadTireInfo();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Data

  getLastTireCheckDate(pressures: TirePressure[]) {
    if (pressures.length) {
      let sorted = _.sortBy(pressures, 'updatedAt');
      sorted = sorted.reverse();
      this.lastCheck = sorted[0].updatedAt;
    }
  }

  loadTireInfo() {
    const vehicle = this.vehicle;
    if (!vehicle) {
      return;
    }
    this.loadingAlert.showLoader();
    this.vehicleService
      .getTirePressuresForVehicle(vehicle)
      .pipe(
        flatMap((pressures: TirePressure[]) => {
          this.pressures = {};
          for (let pressure of pressures) {
            this.pressures[pressure.position] = pressure;
          }

          this.getLastTireCheckDate(pressures);

          return this.vehicleService.getTireTreadsForVehicle(vehicle);
        }),
        finalize(() => this.loadingAlert.dismissLoader())
      )
      .subscribe((treads: TireTread[]) => {
        this.treads = {};
        for (let tread of treads) {
          this.treads[tread.position] = tread;
        }
      });
  }

  // Actions

  didChangeRequestedFrontPSI(psi) {
    if (!this.vehicle) {
      return;
    }
    const params: VehicleParams = {
      requestedPsiFront: psi,
    };
    this.requestedPsiFront = psi;
    this.vehicleService.updateVehicle(this.vehicle, params);
  }

  didChangeRequestedRearPSI(psi) {
    if (!this.vehicle) {
      return;
    }
    const params: VehicleParams = {
      requestedPsiRear: psi,
    };
    this.requestedPsiRear = psi;
    this.vehicleService.updateVehicle(this.vehicle, params);
  }

  // Helpers

  populatePsiOptions() {
    //psi range 24-60
    for (let i = 24; i <= 60; i += this.step) {
      this.psiOptions.push(i);
    }
  }

  isRearPSISelected(psi): boolean {
    if (!this.vehicle) {
      return false;
    }
    let selectedPSI = this.vehicle.requestedPsiRear
      ? this.vehicle.requestedPsiRear
      : this.vehicle.recommendedPsiRear;
    selectedPSI = parseInt(selectedPSI as any);
    psi = parseInt(psi);
    return selectedPSI === psi;
  }

  isFrontPSISelected(psi): boolean {
    if (!this.vehicle) {
      return false;
    }
    let selectedPSI = this.vehicle.requestedPsiFront
      ? this.vehicle.requestedPsiFront
      : this.vehicle.recommendedPsiRear;
    selectedPSI = parseInt(selectedPSI as any);
    psi = parseInt(psi);
    return selectedPSI === psi;
  }
}
