import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { VehicleColorPage } from './vehicle-color.page';
import { VehicleColorPageRoutingModule } from './vehicle-color-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VehicleColorPageRoutingModule,
    ComponentsModule,
  ],
  declarations: [VehicleColorPage],
})
export class VehicleColorPageModule {}
