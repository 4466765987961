import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SelectMembershipPageRoutingModule } from './select-membership-routing.module';

import { SelectMembershipPage } from './select-membership.page';
import { MembershipComponentsModule } from '../components/membership-components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SelectMembershipPageRoutingModule,
    ComponentsModule,
    MembershipComponentsModule,
  ],
  declarations: [SelectMembershipPage],
})
export class SelectMembershipPageModule {}
