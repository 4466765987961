import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ysh-gallery',
  templateUrl: './ysh-gallery.component.html',
  styleUrls: ['./ysh-gallery.component.scss'],
})
export class YshGalleryComponent {
  @Output() onSlideTap: EventEmitter<any> = new EventEmitter();
  @Output() onViewMoreTapped: EventEmitter<any> = new EventEmitter();
  @Input() rounded = false;
  @Input() slidesPerView = 1.25;
  @Input() slides: string[] = [];
  @Input() showMore = false;
  @Input() maxImagesInSlider = 10;
  @Input() spaceBetween = 10;

  constructor() {}

  didTapViewMore() {
    this.onViewMoreTapped.emit();
  }

  async didTapSlide(index: number) {
    this.onSlideTap.emit(index);
  }
}
