import { Benefit } from 'src/app/models/benefit';
import { Injectable } from '@angular/core';
import { OrderService } from '../api/order/order.service';
import { ServiceName } from 'src/app/models/service-type';
import { UserAddress } from 'src/app/models/user-address';
import { UserService } from '../api/user/user.service';
import { Vehicle } from 'src/app/models/vehicle';
import { Visit } from 'src/app/models/visit';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FuelPricingAndDiscountsService {
  private dateToCheck: Date = new Date();
  basePrice: number;

  constructor(private userService: UserService, private orderService: OrderService) {}

  fuelBenefits(vehicle: Vehicle): Benefit[] {
    if (!vehicle) {
      return [];
    }
    let fuelBenefits: Benefit[] = [];
    this.userService.benefits$.pipe(take(1)).subscribe((benefits) => (fuelBenefits = benefits));
    return fuelBenefits.filter((benefit) => {
      return (
        benefit.isValidOn(this.dateToCheck) &&
        benefit.isFuelPrice &&
        benefit.isValidWithVehicleUid(vehicle.uid)
      );
    });
  }

  getFuelPrice(vehicle: Vehicle, address: UserAddress, applyDiscounts: boolean): number {
    if (!vehicle || !address) {
      return 0;
    }
    let fuelPrice = address.getFuelPriceFor(vehicle.fuelGrade);

    this.basePrice = fuelPrice;

    if (applyDiscounts) {
      fuelPrice -= this.totalSavings(vehicle);
    }
    return fuelPrice;
  }

  getBestFixedPriceDiscount(vehicle: Vehicle): Nullable<Benefit> {
    const bestFixedPrice = (benefit1: Benefit, benefit2: Benefit) => {
      return benefit1.value < benefit2.value ? benefit1 : benefit2;
    };
    const fixedPriceBenefits = this.fuelBenefits(vehicle).filter(
      (benefit) => benefit.applyMethod === 'fixed_price'
    );

    if (fixedPriceBenefits && fixedPriceBenefits.length) {
      return this.fuelBenefits(vehicle)
        .filter((benefit) => benefit.applyMethod === 'fixed_price')
        .reduce(bestFixedPrice);
    }
    return null;
  }

  bestFixedPriceDiscountValue(vehicle: Vehicle): number {
    if (!vehicle) {
      return 0;
    }
    const benefit = this.getBestFixedPriceDiscount(vehicle);
    return benefit ? this.benefitValue(benefit) : 0;
  }

  // Computations

  totalServiceDiscount(vehicle: Vehicle): number {
    let visits: Visit[] = [];
    this.orderService.visits$
      .pipe(take(1))
      .subscribe((_visits: Visit[]) => (visits = _visits || []));
    const findService = (visit: Visit) => {
      return (
        visit.vehicle.uid === vehicle?.uid &&
        visit.order.service.serviceType.name === ServiceName.Gas
      );
    };
    const nextFuelVisit = visits.find(findService);
    return nextFuelVisit?.totalFuelServiceDiscount || 0;
  }

  totalBenefitsDiscount(vehicle: Vehicle): number {
    const applyUserBenefits = (total: number, benefit: Benefit) => {
      return total + this.benefitValue(benefit);
    };

    return this.fuelBenefits(vehicle)
      .filter((benefit) => benefit.applyMethod !== 'fixed_price')
      .reduce(applyUserBenefits, 0);
  }

  private totalSavings(vehicle: Vehicle): number {
    if (!vehicle) {
      return 0;
    }
    const serviceDiscounts = this.totalServiceDiscount(vehicle);
    const benefitsDiscounts = this.totalBenefitsDiscount(vehicle);
    const fixedPriceDiscounts = this.bestFixedPriceDiscountValue(vehicle);

    return serviceDiscounts + benefitsDiscounts + fixedPriceDiscounts;
  }

  private benefitValue(benefit: Benefit): number {
    if (!benefit) {
      return 0;
    }
    const value = Number(benefit.value);

    if (benefit.applyMethod === 'fixed_price') {
      return this.basePrice - value;
    }

    return value;
  }
}
