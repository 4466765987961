import { Component, Input } from '@angular/core';

@Component({
  selector: 'ysh-loyalty-stars',
  templateUrl: 'loyalty-stars.html',
  styleUrls: ['./loyalty-stars.scss'],
})
export class LoyaltyStarsComponent {
  @Input() stars: number;
  @Input() postfix: string;
  @Input() color: string;
  @Input() colorIsDark: boolean;
}
