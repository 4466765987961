import { Component, Input } from '@angular/core';

@Component({
  selector: 'ysh-progress-bar',
  templateUrl: 'progress-bar.html',
  styleUrls: ['./progress-bar.scss'],
})
export class ProgressBarComponent {
  @Input() progress;
  @Input() title;
  @Input() hideText: boolean;

  constructor() {}
}
