import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CancellationReasons } from 'src/app/services/api/membership/membership.service';
import { FlowPage } from '../../flow-director';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'ysh-cancellation-notes',
  templateUrl: './cancellation-notes.page.html',
  styleUrls: ['./cancellation-notes.page.scss'],
})
export class CancellationNotesPage implements OnInit, AfterViewInit, FlowPage {
  @ViewChild('notes') notes;
  readonly title: string = `Would you mind telling us more? Your feedback will be immensely valuable for improving the quality of Yoshi's services.`;
  @Input() onComplete: (cancellationNotes: string) => void;
  @Input() onDismiss: () => void;
  @Input() cancellationReason: CancellationReasons;
  cancellationNotes: string;

  constructor(
    private analytics: AnalyticsService,
    private keyboard: Keyboard,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.analytics.trackView('CancellationNotesPage');
  }

  ngAfterViewInit() {
    if (this.platform.is('android')) {
      this.keyboard.show();
    }

    setTimeout(() => {
      this.notes.setFocus();
    }, 200);
  }

  didTapDismiss() {
    if (this.onDismiss) {
      this.onDismiss();
    }
  }

  didTapNext() {
    if (!this.cancellationNotes) {
      return;
    }
    if (this.onComplete) {
      this.onComplete(this.cancellationNotes);
    }
  }
}
