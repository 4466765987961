import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FuelType } from 'src/app/models/fuel-type';
import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-service-status-fuel',
  templateUrl: 'service-status-fuel.html',
  styleUrls: ['./service-status-fuel.scss'],
})
export class ServiceStatusFuelComponent implements OnChanges {
  @Input() vehicle: Vehicle;
  @Input() fuelTypes: FuelType[];

  fuelTheshold: number;
  selectedFuelTypeUid: string | undefined;

  constructor(private vehicleService: VehiclesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedFuelTypeUid = this.vehicle?.fuelType?.uid;
  }

  didChangeFuelType(index?: number) {
    if (index && index > -1) {
      this.selectedFuelTypeUid = this.fuelTypes[index].uid;
    }
    this.vehicleService
      .updateVehicle(this.vehicle, {
        fuelTypeUid: this.selectedFuelTypeUid,
      })
      .subscribe((vehicle) => {
        this.vehicle = vehicle;
      });
  }

  didChangeFuelGaugeThreshold() {
    this.vehicleService.updateVehicle(this.vehicle, {
      fuelGaugeThreshold: this.fuelTheshold / 5,
    });
  }
}
