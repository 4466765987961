import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { Mileage } from 'src/app/models/mileage';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/api/user/user.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-vehicle-mileage',
  templateUrl: './vehicle-mileage.page.html',
  styleUrls: ['./vehicle-mileage.page.scss'],
})
export class VehicleMileagePage implements OnDestroy, OnInit {
  mileages: Mileage[] = [];
  modal = false;
  pageIndex = 1;
  vehicle: Vehicle;

  private unsubscribe: Subject<void> = new Subject();

  ICON_TOYOTA = 'assets/images/icon-toyota.svg';
  ICON_YOSHI = 'assets/images/logo-splash.svg';
  ICON_ONSTAR = 'assets/images/gm-icon-onstar.svg';

  userInputMiles: number | null;

  constructor(
    private modalService: ModalService,
    private vehicleService: VehiclesService,
    private loadingAlert: LoadingAlertService,
    public userService: UserService,
    private analytics: AnalyticsService
  ) {
    this.vehicleService.selectedVehicleMileages$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mileages) => {
        this.mileages = mileages;
      });
  }

  ngOnInit() {
    this.analytics.trackView('VehicleMileagePage');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  didTapAddMiles() {
    if (!this.userInputMiles) {
      return;
    }
    this.loadingAlert.showLoader();
    this.vehicleService
      .addMiles(this.vehicle.uid, this.userInputMiles)
      .pipe(
        finalize(() => this.loadingAlert.dismissLoader()),
        takeUntil(this.unsubscribe)
      )
      .subscribe(
        () => {
          this.userInputMiles = null;
          this.getVehicleMileages();
        },
        (error) => this.loadingAlert.showToastAlert(error)
      );
  }

  getVehicleMileages(page = 1, infiniteLoadEvent?: any) {
    return this.vehicleService.getVehicleMileage(page).subscribe((mileages) => {
      if (infiniteLoadEvent) {
        mileages.length
          ? infiniteLoadEvent.target.complete()
          : (infiniteLoadEvent.target.disabled = true);
      }
    });
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  loadNextPage(event) {
    this.pageIndex++;
    this.getVehicleMileages(this.pageIndex, event);
  }

  deleteMileage(uid) {
    this.loadingAlert.showLoader();
    this.vehicleService
      .deleteMiles(this.vehicle.uid, uid)
      .pipe(
        takeUntil(this.unsubscribe),
        finalize(() => this.loadingAlert.dismissLoader())
      )
      .subscribe(
        () => this.getVehicleMileages(),
        (error) => this.loadingAlert.showToastAlert(error)
      );
  }
}
