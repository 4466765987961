import { BaseEntity, MemoizeGetters, Transform } from './base';

export enum MembershipTerm {
  Monthly = 'monthly',
  Annual = 'annual',
  Unknown = 'unknown',
}

export enum MembershipTermAdverb {
  Monthly = 'monthly',
  Annual = 'annually',
  Unknown = '',
}

@MemoizeGetters
export class MemberTier extends BaseEntity {
  id?: number;
  tier: string;
  description?: string;
  billingCycleDays: number;

  @Transform(parseInt)
  fee: number;

  // Computed Properties

  get term() {
    return this.billingCycleDays === 30
      ? MembershipTerm.Monthly
      : this.billingCycleDays === 365
      ? MembershipTerm.Annual
      : MembershipTerm.Unknown;
  }

  get termAdverb() {
    return this.billingCycleDays === 30
      ? MembershipTermAdverb.Monthly
      : this.billingCycleDays === 365
      ? MembershipTermAdverb.Annual
      : MembershipTermAdverb.Unknown;
  }

  get monthlyFee() {
    return this.term === MembershipTerm.Annual ? this.fee / 12 : this.fee;
  }

  get annualFee() {
    return this.term === MembershipTerm.Annual ? this.fee : this.fee * 12;
  }
}
