import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';

@Component({
  selector: 'ysh-slides-modal',
  templateUrl: 'slides-modal.html',
  styleUrls: ['./slides-modal.scss'],
})
export class SlidesModalComponent implements OnInit {
  @Input() initialSlide: number;
  @Input() photos: string[];
  @ViewChild('slides', { static: true }) slides: IonSlides;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.slides.options = { initialSlide: this.initialSlide };
    this.slides.update();
  }

  didTapSlide() {
    !this.slides.isEnd() ? this.slides.slideNext(0) : this.dismiss();
  }

  didTapDismiss() {
    this.dismiss();
  }

  async slideChanged() {
    const activeIndex = await this.slides.getActiveIndex();
    if (activeIndex >= this.photos.length) {
      this.dismiss();
    }
  }

  dismiss() {
    this.modalCtrl.dismiss(null);
  }
}
