import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'dollarsAndCents',
})
export class DollarsAndCentsPipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}

  transform(value: number, includeDecimal: boolean = true): string | null {
    if (value < 1) {
      return `${Math.round(value * 100)}¢`;
    }

    return this.currency.transform(value, 'USD', 'symbol', includeDecimal ? '1.2-2' : '1.0-0');
  }
}
