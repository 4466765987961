import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'ysh-dropdown',
  templateUrl: 'ysh-dropdown.html',
  styleUrls: ['./ysh-dropdown.scss'],
})
export class YshDropdownComponent {
  @Input() placeholder: string;
  @Input() options: string[];
  @Input() selectedIndex: number;
  @Input() selectOptions: any = { header: 'Choose Option' };
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() showCountLabel = true;

  constructor() {}

  didSelectOption(event) {
    if (event && event.target && event.target) {
      this.onChange.emit(event.target.value);
    }
  }
}
