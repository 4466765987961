import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Make, Model } from 'src/app/models/vehicle';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-vehicle-model',
  templateUrl: './vehicle-model.page.html',
  styleUrls: ['./vehicle-model.page.scss'],
})
export class VehicleModelPage implements OnInit, OnDestroy {
  @Input() onDismiss: () => void;
  @Input() onComplete: (data: Model) => void;
  @Input() make: Make;

  models: Model[];
  filteredModels: Model[] = [];
  selectedModel: Model;
  search: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private analytics: AnalyticsService,
    private vehicleService: VehiclesService,
    private keyboard: Keyboard,
    private platform: Platform
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('VehicleModelPage');
    this.loadData();
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Data
  loadData() {
    this.vehicleService.getCarModels(this.make.uid).subscribe(
      (models) => {
        this.models = models;
        this.filteredModels = models;
      },
      (error) => {
        console.log('error loading car models', error);
      }
    );
  }

  // Actions

  dismissKeyboard() {
    if (this.platform.is('cordova') && this.keyboard.isVisible) {
      this.keyboard.hide();
    }
  }

  onModelFilterChanged() {
    this.filteredModels = this.models.filter((model) => {
      return model.model.toUpperCase().includes(this.search.toUpperCase());
    });
  }

  didTapDismiss() {
    this.onDismiss?.();
  }

  didSelectMake(model: Model) {
    this.selectedModel = model;
    this.onComplete?.(model);
  }
}
