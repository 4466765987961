import { finalize, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Component } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/services/api/user/user.service';
import { UserUpdateParams } from 'src/app/models/user';
import moment from 'moment';

@Component({
  selector: 'ysh-edit-account',
  templateUrl: './edit-account.page.html',
  styleUrls: ['./edit-account.page.scss'],
})
export class EditAccountPage {
  user: any;
  formData: UserUpdateParams = {};
  dirty = false;
  pickerFormat = 'MMMM/DD/YYYY';
  displayFormat = 'MM/DD/YYYY';
  dobDisabled = false;
  maxDate: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private loadingAlertCtrl: LoadingAlertService,
    private userService: UserService,
    private keyboard: Keyboard,
    private analytics: AnalyticsService,
    private modalService: ModalService
  ) {
    this.userService.currentUser$.pipe(takeUntil(this.unsubscribe)).subscribe((user) => {
      if (user) {
        this.dobDisabled = !!user.dateOfBirth;
        this.formData.firstName = user.firstName;
        this.formData.lastName = user.lastName;
        this.formData.phone = user.phone;
        this.formData.email = user.email;
        this.formData.dateOfBirth = user.dateOfBirth && user.dateOfBirth.toISOString();
      }
    });
    this.maxDate = this.setMaxDate();
  }

  ionViewDidLoad() {
    this.analytics.trackView('EditAccountPage');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapSave() {
    this.loadingAlertCtrl.showLoader();
    this.userService
      .updateUser(this.formData)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(
        () => console.log('User updated'),
        (error) => this.loadingAlertCtrl.showToastAlert(`Error saving user: ${error}`)
      );
  }

  closeKeyboard() {
    this.keyboard.hide();
  }

  // Helpers

  setMaxDate() {
    const momentDate = moment();
    momentDate.set('year', momentDate.get('year') - 16);
    return momentDate.toISOString();
  }
}
