import * as moment from 'moment';

import { BaseEntity, MemoizeGetters } from './base';

export enum ShiftName {
  Day = 'day',
  Morning = 'morning',
  Night = 'night',
  LateNight = 'late_night',
  SlotScheduling = 'slot_scheduling',
}

export interface ShiftTime {
  hour: number;
  minute: number;
}

@MemoizeGetters
export class Shift extends BaseEntity {
  title: ShiftName;
  dayIndex: number;
  startTime: ShiftTime;
  endTime: ShiftTime;

  static defaultStartTime(window: ShiftName): ShiftTime {
    return START_TIME_MAP[window] || START_TIME_MAP[ShiftName.Day];
  }

  static defaultEndTime(window: ShiftName): ShiftTime {
    return END_TIME_MAP[window] || END_TIME_MAP[ShiftName.Day];
  }
}

export type ShiftMap<T> = { [key in ShiftName]: T };

const START_TIME_MAP: ShiftMap<ShiftTime> = {
  [ShiftName.Day]: { hour: 9, minute: 0 },
  [ShiftName.Morning]: { hour: 5, minute: 0 },
  [ShiftName.Night]: { hour: 18, minute: 0 },
  [ShiftName.LateNight]: { hour: 0, minute: 0 },
  [ShiftName.SlotScheduling]: { hour: 0, minute: 0 },
};

const END_TIME_MAP: ShiftMap<ShiftTime> = {
  [ShiftName.Day]: { hour: 17, minute: 0 },
  [ShiftName.Morning]: { hour: 9, minute: 0 },
  [ShiftName.Night]: { hour: 23, minute: 0 },
  [ShiftName.LateNight]: { hour: 5, minute: 0 },
  [ShiftName.SlotScheduling]: { hour: 23, minute: 59 },
};
