import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CancellationReasonsPageRoutingModule } from './cancellation-reasons-routing.module';

import { CancellationReasonsPage } from './cancellation-reasons.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CancellationReasonsPageRoutingModule
  ],
  declarations: [CancellationReasonsPage]
})
export class CancellationReasonsPageModule {}
