import { BaseEntity, MemoizeGetters, Transform, toEntity } from './base';

import { Company } from './company';

const GENERAL_MOTORS = 'General Motors';

@MemoizeGetters
export class Compound extends BaseEntity {
  compoundType: CompoundTypes;
  lat?: number;
  lng?: number;
  title: string;
  convertedOnly: boolean;

  @Transform(toEntity(Company))
  companies: Company[];

  get company() {
    return this.companies?.[0];
  }
}

export enum CompoundTypes {
  Corporate = 'corporate',
}
