import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';
import { GM_MAKES, LEXUS, Oem, OemMinYear, TOYOTA } from 'src/app/services/oem/oem.service';
import { Make, Model, Vehicle, VehicleParams } from 'src/app/models/vehicle';
import { finalize, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { BackButtonService } from 'src/app/services/back-button/back-button.service';
import { ConnectOnStarPage } from 'src/app/pages/profile/connect-on-star/connect-on-star.page';
import { ConnectToyotaPage } from 'src/app/pages/profile/connect-toyota/connect-toyota.page';
import { FuelSelectorPage } from '../../ordering/selectors/fuel-selector/fuel-selector.page';
import { FuelType } from 'src/app/models/fuel-type';
import { IonNav } from '@ionic/angular';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { VehicleColorPage } from '../vehicle-color/vehicle-color.page';
import { VehicleLicensePlatePage } from '../vehicle-license-plate/vehicle-license-plate.page';
import { VehicleMakePage } from '../vehicle-make/vehicle-make.page';
import { VehicleModelPage } from '../vehicle-model/vehicle-model.page';
import { VehicleNicknamePage } from '../vehicle-nickname/vehicle-nickname.page';
import { VehicleYearPage } from '../vehicle-year/vehicle-year.page';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

export interface VehicleFlowControllerProps {
  promptForConnectedCar?: boolean;
  promptForFuel?: boolean;
  promptForLicense?: boolean;
  promptForNickname?: boolean;
  isModal?: boolean;
  isSigningUp?: boolean;
}

interface VehicleFlowControllerForm {
  make?: Nullable<Make>;
  model?: Nullable<Model>;
  color?: Nullable<string>;
  fuelType?: Nullable<FuelType>;
  year?: Nullable<number>;
  nickname?: Nullable<string>;
  license?: Nullable<string>;
}

@Component({
  selector: 'ysh-vehicle-flow-controller',
  template: '<ion-nav #vehicleNav ></ion-nav>',
})
export class VehicleFlowController implements OnInit, Flow, FlowPage {
  @ViewChild('vehicleNav') nav: IonNav;

  @Input() onDismiss: () => void;
  @Input() onComplete: (data: Vehicle) => void;
  @Input() preventBackNavigation = false;
  @Input() props: VehicleFlowControllerProps;

  flowDirector: FlowDirector;

  form: VehicleFlowControllerForm = {};
  user: User;
  visitedNicknamePage = false;
  visitedLicensePage = false;
  toyotaConnectEnabled = false;

  vehicle: Vehicle;

  constructor(
    public backButtonService: BackButtonService,
    private analytics: AnalyticsService,
    private loadingAlert: LoadingAlertService,
    private vehicleService: VehiclesService,
    private modalService: ModalService,
    private featureFlags: FeatureFlagService
  ) {
    this.loadFeatureFlags();
  }

  // life cycle hooks
  ngOnInit() {
    this.props.promptForNickname = this.props.promptForNickname ?? true;
    this.props.promptForLicense = this.props.promptForLicense ?? true;
    this.props.promptForFuel = this.props.promptForFuel ?? true;
    this.props.promptForConnectedCar = this.props.promptForConnectedCar ?? true;
    this.props.isModal = this.props.isModal ?? false;
    this.props.isSigningUp = this.props.isSigningUp ?? false;
    this.analytics.trackView('VehicleFlowControllerPage');
  }

  ngAfterViewInit() {
    const options = this.nextPage();
    this.flowDirector = new FlowDirector(this, options);
  }

  private unsubscribe: Subject<void> = new Subject();

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.form.year) {
      return this.optionsForVehicleYear();
    }
    if (!this.form.make) {
      return this.optionsForMakePage();
    }
    if (!this.form.model) {
      return this.optionsForModelPage();
    }
    if (!this.form.color) {
      return this.optionsForColorPage();
    }
    if (!this.form.fuelType && this.form.model?.useGas && this.props.promptForFuel) {
      return this.optionsForFuelSelectorPage();
    }
    if (!this.visitedNicknamePage && this.props.promptForNickname) {
      return this.optionsforNicknamePage();
    }
    if (!this.visitedLicensePage && this.props.promptForLicense) {
      return this.optionsForLicensePage();
    }
  }

  flowDidComplete(): void {
    this.saveVehicle();
  }

  // Params

  optionsForVehicleYear(): FlowPageOptions<VehicleYearPage> {
    return {
      page: VehicleYearPage,
      onComplete: (year: number) => this.didCompleteYearPage(year),
      onDismiss: () => this.onDismiss?.(),
      preventBackNavigation: this.preventBackNavigation,
    };
  }

  optionsForMakePage(): FlowPageOptions<VehicleMakePage> {
    return {
      page: VehicleMakePage,
      onComplete: (make: Make) => this.didCompleteMakePage(make),
      onDismiss: () => (this.form.make = null),
    };
  }

  optionsForModelPage(): FlowPageOptions<VehicleModelPage> {
    return {
      page: VehicleModelPage,
      onComplete: (model: Model) => this.didCompleteModelPage(model),
      onDismiss: () => (this.form.model = null),
      pageProps: {
        make: this.form.make!,
      },
    };
  }

  optionsForColorPage(): FlowPageOptions<VehicleColorPage> {
    return {
      page: VehicleColorPage,
      onComplete: (color: string) => this.didCompleteColorPage(color),
      onDismiss: () => (this.form.color = null),
    };
  }

  optionsforNicknamePage(): FlowPageOptions<VehicleNicknamePage> {
    return {
      page: VehicleNicknamePage,
      onComplete: (name: string) => this.didCompleteNicknamePage(name),
      onDismiss: () => (this.visitedNicknamePage = false),
      pageProps: {
        makeAndModel: `${this.form.make!.make} ${this.form.model!.model}`,
      },
    };
  }

  optionsForLicensePage(): FlowPageOptions<VehicleLicensePlatePage> {
    return {
      page: VehicleLicensePlatePage,
      onComplete: (license: string) => this.didCompleteLicensePage(license),
      onDismiss: () => (this.form.license = null),
    };
  }

  optionsForFuelSelectorPage(): FlowPageOptions<FuelSelectorPage> {
    return {
      page: FuelSelectorPage,
      onComplete: (type: FuelType) => this.didCompleteFuelPage(type),
      onDismiss: () => (this.form.fuelType = null),
    };
  }

  // Completion

  didCompleteYearPage(year: number) {
    this.form.year = year;
    this.flowDirector.next();
  }

  didCompleteMakePage(make: Make) {
    this.form.make = make;
    if (this.canUseOnStar()) {
      this.showOnStarPage();
    } else if (this.canUseLexusConnect()) {
      this.showToyotaConnectPage(Oem.Lexus);
    } else if (this.canUseToyotaConnect()) {
      this.showToyotaConnectPage(Oem.Toyota);
    } else {
      this.flowDirector.next();
    }
  }

  didCompleteModelPage(model: Model) {
    this.form.model = model;
    this.flowDirector.next();
  }

  didCompleteColorPage(color: string) {
    this.form.color = color;
    this.flowDirector.next();
  }

  didCompleteNicknamePage(name: string) {
    this.form.nickname = name;
    this.visitedNicknamePage = true;
    this.flowDirector.next();
  }

  didCompleteLicensePage(license: string) {
    this.form.license = license;
    this.visitedLicensePage = true;
    this.flowDirector.next();
  }

  didCompleteFuelPage(type: FuelType) {
    this.form.fuelType = type;
    this.flowDirector.next();
  }

  // OEM

  loadFeatureFlags() {
    this.featureFlags
      .flag$(Flag.ToyotaConnectAuthFlow)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((flag) => {
        this.toyotaConnectEnabled = flag;
      });
  }

  async showOnStarPage() {
    const onStarPage = await this.modalService.openPage({
      component: ConnectOnStarPage,
      componentProps: {
        isModal: true,
      },
    });

    const { data } = await onStarPage.onDidDismiss();
    if (data && data.success) {
      if (this.onComplete) {
        this.onComplete(data);
      }
    } else {
      this.flowDirector.next();
    }
  }

  async showToyotaConnectPage(make: Oem) {
    const toyotaConnectPage = await this.modalService.openPage({
      component: ConnectToyotaPage,
      componentProps: { isModal: true, make },
    });

    const { data } = await toyotaConnectPage.onDidDismiss();
    if (data?.success) {
      if (this.onComplete) {
        setTimeout(() => {
          this.onComplete(data);
        }, 100);
      }
    } else {
      this.flowDirector.next();
    }
  }

  canUseOnStar() {
    return (
      this.form.make && this.form.year! >= OemMinYear.GM && GM_MAKES.includes(this.form.make.make)
    );
  }

  canUseLexusConnect() {
    return (
      this.toyotaConnectEnabled &&
      this.form.year! >= OemMinYear.Lexus &&
      this.form.make!.make === LEXUS
    );
  }

  canUseToyotaConnect() {
    return (
      this.toyotaConnectEnabled &&
      this.form.year! >= OemMinYear.Toyota &&
      this.form.make!.make === TOYOTA
    );
  }

  // data

  saveVehicle() {
    this.loadingAlert.showLoaderWithText('Saving');
    const params: VehicleParams = {
      vehicleModelUid: this.form.model!.uid,
      color: this.form.color!,
      year: this.form.year!,
      nickname: this.form.nickname!,
      license: this.form.license!,
      fuelTypeUid: this.form.fuelType?.uid,
    };

    return this.vehicleService
      .createVehicle(params)
      .pipe(finalize(() => this.loadingAlert.dismissLoader()))
      .subscribe(
        (data) => {
          this.vehicle = data;
          this.onComplete?.(this.vehicle);
        },
        (error) => {
          console.log('error saving vehicle', error);
          this.loadingAlert.presentAlert('Error saving vehicle', error);
        }
      );
  }
}
