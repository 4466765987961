import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { DayTimeSelectorPage } from './day-time-selector.page';
import { DayTimeSelectorPageRoutingModule } from './day-time-selector-routing.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DayTimeSelectorPageRoutingModule,
    ComponentsModule,
    PipesModule,
  ],
  declarations: [DayTimeSelectorPage],
})
export class DayTimeSelectorPageModule {}
