import * as humps from 'humps';

import { BaseEntity, MemoizeGetters, Transform } from '../base';
import { LoyaltyTier, TierName } from './tier';

export enum PerkName {
  EarnStars = 'earn_stars',
  Reward = 'get_rewards',
  BirthdayGift = 'birthday_gift',
  MemberDeals = 'member_deals',
  Referrals = 'give_referrals',
  TireChecks = 'tire_check',
  PrioritySupport = 'priority_support',
  SameDayOrder = 'cutoff_time',
}

@MemoizeGetters
export class LoyaltyPerk extends BaseEntity {
  @Transform(toCamelCaseVars)
  description: string;
  name: PerkName;
  progressive: boolean;
  tier: TierName;

  descriptionForTier(tier: LoyaltyTier) {
    let title: string = this.description;
    for (let key of this.templateVars) {
      title = title.replace(`%${key}%`, `<b>${tier[key]}</b>`);
    }
    return title;
  }

  get templateVars(): string[] {
    return findTemplateVars(this.description);
  }

  get image(): string {
    return IMAGE_MAP[this.name] || IMAGE_MAP[PerkName.Reward];
  }

  get priority(): number {
    return PRIORITY_MAP[this.name] || 1000;
  }
}

function toCamelCaseVars(value: string) {
  let camelCased = value;
  const vars = findTemplateVars(value);
  vars.forEach((key) => {
    camelCased = camelCased.replace(key, humps.camelize(key));
  });
  return camelCased;
}

function findTemplateVars(value) {
  let found;
  const vars: string[] = [];
  const templateSearch = /\%(.*?)\%/g;
  while ((found = templateSearch.exec(value))) {
    vars.push(found[1]);
  }
  return vars;
}

type PerkNameMap<T> = { [key in PerkName]: T };

const IMAGE_MAP: PerkNameMap<string> = {
  [PerkName.EarnStars]: '/assets/images/loyalty/icon-services.svg',
  [PerkName.Reward]: '/assets/images/loyalty/icon-reward.svg',
  [PerkName.BirthdayGift]: '/assets/images/loyalty/icon-gift.svg',
  [PerkName.MemberDeals]: '/assets/images/loyalty/icon-member.svg',
  [PerkName.Referrals]: '/assets/images/loyalty/icon-share.svg',
  [PerkName.TireChecks]: '/assets/images/loyalty/icon-tire.svg',
  [PerkName.PrioritySupport]: '/assets/images/loyalty/icon-support.svg',
  [PerkName.SameDayOrder]: '/assets/images/loyalty/icon-delivery.svg',
};

const PRIORITY_MAP: PerkNameMap<number> = {
  [PerkName.EarnStars]: 1,
  [PerkName.Reward]: 2,
  [PerkName.BirthdayGift]: 3,
  [PerkName.MemberDeals]: 4,
  [PerkName.Referrals]: 5,
  [PerkName.TireChecks]: 6,
  [PerkName.PrioritySupport]: 7,
  [PerkName.SameDayOrder]: 8,
};
