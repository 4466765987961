import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'ysh-vehicle-nickname',
  templateUrl: './vehicle-nickname.page.html',
  styleUrls: ['./vehicle-nickname.page.scss'],
})
export class VehicleNicknamePage implements OnInit, OnDestroy {
  @Input() onDismiss: () => void;
  @Input() onComplete: (data: string) => void;
  @Input() makeAndModel: string = 'car';

  nickname: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private analytics: AnalyticsService) {}

  ngOnInit() {
    this.analytics.trackView('VehicleNicknamePage');
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  didTapDismiss() {
    this.onDismiss?.();
  }

  didTapNext() {
    this.onComplete?.(this.nickname);
  }
}
