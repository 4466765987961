import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeeplinkEventMap, DeeplinkService } from './services/deeplink/deeplink.service';
import { NavDirection, NavigationService } from './navigation.service';
import { Subject, timer } from 'rxjs';
import { UserEvent, UserService } from './services/api/user/user.service';
import { first, take, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from './services/analytics/analytics.service';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { AppRoutes } from './routes';
import { CreditCardFlowPage } from './pages/flows/payment/credit-card-flow/credit-card-flow.page';
import { ErrorReportingService } from './services/error-reporting/error-reporting.service';
import { EventsService } from './services/events/events.service';
import { LoyaltyService } from './services/api/loyalty/loyalty.service';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { ModalService } from './services/modal/modal.service';
import { OrderFlowEvents } from './services/api/order/order.service';
import { OrderScheduleService } from './services/scheduling/order-schedule/order-schedule.service';
import { Platform } from '@ionic/angular';
import { PushRegistrationService } from './services/push-registration/push-registration.service';
import { Service } from './models/service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TabRoutes } from './pages/tabs/routes';
import { UpsellFlowControllerPage } from './pages/flows/ordering/upsell-flow-controller/upsell-flow-controller.page';
import { User } from './models/user';
import { Vehicle } from './models/vehicle';
import { VehiclesService } from './services/api/vehicles/vehicles.service';
import moment from 'moment';

declare var cordova;
declare var window;

@Component({
  selector: 'ysh-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  webflowFlow = false;

  constructor(
    private platform: Platform,
    private userService: UserService,
    private analytics: AnalyticsService,
    private deeplink: DeeplinkService,
    private modalService: ModalService,
    private vehicleService: VehiclesService,
    private appRate: AppRate,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private navigationService: NavigationService,
    private events: EventsService,
    private errorReporting: ErrorReportingService,
    private pushRegistrationService: PushRegistrationService,
    private loyaltyService: LoyaltyService,
    private mobileAccessability: MobileAccessibility,
    private orderSchedule: OrderScheduleService
  ) {
    this.initializeApp();
    this.events.subscribe(UserEvent.UserDidLogout, () => {
      this.navigationService.navigate({ path: [AppRoutes.Login] }, NavDirection.Root);
    });
    this.events.subscribe(UserEvent.UserDidLogin, () => {
      if (!this.navigationService.currentPath().includes(`/${AppRoutes.Webflow}`)) {
        this.navigationService.navigate({ path: [AppRoutes.Tabs] }, NavDirection.Root);
      }
    });
    this.events.subscribe(
      OrderFlowEvents.DidComplete,
      async ({ service, didSkipPowerUps }: { service: Service; didSkipPowerUps: boolean }) => {
        const services = await this.orderSchedule.availableServiceGroups$.pipe(take(1)).toPromise();
        const addOns = services.filter((addOn) => service.canAccept(addOn.basicService));
        if (addOns?.length && !didSkipPowerUps) {
          this.modalService.openPage({
            component: UpsellFlowControllerPage,
            componentProps: { addOns, didSkipPowerUps },
          });
        }
      }
    );
  }
  rootPage: any;
  menuEnabled = false;
  showSplash = true;
  currentPage: string;
  pages: Array<{ title: string; component: any }>;
  user: User;
  alertCount = 0;
  vehicles: Vehicle[] = [];
  selectedVehicle: Vehicle;
  isAppOpenTracked = false;
  animating = false;

  // private
  private unsubscribe: Subject<void> = new Subject();

  ngOnInit() {
    // TODO: is this necessary?
    // always load landing page first
    //this.navigationService.navigate({ path: [AppRoutes.Landing] }, NavDirection.Root);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(true);
      if (this.platform.is('android')) {
        this.setAndroidNotchInset();
        try {
          this.mobileAccessability.usePreferredTextZoom(false);
        } catch (error) {
          console.error('Error using Mobile Accessibility plugin: ', error);
        }
      }
      this.configureDeeplinking();
      if (this.platform.is('cordova')) {
        this.registerAppRate();
        window.open = cordova.InAppBrowser.open;
      }
      this.subscribeToData();
      this.animateSplash();

      this.analytics.setup();
      this.pushRegistrationService.register();
      this.errorReporting.init();
    });
  }

  private setAndroidNotchInset() {
    if (window && window.AndroidNotch) {
      window.AndroidNotch.hasCutout((hasNotch: boolean) => {
        if (hasNotch) {
          const style = document.documentElement.style;
          window.AndroidNotch.getInsetTop((top) => {
            style.setProperty('--ion-safe-area-top', `${top}px`);
          });
        }
      });
    }
  }

  private deeplinkConfiguredAndBranchInitialized() {
    if (!this.isAppOpenTracked) {
      this.analytics.trackEvent('App Open');
      this.isAppOpenTracked = true;
    }
  }

  private subscribeToData() {
    this.userService.currentUser$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: User) => (this.user = user));
    this.vehicleService.vehicles$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((vehicles: Vehicle[]) => (this.vehicles = vehicles));
    this.vehicleService.selectedVehicle$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((vehicle: Vehicle) => (this.selectedVehicle = vehicle));
  }

  // App Rating

  registerAppRate() {
    if (!this.appRate.preferences) {
      return;
    }
    this.appRate.preferences.storeAppURL = {
      ios: '992735679',
      android: 'market://details?id=com.callyoshi',
    };
    this.appRate.preferences.callbacks!.handleNegativeFeedback = () => {
      window.open('mailto:feedback@startyoshi.com', '_system');
    };
    this.appRate.preferences.callbacks!.onButtonClicked = (index) => {
      if (index === 3) {
        this.analytics.trackEvent('App Rating Accepted - Client');
      }
    };
    this.appRate.preferences.callbacks!.onRateDialogShow = () => {
      this.analytics.trackEvent('App Rating Prompted - Client');
    };
    this.userService.currentUser$.pipe(first((user) => user != null)).subscribe((user) => {
      if (this.shouldPromptUserForAppRate(user)) {
        this.appRate.promptForRating(false);
      }
    });
  }

  private shouldPromptUserForAppRate(user: Nullable<User>) {
    // Checks if the user has been an active member for greater than one month
    if (user?.fuelMembership && user.fuelMembership.createdAt) {
      const membershipStart = user.fuelMembership.createdAt;
      const oneMonthAgo = moment().subtract(1, 'month');
      const olderThanAMonth = moment(membershipStart).isBefore(oneMonthAgo);
      return olderThanAMonth;
    }
    return false;
  }

  private animateSplash() {
    this.splashScreen.hide();
    const shouldShowSplash = !this.navigationService
      .currentPath()
      .startsWith(`/${AppRoutes.Webflow}`);

    if (shouldShowSplash) {
      this.showSplash = true;
      this.animating = false;
      timer(500).subscribe(() => {
        this.animating = true;
        timer(3000).subscribe(() => {
          this.showSplash = false;
        });
      });
    } else {
      this.showSplash = false;
    }
  }

  // Deeplinking
  private configureDeeplinking() {
    this.platform.ready().then(() => {
      // wait a while for root page to load.
      setTimeout(() => this.branchInit(), 2000);
    });
    this.platform.resume.subscribe(() => {
      this.branchInit();
    });
    this.deeplink.deeplinkEvents.subscribe((event) => {
      console.log(`${event} deeplink received`);
      if (this.userService.isLoggedIn$.value) {
        switch (event) {
          case DeeplinkEventMap.Event_Invite: {
            this.navigateToSharePage();
            break;
          }

          case DeeplinkEventMap.Event_CreditCard: {
            this.presentCreditCardPage();
            break;
          }

          case DeeplinkEventMap.Event_Rewards: {
            this.navigateToRewardsPage();
            break;
          }

          default: {
            console.log('deeplink not resolved! ', event);
            break;
          }
        }
      } else {
        console.log('deeplink received but user is not logged in');
      }
    });
  }

  private branchInit() {
    if (!this.platform.is('cordova')) {
      return;
    }
    const Branch = (window as any).Branch;
    if (Branch) {
      Branch.initSession()
        .then((data) => {
          this.deeplink.handleDeeplinkData(data);
          this.deeplinkConfiguredAndBranchInitialized();
        })
        .catch((err) => console.log(err));
    }
  }

  private navigateToSharePage() {
    this.navigateToTab(TabRoutes.Share);
  }

  private navigateToRewardsPage() {
    this.loyaltyService.currentProgram$.pipe(take(1)).subscribe((program) => {
      if (program) {
        this.navigateToTab(TabRoutes.Loyalty);
      }
    });
  }

  private navigateToTab(tabPath: TabRoutes) {
    this.navigationService.navigate({ path: [AppRoutes.Tabs, tabPath] });
  }

  presentCreditCardPage() {
    this.modalService.openPage({
      component: CreditCardFlowPage,
      componentProps: {
        hideBackButton: true,
      },
    });
  }
}
