import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WebflowEndPageRoutingModule } from './webflow-end-routing.module';
import { WebflowEndPage } from './webflow-end.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [CommonModule, IonicModule, WebflowEndPageRoutingModule, ComponentsModule],
  declarations: [WebflowEndPage],
})
export class WebflowEndPageModule {}
