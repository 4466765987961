import { Injectable } from '@angular/core';
import { Gesture, GestureController, GestureDetail } from '@ionic/angular';
@Injectable({ providedIn: 'root' })
export class GesturesService {
  readonly VELOCITY_Y_THRESHOLD = 1;
  readonly DELTA_Y_THRESHOLD = 5;
  readonly GESTURE_ROOT_THRESHOLD = 0;

  constructor(private gestureCtrl: GestureController) {}

  registerGestureListener(
    el: HTMLElement,
    direction: SwipeGesturesMap,
    handler: Function,
    runInsideAngularZone = true
  ): Gesture {
    const gesture = this.gestureCtrl.create(
      {
        el,
        threshold: this.GESTURE_ROOT_THRESHOLD,
        gestureName: `ysh-gesture-${direction}`,
        onMove: (event: GestureDetail) => this.onMoveHandler(event, direction, handler),
      },
      runInsideAngularZone
    );
    gesture.enable(true);
    return gesture;
  }

  private onMoveHandler(event: GestureDetail, direction: SwipeGesturesMap, handler: Function) {
    switch (direction) {
      case SwipeGesturesMap.Down: {
        if (event.velocityY > this.VELOCITY_Y_THRESHOLD && event.deltaY > this.DELTA_Y_THRESHOLD) {
          handler();
        }
      }
    }
  }
}

export enum SwipeGesturesMap {
  Down = 'down',
}
