import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { User, UserParams } from 'src/app/models/user';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { IonContent } from '@ionic/angular';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { TypedFlow } from '../../shared/typed-flow/typed-flow';
import { UserService } from 'src/app/services/api/user/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-update-user-flow',
  templateUrl: './update-user-flow.page.html',
  styleUrls: ['./update-user-flow.page.scss'],
  providers: [Keyboard],
})
export class UpdateUserFlowPage extends TypedFlow implements OnInit {
  @ViewChild('inputName') inputName: ElementRef;
  @ViewChild('inputPhone') inputPhone: ElementRef;
  @ViewChild('inputEmail') inputEmail: ElementRef;
  @ViewChild('Content') content: IonContent;

  @Input() onComplete: () => void;
  @Input() user: User;

  loader: any;
  formData: UserParams = {};
  fullName: string = '';

  isMissingName = false;
  isMissingEmail = false;
  isMissingPhoneNumber = false;
  missingFieldsCount = 0;

  readonly validEmailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private userService: UserService,
    private loadingAlertCrtl: LoadingAlertService,
    private keyboard: Keyboard,
    private analytics: AnalyticsService
  ) {
    super();
    this.pageSelector = '.page-update-user-flow';
  }

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('UpdateUserFlowPage');
    if (this.user) {
      this.isMissingEmail = !this.user.email;
      this.isMissingName = !this.user.firstName || !this.user.lastName;
      this.isMissingPhoneNumber = !this.user.phone;
      this.updateMissingFieldsCount();
    }
    setTimeout(() => {
      this.start();
    }, 500);
  }

  ionViewWillEnter() {
    this.keyboard.disableScroll(true);
  }

  ionViewWillLeave() {
    this.hideKeyboard();
    this.keyboard.disableScroll(false);
  }

  // Data

  updateUser() {
    this.loadingAlertCrtl.showLoaderWithText('Updating Profile');
    this.userService
      .updateUser(this.formData)
      .pipe(finalize(() => this.loadingAlertCrtl.dismissLoader()))
      .subscribe(
        (data) => {
          this.finish();
        },
        (error) => {
          console.log('error updating account', error);
          this.loadingAlertCrtl.presentAlert('Error updating account', error);
        }
      );
  }

  formValidates() {
    if (this.isMissingName && !this.isNameValid()) {
      return false;
    }
    if (this.isMissingPhoneNumber && !this.isPhoneNumberValid()) {
      return false;
    }
    if (this.isMissingEmail && !this.isEmailValid()) {
      return false;
    }
    return true;
  }

  // Flow

  enableNextButton(): boolean {
    if (this.flowIndex === 0 && this.isMissingName) {
      return this.isNameValid();
    } else if (this.flowIndex < 2 && this.isMissingPhoneNumber) {
      return this.isPhoneNumberValid();
    }
    return false;
  }

  isNameValid(): boolean {
    if (!this.formData.firstName || !this.formData.lastName) {
      return false;
    }
    return true;
  }

  isPhoneNumberValid(): boolean {
    if (!this.formData.phone) {
      return false;
    }
    if (!this.formData.phone.length || this.formData.phone.length !== 10) {
      return false;
    }
    return true;
  }

  isEmailValid(): boolean {
    if (!this.formData.email) {
      return false;
    }
    if (this.formData.email.length < 3) {
      return false;
    }
    return this.validEmailRegex.test(this.formData.email);
  }

  didFinishTypingForIndex(index) {
    if (index === 1) {
      this.setFocusOnInput(this.inputName);
    } else if (index === 2) {
      this.setFocusOnInput(this.inputPhone);
    } else if (index === 3) {
      this.setFocusOnInput(this.inputEmail);
    }
  }

  setFocusOnInput(input: ElementRef) {
    setTimeout(() => {
      input.nativeElement.focus();
    }, 0);
  }

  finish() {
    if (this.onComplete) {
      this.onComplete();
    }
  }

  // Actions

  didTapNext() {
    this.hideKeyboard();
    this.next();
  }

  didTapBack() {
    this.advanceToIndex(this.flowIndex - 1);
  }

  didTapEnter() {
    if (this.flowIndex < this.missingFieldsCount - 1) {
      this.didTapNext();
    } else {
      this.hideKeyboard();
      this.didTapDone();
    }
  }

  didTapDone() {
    if (this.formValidates()) {
      this.updateUser();
    }
  }

  updateName() {
    const parts = this.fullName.split(' ');
    if (parts.length === 1) {
      this.formData.firstName = parts[0];
      this.formData.lastName = '';
    } else if (parts.length === 2) {
      this.formData.firstName = parts[0];
      this.formData.lastName = parts[1];
    } else {
      this.formData.firstName = parts[0] + ' ' + parts[1];
      this.formData.lastName = parts[parts.length - 1];
    }
  }

  updateMissingFieldsCount() {
    if (this.isMissingEmail) {
      this.missingFieldsCount++;
    }
    if (this.isMissingName) {
      this.missingFieldsCount++;
    }
    if (this.isMissingPhoneNumber) {
      this.missingFieldsCount++;
    }
  }

  private hideKeyboard = () => this.keyboard.hide();
}
