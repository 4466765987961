import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { VehicleModelPage } from './vehicle-model.page';
import { VehicleModelePageRoutingModule } from './vehicle-model-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VehicleModelePageRoutingModule,
    ComponentsModule,
  ],
  declarations: [VehicleModelPage],
})
export class VehicleModelPageModule {}
