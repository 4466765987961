import * as withIndefiniteArticle from 'indefinite';

import { finalize, tap } from 'rxjs/operators';

import { Benefit } from 'src/app/models/benefit';
import { Company } from 'src/app/models/company';
import { Component } from '@angular/core';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavParams } from '@ionic/angular';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/api/user/user.service';

@Component({
  selector: 'ysh-corporate-promo',
  templateUrl: './corporate-promo.page.html',
  styleUrls: ['./corporate-promo.page.scss'],
})
export class CorporatePromoPage {
  static shouldPresent(
    user: Nullable<User>,
    company: Nullable<Company>,
    benefit: Nullable<Benefit>
  ): Boolean {
    const shouldPromptForInviteCode = !benefit && company?.shouldPromptForInviteCode;
    const shouldPromptForId = company?.shouldPromptForExternalId && !user?.isAffiliated(company);
    return Boolean(shouldPromptForInviteCode || shouldPromptForId);
  }

  isModal: boolean;
  isCompanyEmployee = false;
  company: Company;
  termsOfServiceAccepted = false;
  promoApplied = false;
  showSuccess = false;
  entry: string;
  onComplete: (promoApplied: boolean) => void;
  onDismiss: () => void;

  readonly invalidCodeError = 'That is not a valid code';
  readonly successMessage = 'Your promo has been applied! Please check your profile for details.';

  constructor(
    public navParams: NavParams,
    private modalService: ModalService,
    private loadingAlertCtrl: LoadingAlertService,
    private userService: UserService
  ) {
    this.isModal = this.navParams.get('modal');
    this.showSuccess = this.navParams.get('showSuccess');
    this.company = this.navParams.get('company');
  }

  ionViewDidLoad() {
    console.log('ionViewDidLoad CorporatePromoPage');
  }

  get companyEmployeeTitle() {
    const company = withIndefiniteArticle(this.company.name);
    const intro = `You have entered ${company} address!`;
    const entry = this.company.shouldPromptForExternalId
      ? this.company.externalIdTitle
      : 'employee promo code';
    const cta = `Do you have ${withIndefiniteArticle(entry)}?`;
    return `${intro} ${cta}`;
  }

  get companyEmployeeCertifyStatement() {
    return `I certify that I am ${withIndefiniteArticle(this.company.name)} employee`;
  }

  get submitCTAText() {
    return 'Submit';
  }

  get placeholder() {
    const entry = this.company.shouldPromptForExternalId ? this.company.externalIdTitle : 'code';
    return `Enter ${entry}`;
  }

  get skipCTAText() {
    const entry = this.company.shouldPromptForExternalId ? this.company.externalIdTitle : 'code';
    return `I don't have ${withIndefiniteArticle(entry)}`;
  }

  validates() {
    const acceptedTerms = this.termsOfServiceAccepted && this.isCompanyEmployee;
    const entryValidates = this.company.shouldPromptForExternalId
      ? this.validatesExternalId(this.entry)
      : this.entry?.length;
    return acceptedTerms && entryValidates;
  }

  validatesExternalId(entry: string) {
    return this.company.externalIdValidationRegex
      ? this.company.externalIdValidationRegex.test(entry)
      : entry?.length > 0;
  }

  didTapSubmit() {
    if (this.company.shouldPromptForExternalId) {
      this.submitCompanyId();
    } else {
      this.submitReferral();
    }
  }

  submitReferral() {
    this.loadingAlertCtrl.showLoader();
    this.userService
      .sendPromoCode(this.entry)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(
        () => {
          this.promoApplied = true;
          if (this.showSuccess) {
            this.loadingAlertCtrl.showToastConfirmation(this.successMessage);
          }
          this.dismiss();
        },
        (error) => this.loadingAlertCtrl.showToastAlert(error)
      );
  }

  submitCompanyId() {
    this.loadingAlertCtrl.showLoader();
    this.userService
      .createAffiliation(this.company.uid, this.entry)
      .pipe(
        tap(() => {
          this.userService.getAppliedBenefits();
          this.userService.getUser();
        }),
        finalize(() => this.loadingAlertCtrl.dismissLoader())
      )
      .subscribe(
        () => {
          this.promoApplied = true;
          if (this.showSuccess) {
            this.loadingAlertCtrl.showToastConfirmation(this.successMessage);
          }
          this.dismiss();
        },
        (error) => this.loadingAlertCtrl.showToastAlert(error)
      );
  }

  didTapSkip() {
    this.dismiss();
  }

  didTapTerms() {
    window.open('https://www.startyoshi.com/terms', '_system', 'location=yes');
  }

  dismiss() {
    this.isModal
      ? this.modalService.dismissModal({ promoApplied: this.promoApplied })
      : this.onComplete(this.promoApplied);
  }
}
