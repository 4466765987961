import { Component, Input } from '@angular/core';

@Component({
  selector: 'ysh-service-icon',
  templateUrl: 'service-icon.html',
  styleUrls: ['./service-icon.scss'],
})
export class ServiceIconComponent {
  @Input() serviceImage = 'assets/images/v5/service-gas.png';
  @Input() subscribed = false;

  imageSource: string;
}
