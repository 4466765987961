import { Component, Input, OnInit } from '@angular/core';

import { TierName } from '../../models/loyalty/tier';

@Component({
  selector: 'ysh-loyalty-badge',
  templateUrl: 'loyalty-badge.html',
  styleUrls: ['./loyalty-badge.scss'],
})
export class LoyaltyBadgeComponent implements OnInit {
  @Input() postfix?: string;
  @Input() color: string;
  @Input() label: TierName;
  isTrueBlue: boolean;

  ngOnInit() {
    this.isTrueBlue = this.label === TierName.TrueBlue;
  }
}
