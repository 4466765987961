import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ysh-new-features-tooltip',
  templateUrl: './new-features-tooltip.component.html',
  styleUrls: ['./new-features-tooltip.component.scss'],
})
export class NewFeaturesTooltipComponent {
  @Input() featureDescription: string;
  @Output() onTap: EventEmitter<void> = new EventEmitter();
  @Input() show = false;

  constructor() {}

  didTapTooltip() {
    this.onTap.emit();
  }
}
