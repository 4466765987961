import * as moment from 'moment';
import * as tinycolor from 'tinycolor2';

import { Default, MemoizeGetters, Transform } from '../base';

import { BaseEntity } from '../base/base';

export enum TierName {
  Mint = 'Mint',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
  TrueBlue = 'True Blue',
  Unknown = 'Unkown',
}

@MemoizeGetters
export class LoyaltyTier extends BaseEntity {
  @Default(TierName.Unknown)
  name: TierName;

  @Default(0)
  threshold: number;

  @Default('black')
  color?: string;

  @Transform(to12hourString)
  @Default(24)
  orderCutoff: string;

  static UnknownTier = new LoyaltyTier({});

  get orderCutoffHour(): number {
    return moment(this.orderCutoff, 'ha').hour();
  }

  get colorIsDark() {
    return tinycolor(this.color).getBrightness() < 100;
  }

  get colorIsLight() {
    return tinycolor(this.color).getBrightness() > 210;
  }

  get darkenedColor() {
    return this.colorIsLight ? tinycolor(this.color).darken(15) : this.color;
  }
}

function to12hourString(value): string {
  return moment(value, 'h:mm').format('ha');
}
