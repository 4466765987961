import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NotesForFillerPageRoutingModule } from './notes-for-filler-routing.module';

import { NotesForFillerPage } from './notes-for-filler.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NotesForFillerPageRoutingModule,
    ComponentsModule,
  ],
  declarations: [NotesForFillerPage],
})
export class NotesForFillerPageModule {}
