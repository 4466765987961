import { BaseEntity, Transform, toDate } from './base';

export class Photo extends BaseEntity {
  url: string;

  @Transform(toDate)
  createdAt: Date;

  @Transform(toDate)
  takenAt: Date;
}
