import { Component, EventEmitter, Input, Output } from '@angular/core';

import { OrderCreateService } from 'src/app/services/scheduling/order-create/order-create.service';
import { ServiceGroup } from '../../models/service-group';
import { ServiceName } from './../../models/service-type';
import { UserAddress } from './../../models/user-address';
import { Vehicle } from '../../models/vehicle';
import { YoshiCurrencyPipe } from 'src/app/pipes/yoshi-currency/yoshi-currency';

@Component({
  selector: 'ysh-service-card',
  templateUrl: './service-card.html',
  styleUrls: ['./service-card.scss'],
})
export class ServiceCardComponent {
  @Input() serviceGroup: ServiceGroup;
  @Input() showAddBtn = false;
  @Input() selectedVehicle: Vehicle;
  @Input() selectedAddress: UserAddress;
  @Output() onTapServiceItem: EventEmitter<any> = new EventEmitter();
  @Output() onServiceAdded: EventEmitter<void> = new EventEmitter();

  @Input() serviceIsAdded = false;
  constructor(private orderCreate: OrderCreateService, private yshCurrency: YoshiCurrencyPipe) {}

  get image() {
    return this.serviceGroup.isFeatured ? this.serviceGroup.image : this.serviceGroup.thumbnail;
  }

  get classForService() {
    const serviceName: ServiceName = this.serviceGroup?.serviceType.name;
    const alignment: string = this.serviceGroup?.serviceType.presentation.thumbnailAlignment;
    return serviceName ? `${serviceName} ${alignment}`.trim() : '';
  }

  get priceString() {
    if (this.serviceGroup?.price > 0) {
      const prefix = this.serviceGroup?.services?.length > 1 ? 'From ' : '';
      return `${prefix}${this.yshCurrency.transform(this.serviceGroup.price)}`;
    }
  }

  didTap() {
    this.onTapServiceItem.emit(this.serviceGroup);
  }

  didTapAddService($event?: Event) {
    $event && $event.stopPropagation();
    this.orderCreate.showOrderFlowForService(this.serviceGroup.basicService, this.serviceGroup);
  }
}
