import { BackButtonService } from '../back-button/back-button.service';
import { Injectable } from '@angular/core';
import { KeyboardService } from '../keyboard/keyboard.service';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalCtrl: ModalController, private keyboardService: KeyboardService) {}

  async open<T>(options: YoshiModalOptions<T>) {
    return this.presentModal({
      ...options,
      cssClass: this.classForModal((options.component as any).PAGE_NAME),
    });
  }

  async openPage<T>(options: YoshiModalOptions<T>, attachClass?: boolean) {
    let modalClass = '';
    if (attachClass) {
      modalClass = this.classForModal((options.component as any).PAGE_NAME);
    }
    return this.presentModal({ ...options, cssClass: [YshModalClass.YshModalPage, modalClass] });
  }

  private async presentModal<T>(options: YoshiModalOptions<T>): Promise<HTMLIonModalElement> {
    const { backdropDismiss = true, animated = true } = options;
    if (options['props']) {
      options.componentProps = options.componentProps || {};
      options.componentProps['props'] = options['props'];
    }
    const modal = this.modalCtrl.create({
      ...options,
      backdropDismiss,
      showBackdrop: true,
      animated,
    });

    (await modal).present();
    return modal;
  }

  dismissModal(data?: any): Promise<boolean> {
    if (this.keyboardService.isVisible()) {
      return new Promise<boolean>((resolve) => {
        window.addEventListener(
          'keyboardDidHide',
          () => this.modalCtrl.dismiss(data).then(() => resolve(true)),
          { once: true }
        );
      });
    } else {
      return this.modalCtrl.dismiss(data);
    }
  }

  private classForModal(modal: string) {
    switch (modal) {
      case YshModals.FaqModalPage:
        return `${YshModalClass.YshModal} ${YshModalClass.Faqs}`;
      case YshModals.RequestReceivedModalPage:
        return `${YshModalClass.YshModal} ${YshModalClass.RequestReceived}`;
      case YshModals.CapacityDisclaimerPage:
        return `${YshModalClass.YshModal} ${YshModalClass.CapacityDisclaimer}`;
      case YshModals.CutoffDisclaimerModalComponent:
        return `${YshModalClass.YshModal} ${YshModalClass.CutOffDisclaimer}`;
      case YshModals.TurnOffWeeklyDisclaimerPage:
        return `${YshModalClass.YshModal} ${YshModalClass.TurnOffWeeklyDisclaimer}`;
      case YshModals.PricePerGallonDetailsModalPage:
        return `${YshModalClass.YshModal} ${YshModalClass.GasPrice}`;
      case YshModals.SimpleMessageModalComponent:
        return `${YshModalClass.YshModal} ${YshModalClass.SimpleMsg}`;
      case YshModals.CustomerServiceModalPage:
        return `${YshModalClass.YshModal} ${YshModalClass.CustomerService}`;
      case YshModals.ServiceSheetPage:
        return `${YshModalClass.YshModal} ${YshModalClass.ServiceSheetPage}`;
      case YshModals.YshVideoPlayerComponent:
        return `${YshModalClass.YshModal} ${YshModalClass.YshVideoPlayerComponent}`;
      case YshModals.VehicleLimit:
        return `${YshModalClass.YshModal} ${YshModalClass.VehicleLimitModal}`;
      case YshModals.Welcome:
        return `${YshModalClass.YshModal} ${YshModalClass.Welcome}`;
      case YshModals.ConfirmInviteAll:
        return `${YshModalClass.YshModal} ${YshModalClass.ConfirmInviteAll}`;
      case YshModals.YshPaymentSheetComponent:
        return `${YshModalClass.YshModal} ${YshModalClass.YshPaymentSheet}`;
      case YshModals.ServiceTypeSheet:
        return `${YshModalClass.YshModal} ${YshModalClass.ServiceTypeSheet}`;

      default:
        return `${YshModalClass.YshModal}`;
    }
  }

  getTop() {
    return this.modalCtrl.getTop();
  }
}

type WithProps = { props: any };
export type ComponentType<T> = new (...args: any[]) => T;
export type PropType<T extends WithProps> = T['props'];

interface _YoshiModalOptions<T> extends ModalOptions {
  component: ComponentType<T>;
  /** @deprecated use props instead for type safety */
  componentProps?: Partial<T>;
  card?: boolean;
}

type YoshiModalOptions<T> = _YoshiModalOptions<T> &
  (T extends WithProps ? { props: T['props'] } : {});

enum YshModalClass {
  Addresses = 'addresses-modal',
  GasPrice = 'gas-price-modal',
  Faqs = 'faq-modal',
  RequestReceived = 'request-received-modal',
  TurnOffWeeklyDisclaimer = 'turn-off-weekly-disclaimer-modal',
  CapacityDisclaimer = 'capacity-disclaimer-modal',
  CutOffDisclaimer = 'cut-off-disclaimer-modal',
  SimpleMsg = 'simple-message-modal',
  CustomerService = 'customer-service-modal',
  ServiceSheetPage = 'service-sheet-modal',
  ServicePaymentSheetPage = 'ysh-payment-sheet',
  YshModal = 'ysh-modal',
  YshModalPage = 'ysh-modal-page',
  YshVideoPlayerComponent = 'ysh-video-player-modal',
  VehicleLimitModal = 'vehicle-limit-modal',
  Welcome = 'welcome-modal',
  ConfirmInviteAll = 'confirm-invite-all',
  YshPaymentSheet = 'ysh-payment-sheet',
  ServiceTypeSheet = 'service-type-sheet-modal',
}

export enum YshModals {
  FaqModalPage = 'FaqModalPage',
  RequestReceivedModalPage = 'RequestReceivedModalPage',
  CapacityDisclaimerPage = 'CapacityDisclaimerPage',
  CutoffDisclaimerModalComponent = 'CutoffDisclaimerModalComponent',
  TurnOffWeeklyDisclaimerPage = 'TurnOffWeeklyDisclaimerPage',
  PricePerGallonDetailsModalPage = 'PricePerGallonDetailsModalPage',
  SimpleMessageModalComponent = 'SimpleMessageModalComponent',
  CustomerServiceModalPage = 'CustomerServiceModalPage',
  ServiceSheetPage = 'ServiceSheetPage',
  ServicePaymentSheetPage = 'ServicePaymentSheetPage',
  YshVideoPlayerComponent = 'YshVideoPlayerComponent',
  VehicleLimit = 'VehicleLimitModalComponent',
  Welcome = 'WelcomePage',
  ConfirmInviteAll = 'ConfirmInviteAllPage',
  YshPaymentSheetComponent = 'YshPaymentSheetComponent',
  ServiceTypeSheet = 'ServiceTypeSheet',
}
