import { Component, Input, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { NavParams } from '@ionic/angular';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';

@Component({
  selector: 'ysh-service-sheet',
  templateUrl: './service-sheet.page.html',
  styleUrls: ['./service-sheet.page.scss'],
})
export class ServiceSheetPage implements OnInit {
  static PAGE_NAME = YshModals.ServiceSheetPage;
  @Input() serviceGroup: ServiceGroup;
  @Input() selectedService: Service;
  @Input() onServiceAdded: (selected: Service, selectedFrequency) => void;

  frequencyOptions = [];
  selectedFrequency;

  constructor(
    public navParams: NavParams,
    private modalService: ModalService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.selectedService = this.selectedService || this.serviceGroup.basicService;
    this.selectedFrequency = this.selectedService.defaultFrequency;
  }

  ionViewDidLoad() {
    this.analytics.trackView('ServiceSheetPage');
  }

  // Actions

  didSelectSubType(subtype) {
    this.selectedService = subtype;
  }

  didSelectFrequency(frequency) {
    this.selectedFrequency = frequency;
  }

  async didTapAddService() {
    this.dismiss();
    this.onServiceAdded?.(this.selectedService, this.selectedFrequency);
  }

  dismiss() {
    this.modalService.dismissModal();
  }
}
