import { Component, NgZone, OnInit, ViewChild } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'ysh-places-autocomplete',
  templateUrl: './places-autocomplete.page.html',
  styleUrls: ['./places-autocomplete.page.scss'],
})
export class PlacesAutocompletePage implements OnInit {
  autocompleteItems: Array<any> = [];
  autocomplete: any;
  service = new google.maps.places.AutocompleteService();
  @ViewChild('searchBar') searchbar;

  constructor(
    private zone: NgZone,
    private keyboardService: KeyboardService,
    private analytics: AnalyticsService,
    private platform: Platform,
    private modalService: ModalService
  ) {
    this.autocompleteItems = [];
    this.autocomplete = { query: '' };
  }

  ngOnInit() {
    this.analytics.trackView('PlacesAutocompletePage');
  }

  ionViewWillEnter() {
    this.keyboardService.disableScroll(true);
  }

  ionViewDidEnter() {
    if (this.platform.is('android')) {
      this.keyboardService.open();
    }
    setTimeout(() => {
      this.searchbar.setFocus();
    }, 150);
  }
  ionViewWillLeave() {
    this.keyboardService.close();
    this.keyboardService.disableScroll(false);
  }

  dismiss(data?: any) {
    this.modalService.dismissModal(data);
  }

  didSelectItem(item: any) {
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails(
      {
        placeId: item.place_id,
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.dismiss(place);
        }
      }
    );
  }

  updateSearch() {
    if (this.autocomplete.query === '') {
      this.autocompleteItems = [];
      return;
    }
    this.service.getPlacePredictions(
      {
        input: this.autocomplete.query,
        componentRestrictions: { country: 'US' },
      },
      (predictions, status) => {
        this.autocompleteItems = [];
        this.zone.run(() => {
          if (predictions) {
            predictions.forEach((prediction) => {
              this.autocompleteItems.push(prediction);
            });
          }
        });
      }
    );
  }
}
