import { IonicImageLoaderComponent, IonicImageLoaderModule } from 'ionic-image-loader-v5';

import { AddServiceComponent } from './add-service/add-service';
import { CommonModule } from '@angular/common';
import { CutoffDisclaimerModalComponent } from './cutoff-disclaimer-modal/cutoff-disclaimer-modal';
import { DayCellComponent } from './day-cell/day-cell.component';
import { EmptyStateComponent } from './empty-state/empty-state';
import { FaqsModalComponent } from './faqs-modal/faqs-modal';
import { FormsModule } from '@angular/forms';
import { FuelStatusComponent } from './fuel-status/fuel-status.component';
import { IonicModule } from '@ionic/angular';
import { LegalModalComponent } from './legal-modal/legal-modal';
import { LoyaltyBadgeComponent } from './loyalty-badge/loyalty-badge';
import { LoyaltyStarsComponent } from './loyalty-stars/loyalty-stars';
import { NewFeaturesTooltipComponent } from './new-features-tooltip/new-features-tooltip.component';
import { NgModule } from '@angular/core';
import { OrderFlowHeaderComponent } from './order-flow-header/order-flow-header';
import { OrderFlowNavbar } from './order-flow-navbar/order-flow-navbar';
import { PipesModule } from '../pipes/pipes.module';
import { ProgressBarComponent } from './progress-bar/progress-bar';
import { ServiceCardComponent } from './service-card/service-card';
import { ServiceIconComponent } from './service-icon/service-icon';
import { ServiceTypeSheet } from './service-type-sheet/service-type-sheet';
import { SimpleMessageModalComponent } from './simple-message-modal/simple-message-modal';
import { SlidesModalComponent } from './slides-modal/slides-modal';
import { VehicleImageComponent } from './vehicle-image/vehicle-image';
import { VehicleInformationComponent } from './vehicle-information/vehicle-information';
import { YoshiButtonComponent } from './yoshi-button/yoshi-button';
import { YoshiButtonIconComponent } from './yoshi-button-icon/yoshi-button-icon';
import { YshBackButtonComponent } from './ysh-back-button/ysh-back-button.component';
import { YshBottomSheetComponent } from './bottom-sheet/ysh-bottom-sheet.component';
import { YshCurvedActionsBlockComponent } from './ysh-curved-actions-block/ysh-curved-actions-block.component';
import { YshDropdownComponent } from './ysh-dropdown/ysh-dropdown';
import { YshGalleryComponent } from './ysh-gallery/ysh-gallery.component';
import { YshInputComponent } from './ysh-input/ysh-input';
import { YshPaymentSheetComponent } from './ysh-payment-sheet/ysh-payment-sheet.component';
import { YshPillButtonComponent } from './ysh-pill-button/ysh-pill-button.component';
import { YshVideoPlayerComponent } from './ysh-video-player/ysh-video-player';

@NgModule({
  declarations: [
    ProgressBarComponent,
    AddServiceComponent,
    ServiceIconComponent,
    EmptyStateComponent,
    VehicleImageComponent,
    VehicleInformationComponent,
    ServiceCardComponent,
    OrderFlowNavbar,
    OrderFlowHeaderComponent,
    SlidesModalComponent,
    SimpleMessageModalComponent,
    FaqsModalComponent,
    LegalModalComponent,
    LoyaltyStarsComponent,
    LoyaltyBadgeComponent,
    CutoffDisclaimerModalComponent,
    YoshiButtonComponent,
    YoshiButtonIconComponent,
    YshDropdownComponent,
    YshVideoPlayerComponent,
    YshGalleryComponent,
    YshInputComponent,
    YshBackButtonComponent,
    YshCurvedActionsBlockComponent,
    YshPaymentSheetComponent,
    YshBottomSheetComponent,
    FuelStatusComponent,
    NewFeaturesTooltipComponent,
    ServiceTypeSheet,
    DayCellComponent,
    YshPillButtonComponent,
  ],

  imports: [IonicModule, CommonModule, PipesModule, FormsModule, IonicImageLoaderModule],
  exports: [
    AddServiceComponent,
    ServiceIconComponent,
    ProgressBarComponent,
    EmptyStateComponent,
    VehicleImageComponent,
    ServiceCardComponent,
    OrderFlowNavbar,
    OrderFlowHeaderComponent,
    SlidesModalComponent,
    SimpleMessageModalComponent,
    FaqsModalComponent,
    LegalModalComponent,
    LoyaltyStarsComponent,
    LoyaltyBadgeComponent,
    CutoffDisclaimerModalComponent,
    YoshiButtonComponent,
    YoshiButtonIconComponent,
    YshDropdownComponent,
    YshVideoPlayerComponent,
    VehicleInformationComponent,
    YshGalleryComponent,
    YshInputComponent,
    YshBackButtonComponent,
    YshCurvedActionsBlockComponent,
    YshPaymentSheetComponent,
    YshBottomSheetComponent,
    FuelStatusComponent,
    NewFeaturesTooltipComponent,
    IonicImageLoaderComponent,
    ServiceTypeSheet,
    DayCellComponent,
    YshPillButtonComponent,
  ],
})
export class ComponentsModule {}
