import { Component, Input, Renderer2 } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';
@Component({
  selector: 'ysh-simple-message-modal',
  templateUrl: 'simple-message-modal.html',
  styleUrls: ['./simple-message-modal.scss'],
})
export class SimpleMessageModalComponent {
  static PAGE_NAME = YshModals.SimpleMessageModalComponent;

  @Input() title: string;
  @Input() image: string;
  @Input() imageWidth: number;
  @Input() description: string;
  @Input() actionButtonText?: string;
  @Input() secondaryButtonText?: string;
  @Input() onPrimaryTap?: () => void;
  @Input() onSecondaryTap?: () => void;

  constructor(private modalService: ModalService) {}

  didTapModalAction() {
    this.onPrimaryTap?.();
    this.dismiss();
  }

  didTapSecondaryButton() {
    this.onSecondaryTap?.();
    this.dismiss();
  }

  didTapBackdrop() {
    this.dismiss();
  }

  private dismiss() {
    this.modalService.dismissModal();
  }
}
