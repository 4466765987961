import { BaseEntity, MemoizeGetters, Transform, toDate, toEntity } from '../base';
import { LoyaltyTier, TierName } from './tier';

import { LoyaltyPeriod } from './period';
import { LoyaltyPolicy } from './policy';
import { PerkName } from './perk';

@MemoizeGetters
export class LoyaltyProgram extends BaseEntity {
  tier: TierName;

  @Transform(parseInt)
  points: number;

  @Transform(toDate)
  enrolledAt: Date;

  @Transform(toDate)
  expirationDate: Date;

  @Transform(toEntity(LoyaltyPeriod))
  benefitsPeriod: LoyaltyPeriod;

  @Transform(toEntity(LoyaltyPeriod))
  earningsPeriod: LoyaltyPeriod;

  @Transform(toEntity(LoyaltyPolicy))
  loyaltyPolicy: LoyaltyPolicy;

  get pointsUntilNextReward(): number {
    return Math.max(0, this.rewardCost - this.availablePoints);
  }

  get rewardCost(): number {
    const reward = this.loyaltyPolicy.rewards
      .filter((reward) => reward.name === PerkName.Reward)
      .sort((a, b) => a.price - b.price)[0];
    return reward ? reward.price : 0;
  }

  get rewardProgress(): number {
    return (100 * this.availablePoints) / this.rewardCost;
  }

  get availablePoints(): number {
    return Math.max(0, this.points - this.earningsPeriod.redeemedPoints);
  }

  get valid(): boolean {
    const validPolicy = this.loyaltyPolicy && this.loyaltyPolicy.valid && this.getTier(this.tier);
    const validPeriod = !!this.earningsPeriod;
    return validPolicy != null && validPeriod != null;
  }

  get fullTier(): LoyaltyTier {
    return this.getTier(this.tier);
  }

  private get maxCutoffTier(): LoyaltyTier {
    const sortedTiers = this.loyaltyPolicy.tiers.sort(
      (a, b) => b.orderCutoffHour - a.orderCutoffHour
    );
    return sortedTiers[0];
  }

  get maxCutoff(): string {
    return this.maxCutoffTier.orderCutoff;
  }

  get maxCutoffHour(): number {
    return this.maxCutoffTier.orderCutoffHour;
  }

  getTier(tierName: TierName): LoyaltyTier {
    return (
      this.loyaltyPolicy.tiers.find((tier) => tier.name === tierName) || LoyaltyTier.UnknownTier
    );
  }
}
