import { Component, Input } from '@angular/core';

import { Service } from 'src/app/models/service';
import { ServiceName } from 'src/app/models/service-type';
import { Visit } from 'src/app/models/visit';

@Component({
  selector: 'ysh-service-status',
  templateUrl: 'service-status.html',
  styleUrls: ['./service-status.scss'],
})
export class ServiceStatusComponent {
  @Input() service: Service;
  @Input() visit: Visit;
  @Input() subscribed: boolean;

  get timeWindow(): string | null {
    if (this.visit.order.service.slotScheduling) {
      return this.visit.order.slotTimeString;
    } else {
      const timeWindow = this.visit.order.shiftTimeString();
      return timeWindow ? `(${timeWindow})` : null;
    }
  }

  get oneTimeOnly() {
    return this.visit && !this.subscribed;
  }

  get recurrence() {
    if (this.service.serviceType.name === ServiceName.Gas) {
      return this.visit?.frequency;
    }
  }
}
