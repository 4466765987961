import { Component, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

import { Address } from 'src/app/models/address';

@Component({
  selector: 'ysh-capacity-disclaimer',
  templateUrl: './capacity-disclaimer.page.html',
  styleUrls: ['./capacity-disclaimer.page.scss'],
})
export class CapacityDisclaimerPage implements OnInit {
  static PAGE_NAME = YshModals.CapacityDisclaimerPage;
  warningDescription: string;

  static ShowForAddress(address: Address) {
    // noop
    // TODO: Show dynamically
    return null;
  }

  constructor(private modalService: ModalService) {
    console.log('Hello CapacityDisclaimerPage Component');
    this.warningDescription =
      'We are currently experiencing high growth in the Los Angeles region. While we are working hard to increase capacity, a few customers are experiencing cancellations. If you are impacted, we will do our best to let you know as early in the day as possible and will work with you to reschedule your service to another day. Thanks for your understanding as we quickly grow!';
  }

  ngOnInit() {
    console.log('ionViewDidLoad CapacityDisclaimerPage');
  }

  didTapOK() {
    this.modalService.dismissModal();
  }
}
