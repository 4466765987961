import { MemoizeGetters } from './base';
import { Route } from './route';
import { Shift } from './shift';
import { TimeSlot } from './time-slot';
import moment from 'moment';

const ALL_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const ALL_DAYS_ABBREVIATED = ['SU', 'M', 'TU', 'W', 'TH', 'F', 'SA'];
const DEFAULT_MINIMUM_WINDOW = 4;

@MemoizeGetters
export class Day {
  dayOfTheWeek: string;
  dayIndex = 0;
  displayName: string;
  routes: Route[] = [];
  slots: TimeSlot[] = [];
  date: Date;

  constructor(routes: Route[] = [], date: Date, public cutoff = 24, slots: TimeSlot[] = []) {
    this.date = date;
    this.dayIndex = this.date.getDay();
    this.dayOfTheWeek = ALL_DAYS[this.dayIndex];
    this.displayName = ALL_DAYS_ABBREVIATED[this.dayIndex];
    this.routes = routes;
    this.slots = slots;
  }

  // Computed Properties

  get nextDate() {
    // If there are still routes available, use date
    // If there no routes, we can assume this is a placeholder day
    if (this.routesAvailable || !this.routes.length || this.slotsAvailable) {
      return this.date;
    }
    // Otherwise, calculate the next available date
    return this.nextDateForDayOfWeek(this.dayIndex);
  }

  get shifts(): Shift[] {
    const uniqueShifts: { [title: string]: Shift } = {};
    this.routes.forEach((route) => (uniqueShifts[route.shift.title] = route.shift));
    return Object.values(uniqueShifts);
  }

  private routeForShift(shift: Shift): Route | undefined {
    return this.routes.find((route) => route.shift.uid === shift.uid);
  }

  shiftAvailable(shift: Shift): boolean {
    const route = this.routeForShift(shift);
    return Boolean(route && (!this.isToday || route.available));
  }

  defaultShift(): Shift | undefined {
    return this.isToday ? this.routes.find((route) => route.available)?.shift : this.shifts[0];
  }

  minimumWindowForShift(shift: Shift): number {
    return this.routeForShift(shift)?.minimumTimeWindow || DEFAULT_MINIMUM_WINDOW;
  }

  private nextDateForDayOfWeek(dayIndex: number) {
    // If weekday has already passed this week, use next week
    const todayIndex = new Date().getDay();
    const diff = dayIndex > todayIndex ? dayIndex - todayIndex : dayIndex - todayIndex + 7;
    return moment().add(diff, 'day').toDate();
  }

  get routesAvailable() {
    return Boolean(this.routes.find((route) => route.available(this.cutoff)));
  }

  get slotsAvailable() {
    return this.slots.length > 0;
  }

  get dayOfWeek() {
    return moment(this.date).format('dddd');
  }

  get isToday() {
    const today = new Date();
    return today.toDateString() === this.nextDate.toDateString();
  }

  get isTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toDateString() === this.nextDate.toDateString();
  }

  nextDateString(showStarts = true, showTomorrow = true) {
    const prefix = showStarts ? 'Starts ' : '';
    let dateString = '';
    if (this.isToday) {
      dateString = 'Today';
    } else if (showTomorrow && this.isTomorrow) {
      dateString = 'Tomorrow';
    } else {
      dateString = moment(this.nextDate).format('M/DD');
    }
    return `${prefix}${dateString}`;
  }
}
