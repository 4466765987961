import { BaseEntity, MemoizeGetters, Transform, toEntity } from './base';

import { Company } from './company';

@MemoizeGetters
export class Affiliation extends BaseEntity {
  @Transform(toEntity(Company))
  company: Company;
  externalId: string;
}
