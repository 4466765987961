import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { MembershipService } from 'src/app/services/api/membership/membership.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleFlowController } from '../../flows/vehicle/vehicle-flow-controller/vehicle-flow-controller.page';
import { VehicleLimitModalComponent } from './vehicle-limit-modal/vehicle-limit-modal.component';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-vehicles',
  templateUrl: './vehicles.page.html',
  styleUrls: ['./vehicles.page.scss'],
})
export class VehiclesPage implements OnDestroy {
  readonly LEGACY_POLICY_LIMIT = 100;

  @Input() modal = false;
  @Input() onComplete: (vehicle: Vehicle) => void;

  vehicles$: Observable<Vehicle[]>;
  isLoading$: Observable<boolean>;
  vehicleCount: number;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public vehicleService: VehiclesService,
    private modalService: ModalService,
    private loadingAlertCtrl: LoadingAlertService,
    private analytics: AnalyticsService,
    private memberService: MembershipService
  ) {
    this.isLoading$ = vehicleService.isLoading$;
    this.vehicles$ = vehicleService.vehicles$;
    this.vehicles$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((vehicles: Vehicle[]) => (this.vehicleCount = vehicles.length));
  }

  ionViewDidLoad() {
    this.analytics.trackView('VehiclesPage');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Actions

  didTapDelete(vehicle) {
    this.loadingAlertCtrl.showLoader();
    this.vehicleService
      .deleteVehicle(vehicle)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(
        () => {},
        (error) => {
          this.loadingAlertCtrl.showToastAlert('Error deleting vehicle');
        }
      );
  }

  async didTapNewVehicle() {
    const currentPolicy = this.memberService.memberPolicy$.value;
    const maxPerMonth = currentPolicy?.delivery?.maxPerMonth;
    const hasLegacyPolicy = !maxPerMonth || maxPerMonth >= this.LEGACY_POLICY_LIMIT;
    if (hasLegacyPolicy && this.vehicleCount >= VehiclesService.MAX_VEHICLES_LIMIT) {
      this.showLimitReachedModal();
      return;
    }
    const modal = await this.modalService.openPage({
      component: VehicleFlowController,
      componentProps: {
        onComplete: () => modal.dismiss(),
        onDismiss: () => modal.dismiss(),
      },
      props: {
        isModal: true,
      },
    });
  }

  showLimitReachedModal() {
    this.modalService.open({ component: VehicleLimitModalComponent });
  }

  async didSelectVehicle(vehicle) {
    this.vehicleService.setSelectedVehicle(vehicle);
    if (this.modal) {
      await this.modalService.dismissModal();
    }
    this.onComplete?.(vehicle);
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }
}
