import { Component, HostBinding, Input, OnChanges } from '@angular/core';

export enum YoshiIconName {
  Back = 'back',
  Close = 'close',
  Menu = 'menu',
  Price = 'price',
  Question = 'question',
  Pin = 'pin',
  Locate = 'locate',
}

export enum YoshiIconButtonTheme {
  Light = 'light',
  Dark = 'dark',
}

@Component({
  selector: 'button[ysh-button-icon]',
  templateUrl: 'yoshi-button-icon.html',
  exportAs: 'yoshiButtonIcon',
})
export class YoshiButtonIconComponent implements OnChanges {
  @Input() name: YoshiIconName;
  @Input() theme: YoshiIconButtonTheme = YoshiIconButtonTheme.Light;

  image: string;
  icon: string;

  @Input() class = '';
  @HostBinding('class')
  get hostclass() {
    return `${this.class} ysh-button-icon ${this.theme}`;
  }

  ngOnChanges(): void {
    this.image = ICON_IMAGE_MAP[this.name];
    this.icon = ICON_MAP[this.name];
  }
}

const ICON_IMAGE_MAP = {
  [YoshiIconName.Menu]: '../../../assets/images/icon-menu.svg',
  [YoshiIconName.Price]: '../../../assets/images/icon-price.svg',
  [YoshiIconName.Back]: '../../../assets/images/icon-back.svg',
  [YoshiIconName.Pin]: '../../../assets/images/icon-pin.svg',
  [YoshiIconName.Question]: '../../../assets/images/icon-question.svg',
  [YoshiIconName.Close]: '../../../assets/images/icon-close.svg',
  [YoshiIconName.Locate]: '/assets/images/icon-locate.svg',
};

const ICON_MAP = {};
