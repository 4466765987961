import { CanActivate, Router } from '@angular/router';

import { AppRoutes } from 'src/app/routes';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { UserService } from 'src/app/services/api/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private session: Storage, private router: Router) {}
  async canActivate() {
    const authenticated = await this.isAuthenticated();
    if (!authenticated) {
      this.router.navigate([AppRoutes.Login]);
    }
    return authenticated;
  }
  async isAuthenticated() {
    return Boolean(await this.session.get(UserService.AUTH_DATA_KEY));
  }
}
