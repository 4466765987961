import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { DollarsAndCentsPipe } from 'src/app/pipes/dollars-and-cents/dollars-and-cents';
import { FuelType } from 'src/app/models/fuel-type';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { Order } from 'src/app/models/order';
import { OrderCreateService } from 'src/app/services/scheduling/order-create/order-create.service';
import { OrderEditService } from 'src/app/services/scheduling/order-edit/order-edit.service';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceOrder } from 'src/app/models/service-order';
import { ServiceSubscription } from 'src/app/models/service-subscription';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleSubscription } from 'src/app/models/vehicle-subscription';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { Visit } from 'src/app/models/visit';

@Component({
  selector: 'ysh-service-tools',
  templateUrl: 'service-tools.html',
  styleUrls: ['./service-tools.scss'],
})
export class ServiceToolsComponent implements OnInit, OnChanges {
  @HostBinding('hidden')
  isHidden: boolean = false;

  readonly SELECT_OPTIONS = {
    header: 'Choose Option',
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ],
  };

  @Input() serviceGroup: ServiceGroup;
  @Input() selectedService: Service;
  @Input() fuelTypes: FuelType[] = [];
  @Input() vehicle: Vehicle;
  @Input() order: Order;
  @Input() visit: Visit;
  @Input() vehicleSubscription: VehicleSubscription;
  @Input() serviceOrder: ServiceOrder;
  @Input() serviceSubscription: ServiceSubscription;
  @Input() hideButtons = false;
  @Output() onServiceChange: EventEmitter<Service> = new EventEmitter();

  tierOptions: string[] = [];
  fuelTypeOptions: string[] = [];
  selectedTierIndex = 0;
  selectedFuelIndex = 0;
  hasOrder = false;

  constructor(
    private currency: DollarsAndCentsPipe,
    private orderCreate: OrderCreateService,
    private orderEdit: OrderEditService,
    private vehicleService: VehiclesService,
    private loadingAlert: LoadingAlertService
  ) {}

  ngOnInit(): void {
    this.tierOptions = this.createTierOptions(this.serviceGroup);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tierOptions = this.createTierOptions(this.serviceGroup);
    this.fuelTypeOptions = this.createFuelTypeOptions(this.fuelTypes || []);
    this.hasOrder = Boolean(this.order || this.serviceOrder);
    this.setSelectedFuelIndex();
    this.setSelectectTierIndex();
    this.isHidden =
      this.hideButtons &&
      this.tierOptions.length <= 1 &&
      this.serviceGroup.serviceType.name !== 'gas_refill';
  }

  private createTierOptions(serviceGroup: ServiceGroup) {
    if (serviceGroup.services.length < 1) {
      return [];
    }
    return serviceGroup.services.map(
      (service) => `${service.title} - ${this.currency.transform(service.price, false)}`
    );
  }

  private createFuelTypeOptions(fuelTypes: FuelType[] = []): string[] {
    return fuelTypes.map((type) => type.shortDescription);
  }

  didTapAddService() {
    this.orderCreate.showOrderFlowForService(this.selectedService, this.serviceGroup);
  }

  didTapEdit() {
    if (this.visit && !this.serviceOrder) {
      this.orderEdit.showEditOptionsForVisit(this.visit);
    } else if (this.serviceOrder) {
      this.orderEdit.showEditOptionsForService(this.serviceOrder);
    }
  }

  didChangeTier(index: number) {
    this.selectedTierIndex = index;
    const serviceIndex = this.serviceGroup.services.indexOf(this.selectedService);
    const newIndex = serviceIndex !== index;
    if (newIndex) {
      if (this.hasOrder) {
        this.loadingAlert.showConfirmationDialog(
          'To select a new option, please cancel and reorder.',
          'Show Edit Options',
          () => this.didTapEdit()
        );
        setTimeout(() => {
          this.setSelectectTierIndex();
        }, 1);
      } else {
        this.selectedService = this.serviceGroup.services[index];
        this.onServiceChange.emit(this.selectedService);
      }
    }
  }

  didChangeFuelType(index: number) {
    if (index < 0) {
      return;
    }
    const fuelTypeUid = this.fuelTypes[index].uid;
    this.vehicleService
      .updateVehicle(this.vehicle, {
        fuelTypeUid,
      })
      .subscribe((vehicle) => {
        this.vehicle = vehicle;
      });
  }

  private setSelectedFuelIndex() {
    this.selectedFuelIndex = this.fuelTypes?.findIndex(
      (type) => type.uid === this.vehicle?.fuelType?.uid
    );
  }

  private setSelectectTierIndex() {
    this.selectedTierIndex = this.serviceGroup.services.findIndex(
      (service) => service.uid === this.selectedService.uid
    );
  }
}
