import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import {
  MemberSubscription,
  MemberSubscriptionCreateParams,
} from 'src/app/models/member-subscription';
import { Observable, Subject, combineLatest } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

import { ActionSheetController } from '@ionic/angular';
import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { MemberTier } from 'src/app/models/member-tier';
import { MembershipService } from 'src/app/services/api/membership/membership.service';
import { UserService } from 'src/app/services/api/user/user.service';
import listify from 'listify';

const PROMPT_UPGRADE_TO_ANNUAL = 'Switch over to an annual membership and save!';
const PROMPT_CHOOSE_MEMBERSHIP = 'Select a payment option:';
const PROMPT_START_MEMBERSHIP = 'Start your subscription:';
const CANCELLATION_SUCCESS_MESSAGE = 'Your membership has been cancelled';
const CANCELLATION_ERROR_MESSAGE = 'There was an error cancelling your membership';
const UPGRADE_SUCCESS_MESSAGE = `We've upgraded your account!`;
const UPGRADE_ERROR_MESSAGE = 'There was an error upgrading your account';
const CANCELLATION_REASON_SKIP = 'pay-as-you-go';

@Component({
  selector: 'ysh-select-membership',
  templateUrl: './select-membership.page.html',
  styleUrls: ['./select-membership.page.scss'],
})
export class SelectMembershipPage implements OnDestroy, OnInit {
  @Input() onComplete: (finished: boolean) => void;
  @Input() upgrade = false;
  @Input() isModal = false;

  monthlyTier: Nullable<MemberTier>;
  annualTier: Nullable<MemberTier>;
  selectedTier: Nullable<MemberTier>;
  membership: Nullable<MemberSubscription>;
  freeTrialInEffect = false;
  deliveryFee: string;
  discountedMonthlyFee: number;
  allowSkip = false;
  discountDescription$: Observable<string>;
  maxVisitsPerMonth: number;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public memberService: MembershipService,
    private actionSheet: ActionSheetController,
    private addressService: AddressService,
    private analytics: AnalyticsService,
    private alertService: LoadingAlertService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private featureFlags: FeatureFlagService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.analytics.trackView('SelectMembershipPage');
    this.memberService.getMembershipTiers();
    this.memberService.setLastPromptedDate();
    this.loadDiscountedMonthlyFee();
    this.loadTiers();
    this.loadMembership();
    this.loadFeatureFlags();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Actions

  didSelectPlan(tier: MemberTier) {
    this.selectedTier = tier;
  }

  didTapDismiss() {
    if (this.onComplete) {
      this.onComplete(false);
    }
  }

  didTapSubscribe(selectedTier: MemberTier) {
    const alreadySubscribedToTier = this.membership?.term === selectedTier.term;
    const subscriptionIsFree = !this.discountedMonthlyFee;
    if (alreadySubscribedToTier) {
      this.didFinish();
    } else if (subscriptionIsFree) {
      this.subscribeToTier(selectedTier);
    } else {
      this.promptToSubscribe(selectedTier);
    }
  }

  didTapSkip() {
    this.promptToSkip();
  }

  private didFinish() {
    if (this.onComplete) {
      this.onComplete(true);
    }
  }

  // Prompts

  async promptToSubscribe(tier: MemberTier) {
    const actionSheet = await this.actionSheet.create({
      header: this.getUpgradePrompt(tier),
      buttons: [
        {
          role: 'cancel',
          text: 'Cancel',
        },
        {
          text: 'Confirm',
          handler: () => {
            this.subscribeToTier(tier);
          },
        },
      ],
    });
    actionSheet.present();
  }

  private getUpgradePrompt(tier: MemberTier) {
    const datePaidThrough = new Date();
    datePaidThrough.setDate(datePaidThrough.getDate() + tier.billingCycleDays);
    const dateString = this.datePipe.transform(datePaidThrough, 'MMMM d, y');
    return `Start subscription? Your membership will be paid through ${dateString}. Your card will be charged for the first period today.`;
  }

  async promptToSkip() {
    const actionSheet = await this.actionSheet.create({
      header: this.getSkipPrompt(),
      buttons: [
        {
          role: 'cancel',
          text: 'Cancel',
        },
        {
          text: 'Confirm',
          handler: () => {
            if (this.membership) {
              this.cancelMembership(this.membership);
            } else {
              this.onComplete(true);
            }
          },
        },
      ],
    });
    actionSheet.present();
  }

  private getSkipPrompt() {
    const freeTrialDisclaimer = this.freeTrialInEffect
      ? 'Your free trial will be cancelled effective immediately.'
      : '';
    return `Proceed with ${this.deliveryFee} delivery fees? ${freeTrialDisclaimer}`.trim();
  }

  // Data

  loadDiscountedMonthlyFee() {
    this.memberService.discountedMonthlyTier$.pipe(takeUntil(this.unsubscribe)).subscribe((fee) => {
      this.discountedMonthlyFee = fee;
    });
    this.discountDescription$ = combineLatest([
      this.memberService.totalMonthlyDiscount$,
      this.memberService.membershipSponsors$,
    ]).pipe(
      map(([discount, sponsors]) => {
        const sponsorString = listify(sponsors);
        return discount ? `Includes $${discount} monthly discount from ${sponsorString}` : '';
      })
    );
  }

  loadTiers() {
    combineLatest([
      this.memberService.monthlyTier$,
      this.memberService.annualTier$,
      this.memberService.eligibleForAnnualUpgrade$,
      this.addressService.selectedAddress$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([monthlyTier, annualTier, eligible, userAddress]) => {
        this.monthlyTier = monthlyTier;
        if (!userAddress?.address.isCorporate && eligible) {
          this.annualTier = annualTier;
        }
        this.selectedTier = (this.upgrade && this.annualTier) || this.monthlyTier;
      });
  }

  loadMembership() {
    combineLatest([
      this.memberService.membership$,
      this.memberService.memberPolicy$,
      this.memberService.freeTrialInEffect$,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([membership, policy, freeTrial]) => {
        this.membership = membership;
        this.freeTrialInEffect = freeTrial;
        if (policy) {
          this.deliveryFee =
            this.currencyPipe.transform(
              policy.delivery.feeUnsubscribed,
              'USD',
              'symbol',
              '1.0-2'
            ) || '';
          this.maxVisitsPerMonth = policy.delivery.maxPerMonth;
        }
      });
  }

  loadFeatureFlags() {
    this.featureFlags
      .flag$(Flag.AllowMembershipSkip)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((allow) => {
        this.allowSkip = allow;
      });
  }

  subscribeToTier(tier: MemberTier) {
    const params: MemberSubscriptionCreateParams = { tier: tier.tier };
    const request = this.memberService.createMemberSubscription(params);
    this.alertService.showLoader();
    request
      .pipe(
        finalize(() => {
          this.alertService.dismissLoader();
          this.didFinish();
        })
      )
      .subscribe(
        (data) => this.alertService.showToastConfirmation(UPGRADE_SUCCESS_MESSAGE),
        (error) => this.alertService.showToastAlert(UPGRADE_ERROR_MESSAGE)
      );
    return request;
  }

  cancelMembership(membership) {
    this.alertService.showLoader();
    this.memberService
      .cancelMemberSubscription(membership.uid, CANCELLATION_REASON_SKIP, CANCELLATION_REASON_SKIP)
      .pipe(
        finalize(() => {
          this.alertService.dismissLoader();
          this.onComplete(true);
        })
      )
      .subscribe(
        (data) => this.alertService.showToastConfirmation(CANCELLATION_SUCCESS_MESSAGE),
        (error) => this.alertService.showToastAlert(CANCELLATION_ERROR_MESSAGE)
      );
  }

  // Helpers

  get showSkipOption() {
    const skipAllowed = this.allowSkip;
    const hasDeliveryFee = Boolean(this.deliveryFee);
    const hasMonthlyFee = Boolean(this.discountedMonthlyFee);

    if (this.freeTrialInEffect || !this.membership) {
      return !this.upgrade && hasDeliveryFee && hasMonthlyFee && skipAllowed;
    }

    return false;
  }

  get prompt() {
    const converted = this.userService.currentUser$.value?.converted;
    const onlyOneOption = !this.annualTier && !this.showSkipOption;
    return onlyOneOption
      ? PROMPT_START_MEMBERSHIP
      : this.membership && (converted || this.upgrade)
      ? PROMPT_UPGRADE_TO_ANNUAL
      : PROMPT_CHOOSE_MEMBERSHIP;
  }

  get cta() {
    const converted = this.userService.currentUser$.value?.converted;
    const alreadySubscribed = this.membership?.term === this.selectedTier?.term;
    const verb = converted && alreadySubscribed ? 'Continue' : 'Start';
    return `${verb} ${this.selectedTier?.term || ''} subscription`;
  }
}
