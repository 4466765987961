import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { ServicesSharedModule } from '../services/components/services-components.module';
import { VehicleProfileInformationComponent } from './components/vehicle-profile-information/vehicle-profile-information.component';
import { VehicleProfilePage } from './vehicle-profile.page';
import { VehicleProfilePageRoutingModule } from './vehicle-profile-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VehicleProfilePageRoutingModule,
    ServicesSharedModule,
    ComponentsModule,
  ],
  declarations: [VehicleProfilePage, VehicleProfileInformationComponent],
})
export class VehicleProfilePageModule {}
