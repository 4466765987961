import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { DaySelectorPage } from './day-selector.page';
import { DaySelectorPageRoutingModule } from './day-selector-routing.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DaySelectorPageRoutingModule,
    ComponentsModule,
    PipesModule,
  ],
  declarations: [DaySelectorPage],
})
export class DaySelectorPageModule {}
