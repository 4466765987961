export const addMetadataToMap = (
  mapKey: symbol,
  propertyKey: string,
  value: any,
  target: object
) => {
  const map = Reflect.getMetadata(mapKey, target) || {};
  map[propertyKey] = value;
  Reflect.defineMetadata(mapKey, map, target);
};
