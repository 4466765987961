import { Component, Input, NgZone, OnInit } from '@angular/core';
import { LEXUS, Oem, OemMinYear, OemService, TOYOTA } from 'src/app/services/oem/oem.service';
import { finalize, flatMap, map, retry, take, takeWhile } from 'rxjs/operators';
import { interval, timer } from 'rxjs';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavigationService } from 'src/app/navigation.service';
import { UserService } from 'src/app/services/api/user/user.service';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-connect-toyota',
  templateUrl: './connect-toyota.page.html',
  styleUrls: ['./connect-toyota.page.scss'],
})
export class ConnectToyotaPage implements OnInit {
  static readonly BACKOFF_INTERVAL = 3000;

  MIN_YEAR_TOYOTA = OemMinYear.Toyota;
  MIN_YEAR_LEXUS = OemMinYear.Lexus;

  @Input() isModal = false;
  @Input() make: Oem;
  makeIcon: string;
  makePretty: string;
  minYear: number;

  constructor(
    private zone: NgZone,
    private vehicleService: VehiclesService,
    private oem: OemService,
    private userService: UserService,
    private loadingAlertCrtl: LoadingAlertService,
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.analytics.trackView('ConnectToyotaPage');
    if (!this.isModal && this.navigationService.currentRouteState) {
      this.make = this.navigationService.currentRouteState.make;
    }
    this.setupUIForMake();
  }

  setupUIForMake() {
    if (this.make === Oem.Lexus) {
      this.makeIcon = 'assets/images/icon-lexus.svg';
      this.makePretty = LEXUS;
      this.minYear = OemMinYear.Lexus;
    } else {
      this.makeIcon = 'assets/images/icon-toyota.svg';
      this.makePretty = TOYOTA;
      this.minYear = OemMinYear.Toyota;
    }
  }

  didTapBack() {
    this.isModal ? this.modalService.dismissModal() : this.navigationService.goBack();
  }

  didTapConnectToyota() {
    this.oem
      .authenticate(this.make)
      .then(
        (code) => {
          this.onToyotaSuccess(code);
        },
        () => {
          this.onReject();
        }
      )
      .catch((error) => {
        this.onToyotaError();
      });
  }

  didTapNotNow() {
    this.dismiss({
      success: false,
    });
  }

  onToyotaSuccess(authCode: string) {
    this.loadingAlertCrtl.showLoaderWithText(`Getting your vehicle(s) from ${this.makePretty}`);
    timer(ConnectToyotaPage.BACKOFF_INTERVAL)
      .pipe(
        flatMap(() => {
          return this.userService.refreshToyotaToken(authCode, this.make);
        }),
        retry(4),
        finalize(() => this.loadingAlertCrtl.dismissLoader())
      )
      .subscribe(
        (success) => {
          this.zone.run(() => {
            this.loadingAlertCrtl.showToastConfirmation(
              "Congratulations. You have connected your ToyotaConnect account to Yoshi! We've saved your vehicles."
            );
            this.dismiss({ success: true });
            if (!this.isModal) {
              this.navigationService.goBack();
            }
            this.pollForToyotaVehicles();
          });
        },
        (error) => {
          this.onToyotaError();
          console.log('error updating user', JSON.stringify(error));
        }
      );
  }

  private pollForToyotaVehicles() {
    interval(ConnectToyotaPage.BACKOFF_INTERVAL)
      .pipe(
        flatMap(() => this.vehicleService.getVehicles()),
        map((vehicles) => vehicles.find((vehicle) => vehicle.toyotaConnect)),
        take(5),
        takeWhile((vehicle) => {
          if (vehicle) {
            this.vehicleService.setSelectedVehicle(vehicle);
          }
          return !vehicle;
        })
      )
      .subscribe(() => console.log('Vehicle poll'));
  }

  onReject() {
    this.didTapNotNow();
  }

  onToyotaError() {
    this.loadingAlertCrtl.showToastAlert(
      `An error occurred while trying to authenticate with ${this.makePretty}. Please add your vehicle manually.`
    );

    if (this.isModal) {
      this.dismiss({
        success: false,
      });
    }
  }

  private dismiss(data?: any) {
    this.modalService.dismissModal(data);
  }
}
