import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { DaySelectorPageModule } from '../selectors/day-selector/day-selector.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { OrderFlowControllerPage } from './order-flow-controller.page';
import { OrderFlowControllerPageRoutingModule } from './order-flow-controller-routing.module';
import { PinFlowPageModule } from '../pin-flow/pin-flow.module';
import { TimeSlotSelectorPageModule } from '../selectors/time-slot-selector/time-slot-selector.module';
import { TimeWindowSelectorPageModule } from '../selectors/time-window-selector/time-window-selector.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    OrderFlowControllerPageRoutingModule,
    ComponentsModule,
    DaySelectorPageModule,
    TimeSlotSelectorPageModule,
    TimeWindowSelectorPageModule,
    PinFlowPageModule,
  ],
  declarations: [OrderFlowControllerPage],
})
export class OrderFlowControllerPageModule {}
