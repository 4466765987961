export enum TabRoutes {
  Landing = '',
  Login = 'login',
  Tabs = 'tabs',
  Home = `home`,
  Vehicles = 'vehicles',
  Services = 'services',
  Share = 'share',
  Loyalty = 'loyalty',
  Alerts = 'alerts',
  Profile = 'profile',
}
