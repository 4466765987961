import { Component, Input } from '@angular/core';

import { Service } from 'src/app/models/service';
import { YshModals } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'ysh-faq-modal',
  templateUrl: './faq-modal.page.html',
  styleUrls: ['./faq-modal.page.scss'],
})
export class FaqModalPage {
  static PAGE_NAME = YshModals.FaqModalPage;
  @Input() service: Service;
}
