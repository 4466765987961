import * as moment from 'moment';

import { Component, Input, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Day } from 'src/app/models/day';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Order } from 'src/app/models/order';
import { OrderService } from 'src/app/services/api/order/order.service';
import { Service } from 'src/app/models/service';
import { TimeSlot } from 'src/app/models/time-slot';
import { UserAddress } from 'src/app/models/user-address';

export interface TimeSlotSelectorPageProps {
  day: Day;
  service: Service;
  userAddress: UserAddress;
  isModal: boolean;
  selectedSlotStartTime?: string;
  currentOrder?: Order;
  onAddressChanged: (address: UserAddress) => void;
}

export interface TimeSlotSelectorPageForm {
  slot?: TimeSlot;
  availableSlots?: TimeSlot[];
}

@Component({
  selector: 'ysh-time-slot-selector',
  templateUrl: './time-slot-selector.page.html',
  styleUrls: ['./time-slot-selector.page.scss'],
})
export class TimeSlotSelectorPage implements OnInit {
  @Input() onComplete: (requestedSlot: TimeSlot) => void;
  @Input() onDismiss: () => void;

  props: TimeSlotSelectorPageProps;
  form: TimeSlotSelectorPageForm = {};

  validTimeSlot = true;

  constructor(
    private analytics: AnalyticsService,
    private orderService: OrderService,
    private modalService: ModalService,
    private loadingAlertCtrl: LoadingAlertService,
    private addressService: AddressService
  ) {}

  ngOnInit() {
    this.analytics.trackView('TimeSlotSelectorPage');
    if (this.props.day.slots.length <= 0) {
      this.loadTimeSlots().subscribe(
        (slots: TimeSlot[]) => {
          this.form.availableSlots = slots.filter(
            (slot) => slot.date.toDateString() === this.props.day.date.toDateString()
          );
        },
        (error) => {
          this.loadingAlertCtrl.showToastAlert(`Error finding slots for selected Date: ${error}`);
        }
      );
    } else {
      this.form.availableSlots = this.props.day.slots;
    }

    this.form.slot =
      this.form.availableSlots!.find((slot) => {
        slot.startTimeString == this.props.selectedSlotStartTime &&
          slot.date.toDateString() == this.props.day.date.toDateString();
      }) || this.form.availableSlots![0];
  }

  didTapOnSlot(slot: TimeSlot) {
    this.form.slot = slot;
  }

  didSelectTimeSlot(requestedSlot: TimeSlot) {
    this.onComplete?.(requestedSlot);
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didUpdateAddress(userAddress: UserAddress) {
    if (this.props.onAddressChanged) {
      this.props.onAddressChanged(userAddress);
    }
  }

  formatSlotStartTime(slotStartTime: string) {
    return moment(`${this.props.day.date.toDateString()} ${slotStartTime}`).format('h:mma');
  }

  private loadTimeSlots() {
    return this.props.currentOrder
      ? this.orderService.getAvailableSlotsByOrderId(this.props.currentOrder!.uid)
      : this.addressService.getTimeSlots(
          this.props.userAddress,
          this.props.day.date,
          this.props.day.date,
          this.props.service
        );
  }

  checkSelectedSlot(slot: TimeSlot) {
    return (
      slot.date.toDateString() == (this.form.slot || this.props.day).date.toDateString() &&
      slot.startTimeString == (this.form.slot?.startTimeString || this.props.selectedSlotStartTime)
    );
  }
}
