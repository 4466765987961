import { BaseEntity, Default, MemoizeGetters, Transform } from './base';

@MemoizeGetters
export class ServicePresentation extends BaseEntity {
  static DEFAULT_BACKGROUND = '#ffffff';
  static DEFAULT_COLOR = 'var(--ysh-primary-blue)';
  static DEFAULT_ALIGNMENT = 'center center';

  @Default(ServicePresentation.DEFAULT_COLOR)
  color: string;

  @Default(ServicePresentation.DEFAULT_BACKGROUND)
  backgroundColor: string;

  @Default(ServicePresentation.DEFAULT_ALIGNMENT)
  thumbnailAlignment: Alignment;

  @Default(false)
  @Transform(JSON.parse)
  reverseDisplay: Boolean;

  @Default(ServicePresentation.DEFAULT_ALIGNMENT)
  imageAlignment: Alignment;
}

// TODO Use template literals after tsc 4.1
export type Alignment =
  | 'top left'
  | 'top center'
  | 'top right'
  | 'center left'
  | 'center center'
  | 'center right'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right';
