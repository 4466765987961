import { Component, Input, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

import { Service } from 'src/app/models/service';

export interface RequestReceivedPageProps {
  service: Nullable<Service>;
}

@Component({
  selector: 'ysh-request-received-modal',
  templateUrl: './request-received-modal.page.html',
  styleUrls: ['./request-received-modal.page.scss'],
})
export class RequestReceivedModalPage implements OnInit {
  static PAGE_NAME = YshModals.RequestReceivedModalPage;

  description: string = '';
  headerText: string = `Request received!`;

  readonly buttonText: string = `Sounds Good!`;

  @Input() props: RequestReceivedPageProps;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    console.log('ionViewDidLoad RequestReceivedModalPage');
    this.description = this.props.service?.slotScheduling
      ? `Thank you for your order! We will see you soon!`
      : `Thank you for your request! We are searching for a place on our schedule and will let you know when your order is confirmed.`;
  }

  didTapSoundsGood() {
    this.modalService.dismissModal();
  }
}
