import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FaqComponent } from '../components/faq/faq';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../../pipes/pipes.module';
import { ServiceCreditComponent } from './service-credit/service-credit';
import { ServiceHeaderComponent } from './service-header/service-header';
import { ServiceNavbarComponent } from './service-navbar/service-navbar';
import { ServiceRepeatComponent } from './service-repeat/service-repeat';
import { ServiceStatusComponent } from './service-status/service-status';
import { ServiceStatusFuelComponent } from './service-status-fuel/service-status-fuel';
import { ServiceStatusTireComponent } from './service-status-tire/service-status-tire';
import { ServiceTabsComponent } from './service-tabs/service-tabs';
import { ServiceToolsComponent } from './service-tools/service-tools';

@NgModule({
  declarations: [
    ServiceNavbarComponent,
    ServiceHeaderComponent,
    FaqComponent,
    ServiceTabsComponent,
    ServiceStatusComponent,
    ServiceRepeatComponent,
    ServiceToolsComponent,
    ServiceStatusFuelComponent,
    ServiceStatusTireComponent,
    ServiceCreditComponent,
  ],
  imports: [IonicModule, PipesModule, CommonModule, FormsModule, ComponentsModule],
  exports: [
    ServiceNavbarComponent,
    ServiceHeaderComponent,
    FaqComponent,
    ServiceTabsComponent,
    ServiceStatusComponent,
    ServiceRepeatComponent,
    ServiceToolsComponent,
    ServiceStatusFuelComponent,
    ServiceStatusTireComponent,
    ServiceCreditComponent,
  ],
})
export class ServicesSharedModule {}
