import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CancellationNotesPage } from './cancellation-notes.page';

const routes: Routes = [
  {
    path: '',
    component: CancellationNotesPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CancellationNotesPageRoutingModule {}
