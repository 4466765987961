import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

import { ConnectToyotaPage } from './connect-toyota.page';
import { ConnectToyotaPageRoutingModule } from './connect-toyota-routing.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ConnectToyotaPageRoutingModule,
    ComponentsModule,
  ],
  declarations: [ConnectToyotaPage],
})
export class ConnectToyotaPageModule {}
