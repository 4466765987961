import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ActionSheetController } from '@ionic/angular';
import { AddressesPage } from 'src/app/pages/profile/addresses/addresses.page';
import { DeliveryLocation } from 'src/app/models/delivery-location';
import { FlowPage } from '../../flow-director';
import { LocationPickerResponse } from './location-picker/location-picker';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NotesForFillerPage } from '../notes-for-filler/notes-for-filler.page';
import { Service } from 'src/app/models/service';
import { Subject } from 'rxjs';
import { UserAddress } from 'src/app/models/user-address';

export interface PinFlowPageProps {
  onAddressChanged: (address: UserAddress) => void;
  service: Service;
  userAddress: UserAddress;
  editing?: boolean;
  forToday?: boolean;
  orderClaimed?: boolean;
  deliveryLocation?: Nullable<DeliveryLocation>;
}

export interface PinFlowPageForm {
  mapInfo?: LocationPickerResponse;
  note?: string;
  persistDeliveryLocation?: boolean;
  isCorporateAddress?: boolean;
}

@Component({
  selector: 'ysh-pin-flow',
  templateUrl: './pin-flow.page.html',
  styleUrls: ['./pin-flow.page.scss'],
})
export class PinFlowPage implements OnDestroy, OnInit, FlowPage {
  readonly NOTE_CTA = 'Add note for technician';
  readonly BUTTON_TEXT = 'Change Delivery Address';

  @Input() onDismiss: () => void;
  @Input() onComplete: (form: PinFlowPageForm) => void;
  @Input() props: PinFlowPageProps;

  form: PinFlowPageForm = {};

  parkingCoord: google.maps.LatLng;
  addressCoord: google.maps.LatLng;
  disableActionButton = false;
  darkTheme = false;

  unsubscribe: Subject<void> = new Subject();

  constructor(private actionSheetCtrl: ActionSheetController, private modalService: ModalService) {}

  ngOnInit(): void {
    this.form.note = this.props.deliveryLocation?.details || '';
    this.form.persistDeliveryLocation = this.props.deliveryLocation?.persistent || false;
    this.setCoordinates(this.props.userAddress, this.props.deliveryLocation);
    setTimeout(() => (this.darkTheme = true), 1400);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Actions

  didTapNext() {
    this.form.isCorporateAddress = this.props.userAddress.address.isCorporate;
    this.onComplete?.(this.form);
  }

  didTapDismiss() {
    this.onDismiss ? this.onDismiss() : this.modalService.dismissModal();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  mapDidUpdate(response: LocationPickerResponse) {
    this.form.mapInfo = response;
    this.disableActionButton = response.invalidLocation;
  }

  didTapChangeAddress() {
    this.props.forToday ? this.showAddressChangeSheetWithDisclaimer() : this.presentAddressesPage();
  }

  async didTapAddNote() {
    const notesModal = await this.modalService.openPage({
      component: NotesForFillerPage,
      componentProps: {
        isModal: true,
        note: this.form.note,
        showBackBtn: false,
        showDismissBtn: true,
      },
    });
    const { data } = await notesModal.onDidDismiss();
    this.form.note = data.note;
  }

  didTapDeletedNote() {
    this.form.note = '';
  }

  async showAddressChangeSheetWithDisclaimer() {
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ];

    const changeAddressButton = {
      text: 'Change Address',
      handler: () => {
        this.presentAddressesPage();
      },
      role: '',
    };

    if (!this.props.orderClaimed) {
      buttons.push(changeAddressButton);
    }
    const actionSheet = await this.actionSheetCtrl.create({
      header: this.props.orderClaimed
        ? 'Your driver is already enroute. Please contact customer service if you need assistance.'
        : 'Changing your address on the day of your scheduled service will require your order to be re-routed',
      buttons,
    });
    actionSheet.present();
  }

  presentAddressesPage() {
    this.modalService.openPage({
      component: AddressesPage,
      componentProps: {
        modal: true,
        preventOutOfServiceSelection: true,
        onComplete: (address: UserAddress) => {
          this.props.onAddressChanged?.(address);
        },
      },
    });
  }

  // Helper Functions

  private setCoordinates(userAddress: UserAddress, deliveryLocation?: Nullable<DeliveryLocation>) {
    this.addressCoord = new google.maps.LatLng(userAddress.address.lat, userAddress.address.lng);
    const parkingCoord =
      deliveryLocation && new google.maps.LatLng(deliveryLocation.lat, deliveryLocation.lng);
    this.parkingCoord = parkingCoord || this.addressCoord;
  }
}
