import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CorporatePromoPageRoutingModule } from './corporate-promo-routing.module';
import { CorporatePromoPage } from './corporate-promo.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CorporatePromoPageRoutingModule,
    ComponentsModule,
  ],
  declarations: [CorporatePromoPage],
})
export class CorporatePromoPageModule {}
