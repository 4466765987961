import { BaseEntity, MemoizeGetters, Transform, toDate } from './base';

@MemoizeGetters
export class TruckLocation extends BaseEntity {
  heading?: number;
  private lat: number;
  private lng: number;

  @Transform(toDate)
  timestamp?: Date;

  @Transform(toDate)
  createdAt: Date;

  get location() {
    return new google.maps.LatLng(this.lat, this.lng);
  }
}
