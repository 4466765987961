import { Observable, Subject } from 'rxjs';

import ActionCable from 'actioncable';
import { ApiService } from '../api/api.service';
import { EnvironmentService } from '../environment/environment.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private connections: { [key: string]: SocketConnection } = {};
  constructor(private api: ApiService, private environment: EnvironmentService) {}

  connect(options: SocketOptions): ISocketConnection | undefined {
    if (!this.api.authData) {
      return;
    }
    let connection = this.connections[options.id];
    if (!connection) {
      // TODO use angular environment pattern
      const websocketUrl = `${this.environment.serverVars().websocketUrl}?auth_key=${
        this.api.authData.auth_key
      }`;
      connection = new SocketConnection(options, websocketUrl);
      this.connections[options.id] = connection;
    }
    return connection;
  }

  disconnect(id: string) {
    console.log('disconnecting from ', id);
    const connection = this.connections[id];
    if (connection) {
      connection.channel.unsubscribe();
      connection.cable.disconnect();
    }

    delete this.connections[id];
  }

  disconnectAll() {
    // tslint:disable-next-line:forin
    for (let key in this.connections) {
      this.disconnect(key);
    }
  }

  sendUpdate(id: string, data: any) {
    console.log('sending update ', id, data);
    const connection = this.connections[id];
    connection ? connection.sendUpdate(data) : console.log('no connection');
  }
}

export interface SocketOptions {
  channel: string;
  id: string;
}

enum SocketPerformAction {
  Update = 'update',
}

export enum SocketConnectionState {
  Connected = 'connected',
  Disconnected = 'disconnected',
}

export interface ISocketConnection {
  name: string;
  state: SocketConnectionState;
  onDisconnect: Observable<any>;
  onConnect: Observable<any>;
  onUpdate: Observable<any>;
}

class SocketConnection implements ISocketConnection {
  name: string;
  state: SocketConnectionState = SocketConnectionState.Disconnected;
  onDisconnect = new Subject();
  onConnect = new Subject();
  onUpdate = new Subject();

  cable: ActionCable.Cable;
  channel: ActionCable.Channel;

  constructor(options: SocketOptions, url) {
    this.name = options.channel;
    this.cable = ActionCable.createConsumer(url);

    console.log('connecting');

    this.cable.ensureActiveConnection();
    this.channel = this.cable.subscriptions.create(options, {
      received: (data) => {
        console.log('received', data);
        this.onUpdate.next(data);
      },

      connected: () => {
        console.log('connected', this.name);
        this.state = SocketConnectionState.Connected;
        if (this.queuedUpdate) {
          this.sendUpdate(this.queuedUpdate);
        }
        this.onConnect.next();
        this.onConnect.complete();
      },
      disconnected: () => {
        console.log('disconnected', this.name);
        this.state = SocketConnectionState.Disconnected;
        this.onDisconnect.next();
        this.onDisconnect.complete();
      },
      error: (error) => console.error('error connecting', error),
    });
  }

  private queuedUpdate;

  sendUpdate(data: any) {
    if (this.state !== SocketConnectionState.Connected) {
      this.queuedUpdate = data;
    }
    this.channel.perform(SocketPerformAction.Update, data);
  }
}
