import { BaseEntity, Transform, toDate } from './base';

import { TirePosition } from './tire-tread';

export class TirePressure extends BaseEntity {
  lat?: string;
  lng?: string;
  position: TirePosition;
  value?: number;

  @Transform(toDate)
  updatedAt?: Date;
}
