import { Component, OnInit } from '@angular/core';

import { NavigationService } from 'src/app/navigation.service';

@Component({
  selector: 'ysh-back-button',
  templateUrl: './ysh-back-button.component.html',
  styleUrls: ['./ysh-back-button.component.scss'],
})
export class YshBackButtonComponent implements OnInit {
  constructor(private navigationService: NavigationService) {}

  ngOnInit() {}

  didTapBack() {
    this.navigationService.goBack();
  }
}
