import { BaseEntity } from './base';

export class CreditCard extends BaseEntity {
  applePay?: boolean;
  brand?: string;
  last4?: string;
}

export interface CreditCardParams {
  token: string;
}
