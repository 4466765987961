import { ApplyPurePipe } from './apply-pure/apply-pure';
import { DollarsAndCentsPipe } from './dollars-and-cents/dollars-and-cents';
import { KeysPipe } from './keys/keys';
import { NgModule } from '@angular/core';
import { RelativeTimePipe } from './relative-time/relative-time';
import { TitleForFrequencyPipe } from './title-for-frequency/title-for-frequency';
import { YoshiCurrencyPipe } from './yoshi-currency/yoshi-currency';

@NgModule({
  declarations: [
    KeysPipe,
    DollarsAndCentsPipe,
    TitleForFrequencyPipe,
    RelativeTimePipe,
    ApplyPurePipe,
    YoshiCurrencyPipe,
  ],
  imports: [],
  exports: [
    KeysPipe,
    DollarsAndCentsPipe,
    TitleForFrequencyPipe,
    RelativeTimePipe,
    ApplyPurePipe,
    YoshiCurrencyPipe,
  ],
  providers: [DollarsAndCentsPipe, TitleForFrequencyPipe, RelativeTimePipe, YoshiCurrencyPipe],
})
export class PipesModule {}
