import { Component, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { MembershipService } from '../../../../services/api/membership/membership.service';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'ysh-cancellation-reasons',
  templateUrl: './cancellation-reasons.page.html',
  styleUrls: ['./cancellation-reasons.page.scss'],
})
export class CancellationReasonsPage implements OnInit, FlowPage {
  cancellationReasons: { [key: string]: string }[];
  pageTitle: string;
  onComplete: (reason: string) => void;
  onDismiss: () => void;

  constructor(
    private membershipService: MembershipService,
    private analytics: AnalyticsService,
    private navParams: NavParams
  ) {
    this.cancellationReasons = this.membershipService.cancellationReasons();
    this.pageTitle = `We hate to see you go! What is your reason for cancelling?`;
  }

  ngOnInit() {
    this.onComplete = this.navParams.get('onComplete');
    this.onDismiss = this.navParams.get('onDismiss');
    this.analytics.trackView('CancellationReasonsPage');
  }

  didTapDismiss() {
    this.onDismiss();
  }

  didSelectReason(reason: string) {
    if (this.onComplete) {
      this.onComplete(reason);
    }
  }
}
