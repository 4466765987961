import { BaseEntity, Transform, toDate } from './base';
import { BenefitMethod, BenefitTarget } from './benefit';

export class ReferralBenefit extends BaseEntity {
  applyMethod: BenefitMethod;
  description: string;
  durationInDays?: string;
  perUseAmount?: string;
  target: BenefitTarget;
  useLimit?: number;
  value: string;

  @Transform(toDate)
  createdAt: Date;
}
