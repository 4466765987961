import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IonContent, IonSlides } from '@ionic/angular';

import { ModalService } from 'src/app/services/modal/modal.service';
import { Service } from 'src/app/models/service';
import { SlidesModalComponent } from 'src/app/components/slides-modal/slides-modal';
import { Faq } from 'src/app/models/faq';

@Component({
  selector: 'ysh-service-tabs',
  templateUrl: 'service-tabs.html',
  styleUrls: ['./service-tabs.scss'],
})
export class ServiceTabsComponent implements OnInit, OnChanges {
  @Input() service: Service;
  @Input() hideTabs = false;
  @Input() faqs: Faq[] = []

  page: 'about' | 'status' = 'about';

  galleryPhotos: string[] = [];

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.galleryPhotos = this.service?.serviceType.photos.map((photo) => photo.url);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.galleryPhotos = this.service?.serviceType.photos.map((photo) => photo.url);
  }

  didTapGalleryPhoto(index) {
    if (!this.service || !this.service.serviceType.photos.length) {
      return;
    }

    this.modalService.openPage({
      component: SlidesModalComponent,
      componentProps: {
        initialSlide: index,
        photos: this.service.serviceType.photos.map((photo) => photo.url),
      },
    });
  }
}
