import { BaseEntity, Default, Transform, toEntity } from 'src/app/models/base';

export class MemberPromotionalDetails extends BaseEntity {
  static readonly DEFAULT_BENEFITS = [
    '1-month free trial',
    'No delivery fees up to 6 visits/mo',
    'Automatic enrollment in Yoshi Rewards',
    'Cancel anytime',
  ];

  static readonly DEFAULT_TERMS = [''];

  @Default(MemberPromotionalDetails.DEFAULT_BENEFITS)
  benefits: string[];

  @Default(MemberPromotionalDetails.DEFAULT_TERMS)
  terms: string;
}

export class MemberDeliveryPolicy extends BaseEntity {
  @Transform(parseFloat)
  feeSubscribed: number;

  @Transform(parseFloat)
  feeUnsubscribed: number;

  maxPerMonth: number;
}

export class MemberPolicy extends BaseEntity {
  @Transform(toEntity(MemberDeliveryPolicy))
  delivery: MemberDeliveryPolicy;

  @Default(new MemberPromotionalDetails({}))
  @Transform(toEntity(MemberPromotionalDetails))
  promotionalDetails: MemberPromotionalDetails;
}
