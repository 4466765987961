import { InAppBrowser, InAppBrowserEvent } from '@ionic-native/in-app-browser/ngx';

import { EnvironmentService } from '../environment/environment.service';
import { Injectable } from '@angular/core';

export enum OemMinYear {
  GM = 2019,
  Toyota = 2019,
  Lexus = 2015,
}

export enum Oem {
  GM = 'gm',
  Toyota = 'toyota',
  Lexus = 'lexus',
}

export const GM_MAKES = ['Buick', 'Cadillac', 'Chevrolet', 'GMC'];
export const TOYOTA = 'Toyota';
export const LEXUS = 'Lexus';

@Injectable({
  providedIn: 'root',
})
export class OemService {
  constructor(private environment: EnvironmentService, private iab: InAppBrowser) {}

  authenticate(oem: Oem): Promise<any> {
    return new Promise((resolve, reject) => {
      const authUrl = this.getAuthUrl(oem);
      const inAppBrowser = this.iab.create(authUrl, '_blank', {
        toolbarposition: 'top',
        location: 'no',
        keyboardDisplayRequiresUserAction: 'yes',
      });
      let code: string | undefined;
      inAppBrowser.on('loadstart').subscribe((event) => {
        if (event.url.includes('yoshi://?code')) {
          code = this.getCodeFromEvent(event);
          code ? resolve(code) : reject();
          inAppBrowser.close();
        }
      });
      inAppBrowser.on('exit').subscribe((event) => {
        if (!code) {
          reject();
        }
      });
    });
  }

  private getAuthUrl(oem: Oem) {
    const urlMap: { [key in Oem]: string } = {
      [Oem.GM]: this.environment.serverVars().gmAuthURL,
      [Oem.Toyota]: this.environment.serverVars().toyotaAuthURL,
      [Oem.Lexus]: this.environment.serverVars().lexusAuthURL,
    };
    return urlMap[oem];
  }

  private getCodeFromEvent(event: InAppBrowserEvent): string | undefined {
    const CODE_REGEX = /code\=(.+)\&/;
    const temp = event.url;
    const queryString = temp.split('?').pop();
    return queryString?.match(CODE_REGEX)?.[1];
  }
}
