import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yshCurrency',
})
export class YoshiCurrencyPipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}
  transform(value: number, ...args) {
    if (value % 1 > 0) {
      return this.currency.transform(value, 'USD', 'symbol', '1.2-2');
    } else {
      return this.currency.transform(value, 'USD', 'symbol', '1.0-0');
    }
  }
}
