import { BaseEntity } from '../base';
import { Transform, toDate } from '../base/transform';

export class LoyaltyPoints extends BaseEntity {
  @Transform(parseInt)
  amount: number;
  description: string;

  @Transform(toDate)
  transactedAt: Date;
}
