import * as moment from 'moment';

import { BaseEntity, Default, MemoizeGetters, Transform, toDate, toEntity } from './base';
import { OrderStatus, createShiftWindowString } from './order';
import { Shift, ShiftName, ShiftTime } from './shift';

import { Address } from './address';
import { Service } from './service';
import { Vehicle } from './vehicle';

export const CancellableStatuses = [
  OrderStatus.Pending,
  OrderStatus.Proposed,
  OrderStatus.Claimed,
  OrderStatus.MissingPayment,
];

export const ScheduledStatuses = [
  OrderStatus.Pending,
  OrderStatus.Proposed,
  OrderStatus.Claimed,
  OrderStatus.MissingPayment,
];

@MemoizeGetters
export class ServiceOrder extends BaseEntity {
  status: OrderStatus;

  @Default(Shift.defaultStartTime(ShiftName.Day))
  shiftStartTime: ShiftTime;

  @Default(Shift.defaultEndTime(ShiftName.Day))
  shiftEndTime: ShiftTime;

  lat?: number;
  lng?: number;

  @Transform(toDate)
  date: Date;

  @Transform(toDate)
  eta?: Date;

  @Transform(toDate)
  timeCompleted?: Date;

  @Transform(toEntity(Service))
  service: Service;

  @Transform(toEntity(Address))
  address: Address;

  @Transform(toEntity(Vehicle))
  vehicle: Vehicle;

  sourceType?: 'ServiceSubscription';
  sourceUid?: string;

  // Computed Properties

  get isCancellable() {
    return CancellableStatuses.includes(this.status);
  }

  get isScheduled() {
    return ScheduledStatuses.includes(this.status);
  }

  get etaString() {
    if (this.eta) {
      let momentDate = moment(this.eta);
      return momentDate.format('h:mmA');
    }
  }

  shiftTimeString(withMinutes?) {
    return createShiftWindowString(this, withMinutes);
  }
}

export interface ServiceOrderParams {
  serviceUid: string;
  frequency?: number;
  date?: string;
  shiftStartTime?: number;
  shiftEndTime?: number;
  locationName?: string;
  userAddressUid?: string;
  lat?: number;
  lng?: number;
}

export type ServiceOrderStatus = 'complete' | 'claimed';

export interface ServiceOrderUpdateParams {
  vehicleUid?: string;
  date?: string | null;
  shiftStartTime?: string;
  shiftEndTime?: string;
  status?: ServiceOrderStatus;
}
