import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { AddressService } from 'src/app/services/api/address/address.service';
import { OrderScheduleService } from 'src/app/services/scheduling/order-schedule/order-schedule.service';
import { OrderService } from 'src/app/services/api/order/order.service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceMap } from 'src/app/models/service-type';
import { UserAddress } from 'src/app/models/user-address';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-service-type-sheet',
  templateUrl: './service-type-sheet.html',
  styleUrls: ['./service-type-sheet.scss'],
})
export class ServiceTypeSheet implements OnInit, OnDestroy {
  @Input() onSelect: (service: ServiceGroup) => void;
  @Input() services: ServiceGroup[];

  static PAGE_NAME = YshModals.ServiceTypeSheet;
  title = 'Select Service Type';

  orderStatus: ServiceMap<boolean> = {};

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public addressService: AddressService,
    private vehicleService: VehiclesService,
    private modalService: ModalService,
    private orderService: OrderService
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.subscribeToStatus();
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  subscribeToStatus() {
    combineLatest([this.vehicleService.selectedVehicle$, this.orderService.upcomingOrders$])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([vehicle, orders]) => {
        orders
          .filter((order) => order.vehicle.uid === vehicle?.uid)
          .forEach((order) => {
            this.orderStatus[order.service.serviceType.name] = true;
          });
      });
  }

  didTapServiceItem(event: CustomEvent, service: ServiceGroup) {
    event.stopPropagation();
    if (this.orderStatus[service.serviceType.name]) {
      return;
    }
    this.onSelect?.(service);
    this.modalService.dismissModal();
  }

  didTapBackdrop() {
    this.modalService.dismissModal();
  }
}
