import { BaseEntity, Default, Transform, toEntity } from './base';

import { ReferralBenefit } from './referral-benefits';

export enum ReferralProgramType {
  Default = 'default_program',
  Company = 'company',
  Promotional = 'promotional',
  Loyalty = 'loyalty_referral_program',
}

const SEATS_LIMIT = 100;
const SEATS_NOT_AVAILABLE = -1;

export class ReferralProgram extends BaseEntity {
  inviteCode?: string;
  seats: number;

  @Default(0)
  referralCount: number;
  programType: ReferralProgramType;

  @Transform(toEntity(ReferralBenefit))
  @Default([])
  giveBenefits: ReferralBenefit[];

  @Transform(toEntity(ReferralBenefit))
  @Default([])
  getBenefits: ReferralBenefit[];

  get seatsLeft() {
    return this.seats - this.referralCount;
  }

  get isLoyalty() {
    return this.programType === ReferralProgramType.Loyalty;
  }

  get friendsString(): string {
    return `${
      this.availableSeats === SEATS_NOT_AVAILABLE || this.availableSeats === 0
        ? 'your'
        : `up to ${this.availableSeats}`
    } friends`;
  }

  get availableSeats(): number {
    const tooManySeats = this.seats > SEATS_LIMIT;
    return tooManySeats || !this.seats ? SEATS_NOT_AVAILABLE : Math.max(this.seatsLeft, 0);
  }

  get coworkersString() {
    return `${
      this.availableSeats === SEATS_NOT_AVAILABLE || this.availableSeats === 0
        ? 'your'
        : `up to ${this.availableSeats}`
    } coworkers`;
  }
}
