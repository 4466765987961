import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';
import { IonNav, NavParams } from '@ionic/angular';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CorporatePromoPage } from './../../ordering/order-flow-controller/corporate-promo/corporate-promo.page';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { SignupFlowPage } from '../signup-flow/signup-flow.page';
import { UserAddress } from 'src/app/models/user-address';
import { UserLocationPage } from '../user-location/user-location.page';
import { UserService } from 'src/app/services/api/user/user.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleFlowController } from '../../vehicle/vehicle-flow-controller/vehicle-flow-controller.page';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { WelcomePage } from './../welcome/welcome.page';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-onboarding-flow-controller',
  template: '<ion-nav #onboardingNav ></ion-nav>',
})
export class OnboardingFlowControllerPage implements Flow, OnInit, AfterViewInit {
  @ViewChild('onboardingNav') nav: IonNav;

  userAddresses: UserAddress[] = [];
  selectedAddress: UserAddress;
  vehicles: Vehicle[] = [];

  didFinishSignUpFlowPage = false;
  didFinishVehicleFlowPage = false;
  didFinishAddressFLowPage = false;
  didFinishCorporatePromoPage = false;

  phone: string;

  flowDirector: FlowDirector;

  constructor(
    navParams: NavParams,
    private addressService: AddressService,
    private vehicleService: VehiclesService,
    private analytics: AnalyticsService,
    private userService: UserService,
    private loadingAlert: LoadingAlertService,
    private modalService: ModalService
  ) {
    this.vehicles = navParams.get('vehicles') || [];
    this.userAddresses = navParams.get('userAddresses') || [];
    this.phone = navParams.get('phone');

    if (this.userAddresses && this.userAddresses.length) {
      this.selectedAddress = this.userAddresses[0];
    }
  }

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('OnboardingFlowControllerPage');
  }

  ngAfterViewInit(): void {
    const options = this.nextPage();
    this.flowDirector = new FlowDirector(this, options);
  }

  // Flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.userService.currentUser$.value && !this.didFinishSignUpFlowPage) {
      return this.optionsForSignUpPage();
    }
    if (!this.vehicles.length && !this.didFinishVehicleFlowPage) {
      return this.optionsForVehicleFlowPage();
    }
    if (!this.userAddresses.length && !this.didFinishAddressFLowPage) {
      return this.optionsForAddressPage();
    }
    const requestCompanyInfo = this.selectedAddress.address.shouldRequestCompanyInfo;
    if (requestCompanyInfo && !this.didFinishCorporatePromoPage) {
      return this.optionsForCorporatePromoPage();
    }
  }

  flowDidComplete(): void {
    this.dismissAndNavigate().then(() => this.showWelcomePage());
  }

  // Params

  optionsForSignUpPage(): FlowPageOptions<SignupFlowPage> {
    return {
      page: SignupFlowPage,
      onDismiss: () => this.modalService.dismissModal(),
      onComplete: () => {
        this.loadingAlert.showLoader();
        this.addressService
          .getUserAddresses()
          .pipe(finalize(() => this.dismissLoader()))
          .subscribe((addresses) => {
            this.userAddresses = addresses;
            this.selectedAddress = this.userAddresses[0];
            this.didFinishSignUpFlowPage = true;
            this.flowDirector.next();
          });
      },
      pageProps: {
        phone: this.phone,
      },
    };
  }

  optionsForVehicleFlowPage(): FlowPageOptions<VehicleFlowController> {
    return {
      page: VehicleFlowController,
      onComplete: (data) => {
        this.didFinishVehicleFlowPage = true;
        this.vehicleService.setSelectedVehicle(data);
        this.flowDirector.next();
      },
      preventBackNavigation: true,
      props: {
        isSigningUp: true,
      },
    };
  }

  optionsForAddressPage(): FlowPageOptions<UserLocationPage> {
    return {
      page: UserLocationPage,
      onComplete: (data) => {
        if (data) {
          this.addressService.createUserAddress(data).subscribe((address) => {
            this.selectedAddress = address;
            this.addressService.setSelectedAddress(address);
            this.didFinishAddressFLowPage = true;
            this.flowDirector.next();
          });
        }
      },
    };
  }

  optionsForCorporatePromoPage(): FlowPageOptions<CorporatePromoPage> {
    return {
      page: CorporatePromoPage,
      onComplete: (data) => {
        this.didFinishCorporatePromoPage = true;
        this.flowDirector.next();
      },
      pageProps: {
        company: this.selectedAddress?.address?.compound?.company,
      },
    };
  }

  private dismissAndNavigate() {
    return this.modalService.dismissModal();
  }

  private showWelcomePage() {
    this.loadingAlert.showLoaderWithText('Please wait...');
    this.userService
      .getAppliedBenefits()
      .pipe(finalize(() => this.dismissLoader()))
      .subscribe(async (benefits) => {
        this.modalService.open({
          component: WelcomePage,
        });
      });
  }

  private dismissLoader = () => this.loadingAlert.dismissLoader();
}
