import * as moment from 'moment';

import { BaseEntity, MemoizeGetters, Transform } from './base';

export enum EtaStatus {
  OnTime = 'on-time',
  RunningEarly = 'running-early',
  RunningLate = 'running-late',
  NotAvailable = 'not-available',
}

export enum EtaStatusExplanation {
  OnTime = 'Things are currently looking good for the Yoshi truck to arrive to your vehicle within your requested window!',
  RunningEarly = 'We are projecting the Yoshi truck may arrive at your vehicle earlier than your requested window.',
  RunningLate = 'Our system is projecting the Yoshi truck may arrive at your vehicle later than your requested window. Please stay tuned as we attempt to remain schedule.',
  NotAvailable = 'Eta status is not available at this moment. Check back later.',
}

@MemoizeGetters
export class Eta extends BaseEntity {
  @Transform((value) => moment(value, 'HH:mm').toDate())
  etaStart: Date;

  @Transform((value) => moment(value, 'HH:mm').toDate())
  etaEnd: Date;

  get displayString() {
    if (this.etaStart && this.etaEnd) {
      return `${moment(this.etaStart).format('h:mmA')}-${moment(this.etaEnd).format('h:mmA')}`;
    }
  }
}
