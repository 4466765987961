import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

import { UpdateUserFlowPage } from './update-user-flow.page';
import { UpdateUserFlowPageRoutingModule } from './update-user-flow-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    IonicModule,
    UpdateUserFlowPageRoutingModule,
  ],
  declarations: [UpdateUserFlowPage],
})
export class UpdateUserFlowPageModule {}
