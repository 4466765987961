import { Component, Input, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FuelType } from 'src/app/models/fuel-type';
import { ModalService } from 'src/app/services/modal/modal.service';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-fuel-selector',
  templateUrl: './fuel-selector.page.html',
  styleUrls: ['./fuel-selector.page.scss'],
})
export class FuelSelectorPage implements OnInit {
  @Input() isModal: boolean;
  @Input() onComplete: (type: FuelType) => void;
  @Input() onDismiss: () => void;

  fuelTypes: FuelType[] = [];
  selectedFuelType: FuelType;
  constructor(private vehicleService: VehiclesService, private analytics: AnalyticsService) {
    this.vehicleService.getFuelsTypes().subscribe((types) => (this.fuelTypes = types));
  }

  ngOnInit() {
    this.analytics.trackView('FuelSelectorPage');
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didSelectFuelType(type) {
    this.selectedFuelType = type;
    this.onComplete(type);
  }
}
