import { Injectable } from '@angular/core';
import { AnalyticsService } from '../analytics/analytics.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeeplinkService {
  deeplinkEvents: Subject<string> = new Subject();
  constructor(private analytics: AnalyticsService) {}

  handleDeeplinkData(data) {
    if (!data) {
      return;
    }
    if (!data['+clicked_branch_link']) {
      // Handle yoshi:// deeplinks
      this.handleDeepLinkUrl(data['+non_branch_link']);
    } else {
      this.handleDeepLinkUrl(data['$canonical_identifier']);
    }
    console.log('Deep Link Data: ', JSON.stringify(data));
    this.analytics.handleDeeplink(data);
  }

  handleDeepLinkUrl(url) {
    if (url) {
      console.log('handling deeplink url', url);
      const event = url.split('yoshi://').pop();
      this.deeplinkEvents.next(event);
    }
  }
}

export enum DeeplinkEventMap {
  Event_Invite = 'invite',
  Event_MembershipUpgrade = 'member-upgrade',
  Event_OneTapOrder = 'quick-order',
  Event_CreditCard = 'credit-card',
  Event_Rewards = 'rewards',
}
