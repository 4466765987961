import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FaqModalPage } from 'src/app/pages/flows/ordering/faq-modal/faq-modal.page';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Service } from 'src/app/models/service';

@Component({
  selector: 'ysh-order-flow-navbar',
  templateUrl: './order-flow-navbar.html',
  styleUrls: ['./order-flow-navbar.scss'],
})
export class OrderFlowNavbar {
  @Input() service: Service;
  @Input() showFaqBtn: boolean;
  @Input() showInDarkTheme: boolean;
  @Input() showBackBtn: boolean;
  @Input() showDismissBtn: boolean;
  @Input() iconOnly: boolean;
  @Output() onDismiss: EventEmitter<any> = new EventEmitter();
  @Output() onBack: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: ModalService) {}

  didTapDismiss() {
    this.onDismiss.emit();
  }

  didTapBack() {
    this.onBack.emit();
  }

  didTapFaq() {
    this.modalService.open({ component: FaqModalPage, componentProps: { service: this.service } });
  }
}
