import { Component, Input, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { RecallDetailsPage } from '../recall-details/recall-details.page';
import { VehicleRecall } from 'src/app/models/vehicle-recall';

@Component({
  selector: 'ysh-vehicle-recalls',
  templateUrl: './vehicle-recalls.page.html',
  styleUrls: ['./vehicle-recalls.page.scss'],
})
export class VehicleRecallsPage implements OnInit {
  constructor(private modalService: ModalService, private analytics: AnalyticsService) {}
  @Input() recalls: VehicleRecall[] = [];

  ngOnInit() {
    this.analytics.trackView('VehicleRecallsPage');
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapRecallItem(recall: VehicleRecall) {
    this.modalService.openPage({
      component: RecallDetailsPage,
      componentProps: {
        modal: true,
        recall,
      },
    });
  }
}
