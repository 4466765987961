import { CameraService, ImgSourceType } from 'src/app/services/camera/camera.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';

import { ActionSheetController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { EditVehiclePage } from '../profile/edit-vehicle/edit-vehicle.page';
import { EmptySubscriber } from 'src/app/services/api/api.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { Mileage } from 'src/app/models/mileage';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Photo } from 'src/app/models/photo';
import { PhotoStoryPage } from '../home/photo-story/photo-story.page';
import { PhotoStoryService } from 'src/app/services/photo-story/photo-story.service';
import { Subject } from 'rxjs';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleMileagePage } from './vehicle-mileage/vehicle-mileage.page';
import { VehiclePhotosPage } from './vehicle-photos/vehicle-photos.page';
import { VehicleRecall } from 'src/app/models/vehicle-recall';
import { VehicleRecallsPage } from './vehicle-recalls/vehicle-recalls.page';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

interface VehicleProfilePageProps {
  vehicle: Vehicle;
}

@Component({
  selector: 'ysh-vehicle-profile',
  templateUrl: './vehicle-profile.page.html',
  styleUrls: ['./vehicle-profile.page.scss'],
})
export class VehicleProfilePage implements OnDestroy, OnInit {
  @Input() props: VehicleProfilePageProps;

  recalls: VehicleRecall[] = [];
  mileages: Mileage[] = [];
  photoStory: Photo[] = [];
  photosAsUrls: string[] = [];
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private modalService: ModalService,
    private vehicleService: VehiclesService,
    private photoStoryService: PhotoStoryService,
    private analytics: AnalyticsService,
    private actionSheetCtrl: ActionSheetController,
    private cameraService: CameraService,
    private loadingAlertCtrl: LoadingAlertService
  ) {}

  ngOnInit() {
    this.analytics.trackView('VehicleProfilePage');
    this.getVehicleRecalls();
    this.getVehicleMileages();
    this.vehicleService.selectedVehicleMileages$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((mileages) => (this.mileages = mileages));
    this.photoStoryService.photoStoryForSelectedVehicle$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((photoStory) => {
        this.photoStory = photoStory;
        this.photosAsUrls = photoStory.map((photo) => photo.url);
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private getVehicleRecalls() {
    this.vehicleService
      .getVehicleRecalls(this.props.vehicle.uid)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((recalls) => {
        this.recalls = recalls;
      });
  }

  private getVehicleMileages() {
    this.vehicleService.getVehicleMileage().subscribe(new EmptySubscriber());
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapEdit(missingField: string) {
    this.modalService.openPage({
      component: EditVehiclePage,
      componentProps: { modal: true, vehicle: this.props.vehicle, missingField },
    });
  }

  didTapRecalls() {
    this.modalService.openPage({
      component: VehicleRecallsPage,
      componentProps: { recalls: this.recalls },
    });
  }

  didTapMileage() {
    this.modalService.openPage({
      component: VehicleMileagePage,
      componentProps: {
        modal: true,
        vehicle: this.props.vehicle,
      },
    });
  }

  didTapViewMore() {
    this.modalService.openPage({
      component: VehiclePhotosPage,
      componentProps: { photoStory: this.photoStory, photos: this.photosAsUrls, modal: true },
    });
  }

  didTapGalleryImage(index: number) {
    this.modalService.openPage({
      component: PhotoStoryPage,
      componentProps: {
        photoStory: this.photoStory,
        activeIndex: index,
      },
      animated: false,
    });
  }

  async didTapVehicleImage() {
    const actionSheet = await this.actionSheetCtrl.create({
      buttons: [
        {
          text: 'Take Picture',
          handler: () => this.didSelectImgSource(ImgSourceType.Camera),
        },
        {
          text: 'Choose Existing',
          handler: () => this.didSelectImgSource(ImgSourceType.Gallery),
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });
    actionSheet.present();
  }

  private didSelectImgSource(imgSrc: ImgSourceType) {
    this.cameraService.accessCamera(imgSrc, (imgPath) => {
      if (imgPath) {
        this.loadingAlertCtrl.showLoader();
        this.vehicleService
          .updateVehicleImage(this.props.vehicle, imgPath)
          .pipe(
            takeUntil(this.unsubscribe),
            finalize(() => this.loadingAlertCtrl.dismissLoader())
          )
          .subscribe({
            next: () => {
              this.vehicleService.loadSelectedVehicle();
            },
            error: () => {
              this.loadingAlertCtrl.showToastAlert('Error updating vehicle image');
            },
          });
      }
    });
  }
}
