import { ActionSheetController } from '@ionic/angular';
import { AnalyticsService } from '../analytics/analytics.service';
import { Injectable } from '@angular/core';
import { LoadingAlertService } from '../loading-alert/loading-alert.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Injectable({
  providedIn: 'root',
})
export class SocialSharingService {
  constructor(
    private socialSharing: SocialSharing,
    private actionSheetCtrl: ActionSheetController,
    private analytics: AnalyticsService,
    private loadingAlert: LoadingAlertService
  ) {}

  presentNativeShareSheet(params: SocialSharingParams) {
    const { message, fileToShare, subject, event } = params;
    this.didTapShareWithOther(fileToShare, message, subject, event);
  }

  async presentSocialSharingSheet(params: SocialSharingParams) {
    if (!params) {
      return;
    }

    const { message, fileToShare, subject, event, hasPrefilledMsg } = params;
    const buttons = [
      {
        text: 'Share with Twitter',
        handler: () => this.didTapShareWithTwitter(fileToShare, message, event),
      },
      {
        text: 'Other',
        handler: () => this.didTapShareWithOther(fileToShare, message, subject, event),
      },
      {
        text: 'Cancel',
        role: 'cancel',
      },
    ];

    if (!hasPrefilledMsg) {
      // pre-filled messages not supported by facebook and instagram.
      buttons.unshift({
        text: 'Share with Facebook',
        handler: () => this.didTapShareWithFacebook(fileToShare, message, event),
      });
    }

    if (fileToShare) {
      buttons.unshift({
        text: 'Share with Instagram',
        handler: () => this.didTapShareWithInstagram(fileToShare, event),
      });
    }

    (await this.actionSheetCtrl.create({ buttons })).present();
  }

  // helpers
  private didTapShareWithFacebook(shareImage: string | undefined, message: string, event: string) {
    this.socialSharing
      .shareViaFacebookWithPasteMessageHint(
        'I love my yoshi',
        shareImage || undefined,
        undefined,
        message || undefined
      )
      .then(() => {
        this.analytics.trackEvent(`${event} - Facebook`);
      })
      .catch((error) => {
        this.loadingAlert.showToastAlert(error.message);
      });
  }

  private didTapShareWithInstagram(shareImage: string, event: string) {
    // Instagram no longer permits prefilling a message
    this.socialSharing
      .shareViaInstagram('', shareImage)
      .then(() => {
        this.analytics.trackEvent(`${event} - Instagram`);
      })
      .catch((error) => {
        this.loadingAlert.showToastAlert(error.message);
      });
  }

  private didTapShareWithTwitter(shareImage: string | undefined, message: string, event: string) {
    this.socialSharing
      .shareViaTwitter(message, shareImage)
      .then(() => {
        this.analytics.trackEvent(`${event} - Twitter`);
      })
      .catch((error) => {
        this.loadingAlert.showToastAlert(error.message);
      });
  }

  private didTapShareWithOther(
    file: string | undefined,
    message: string,
    subject: string,
    event: string
  ) {
    const options = {
      message,
      subject,
      chooserTitle: 'Choose an app to share', // overwrites android's share sheet title.
    };

    if (file) {
      options['files'] = [file];
    }
    this.socialSharing
      .shareWithOptions(options)
      .then(() => {
        this.analytics.trackEvent(`${event} - Other`);
        this.loadingAlert.showToastConfirmation('Success');
      })
      .catch((error) => {
        this.loadingAlert.showToastAlert(error.message);
      });
  }
}

export enum SocialSharingEvent {
  PromoCode = 'Promo Code Referral',
  PhotoStory = 'Photo Shared',
  NoCode = 'No Code Referral',
}

export interface SocialSharingParams {
  message: string;
  subject: string;
  fileToShare?: string;
  event: SocialSharingEvent;
  hasPrefilledMsg?: boolean;
}
