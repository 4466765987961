
import { BaseEntity, toDate, Transform } from './base';

export class VehicleRecall extends BaseEntity {
  componentDescription: string;
  consequenceSummary: string;
  correctiveSummary: string;
  defectSummary: string;
  make: string;
  model: string;
  nhtsaCampaignNumber: string;
  potentialNumberOfUnitsAffected: number;
  vehicleOrEquipmentOrTire: string;
  year: number;
  notes: string;

  @Transform(toDate)
  recordCreationDate: Date;

  @Transform(toDate)
  reportReceivedDate: Date;
}
