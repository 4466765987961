import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Day } from 'src/app/models/day';

@Component({
  selector: 'ysh-day-cell',
  templateUrl: 'day-cell.component.html',
  styleUrls: ['./day-cell.component.scss'],
})
export class DayCellComponent {
  @Input() selected: boolean;
  @Input() small: boolean;
  @Input() disabled: boolean;
  @Input() day: Day;
  @Output() tap = new EventEmitter();

  didTap() {
    if (!this.disabled) {
      this.tap.emit();
    }
  }
}
