import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  CancellationReasons,
  MembershipInfo,
  MembershipService,
} from 'src/app/services/api/membership/membership.service';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CancellationNotesPage } from '../cancellation-notes/cancellation-notes.page';
import { CancellationReasonsPage } from '../cancellation-reasons/cancellation-reasons.page';
import { ConfirmCancellationPage } from '../confirm-cancellation/confirm-cancellation.page';
import { IonNav } from '@ionic/angular';
import { MembershipBenefitsPage } from '../membership-benefits/membership-benefits.page';
import { MembershipDiscountOfferPage } from '../membership-discount-offer/membership-discount-offer.page';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UserService } from 'src/app/services/api/user/user.service';

@Component({
  selector: 'ysh-membership-cancellation-flow',
  template: '<ion-nav #cancelMembershipNav ></ion-nav>',
})
export class MembershipCancellationFlowPage implements Flow, OnInit, AfterViewInit {
  @ViewChild('cancelMembershipNav', { static: true }) nav: IonNav;

  flowDirector: FlowDirector;

  completedBenefitsPage = false;
  completedDiscountPage = false;
  completedNotesPage = false;
  completedConformationPage = false;

  cancellationReason: string;
  cancellationNotes: string;

  membershipInfo: MembershipInfo;
  paidThroughString: string;

  constructor(
    private analytics: AnalyticsService,
    private membershipService: MembershipService,
    private userService: UserService,
    private modalService: ModalService
  ) {
    this.subscribeToMembership();
  }

  // Data

  subscribeToMembership() {
    this.membershipService.getCurrentMembershipInfo().subscribe((info) => {
      this.membershipInfo = info;
      this.paidThroughString = this.membershipService.formattedPaidThroughString(
        this.membershipInfo
      );
    });
  }

  refreshMembershipInfo() {
    this.userService.getUser();
    this.userService.getAppliedBenefits();
  }

  // Angular

  ngOnInit() {
    this.analytics.trackView('MembershipCancellationFlowPage');
  }

  ngAfterViewInit(): void {
    this.flowDirector = new FlowDirector(this);
  }

  // Flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.completedBenefitsPage) {
      return this.optionsForBenefitsPage();
    }
    if (!this.cancellationReason) {
      return this.optionsForReasonsPage();
    }
    const tooExpensive = this.cancellationReason === CancellationReasons.Membership_Is_Expensive;
    const canShowDiscountPage = tooExpensive && this.isEligibleForDiscount;

    if (!this.completedDiscountPage && canShowDiscountPage) {
      return this.optionsForDiscountPage();
    }

    if (!this.cancellationNotes) {
      return this.optionsForNotesPage();
    }

    if (!this.completedConformationPage) {
      return this.optionsForConfirmPage();
    }
  }

  flowDidComplete(): void {
    this.didDismissPage();
  }

  private isEligibleForDiscount(): boolean {
    // offer discount only if monthly fee is not less than 20
    return !(this.membershipInfo.discountedMonthlyFee < 20);
  }

  // Params

  optionsForBenefitsPage(): FlowPageOptions<MembershipBenefitsPage> {
    return {
      page: MembershipBenefitsPage,
      onComplete: (didCancel) => this.didCompleteBenenitsPage(didCancel),
      onDismiss: () => this.didDismissPage(),
    };
  }

  optionsForReasonsPage(): FlowPageOptions<CancellationReasonsPage> {
    return {
      page: CancellationReasonsPage,
      onComplete: (reason: string) => this.didCompleteReasonsPage(reason),
      onDismiss: () => this.didDismissPage(),
    };
  }

  optionsForDiscountPage(): FlowPageOptions<MembershipDiscountOfferPage> {
    return {
      page: MembershipDiscountOfferPage,
      onComplete: (status: boolean) => this.didCompleteDiscountPage(status),
      onDismiss: () => this.didDismissPage(),
    };
  }

  optionsForNotesPage(): FlowPageOptions<CancellationNotesPage> {
    return {
      page: CancellationNotesPage,
      onComplete: (notes) => this.didCompleteNotesPage(notes),
      onDismiss: () => this.didDismissPage(),
    };
  }

  optionsForConfirmPage(): FlowPageOptions<ConfirmCancellationPage> {
    return {
      page: ConfirmCancellationPage,
      onComplete: () => this.didCompleteConfirmationPage(),
      onDismiss: () => this.didDismissPage(),
      props: {
        membershipUid: this.membershipInfo.subscription?.uid,
        paidThrough: this.paidThroughString,
        cancellationReason: this.cancellationReason,
        cancellationNotes: this.cancellationNotes,
      },
    };
  }

  // Completion

  didCompleteBenenitsPage(didCancel: boolean) {
    this.completedBenefitsPage = true;
    if (!didCancel) {
      this.didDismissPage();
    } else {
      this.flowDirector.next();
    }
  }

  didCompleteReasonsPage(reason: string) {
    this.cancellationReason = reason;
    this.flowDirector.next();
  }

  didCompleteDiscountPage(accepted: boolean) {
    this.completedDiscountPage = true;
    if (!accepted) {
      this.flowDirector.next();
    }
  }

  didCompleteNotesPage(notes?: string) {
    this.completedNotesPage = true;
    this.cancellationNotes = notes || '';
    this.flowDirector.next();
  }

  didCompleteConfirmationPage() {
    this.completedConformationPage = true;
    this.flowDirector.next();
  }

  didDismissPage() {
    this.refreshMembershipInfo();
    this.modalService.dismissModal();
  }
}
