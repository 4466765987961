import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { WebflowSignupPageRoutingModule } from './webflow-signup-routing.module';

import { WebflowSignUpPage } from './webflow-signup.page';
import { ComponentsModule } from 'src/app/components/components.module';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, WebflowSignupPageRoutingModule, ComponentsModule, TextMaskModule],
  declarations: [WebflowSignUpPage],
})
export class WebflowSignupPageModule {}
