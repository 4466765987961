import { ApiService, EmptySubscriber } from '../api.service';
import { AppState, getAll, getCollection } from 'src/state/state';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { Order, OrderCancelReason, OrderParams } from 'src/app/models/order';
import {
  ServiceOrder,
  ServiceOrderParams,
  ServiceOrderUpdateParams,
} from 'src/app/models/service-order';
import {
  ServiceSubscription,
  ServiceSubscriptionUpdateParams,
} from 'src/app/models/service-subscription';
import { SocketOptions, SocketService } from '../../socket/socket.service';
import {
  VehicleSubscription,
  VehicleSubscriptionParams,
} from 'src/app/models/vehicle-subscription';
import { finalize, flatMap, map, take, tap } from 'rxjs/operators';

import { CollectionActions } from 'src/state/actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TimeSlot } from 'src/app/models/time-slot';
import { TruckLocation } from 'src/app/models/truck_location';
import { UnservicedDay } from 'src/app/models/unserviced-day';
import { Vehicle } from 'src/app/models/vehicle';
import { Visit } from 'src/app/models/visit';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  static readonly TRUCK_LOCATION_CHANNEL = 'TruckLocationChannel';

  orders$: Observable<Order[]>;
  serviceOrders$: Observable<ServiceOrder[]>;
  vehicleSubscriptions$: Observable<VehicleSubscription[]>;
  serviceSubscriptions$: Observable<ServiceSubscription[]>;
  upcomingOrders$: Observable<Order[]>;
  todaysOrders$: Observable<Order[]>;
  loadingOrders$: Observable<boolean>;
  loadingServiceOrders$: Observable<boolean>;
  loadingVehicleSubscriptions$: Observable<boolean>;
  unservicedDays?: UnservicedDay[] = [];
  visits$ = new BehaviorSubject<Visit[]>([]);

  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    private collectionActions: CollectionActions,
    private socket: SocketService
  ) {
    this.orders$ = store.select((state) => getAll(state, Order));
    this.upcomingOrders$ = this.store
      .select((state) => getAll(state, Order))
      .pipe(
        map((orders: Order[]) => {
          return orders.filter((order) => order.isScheduled);
        })
      );
    this.todaysOrders$ = store
      .select((state) => getAll(state, Order))
      .pipe(
        map((orders: Order[]) => {
          return orders.filter((order: Order) => order.isToday);
        })
      );

    this.serviceOrders$ = store.select((state) => getAll(state, ServiceOrder));
    this.serviceSubscriptions$ = store.select((state) => getAll(state, ServiceSubscription));
    this.vehicleSubscriptions$ = store.select((state) => getAll(state, VehicleSubscription));
    this.loadingOrders$ = store.select((state) => getCollection(state, Order).loading);
    this.loadingServiceOrders$ = store.select(
      (state) => getCollection(state, ServiceOrder).loading
    );
    this.loadingVehicleSubscriptions$ = store.select(
      (state) => getCollection(state, VehicleSubscription).loading
    );
    this.subscribeToVisits();
  }

  subscribeToVisits() {
    combineLatest([
      this.upcomingOrders$,
      this.serviceOrders$,
      this.vehicleSubscriptions$,
      this.serviceSubscriptions$,
    ])
      .pipe(
        map(([orders, serviceOrders, vehicleSubs, serviceSubs]) => {
          return Visit.createVisits(orders, serviceOrders, vehicleSubs, serviceSubs);
        })
      )
      .subscribe(this.visits$);
  }

  // Order

  getOrders() {
    this.store.dispatch(this.collectionActions.setLoading(Order, true));
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/orders`,
        method: 'GET',
        params: {
          provider: true,
        },
      })
      .pipe(
        map((result) => {
          const orderList: Order[] = result.map((item) => new Order(item));
          this.sortOrdersByDate(orderList);
          return orderList;
        }),
        finalize(() => this.store.dispatch(this.collectionActions.setLoading(Order, false))),
        tap((orders) => {
          this.store.dispatch(this.collectionActions.set(Order, orders));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  getOrderById(uid: string) {
    const request = this.api
      .call({
        url: `/orders/${uid}`,
        method: 'GET',
      })
      .pipe(
        map((order) => new Order(order)),
        tap((order) => {
          this.store.dispatch(this.collectionActions.update(Order, order));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  getAvailableSlotsByOrderId(uid: string) {
    const request = this.api
      .call({
        url: `/orders/${encodeURIComponent(uid)}/time_slots`,
        method: 'GET',
      })
      .pipe(
        map((dateSlotsCollection) => {
          if (dateSlotsCollection.error) {
            return [];
          }

          return dateSlotsCollection.map((slot) => {
            return new TimeSlot({
              date: slot.date,
              startTime: slot.start_time,
              available: slot.available,
            });
          });
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  private sortOrdersByDate(orders: Order[]) {
    orders.sort((a, b) => {
      const timeA = a.date ? a.date.getTime() : -1;
      const timeB = b.date ? b.date.getTime() : -1;
      return timeA - timeB;
    });
  }

  createOrder(vehicle: Vehicle, params: OrderParams) {
    params['confirmed'] = true;
    delete params.quote;
    const request = this.api
      .call({
        url: `/vehicles/${vehicle.uid}/orders`,
        method: 'POST',
        body: params,
      })
      .pipe(
        map((data) => new Order(data)),
        tap((order) => {
          this.getVehicleSubscriptions();
          this.store.dispatch(this.collectionActions.add(Order, order));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  updateOrder(order: Order, params: OrderParams) {
    delete params.quote;
    const request = this.api
      .call({
        url: '/orders/' + order.uid,
        method: 'PATCH',
        body: params,
      })
      .pipe(
        map((data) => new Order(data)),
        tap((updatedOrder: Order) => {
          if (order.uid === updatedOrder.uid) {
            this.store.dispatch(this.collectionActions.update(Order, updatedOrder));
          } else {
            this.store.dispatch(this.collectionActions.delete(Order, order));
            this.store.dispatch(this.collectionActions.add(Order, updatedOrder));
          }
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  cancelOrder(order: Order, reason?: OrderCancelReason) {
    const request = this.api.call({
      url: `/orders/${order.uid}/cancel`,
      method: 'POST',
      params: { cancellation_reason: reason },
    });
    request
      .pipe(
        tap((response) => {
          this.store.dispatch(this.collectionActions.delete(Order, order));
          this.getVehicleSubscriptions();
        })
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  getDriverLocation(order: Order) {
    return this.api
      .call({
        url: `/orders/${order.uid}/driver_location`,
        method: 'GET',
      })
      .pipe(map((data) => data && new TruckLocation(data)));
  }

  // Real-time Truck Updates

  locationUpdatesForTruckUid(truckUid) {
    const connectionId = this.socketKeyForTruck(truckUid);
    const options: TruckSocketSubscribeOptions = {
      truck_uid: truckUid,
      id: connectionId,
      driver: false,
      channel: OrderService.TRUCK_LOCATION_CHANNEL,
    };
    const connection = this.socket.connect(options);
    return connection!.onUpdate.pipe(map((data) => new TruckLocation(data)));
  }

  stopLocationUpdates(truckUid) {
    const connectionId = this.socketKeyForTruck(truckUid);
    return this.socket.disconnect(connectionId);
  }

  private socketKeyForTruck(truckUid: string) {
    return `${OrderService.TRUCK_LOCATION_CHANNEL}_subscribe_${truckUid}`;
  }

  // Vehicle Subscriptions

  createVehicleSubscription(params: VehicleSubscriptionParams) {
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/vehicle_subscriptions`,
        method: 'POST',
        body: params,
      })
      .pipe(
        map((data) => new VehicleSubscription(data)),
        tap((sub) => {
          this.getVehicleSubscriptions();
          this.getOrders();
          this.store.dispatch(this.collectionActions.add(VehicleSubscription, sub));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  getVehicleSubscriptions() {
    this.store.dispatch(this.collectionActions.setLoading(VehicleSubscription, true));
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/vehicle_subscriptions`,
        method: 'GET',
        params: {
          provider: true,
        },
      })
      .pipe(
        map((result) => result.map((item) => new VehicleSubscription(item))),
        finalize(() =>
          this.store.dispatch(this.collectionActions.setLoading(VehicleSubscription, false))
        ),
        tap((vehicleSubscriptions) => {
          this.store.dispatch(
            this.collectionActions.set(VehicleSubscription, vehicleSubscriptions)
          );
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  deleteVehicleSubsciption(subscription: VehicleSubscription) {
    const request = this.api.call({
      url: '/vehicle_subscriptions/' + subscription.uid,
      method: 'DELETE',
    });
    request
      .pipe(
        tap((sub) => {
          this.store.dispatch(this.collectionActions.delete(VehicleSubscription, subscription));
        })
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  updateVehicleSubscription(
    vehicleSubscription: VehicleSubscription,
    params: VehicleSubscriptionParams
  ) {
    const request = this.api
      .call({
        url: '/vehicle_subscriptions/' + vehicleSubscription.uid,
        method: 'PATCH',
        body: params,
      })
      .pipe(
        map((data) => new VehicleSubscription(data)),
        tap((sub) => {
          this.store.dispatch(this.collectionActions.update(VehicleSubscription, sub));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  cancelVisit(visit: Visit) {
    const deleteRequests = [of(true)];
    if (visit.vehicleSubscription) {
      deleteRequests.push(this.deleteVehicleSubsciption(visit.vehicleSubscription));
    }
    visit.serviceSubscriptions.forEach((order) => {
      deleteRequests.push(this.deleteServiceSubscription(order));
    });
    return combineLatest(deleteRequests).pipe(
      flatMap((response) => {
        // Get all orders
        const getOrder$ = this.getOrderById(visit.order.uid);
        const getServiceOrders$ = this.getServiceOrders();
        return combineLatest([getOrder$, getServiceOrders$]);
      }),
      flatMap(([order, serviceOrders]) => {
        const cancelRequests = [of(true)];
        if (order?.isScheduled) {
          cancelRequests.push(this.cancelOrder(order, OrderCancelReason.SubscriptionCancelled));
        }
        serviceOrders.forEach((serviceOrder) => {
          if (
            serviceOrder.isScheduled &&
            visit.serviceOrders.find(
              (visitServiceOrder) => visitServiceOrder.uid === serviceOrder.uid
            )
          ) {
            cancelRequests.push(this.cancelServiceOrder(serviceOrder));
          }
        });
        return combineLatest(cancelRequests);
      }),
      finalize(() => {
        return this.getOrders();
      })
    );
  }

  cancelAllServices() {
    // Get all subscriptions
    const getFuelSubs$ = this.vehicleSubscriptions$.pipe(take(1));
    const getServiceSubs$ = this.serviceSubscriptions$.pipe(take(1));
    return combineLatest([getFuelSubs$, getServiceSubs$]).pipe(
      flatMap(([fuelSubs, serviceSubs]) => {
        // Delete all subscriptions
        const deleteRequests = [of(true)];
        fuelSubs.forEach((subscription) => {
          deleteRequests.push(this.deleteVehicleSubsciption(subscription));
        });

        serviceSubs.forEach((order) => {
          deleteRequests.push(this.deleteServiceSubscription(order));
        });
        return combineLatest(deleteRequests);
      }),
      flatMap((response) => {
        // Get all orders
        const getFuelOrders$ = this.getOrders();
        const getServiceOrders$ = this.getServiceOrders();
        return combineLatest([getFuelOrders$, getServiceOrders$]);
      }),
      flatMap(([orders, serviceOrders]) => {
        // Cancel all remaining orders
        const cancelRequests = [of(true)];
        orders.forEach((order) => {
          cancelRequests.push(this.cancelOrder(order, OrderCancelReason.SubscriptionCancelled));
        });
        serviceOrders.forEach((order) => {
          cancelRequests.push(this.cancelServiceOrder(order));
        });
        return combineLatest(cancelRequests);
      }),
      finalize(() => {
        this.getOrders();
        this.getVehicleSubscriptions();
        this.getServiceOrders();
        this.getServiceSubscriptions();
      })
    );
  }

  // Service Subscriptions

  getServiceSubscriptions() {
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/service_subscriptions`,
        method: 'GET',
        params: {
          provider: false,
        },
      })
      .pipe(
        map((result) => result.map((item) => new ServiceSubscription(item))),
        tap((subs) => {
          this.store.dispatch(this.collectionActions.set(ServiceSubscription, subs));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  updateServiceSubscription(
    serviceSubscription: ServiceSubscription,
    params: ServiceSubscriptionUpdateParams
  ) {
    const request = this.api
      .call({
        url: `/service_subscriptions/${serviceSubscription.uid}`,
        method: 'PATCH',
        body: params,
      })
      .pipe(
        map((data) => new ServiceSubscription(data)),
        tap((sub) => {
          this.store.dispatch(this.collectionActions.update(ServiceSubscription, sub));
        })
      );
    request.subscribe();
    return request;
  }

  cancelUpcomingServiceOrders(serviceName: string, vehicleUid: string) {
    let serviceOrders: ServiceOrder[] = [];
    this.serviceOrders$.pipe(take(1)).subscribe((orders) => {
      serviceOrders = orders.filter((order) => {
        return (
          order.vehicle.uid === vehicleUid &&
          order.service.serviceType.name === serviceName &&
          order.isCancellable
        );
      });
    });
    const requests = [of(true)];
    serviceOrders.forEach((order) => {
      requests.push(this.cancelServiceOrder(order));
    });
    return combineLatest(requests);
  }

  deleteServiceSubscription(serviceSubscription: ServiceSubscription) {
    const request = this.api.call({
      url: `/service_subscriptions/${serviceSubscription.uid}`,
      method: 'DELETE',
    });
    request
      .pipe(
        tap(() => {
          this.store.dispatch(
            this.collectionActions.delete(ServiceSubscription, serviceSubscription)
          );
        })
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  // Service Order

  getServiceOrders() {
    this.store.dispatch(this.collectionActions.setLoading(ServiceOrder, true));
    const request: Observable<ServiceOrder[]> = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/service_orders`,
        method: 'GET',
        params: {
          provider: false,
        },
      })
      .pipe(
        map((result) => result.map((item) => new ServiceOrder(item))),
        finalize(() => this.store.dispatch(this.collectionActions.setLoading(ServiceOrder, false))),
        tap((orders: ServiceOrder[]) => {
          this.store.dispatch(this.collectionActions.set(ServiceOrder, orders));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  createServiceOrder(vehicle: Vehicle, params: ServiceOrderParams) {
    const request = this.api
      .call({
        url: `/vehicles/${vehicle.uid}/service_orders`,
        method: 'POST',
        body: params,
      })
      .pipe(
        map((data) => new ServiceOrder(data)),
        tap((order) => {
          this.store.dispatch(this.collectionActions.add(ServiceOrder, order));
          this.getServiceSubscriptions();
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  cancelServiceOrder(serviceOrder: ServiceOrder) {
    const request = this.api.call({
      url: `/service_orders/${serviceOrder.uid}/cancel`,
      method: 'POST',
    });
    request
      .pipe(
        tap((response) => {
          this.store.dispatch(this.collectionActions.delete(ServiceOrder, serviceOrder));
        })
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  updateServiceOrder(serviceOrder: ServiceOrder, params: ServiceOrderUpdateParams) {
    const request = this.api
      .call({
        url: `/service_orders/${serviceOrder.uid}`,
        method: 'PATCH',
        body: params,
      })
      .pipe(
        map((data) => new ServiceOrder(data)),
        tap((updatedServiceOrder: ServiceOrder) => {
          this.store.dispatch(this.collectionActions.update(ServiceOrder, updatedServiceOrder));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  // Un Serviced Days

  getUnservicedDays() {
    const request = this.api
      .call({
        url: `/unserviced_days`,
        method: 'GET',
      })
      .pipe(
        map((result) => result.map((item) => new UnservicedDay(item))),
        tap((days) => {
          this.unservicedDays = days;
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  isDateServiced(date: Date) {
    for (const day of this.unservicedDays || []) {
      const isSameDay = moment(date).isSame(day.date, 'day');
      if (isSameDay) {
        return false;
      }
    }
    return true;
  }
}
interface TruckSocketSubscribeOptions extends SocketOptions {
  channel: string;
  id: string;
  truck_uid: string;
  driver: false;
}

export enum OrderFlowEvents {
  DidComplete = 'order-flow-completed',
}
