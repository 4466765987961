import { Component, Input, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'ysh-turn-off-weekly-disclaimer',
  templateUrl: './turn-off-weekly-disclaimer.page.html',
  styleUrls: ['./turn-off-weekly-disclaimer.page.scss'],
})
export class TurnOffWeeklyDisclaimerPage implements OnInit {
  static PAGE_NAME = YshModals.TurnOffWeeklyDisclaimerPage;
  title = 'Turn off weekly visits?';
  detail = `Don't worry you can cancel or reschedule your weekly Yoshi visits anytime without penalty. Yoshi works best as a recurring service so you don't need to worry about going to the gas station again!`;
  @Input() onComplete: (weekly) => void;
  weekly = true;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    console.log('ionViewDidLoad TurnOffWeeklyDisclaimerPage');
  }

  didTapTryWeekly() {
    this.weekly = true;
    this.dismissModal();
  }
  didTapStopWeekly() {
    this.weekly = false;
    this.dismissModal();
  }

  dismissModal() {
    this.modalService.dismissModal();
    this.onComplete(this.weekly);
  }
}
