import { Component, Input, OnInit } from '@angular/core';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CutoffDisclaimerModalComponent } from 'src/app/components/cutoff-disclaimer-modal/cutoff-disclaimer-modal';
import { Day } from 'src/app/models/day';
import { FlowPage } from '../../../flow-director';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { LoyaltyProgram } from 'src/app/models/loyalty/program';
import { LoyaltyService } from 'src/app/services/api/loyalty/loyalty.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Order } from 'src/app/models/order';
import { OrderService } from 'src/app/services/api/order/order.service';
import { Service } from 'src/app/models/service';
import { ServiceName } from 'src/app/models/service-type';
import { TimeSlot } from 'src/app/models/time-slot';
import { TurnOffWeeklyDisclaimerPage } from '../../turn-off-weekly-disclaimer/turn-off-weekly-disclaimer.page';
import { User } from 'src/app/models/user';
import { UserAddress } from 'src/app/models/user-address';
import { UserService } from 'src/app/services/api/user/user.service';
import { take } from 'rxjs/operators';

export interface DaySelectorPageProps {
  service: Service;
  userAddress: UserAddress;
  allowFrequencySelect: boolean;
  showCutoff: boolean;
  editing: boolean;
  onAddressChanged?: (address: UserAddress) => void;
  days: Day[];
  selectedGasFrequency?: RepeatFrequencyGas;
  selectedFrequency?: number;
  currentOrder?: Order;
}

export interface DaySelectorPageForm {
  days: Day[];
  repeat: boolean;
  frequency?: number;
}

export enum RepeatFrequencyGas {
  OneTime = 'One Time Only',
  OnceWeekly = 'Once A Week',
  TwiceWeekly = 'Twice A Week',
}

@Component({
  selector: 'ysh-day-selector',
  templateUrl: './day-selector.page.html',
  styleUrls: ['./day-selector.page.scss'],
})
export class DaySelectorPage implements OnInit, FlowPage {
  frequencyOptions: RepeatFrequencyGas[];
  readonly DAY_PER_WEEK_LIMIT = 2;
  readonly SELECT_OPTIONS_GAS = {
    header: 'How often should we deliver gas to your car?',
  };
  readonly SELECT_OPTIONS = {
    header: 'Repeat service',
  };
  readonly NEW_USER_LABEL = 'New User';
  readonly WHITE_COLOR = '#ffffff';

  @Input() onComplete: (days: Day[], repeat?: boolean, frequency?: number) => void;
  @Input() onDismiss: () => void;
  @Input() preventBackNavigation?: boolean = false;

  @Input() props: DaySelectorPageProps;

  form: DaySelectorPageForm = {
    days: [],
    repeat: false,
  };

  availableDays: Day[] = [];
  program: Nullable<LoyaltyProgram>;
  currentUser: Nullable<User>;

  private dayAvailability = {};
  private lastSelectedGasFrequency: RepeatFrequencyGas;

  constructor(
    public loyaltyService: LoyaltyService,
    private orderService: OrderService,
    private analytics: AnalyticsService,
    private userService: UserService,
    private modalService: ModalService,
    private addressService: AddressService,
    private loadingAlertCtrl: LoadingAlertService
  ) {
    this.loyaltyService.getLoyaltyProgram();
  }

  ngOnInit() {
    const updatingSub = this.props.allowFrequencySelect && this.props.editing;
    // Don't allow OneTime selection if we're updating a subscription
    const options = Object.keys(RepeatFrequencyGas).map((key) => RepeatFrequencyGas[key]);
    this.frequencyOptions = updatingSub ? options.slice(1, 3) : options;
    this.lastSelectedGasFrequency =
      this.props.selectedGasFrequency || RepeatFrequencyGas.OnceWeekly;
    this.analytics.trackView('DaySelectorPage');

    this.userService.currentUser$.pipe(take(1)).subscribe((user) => (this.currentUser = user));
    this.loyaltyService.currentProgram$.pipe(take(1)).subscribe((program) => {
      this.program = program;
      this.refreshDays();
    });
    this.orderService.getUnservicedDays().subscribe((days) => {
      this.dayAvailability = {};
    });

    this.form.days = this.props.days;
    if (!this.form.days.length) {
      this.setDefaultDay();
    }
    this.form.frequency = this.frequency();
    this.props.selectedGasFrequency =
      this.props.selectedGasFrequency || this.props.service.isGas
        ? RepeatFrequencyGas.OnceWeekly
        : RepeatFrequencyGas.OneTime;
  }

  // Actions

  didTapOnDay(day) {
    if (this.isDaySelected(day)) {
      this.removeDay(day);
    } else if (this.props.selectedGasFrequency !== RepeatFrequencyGas.TwiceWeekly) {
      this.form.days = [day];
    } else if (this.form.days.length < this.DAY_PER_WEEK_LIMIT) {
      this.addDay(day);
    }
  }

  didSelectDays() {
    const repeatWeekly = this.props.selectedGasFrequency !== RepeatFrequencyGas.OneTime;
    this.onComplete(this.form.days, repeatWeekly, this.form.frequency);
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didTapCutoffDisclaimer() {
    this.modalService.open({
      component: CutoffDisclaimerModalComponent,
      componentProps: {
        color: this.currentUser!.converted ? this.program?.fullTier.color : this.WHITE_COLOR,
        name: this.currentUser!.converted ? this.program?.fullTier.name : this.NEW_USER_LABEL,
        cutOff: this.loyaltyService.computeCutoffTimeString,
      },
    });
  }

  didSelectGasFrequency(event) {
    const frequency = event?.target?.value;
    if (frequency === RepeatFrequencyGas.OneTime) {
      this.showDisclaimerTurnOffWeekly(this.lastSelectedGasFrequency);
    } else if (frequency === RepeatFrequencyGas.OnceWeekly) {
      this.removeSecondDay();
    }
    // Save selected frequency so we can revert the selection later if needed
    this.lastSelectedGasFrequency = frequency;
  }

  addressDidUpdate(address) {
    if (!address) {
      return;
    }
    if (this.props.onAddressChanged) {
      this.props.onAddressChanged(address);
      return;
    }
    this.props.userAddress = address;
    this.refreshDays();
    this.setDefaultDay();
  }

  showDisclaimerTurnOffWeekly(savedSelection: RepeatFrequencyGas) {
    this.modalService.open({
      component: TurnOffWeeklyDisclaimerPage,
      componentProps: {
        onComplete: (weekly) => {
          if (!weekly) {
            this.removeSecondDay();
          } else {
            this.props.selectedGasFrequency = savedSelection;
          }
        },
      },
      backdropDismiss: false,
    });
  }
  // Days

  refreshDays() {
    const cutoff = this.program
      ? this.currentUser!.converted
        ? this.program.fullTier.orderCutoffHour
        : this.program.maxCutoffHour
      : 24;
    const service = this.props.service;

    if (service.slotScheduling) {
      this.loadTimeSlots().subscribe(
        (slots: TimeSlot[]) => {
          this.availableDays = this.props.userAddress.getDays(cutoff, service, slots) || [];
        },
        (error) => {
          this.loadingAlertCtrl.showToastAlert(`Error finding slots for days: ${error}`);
        }
      );
    } else {
      this.availableDays = this.props.userAddress.getDays(cutoff, service) || [];
    }
    this.setDefaultDay();
  }

  isDaySelected(someDay: Day) {
    return this.form.days.find((day) => day.dayIndex === someDay.dayIndex);
  }

  private loadTimeSlots() {
    return this.props.currentOrder
      ? this.orderService.getAvailableSlotsByOrderId(this.props.currentOrder!.uid)
      : this.addressService.getTimeSlots(this.props.userAddress, null, null, this.props.service);
  }

  private setDefaultDay() {
    const availableDay =
      this.availableDays && this.availableDays.find((day) => this.isDayServiced(day));
    this.form.days = availableDay ? [availableDay] : [];
  }

  private removeDay(dayToRemove: Day) {
    const days = [...this.form.days];
    days.splice(this.form.days.indexOf(dayToRemove), 1);
    this.form.days = this.sortDays(days);
  }

  private addDay(dayToAdd: Day) {
    const days = [...this.form.days];
    days.push(dayToAdd);
    this.form.days = this.sortDays(days);
  }

  private frequency() {
    return !this.props.service?.slotScheduling
      ? this.props.selectedFrequency || this.props.service?.defaultFrequency || 0
      : 0;
  }

  private removeSecondDay() {
    if (this.form.days.length) {
      this.form.days = [this.form.days[0]];
    } else {
      this.setDefaultDay();
    }
  }

  private sortDays(days: Day[]) {
    return days.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  // Helpers

  disclaimer() {
    if (!this.props.allowFrequencySelect || !this.props.service.isGas) {
      return '';
    }
    switch (this.props.selectedGasFrequency) {
      case RepeatFrequencyGas.OneTime:
        return 'Yoshi can come up to twice a week 😎';
      case RepeatFrequencyGas.OnceWeekly:
        return 'Yoshi can come up to twice a week 😎';
      case RepeatFrequencyGas.TwiceWeekly:
        return 'Choose up to two days! 💪';
    }
  }

  isDayServiced(day: Day) {
    const key = day.nextDate.toDateString();
    if (!(key in this.dayAvailability)) {
      const serviced = this.orderService.isDateServiced(day.nextDate);
      this.dayAvailability[key] = serviced;
    }

    const nonSlotSchedulingRoutes = day.routes.filter((r) => r.slotScheduling === false);

    return (
      this.dayAvailability[key] &&
      (this.props.service.slotScheduling
        ? day.slots.length > 0
        : nonSlotSchedulingRoutes.length > 0)
    );
  }
}
