import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { Benefit } from '../../../../models/benefit';
import { ServiceType } from 'src/app/models/service-type';
import { UserAddress } from '../../../../models/user-address';
import { UserService } from 'src/app/services/api/user/user.service';
import { Vehicle } from '../../../../models/vehicle';

@Component({
  selector: 'ysh-service-credit',
  templateUrl: 'service-credit.html',
  styleUrls: ['./service-credit.scss'],
})
export class ServiceCreditComponent implements OnInit, OnChanges {
  @Input() selectedVehicle: Vehicle;
  @Input() selectedAddress: UserAddress;
  @Input() serviceType: ServiceType;

  title: Nullable<string>;
  benefits: Benefit[] = [];

  readonly DATE_FORMAT = 'M/DD/YY';

  constructor(private userService: UserService) {
    this.title = 'Credit';
  }

  ngOnInit() {
    this.subscribeToBenefits();
  }

  ngOnChanges(changes) {
    console.log('CHANGES', changes);
  }

  subscribeToBenefits() {
    this.userService.benefits$.subscribe((benefits) => {
      this.benefits = benefits
        .filter((benefit) => {
          const validService = this.serviceType && benefit.appliable(this.serviceType);
          const validVehicle = benefit?.isValidWithVehicleUid(this.selectedVehicle?.uid);
          const hasDetails = Boolean(benefit.promotionalDetails);
          return validService && validVehicle && hasDetails;
        })
        .sort((a, b) => b.value - a.value);
    });
  }
}
