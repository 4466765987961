import * as a from 'indefinite';

import { Component, Input } from '@angular/core';

import { DollarsAndCentsPipe } from '../../../../pipes/dollars-and-cents/dollars-and-cents';
import { ImageAttribute } from 'ionic-image-loader-v5';
import { Service } from 'src/app/models/service';
import { ServiceName } from 'src/app/models/service-type';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-service-header',
  templateUrl: 'service-header.html',
  styleUrls: ['./service-header.scss'],
})
export class ServiceHeaderComponent {
  readonly IMAGE_LOADER_ATTRIBUTES: ImageAttribute[] = [
    { element: 'class', value: 'service-bg__img' },
  ];

  @Input() service: Service;
  @Input() fuelDiscount: number;
  @Input() hideFuelDiscount: boolean;

  constructor(
    private dollarsAndCents: DollarsAndCentsPipe,
    private vehicleService: VehiclesService
  ) {}

  // View Helpers
  showAmountDiscounted() {
    return this.fuelDiscount ? true : false;
  }

  fuelDiscountAmount() {
    return this.dollarsAndCents.transform(Number(this.fuelDiscount));
  }

  get showShadow() {
    return ['white', '#ffffff', '#fff'].includes(
      this.service.serviceType.presentation.backgroundColor.toLowerCase()
    );
  }

  get classForService(): string {
    const serviceName: ServiceName = this.service?.serviceType?.name;
    const alignment: string = this.service?.serviceType?.presentation?.imageAlignment;
    return serviceName ? `${serviceName} ${alignment}`.trim() : '';
  }
  get description() {
    if (
      !this.hideFuelDiscount &&
      this.service.perGallonDiscount &&
      this.vehicleService.selectedVehicle$.value?.useGas
    ) {
      const title = a(this.service.title.toLowerCase());
      return `Add ${title} to your next Yoshi day for a <strong>${this.dollarsAndCents.transform(
        this.service.perGallonDiscount
      )}/gallon</strong> discount!`;
    }
    return this.service.shortDescription;
  }
}
