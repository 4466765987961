import { Component, Input, OnInit } from '@angular/core';
import { ServiceGroup } from 'src/app/models/service-group';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { User } from 'src/app/models/user';

export interface WebflowEndPageProps {
  serviceGroup: ServiceGroup;
  user: User;
}
@Component({
  selector: 'ysh-webflow-end-page',
  templateUrl: './webflow-end.page.html',
  styleUrls: ['./webflow-end.page.scss'],
})
export class WebflowEndPage implements OnInit, FlowPage {
  @Input() props: WebflowEndPageProps;
  onComplete: (result: any) => void;
  onDismiss: () => void;

  readonly DEFAULT_SUPPORT_PHONE = '+16502854778';

  constructor(private analytics: AnalyticsService) {}

  // life cycle hooks

  ngOnInit() {
    this.analytics.trackView('WebflowEndPage');
  }

  formatPhoneNumber(str: string) {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
}
