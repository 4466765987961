import * as mapboxgl from 'mapbox-gl';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LOCAL_FEATURE_LAYER, MapService, MapStyle } from 'src/app/services/map/map.service';

import circle from '@turf/circle';

export interface LocationPickerResponse {
  coords: google.maps.LatLng;
  invalidLocation: boolean;
}

@Component({
  selector: 'ysh-location-picker',
  templateUrl: 'location-picker.html',
  styleUrls: ['./location-picker.scss'],
})
export class LocationPickerComponent implements OnDestroy, OnInit {
  @ViewChild('mapRef') mapRef: ElementRef;

  static readonly DEFAULT_OUT_OF_BOUNDS_RADIUS_IN_METERS = 400;
  static readonly CORPORATE_OUT_OF_BOUNDS_RADIUS_IN_METERS = 2000;

  @Input() public title: string;
  @Input() public parkingCoord: google.maps.LatLng = new google.maps.LatLng(37, 121);
  @Input() public addressCoord: google.maps.LatLng = new google.maps.LatLng(37, 121);
  @Input() public locationName: string;
  @Input() public buttonText: string;
  @Input() isCorporateAddress = false;

  @Output() public onUpdate: EventEmitter<LocationPickerResponse> = new EventEmitter();
  @Output() public onGeocodeStatusChange: EventEmitter<boolean> = new EventEmitter();

  map: mapboxgl.Map;
  geocoderPending: boolean;
  parkingRestricted = false;
  addressMarker: mapboxgl.Marker;
  showPin = false;
  dragging = false;
  message: string;

  constructor(private zone: NgZone, private mapService: MapService) {
    this.title = '';
    this.locationName = 'Getting location...';
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.configureMap();
    }, 0);
  }

  ngOnDestroy() {
    google.maps.event.clearListeners(this.map, 'center_changed');
    google.maps.event.clearListeners(this.map, 'bounds_changed');
    google.maps.event.clearListeners(this.map, 'dragstart');
    google.maps.event.clearListeners(this.map, 'dragend');
  }

  // Map

  async configureMap() {
    this.map = this.mapService.createMBMap('map', {
      style: MapStyle.RoadmapWithNoData,
      center: [this.parkingCoord.lng(), this.parkingCoord.lat()],
      zoom: 15,
    });

    this.map.on('dragstart', () => this.zone.run(() => (this.dragging = true)));
    this.map.on('dragend', () => this.zone.run(() => (this.dragging = false)));
    this.map.on('moveend', () => this.zone.run(() => this.mapCenterDidChange()));
    this.map.on('render', () => this.zone.run(() => this.mapCenterDidChange()));

    setTimeout(
      () =>
        this.map.flyTo({
          zoom: 17,
        }),
      500
    );

    setTimeout(() => {
      this.map.setStyle(MapStyle.SatelliteWithData);
      this.map.once('idle', () => this.zone.run(() => this.drawOutOfBoundsArea()));
      this.showPin = true;
    }, 1200);
    this.addressMarker = this.mapService
      .createAddressMarker(this.addressCoord.lng(), this.addressCoord.lat())
      .addTo(this.map);
  }

  drawOutOfBoundsArea() {
    if (this.map.getLayer(LOCAL_FEATURE_LAYER)) {
      return;
    }
    const worldBounds = [
      [-180, -85.1054596961173],
      [-180, 85.1054596961173],
      [180, 85.1054596961173],
      [180, -85.1054596961173],
      [0, -85.1054596961173],
    ];
    const circlePath = circle(
      [this.addressCoord.lng(), this.addressCoord.lat()],
      this.inboundRadius,
      {
        units: 'meters',
      }
    );
    circlePath.geometry.coordinates.unshift(worldBounds);
    circlePath.properties!.restricted = 'true';
    circlePath.properties!.message = 'Please move pin closer to delivery address';

    // Add a new layer to visualize the polygon.
    this.map.addLayer({
      id: LOCAL_FEATURE_LAYER,
      type: 'fill',
      layout: {},
      source: {
        type: 'geojson',
        data: circlePath,
      },
      paint: {
        'fill-color': '#FF6D5B', // blue color fill
        'fill-opacity': 0.5,
      },
    });
  }

  mapCenterDidChange() {
    const map = this.map;
    if (!this.mapRef || !map || !map.getCenter()) {
      return;
    }
    const features = this.mapService.getFeatures(this.map);
    this.parkingRestricted = Boolean(
      features.find((feature) => feature.properties?.restricted == 'true')
    );
    this.message = features.find((feature) => feature.properties?.message)?.properties?.message;
    const latlng = new google.maps.LatLng(this.map.getCenter().lat, this.map.getCenter().lng);
    this.onUpdate.emit({
      coords: latlng,
      invalidLocation: this.parkingRestricted,
    });
  }

  private get inboundRadius(): number {
    return this.isCorporateAddress
      ? LocationPickerComponent.CORPORATE_OUT_OF_BOUNDS_RADIUS_IN_METERS
      : LocationPickerComponent.DEFAULT_OUT_OF_BOUNDS_RADIUS_IN_METERS;
  }
}
