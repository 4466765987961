import { Action } from '@ngrx/store';
import { BaseEntity } from 'src/app/models/base';
import { Collection } from './state';
import { Injectable } from '@angular/core';
import { PageInfo } from 'src/app/services/api/api.service';

/**
 * Generic actions for a list of BaseEntity objects.
 * Objects must be of type BaseEntity with a prepopulated uid field.
 */

@Injectable()
export class CollectionActions {
  static SET = 'SET';
  static APPEND = 'APPEND';
  static ADD = 'ADD';
  static ADD_MANY = 'ADD_MANY';
  static INSERT = 'INSERT';
  static UPDATE = 'UPDATE';
  static UPDATE_MANY = 'UPDATE_MANY';
  static SET_LOADING = 'SET_LOADING';
  static SET_WITH_PAGE_INFO = 'SET_WITH_PAGE_INFO';
  static DELETE = 'DELETE';
  static CLEAR_ALL = 'CLEAR_ALL';

  set(collection: Collection, items: BaseEntity[]): NamedAction {
    return {
      type: CollectionActions.SET,
      payload: items,
      collectionName: collection.classId,
    };
  }

  append(collection: Collection, items: BaseEntity[]): NamedAction {
    return {
      type: CollectionActions.APPEND,
      payload: items,
      collectionName: collection.classId,
    };
  }

  add(collection: Collection, item: BaseEntity): NamedAction {
    return {
      type: CollectionActions.ADD,
      payload: item,
      collectionName: collection.classId,
    };
  }

  addMany(collection: Collection, items: BaseEntity[]): NamedAction {
    return {
      type: CollectionActions.ADD_MANY,
      payload: items,
      collectionName: collection.classId,
    };
  }

  insert(collection: Collection, item: BaseEntity): NamedAction {
    return {
      type: CollectionActions.INSERT,
      payload: item,
      collectionName: collection.classId,
    };
  }

  update(collection: Collection, item: BaseEntity): NamedAction {
    return {
      type: CollectionActions.UPDATE,
      payload: item,
      collectionName: collection.classId,
    };
  }

  updateMany(collection: Collection, items: BaseEntity[]): NamedAction {
    return {
      type: CollectionActions.UPDATE_MANY,
      payload: items,
      collectionName: collection.classId,
    };
  }

  setLoading(collection: Collection, loading: boolean): NamedAction {
    return {
      type: CollectionActions.SET_LOADING,
      payload: loading,
      collectionName: collection.classId,
    };
  }

  setWithPageInfo(collection: Collection, pagedItems: PagedItems): NamedAction {
    return {
      type: CollectionActions.SET_WITH_PAGE_INFO,
      payload: pagedItems,
      collectionName: collection.classId,
    };
  }

  delete(collection: Collection, item: BaseEntity): NamedAction {
    return {
      type: CollectionActions.DELETE,
      payload: item,
      collectionName: collection.classId,
    };
  }

  clearAll(): Action {
    return {
      type: CollectionActions.CLEAR_ALL,
    };
  }
}

export interface NamedAction extends Action {
  collectionName: string;
  payload?: any;
}

export interface PagedItems {
  items: BaseEntity[];
  pageInfo: PageInfo;
}
