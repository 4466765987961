import { BaseEntity, Default, MemoizeGetters, Transform, toDate, toEntity } from './base';

import { Affiliation } from './affiliation';
import { Company } from './company';
import { MemberSubscription } from './member-subscription';

@MemoizeGetters
export class User extends BaseEntity {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone: string;
  converted: boolean;

  @Transform(toDate)
  dateOfBirth?: Date;

  firstTimeCustomer: boolean;
  admin: boolean;
  filler: boolean;
  provider: boolean;
  vip: boolean;

  @Default('')
  twilioPhone: string;

  @Transform(toDate)
  createdAt: Date;

  @Transform(toEntity(MemberSubscription))
  memberSubscriptions: MemberSubscription[];

  @Transform(toEntity(Affiliation))
  affiliations: Affiliation[];

  // Computed Properties

  get fuelMembership() {
    return this.memberSubscriptions.find((sub) => sub.active);
  }

  get hasCompleteProfile() {
    let hasName = this.firstName || this.lastName;
    let hasContactInfo = this.phone && this.phone.length && this.email && this.email.length;
    return Boolean(hasName && hasContactInfo);
  }

  get initials(): string {
    return this.firstName?.charAt(0) || '' + this.lastName?.charAt(0) || '';
  }

  isAffiliated(company: Company): Boolean {
    return Boolean(
      this.affiliations.find((affiliation) => affiliation.company.uid === company?.uid)
    );
  }

  shouldPromptForAffilation(company: Company) {
    return company?.shouldPromptForExternalId && !this.isAffiliated(company);
  }
}

export interface UserParams {
  firstName?: string | null;
  lastName?: string | null;
  signupSource?: string | null;
  signupRef?: string | null;
  locationName?: string | null;
  lat?: number | null;
  lng?: number | null;
  zipCode?: string | null;
  phone?: string;
  email?: string;
  inviteCode?: string | null;
  skipMembership?: boolean | null;
}

export interface UserUpdateParams {
  ref?: string;
  deepLinkData?: any;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  dateOfBirth?: string;
}
