import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from '../../../services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { TypedFlow } from '../shared/typed-flow/typed-flow';
import { User } from 'src/app/models/user';
import { UserService } from '../../../services/api/user/user.service';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'ysh-login-flow-page',
  templateUrl: './login-flow.page.html',
  styleUrls: ['./login-flow.page.scss'],
})
export class LoginFlowPage extends TypedFlow implements OnInit {
  @ViewChild('inputPin') inputPin: any;
  @Input() phone;
  @Input() onComplete: (user: User) => void;
  @Input() onDismiss: () => void;
  @Input() outlineInputStyle = false;
  pin: string;
  constructor(
    private userService: UserService,
    private loadingAlertCtrl: LoadingAlertService,
    private modalService: ModalService,
    private vehicleService: VehiclesService,
    private addressService: AddressService,
    private analytics: AnalyticsService,
    private keyboardService: KeyboardService
  ) {
    super();
    this.pageSelector = 'page-login-flow';
  }

  ngOnInit() {
    this.analytics.trackView('LoginFlowPage');
    this.start();
    setTimeout(() => {
      this.inputPin.setFocus();
    }, 600);
  }

  ionViewWillEnter() {
    this.keyboardService.disableScroll(true);
  }

  ionViewWillLeave() {
    this.keyboardService.close();
    this.keyboardService.disableScroll(false);
  }

  didTypePin(pin: string) {
    if (pin) {
      this.pin = pin.trim();
    }
  }

  login() {
    this.loadingAlertCtrl.showLoaderWithText('Logging In');
    this.userService
      .login(this.phone, this.pin)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(
        (data) => {
          if (this.onComplete) {
            this.onComplete(data);
          } else {
            this.dismissAndNavigate();
          }
        },
        (error) => {
          console.log('error logging in', error);
          this.loadingAlertCtrl.showToastAlert('That pin is incorrect.');
        }
      );
  }

  private dismissAndNavigate() {
    this.modalService.dismissModal();
  }

  formValidates() {
    return this.phone && this.pin && this.pin.length === 4;
  }

  // Actions

  didTapDismiss() {
    if (this.onDismiss) {
      this.onDismiss();
    } else {
      this.modalService.dismissModal();
    }
  }

  didTapLogin() {
    this.login();
  }

  didTapEnter() {
    this.formValidates() && this.login();
  }
}
