import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ysh-curved-actions-block',
  templateUrl: './ysh-curved-actions-block.component.html',
  styleUrls: ['./ysh-curved-actions-block.component.scss'],
})
export class YshCurvedActionsBlockComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
