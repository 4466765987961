import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ysh-legal-modal',
  templateUrl: 'legal-modal.html',
  styleUrls: ['./legal-modal.scss'],
})
export class LegalModalComponent implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  didTapDismiss() {
    this.modalCtrl.dismiss(false);
  }

  didTapAccept() {
    this.modalCtrl.dismiss(true);
  }

  openLink(link) {
    window.open(link, '_system', 'location=yes');
    return false;
  }
}
