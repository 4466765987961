import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import { finalize, takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { LoadingAlertService } from '../../../../services/loading-alert/loading-alert.service';
import { MembershipService } from '../../../../services/api/membership/membership.service';
import { OrderService } from '../../../../services/api/order/order.service';
import { ServiceOrder } from '../../../../models/service-order';
import { ServiceSubscription } from '../../../../models/service-subscription';
import { Subject } from 'rxjs';
import { VehicleSubscription } from '../../../../models/vehicle-subscription';

export interface ConfirmCancellationPageProps {
  membershipUid?: string;
  paidThrough?: string;
  cancellationReason?: string;
  cancellationNotes?: string;
}

@Component({
  selector: 'ysh-confirm-cancellation',
  templateUrl: './confirm-cancellation.page.html',
  styleUrls: ['./confirm-cancellation.page.scss'],
})
export class ConfirmCancellationPage implements OnInit, OnDestroy, FlowPage {
  @Input() onComplete: () => void;
  @Input() onDismiss: () => void;
  @Input() props: ConfirmCancellationPageProps;

  membershipCancelled: boolean;
  allowMembershipSkip = false;
  pendingServices: ServiceOrder[];
  vehicleSubscriptions: VehicleSubscription[];
  serviceSubscriptions: ServiceSubscription[];
  deliveryCharge: number = 0;
  unsubscribe: Subject<void> = new Subject();

  readonly keepServicesText = 'No, keep my services scheduled';
  readonly nevermindText = 'Nevermind';
  readonly cancelPendingServicesBtnText = 'Yes, cancel pending services';

  constructor(
    private analytics: AnalyticsService,
    private featureFlags: FeatureFlagService,
    private loadingAlertCtrl: LoadingAlertService,
    private membershipService: MembershipService,
    private orderService: OrderService
  ) {
    this.subscribeToScheduledServices();
  }

  // data
  subscribeToScheduledServices() {
    this.orderService.serviceOrders$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((services) => (this.pendingServices = services));
    this.orderService.serviceSubscriptions$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((subscriptions) => (this.serviceSubscriptions = subscriptions));
    this.orderService.vehicleSubscriptions$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((subscriptions) => (this.vehicleSubscriptions = subscriptions));
  }

  // hooks
  ngOnInit() {
    this.analytics.trackView('ConfirmCancellationPage');
    this.featureFlags
      .flag$(Flag.AllowMembershipSkip)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((allow) => (this.allowMembershipSkip = allow));
    this.membershipService.memberPolicy$.pipe(takeUntil(this.unsubscribe)).subscribe((policy) => {
      this.deliveryCharge = policy?.delivery.feeUnsubscribed || 0;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // actions
  didTapConfirm() {
    this.membershipCancelled
      ? this.showDeliveryChargeDisclaimer()
        ? this.cancelPendingServices()
        : this.onComplete()
      : this.cancelMembership();
  }

  didTapDismiss() {
    this.onDismiss();
  }

  didTapNevermind() {
    this.onDismiss();
  }

  // helpers
  mainText(): string {
    const didCancelText = `Your membership is cancelled. You can come back anytime!`;
    const deliveryOptionText = `You may also continue to use Yoshi with a $${this.deliveryCharge} delivery fee (per vehicle fuel order) if you don't wish to restart a monthly payment.`;
    const cancellationText = this.allowMembershipSkip
      ? `${didCancelText} ${deliveryOptionText}`
      : didCancelText;

    return this.membershipCancelled
      ? cancellationText
      : `Please confirm you would like to cancel your Yoshi subscription. You will still have access to Yoshi's services until your membership expires on ${this.props.paidThrough}.`;
  }

  showDeliveryChargeDisclaimer(): boolean {
    if (
      !this.pendingServices.length &&
      !this.vehicleSubscriptions.length &&
      !this.serviceSubscriptions.length
    ) {
      return false;
    }
    return true;
  }

  confirmBtnText(): string {
    return this.membershipCancelled
      ? this.showDeliveryChargeDisclaimer()
        ? 'Yes, cancel pending services'
        : 'Finish'
      : 'Yes, Cancel';
  }

  cancelMembership() {
    if (!this.props.membershipUid || !this.props.cancellationReason) {
      return;
    }
    this.loadingAlertCtrl.showLoader();
    this.membershipService
      .cancelMemberSubscription(
        this.props.membershipUid,
        this.props.cancellationReason,
        this.props.cancellationNotes
      )
      .pipe(
        finalize(() => {
          this.loadingAlertCtrl.dismissLoader();
        })
      )
      .subscribe(
        () => {
          this.membershipCancelled = true;
          this.loadingAlertCtrl.showToastConfirmation('Membership Cancelled!');
        },
        (error) => {
          this.membershipCancelled = false;
          this.loadingAlertCtrl.showToastAlert(`Error cancelling membership: ${error}`);
        }
      );
  }

  cancelPendingServices() {
    this.loadingAlertCtrl.showLoaderWithText('Please Wait..');
    this.orderService
      .cancelAllServices()
      .pipe(
        finalize(() => {
          this.loadingAlertCtrl.dismissLoader();
        })
      )
      .subscribe(
        () => {
          this.onDismiss();
        },
        (error) => {
          this.loadingAlertCtrl.showToastAlert(`Error cancelling services: ${error}`);
        }
      );
  }

  // helpers

  deliveryChargeDisclaimer() {
    return `Would you like to cancel your pending services as well? Fuel orders will incur a $${this.deliveryCharge} delivery fee if they are completed after your membership paid through date.`;
  }
}
