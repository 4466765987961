import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';

import { IonNav } from '@ionic/angular';
import { Subject } from 'rxjs';
import { UpdateUserFlowPage } from '../update-user-flow/update-user-flow.page';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/api/user/user.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleFlowController } from '../../vehicle/vehicle-flow-controller/vehicle-flow-controller.page';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ysh-complete-profile-flow-controller',
  template: '<ion-nav #completeProfileNav ></ion-nav>',
})
export class CompleteProfileFlowControllerPage implements Flow, AfterViewInit, OnInit, OnDestroy {
  @ViewChild('completeProfileNav') nav: IonNav;

  onComplete: () => void;

  flowDirector: FlowDirector;

  currentUser: Nullable<User>;
  vehicles: Vehicle[];

  private unsubscribe: Subject<void> = new Subject();

  constructor(private userService: UserService, private vehicleService: VehiclesService) {}

  // Angular

  ngOnInit() {
    this.initSubscriptions();
  }

  ngAfterViewInit(): void {
    this.flowDirector = new FlowDirector(this);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Data

  initSubscriptions() {
    this.userService.currentUser$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user) => (this.currentUser = user));
    this.vehicleService.vehicles$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((vehicles) => (this.vehicles = vehicles));
  }

  // Flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.currentUser?.hasCompleteProfile) {
      return this.optionsForUserPage();
    } else if (!this.vehicles.length) {
      return this.optionsforVehiclePage();
    }
  }

  flowDidComplete(): void {
    this.dismissController();
  }

  // Params

  optionsForUserPage(): FlowPageOptions<UpdateUserFlowPage> {
    return {
      page: UpdateUserFlowPage,
      onComplete: () => this.flowDirector.next(),
      pageProps: {
        user: this.currentUser!,
      },
    };
  }

  optionsforVehiclePage(): FlowPageOptions<VehicleFlowController> {
    return {
      page: VehicleFlowController,
      onComplete: () => this.flowDirector.next(),
      onDismiss: () => this.dismissController(),
      props: {},
    };
  }

  // Actions

  dismissController() {
    this.onComplete?.();
  }
}
