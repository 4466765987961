import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonInput, NavParams } from '@ionic/angular';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PlacesAutocompletePage } from 'src/app/pages/misc/places-autocomplete/places-autocomplete.page';
import { TypedFlow } from '../../shared/typed-flow/typed-flow';
import { UserParams } from 'src/app/models/user';
import { UserService } from 'src/app/services/api/user/user.service';
import { addressParamsForGooglePlaceData } from 'src/app/models/user-address';

@Component({
  selector: 'ysh-signup-flow',
  templateUrl: './signup-flow.page.html',
  styleUrls: ['./signup-flow.page.scss'],
})
export class SignupFlowPage extends TypedFlow implements OnInit {
  @ViewChild('inputFirstName') inputFirstName: IonInput;
  @ViewChild('inputLastName') inputLastName: IonInput;
  @ViewChild('inputPhone') inputPhone: IonInput;
  @ViewChild('inputEmail') inputEmail: IonInput;
  @ViewChild('Content') content: IonContent;

  @Input() onDismiss: () => void;
  @Input() onComplete: () => void;
  @Input() phone: string;

  loader: any;
  formData: UserParams = {};

  disabled = false;

  constructor(
    private userService: UserService,
    private loadingAlertCrtl: LoadingAlertService,
    private geolocation: Geolocation,
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private keyboardService: KeyboardService
  ) {
    super();
    this.pageSelector = '.page-signup-flow';
  }

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('SignupFlowPage');
    this.formData.phone = this.phone;
    this.start();
  }

  ionViewWillEnter() {
    this.keyboardService.disableScroll(true);
    setTimeout(() => {
      this.inputFirstName.setFocus();
    }, 600);
  }

  ionViewWillLeave() {
    this.keyboardService.close();
    this.keyboardService.disableScroll(false);
  }

  // Data

  signupUser() {
    this.loadingAlertCrtl.showLoaderWithText('Logging In');
    this.userService.signup(this.formData).subscribe(
      (data) => {
        this.loadingAlertCrtl.dismissLoader();
        if (this.onComplete) {
          this.onComplete();
        }
      },
      (error) => {
        console.log('error logging in', error);
        this.loadingAlertCrtl.dismissLoader();
        this.loadingAlertCrtl.presentAlert('Error creating account', error);
      }
    );
  }

  formValidates() {
    return (
      this.nameValidates() &&
      this.phoneValidates() &&
      this.emailValidates() &&
      this.addressValidates()
    );
  }

  nameValidates() {
    return this.formData.firstName && this.formData.lastName;
  }

  phoneValidates() {
    return this.formData.phone && this.formData.phone.length === 10;
  }

  emailValidates() {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}/;
    return this.formData.email && this.formData.email.match(emailRegex);
  }

  addressValidates() {
    return (
      this.formData.locationName && this.formData.lat && this.formData.lng && this.formData.zipCode
    );
  }

  // Actions

  didTapEnter() {
    this.inputLastName.setFocus();
  }

  didTapDismiss() {
    if (this.onDismiss) {
      this.onDismiss();
    }
  }

  didTapBack() {
    this.hideKeyboad();
    this.back();
  }

  didTapNext() {
    this.disabled = true;
    this.hideKeyboad();
    this.next();
    if (this.flowIndex === 1) {
      setTimeout(() => {
        this.inputEmail.setFocus();
      }, 600);
    }
    // enable button
    setTimeout(() => {
      this.disabled = false;
    }, 300);
  }

  didTapDone() {
    this.signupUser();
  }

  async didTapNewAddress() {
    const modal = await this.modalService.openPage({ component: PlacesAutocompletePage });
    this.keyboardService.close();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.didSelectNewAddress(data);
    }
    setTimeout(() => {
      this.keyboardService.close();
    }, 600);
  }

  didSelectNewAddress(data) {
    this.clearLocationData();
    this.createDataForGooglePlace(data);
  }

  clearLocationData() {
    delete this.formData.locationName;
    delete this.formData.lat;
    delete this.formData.lng;
    delete this.formData.zipCode;
  }

  createDataForGooglePlace(data: google.maps.places.PlaceResult) {
    const addressParams = addressParamsForGooglePlaceData(data);
    if (addressParams.zipCode) {
      this.formData = {
        ...this.formData,
        ...addressParams,
      };
    } else {
      this.loadingAlertCrtl.showToastAlert('Please pick a more specific address.');
    }
  }

  didTapGetLocation() {
    this.loadingAlertCrtl.showLoader();
    this.clearLocationData();
    this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        this.loadingAlertCrtl.dismissLoader();
        this.reverseGeocode(resp.coords);
      })
      .catch((error) => {
        this.loadingAlertCrtl.dismissLoader();
        this.loadingAlertCrtl.presentAlert('Failed to get location', 'Please type an address');
        console.log('Error getting location', error);
      });
  }

  reverseGeocode(coords) {
    const geocoder = new google.maps.Geocoder();
    console.log('geocoding');
    const latlng = new google.maps.LatLng(coords.latitude, coords.longitude);
    this.loadingAlertCrtl.showLoader();
    geocoder.geocode({ location: latlng }, (results: any[], status) => {
      this.loadingAlertCrtl.dismissLoader();
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.clearLocationData();
          this.createDataForGooglePlace(results[0]);
        } else {
          this.loadingAlertCrtl.presentAlert('Failed to get location', 'No results found');
        }
      } else {
        this.loadingAlertCrtl.presentAlert(
          'Failed to get location',
          'Geocoder failed due to status ' + status
        );
      }
    });
  }

  private hideKeyboad = () => this.keyboardService.close();
}
