import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { AppRoutes } from './routes';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ProfileRoutes } from './pages/profile/routes';
import { ServicesRoutes } from './pages/services/routes';
import { TabRoutes } from './pages/tabs/routes';

export enum NavDirection {
  Forward,
  Back,
  Root,
}

export enum QueryParamKey {
  SignupRef = 'signup_ref',
  InviteCode = 'invite_code',
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  initParams: URLSearchParams;

  constructor(private router: Router, private navController: NavController) {
    this.initParams = this.queryParams;
  }

  navigate(params: NavigationParams, direction = NavDirection.Forward) {
    const { path, relativeTo, state } = params;
    const extras: NavigationExtras = {};
    if (relativeTo) {
      extras.relativeTo = relativeTo;
    }
    if (state) {
      extras.state = { ...state };
    }
    switch (direction) {
      case NavDirection.Forward:
        this.navController.navigateForward([...path], extras);
        break;
      case NavDirection.Back:
        this.navController.navigateBack([...path], extras);
        break;
      case NavDirection.Root:
        this.navController.navigateRoot([...path], extras);
        break;
    }
  }

  currentPath() {
    return window.location.pathname;
  }

  inWebFlow() {
    return this.currentPath().includes(`/${AppRoutes.Webflow}`);
  }

  goBack() {
    this.navController.pop();
  }
  get queryParams() {
    return new URL(window.location.toString()).searchParams;
  }

  get currentRouteState() {
    return this.router.getCurrentNavigation()?.extras?.state;
  }
}

export interface NavigationParams {
  path: Array<AppRoutes | TabRoutes | ServicesRoutes | ProfileRoutes>;
  relativeTo?: ActivatedRoute;
  state?: object;
}
