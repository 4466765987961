import { AlertController, Platform } from '@ionic/angular';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';

import { AnalyticsService } from '../analytics/analytics.service';
import { DeeplinkService } from '../deeplink/deeplink.service';
import { Device } from '@ionic-native/device/ngx';
import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';
import { UserService } from '../api/user/user.service';
import { first } from 'rxjs/operators';

enum PushStorageKey {
  PromptedForPush = 'has_been_prompted_for_push',
  DeclinedPush = 'did_decline_push',
  PushToken = 'push_token',
}

enum PushEvent {
  Registration = 'registration',
  Notification = 'notification',
  Error = 'error',
}

enum PushPrompt {
  Title = 'Welcome to Yoshi!',
  Message = 'To start, please tap OK when prompted about Push Notifications so that we can notify you about any changes to your vehicle status.',
  Accept = 'Ok',
  Decline = 'Maybe Later',
}

const PUSH_ANALYTICS_EVENT = 'Push Accepted';

const PUSH_OPTIONS: PushOptions = {
  android: {
    senderID: '851381956609',
  },
  ios: {
    alert: 'true',
    badge: true,
    sound: 'true',
  },
};

/**
 * This class encapsulates the flow that
 *   1. prompts a user for push permission
 *   2. requests a device token
 *   3. send a device token to the server
 */
@Injectable({
  providedIn: 'root',
})
export class PushRegistrationService {
  constructor(
    private analytics: AnalyticsService,
    private deeplink: DeeplinkService,
    private userService: UserService,
    private session: SessionService,
    private push: Push,
    private alertCtlr: AlertController,
    private device: Device,
    private platform: Platform
  ) {}
  async register() {
    if (!this.platform.is('cordova')) {
      return;
    }
    // For android register for push automatically since android does not prompt a user for permission
    if (this.platform.is('android')) {
      this.registerForPush();
    } else if (this.platform.is('ios')) {
      // For ios show a primer message before the system prompt
      // so that we retain the ability to ask users in the future
      // https://techcrunch.com/2014/04/04/the-right-way-to-ask-users-for-ios-permissions/
      const hasBeenPrompted = await this.session.get(PushStorageKey.PromptedForPush);
      const hasAlreadyDeclined = await this.session.get(PushStorageKey.DeclinedPush);
      const pushPermissionStatus = await this.push.hasPermission();
      const hasAlreadyAccepted = pushPermissionStatus && pushPermissionStatus.isEnabled;
      if (hasBeenPrompted) {
        // Always re-register so that we have the latest device token
        if (!hasAlreadyDeclined) {
          this.registerForPush();
        }
      } else if (!hasAlreadyAccepted) {
        const acceptedPrompt = await this.promptForPush();
        acceptedPrompt ? this.registerForPush() : this.didDeclinePush();
      }
    }
  }

  // Private
  private promptForPush() {
    return new Promise<boolean>(async (resolve) => {
      const alert = await this.alertCtlr.create({
        header: PushPrompt.Title,
        message: PushPrompt.Message,
        buttons: [
          {
            text: PushPrompt.Decline,
            handler: () => resolve(false),
          },
          {
            text: PushPrompt.Accept,
            handler: () => resolve(true),
          },
        ],
      });
      alert.present();
    });
  }

  private registerForPush() {
    const pushObject: PushObject = this.push.init(PUSH_OPTIONS);
    pushObject
      .on(PushEvent.Registration)
      .subscribe((registration: any) =>
        this.didRegisterForPushWithToken(registration.registrationId)
      );
    pushObject
      .on(PushEvent.Notification)
      .subscribe((notification: any) => this.didReceivePushNotification(notification));
    pushObject
      .on(PushEvent.Error)
      .subscribe((error) => console.error('Error with Push plugin', error));
  }

  private didDeclinePush() {
    this.session.set(PushStorageKey.DeclinedPush, true);
  }

  private didReceivePushNotification(notification) {
    console.log('did receive a push notification', JSON.stringify(notification));
    if (notification && notification.additionalData) {
      const deeplinkUrl = notification.additionalData.deeplink;
      this.deeplink.handleDeepLinkUrl(deeplinkUrl);
    }
  }

  private async didRegisterForPushWithToken(token) {
    console.log('did register for push with token', token);
    const storedToken = await this.session.get(PushStorageKey.PushToken);
    if (token && token !== storedToken) {
      await this.session.set(PushStorageKey.PushToken, token);
      this.userService.currentUser$
        .pipe(first((user) => user != null))
        .subscribe(() => this.saveDeviceWithToken(token));
    }
  }

  private saveDeviceWithToken(token: string) {
    this.userService.registerDevice(token, this.device.platform).subscribe(
      () => {
        console.log('Saved device', token, this.device.platform);
        this.analytics.trackEvent(PUSH_ANALYTICS_EVENT);
      },
      (error) => {
        console.log('Error saving device registration', error);
      }
    );
  }
}
