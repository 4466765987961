import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { Subject } from 'rxjs';
import { Vehicle } from 'src/app/models/vehicle';

@Component({
  selector: 'ysh-vehicle-year',
  templateUrl: './vehicle-year.page.html',
  styleUrls: ['./vehicle-year.page.scss'],
})
export class VehicleYearPage implements OnInit, OnDestroy, FlowPage {
  @Input() onDismiss: () => void;
  @Input() onComplete: (data: number) => void;
  @Input() preventBackNavigation = false;

  years: number[] = [];
  selectedYear: number;

  private unsubscribe: Subject<void> = new Subject();

  constructor(private analytics: AnalyticsService) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('VehicleYearPage');
    this.initializeYears();
  }

  initializeYears() {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    const maxYear = date.getFullYear();
    for (let i = maxYear; i >= 1960; i--) {
      this.years.push(i);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  didSelectYear(year: number) {
    this.selectedYear = year;
    this.onComplete?.(year);
  }
}
