import { BaseEntity, BaseEntityType } from 'src/app/models/base';
import { CollectionState, createCollectionState } from './collection';

import { ActionReducerMap } from '@ngrx/store';
import { Alert } from 'src/app/models/alert';
import { Benefit } from 'src/app/models/benefit';
import { CreditCard } from 'src/app/models/credit-card';
import { InjectionToken } from '@angular/core';
import { MemberSubscription } from 'src/app/models/member-subscription';
import { MemberTier } from 'src/app/models/member-tier';
import { Order } from 'src/app/models/order';
import { ReducerForCollection } from './reducers';
import { Referral } from 'src/app/models/referral';
import { Service } from 'src/app/models/service';
import { ServiceOrder } from 'src/app/models/service-order';
import { ServiceSubscription } from 'src/app/models/service-subscription';
import { UserAddress } from 'src/app/models/user-address';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleSubscription } from 'src/app/models/vehicle-subscription';

/**
 * Interface representing application storage tree
 */
export interface AppState {
  [key: string]: CollectionState<BaseEntity>;
}

export type Collection = BaseEntityType<BaseEntity>;
const collectionManifest: Collection[] = [
  Vehicle,
  CreditCard,
  Order,
  ServiceOrder,
  Alert,
  ServiceSubscription,
  VehicleSubscription,
  Benefit,
  MemberTier,
  Referral,
  MemberSubscription,
  UserAddress,
  Service,
];

export var reducers: ActionReducerMap<AppState> = collectionManifest.reduce((obj, collection) => {
  obj[collection.classId] = ReducerForCollection(collection);
  return obj;
}, {} as ActionReducerMap<AppState>);

/**
 * Make reducers injectable. This is necessary for AOT.
 */
export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');
export const reducerProvider = [{ provide: reducerToken, useValue: reducers }];

/**
 * Selects an entity collection from the application tree
 * @param  {state} AppState - application state tree
 * @param  {BaseEntityType<T>} collection - entity to select (eg User)
 * @returns CollectionState<T> - The collection object for the selected entity
 */
export function getCollection<T extends BaseEntity>(
  state: AppState,
  collection: BaseEntityType<T>
): CollectionState<T> {
  if (!collectionManifest.includes(collection)) {
    throw 'Please register entity in collection manifest';
  }
  return state[collection.classId] as CollectionState<T>;
}

/**
 * Maps a collection state object to an ordered array of entities
 * @param  {state} AppState - application state tree
 * @param  {BaseEntityType<T>} collection - entity
 * @returns T[] - An ordered array of entities
 */
export function getAll<T extends BaseEntity>(state: AppState, collection: BaseEntityType<T>): T[] {
  const collectionState = getCollection<T>(state, collection);
  const list = collectionState.ids.map((id) => collectionState.entities[id]);
  return list;
}

/**
 * Selects a single entity by unique id
 * @param  {string} id - id of entity to select
 * @param  {CollectionState<T>} collectionState
 * @returns T - A single entity
 */
export function getById<T>(id: string, collectionState: CollectionState<T>): T {
  const obj = collectionState.entities[id];
  return obj;
}
