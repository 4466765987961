import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserAddress, addressParamsForGooglePlaceData } from 'src/app/models/user-address';
import { finalize, take, takeUntil } from 'rxjs/operators';

import { Address } from 'src/app/models/address';
import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CorporatePromoPage } from '../../flows/ordering/order-flow-controller/corporate-promo/corporate-promo.page';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { MembershipService } from 'src/app/services/api/membership/membership.service';
import { ModalController } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal/modal.service';
import { OrderScheduleService } from 'src/app/services/scheduling/order-schedule/order-schedule.service';
import { PlacesAutocompletePage } from '../../misc/places-autocomplete/places-autocomplete.page';
import { UserService } from 'src/app/services/api/user/user.service';

@Component({
  selector: 'ysh-addresses',
  templateUrl: './addresses.page.html',
  styleUrls: ['./addresses.page.scss'],
})
export class AddressesPage implements OnDestroy, OnInit {
  @Input() modal = false;
  @Input() onComplete: (address: UserAddress) => void;
  @Input() preventOutOfServiceSelection = false;

  currentAddress: Nullable<UserAddress>;
  addresses$: Observable<UserAddress[]>;
  isLoading$: Observable<boolean>;
  currentPage = 1;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public addressService: AddressService,
    public scheduleHelper: OrderScheduleService,
    private modalService: ModalService,
    private modalCtrl: ModalController,
    private loadingAlertCtrl: LoadingAlertService,
    private analytics: AnalyticsService,
    private membershipService: MembershipService,
    private userService: UserService
  ) {
    this.addresses$ = addressService.userAddresses$;
    this.isLoading$ = addressService.isLoading$;
    this.getUserAddresses();
  }

  ngOnInit() {
    this.analytics.trackView('AddressesPage');
    this.scheduleHelper.currentAddress$.pipe(takeUntil(this.unsubscribe)).subscribe((address) => {
      this.currentAddress = address;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUserAddresses() {
    return this.addressService.getUserAddresses(this.currentPage);
  }

  // Actions

  didTapDelete(address) {
    this.loadingAlertCtrl.showLoader();
    this.addressService
      .deleteUserAddress(address)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()))
      .subscribe(null, (error) => {
        this.loadingAlertCtrl.showToastAlert('Error deleting address');
      });
  }

  didTapDismiss() {
    this.dismiss();
  }

  didSelectAddress(address: UserAddress) {
    if (this.preventOutOfServiceSelection && !address.serviceAvailable) {
      return;
    }
    this.onComplete?.(address);
    return this.addressService.setSelectedAddress(address).then(() => {
      if (this.modal) {
        this.dismiss();
      }
    });
  }

  async didTapNewAddress() {
    const modal = await this.modalService.openPage({ component: PlacesAutocompletePage });
    const { data } = await modal.onDidDismiss();
    if (data && Object.keys(data).length) {
      const params = addressParamsForGooglePlaceData(data);
      if (params.zipCode) {
        this.didSelectNewAddress(params);
      } else {
        this.loadingAlertCtrl.showToastAlert('Please pick a more specific address.');
      }
    }
    modal.present();
  }

  didSelectNewAddress(params) {
    this.loadingAlertCtrl.showLoader();
    this.addressService.createUserAddress(params).subscribe((userAddress) => {
      this.addressService
        .getUserAddresses()
        .pipe(
          finalize(() => {
            this.loadingAlertCtrl.dismissLoader();
          })
        )
        .subscribe(() => {
          this.didSelectAddress(userAddress);
          this.showCorporatePromoPageIfNeeded(userAddress.address);
        });
    });
  }

  showCorporatePromoPageIfNeeded(address: Address) {
    this.membershipService.sponsoredFreeMemberBenefit$.pipe(take(1)).subscribe((benefit) => {
      const company = address?.compound?.company;
      const currentUser = this.userService.currentUser$.value;
      if (CorporatePromoPage.shouldPresent(currentUser, company, benefit)) {
        const params = {
          company,
          modal: true,
          showSuccess: true,
        };
        this.modalService.openPage({ component: CorporatePromoPage, componentProps: params });
      }
    });
  }

  isAddressSelected(userAddress: UserAddress) {
    const addressSelected = this.currentAddress && this.currentAddress.uid === userAddress.uid;
    return addressSelected;
  }

  serviceAvailable(address: UserAddress) {
    return this.addressService.isServiceAvailable(address);
  }

  getNextPage(event) {
    this.currentPage++;
    this.getUserAddresses().subscribe((addresses) => {
      if (!addresses.length) {
        this.currentPage--;
        event.target.disabled = true;
      }

      event.target.complete();
    });
  }

  private dismiss() {
    this.modalCtrl.dismiss();
  }
}
