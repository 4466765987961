import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';

import { Address } from 'src/app/models/address';
import { AddressParams } from 'src/app/models/user-address';
import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { IonNav } from '@ionic/angular';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { OrderFlowControllerPage } from '../../ordering/order-flow-controller/order-flow-controller.page';
import { ServiceGroup } from 'src/app/models/service-group';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/api/user/user.service';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleFlowController } from '../../vehicle/vehicle-flow-controller/vehicle-flow-controller.page';
import { WebflowEndPage } from '../webflow-end/webflow-end.page';
import { WebflowSignUpPage } from '../webflow-signup/webflow-signup.page';
import { WebflowVariant } from '../webflow-start/webflow-start.variants';

export interface WebflowSignupFlowControllerPageProps {
  serviceGroup: ServiceGroup;
  address: Address;
  addressParams: AddressParams;
  webflowVariant: WebflowVariant;
  didRequestLogin: () => void;
}

interface WebflowSignupFlowControllerPageForm {
  user?: Nullable<User>;
  vehicle?: Nullable<Vehicle>;
}

@Component({
  selector: 'ysh-webflow-flow-controller',
  template: '<ion-nav #webflowNav ></ion-nav>',
})
export class WebflowSignupFlowControllerPage implements Flow, OnInit, AfterViewInit {
  @ViewChild('webflowNav') nav: IonNav;

  @Input() props: WebflowSignupFlowControllerPageProps;
  @Input() onDismiss: () => void;

  flowDirector: FlowDirector;
  form: WebflowSignupFlowControllerPageForm = {};

  orderPlaced = false;

  constructor(
    private analytics: AnalyticsService,
    private userService: UserService,
    private addressService: AddressService,
    private loadingAlert: LoadingAlertService
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('WebflowSignupFlowControllerPage');
  }

  ngAfterViewInit(): void {
    this.flowDirector = new FlowDirector(this);
  }

  // flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.form.user) {
      return this.optionsForSignupPage();
    }
    if (!this.form.vehicle) {
      return this.optionsForVehiclePage();
    }
    if (!this.orderPlaced) {
      return this.optionsForOrderFlow();
    }
  }

  flowDidComplete(): void {
    this.flowDirector.setRoot(this.optionsForEndPage());
    this.userService.logout();
  }

  // params

  optionsForSignupPage(): FlowPageOptions<WebflowSignUpPage> {
    return {
      page: WebflowSignUpPage,
      onComplete: () => this.signupPageDidComplete(),
      onDismiss: () => this.onDismiss?.(),
      props: {
        addressParams: this.props.addressParams,
        onAlreadyHasAccount: () => this.signupPageAlreadyHasAccount(),
      },
    };
  }

  optionsForVehiclePage(): FlowPageOptions<VehicleFlowController> {
    return {
      page: VehicleFlowController,
      onComplete: (data) => this.vehiclePageDidComplete(data),
      onDismiss: () => (this.form.vehicle = null),
      preventBackNavigation: true,
      props: {
        promptForConnectedCar: false,
        promptForFuel: false,
        promptForLicense: false,
        promptForNickname: false,
      },
    };
  }

  optionsForOrderFlow(): FlowPageOptions<OrderFlowControllerPage> {
    const userAddress = this.addressService.selectedAddress$.value;
    return {
      page: OrderFlowControllerPage,
      onComplete: () => this.orderFlowDidComplete(),
      preventBackNavigation: true,
      props: {
        skipCompletionModal: true,
        skipCompletionToast: true,
        serviceGroup: this.props.serviceGroup,
        service: null,
        vehicle: this.form.vehicle!,
        userAddress: userAddress!,
        outlineInputStyle: true,
        webflowVariant: this.props.webflowVariant
      },
    };
  }

  optionsForEndPage(): FlowPageOptions<WebflowEndPage> {
    return {
      page: WebflowEndPage,
      onComplete: () => {},
      props: {
        serviceGroup: this.props.serviceGroup,
        user: this.userService.currentUser$.value!,
      },
    };
  }

  // completion

  signupPageDidComplete() {
    this.userService.getUser().subscribe((user) => {
      this.form.user = user;
      this.addressService.getUserAddresses();
      this.flowDirector.next();
    });
  }

  signupPageAlreadyHasAccount() {
    this.loadingAlert.showConfirmationDialog(
      'An account with this phone number already exists.',
      'Sign In',
      () => {
        this.props.didRequestLogin();
      }
    );
  }

  vehiclePageDidComplete(data: Vehicle) {
    this.form.vehicle = data;
    this.flowDirector.next();
  }

  orderFlowDidComplete() {
    this.orderPlaced = true;
    this.flowDirector.next();
  }
}
