import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { WebflowStartPageRoutingModule } from './webflow-start-routing.module';

import { WebflowStartPage } from './webflow-start.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, WebflowStartPageRoutingModule, ComponentsModule],
  declarations: [WebflowStartPage],
})
export class WebflowStartPageModule {}
