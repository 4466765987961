import { BaseEntity } from './base';

export class TireTread extends BaseEntity {
  position: TirePosition;
  colorValue?: TreadLevel;
}

export interface TireTreadParams {
  colorValue?: TreadLevel;
  position?: TirePosition;
}

export type TirePosition = 'left_front' | 'right_front' | 'left_rear' | 'right_rear';
export type TreadLevel = 'green' | 'yellow' | 'red';
