import { Component, Input, OnInit } from '@angular/core';

import { Address } from 'src/app/models/address';
import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CorporatePromoPage } from '../../ordering/order-flow-controller/corporate-promo/corporate-promo.page';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { KeyboardService } from 'src/app/services/keyboard/keyboard.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { MembershipService } from 'src/app/services/api/membership/membership.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PlacesAutocompletePage } from 'src/app/pages/misc/places-autocomplete/places-autocomplete.page';
import { UserParams } from 'src/app/models/user';
import { UserService } from 'src/app/services/api/user/user.service';
import { addressParamsForGooglePlaceData } from 'src/app/models/user-address';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ysh-user-location',
  templateUrl: './user-location.page.html',
  styleUrls: ['./user-location.page.scss'],
})
export class UserLocationPage implements OnInit {
  formData: UserParams = {};
  @Input() onComplete: (params: UserParams) => void;
  @Input() onDismiss: () => void;
  @Input() outlineInputStyle = false;

  constructor(
    private loadingAlertCrtl: LoadingAlertService,
    private geolocation: Geolocation,
    private keyboardService: KeyboardService,
    private analytics: AnalyticsService,
    private addressService: AddressService,
    private membershipService: MembershipService,
    private modalService: ModalService,
    private userService: UserService
  ) {}

  // life cycle hooks

  ngOnInit() {
    this.analytics.trackView('UserLocationPage');
  }

  didTapDismiss() {
    if (this.onDismiss) {
      this.onDismiss();
    } else {
      this.dismiss();
    }
  }

  didTapDone() {
    if (this.onComplete) {
      this.onComplete(this.formData);
    } else {
      this.addressService.createUserAddress(this.formData).subscribe((userAddress) => {
        this.addressService
          .setSelectedAddress(userAddress)
          .then(() => {
            return this.dismiss();
          })
          .then(() => {
            this.showCorporatePromoPageIfNeeded(userAddress.address);
          });
      });
    }
  }

  async didTapNewAddress() {
    const modal = await this.modalService.openPage({ component: PlacesAutocompletePage });
    this.keyboardService.close();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.clearLocationData();
      this.createDataForGooglePlace(data);
    }
    setTimeout(() => {
      this.keyboardService.close();
    }, 600);
  }

  createDataForGooglePlace(data) {
    const addressParams = addressParamsForGooglePlaceData(data);
    if (addressParams.zipCode) {
      this.formData = {
        ...this.formData,
        ...addressParams,
      };
    } else {
      this.loadingAlertCrtl.showToastAlert('Please pick a more specific address.');
    }
  }

  didTapGetLocation() {
    this.loadingAlertCrtl.showLoader();
    this.clearLocationData();
    this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        this.loadingAlertCrtl.dismissLoader();
        this.reverseGeocode(resp.coords);
      })
      .catch((error) => {
        this.loadingAlertCrtl.dismissLoader();
        this.loadingAlertCrtl.presentAlert('Failed to get location', 'Please type an address');
        console.log('Error getting location', error);
      });
  }

  clearLocationData() {
    this.formData.locationName = null;
    this.formData.lat = null;
    this.formData.lng = null;
    this.formData.zipCode = null;
  }

  addressValidates() {
    return (
      this.formData.locationName && this.formData.lat && this.formData.lng && this.formData.zipCode
    );
  }

  reverseGeocode(coords) {
    const geocoder = new google.maps.Geocoder();
    console.log('geocoding');
    const latlng = new google.maps.LatLng(coords.latitude, coords.longitude);
    this.loadingAlertCrtl.showLoader();
    geocoder.geocode({ location: latlng }, (results, status) => {
      this.loadingAlertCrtl.dismissLoader();
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          this.clearLocationData();
          this.createDataForGooglePlace(results[0]);
        } else {
          this.loadingAlertCrtl.presentAlert('Failed to get location', 'No results found');
        }
      } else {
        this.loadingAlertCrtl.presentAlert(
          'Failed to get location',
          'Geocoder failed due to status ' + status
        );
      }
    });
  }

  showCorporatePromoPageIfNeeded(address: Address) {
    this.membershipService.sponsoredFreeMemberBenefit$.pipe(take(1)).subscribe((benefit) => {
      const currentUser = this.userService.currentUser$.value;
      const company = address?.compound?.company;
      if (CorporatePromoPage.shouldPresent(currentUser, company, benefit)) {
        const params = {
          company: address?.compound?.company,
          modal: true,
          showSuccess: true,
        };
        this.modalService.openPage({ component: CorporatePromoPage, componentProps: params });
      }
    });
  }

  private dismiss(data?: any) {
    this.modalService.dismissModal(data);
  }
}
