import { Component, Input } from '@angular/core';

import { Faq } from 'src/app/models/faq';
import { ModalController } from '@ionic/angular';

export interface FaqsModalComponentProps {
  faq: Faq[];
}

@Component({
  selector: 'ysh-faqs-modal',
  templateUrl: 'faqs-modal.html',
  styleUrls: ['./faqs-modal.scss'],
})
export class FaqsModalComponent {
  @Input() props: FaqsModalComponentProps;

  constructor(private modalCtrl: ModalController) {}

  didTapDismiss() {
    this.modalCtrl.dismiss(false);
  }

  openLink(link) {
    window.open(link, '_system', 'location=yes');
    return false;
  }
}
