import { Component, OnDestroy, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { Subject } from 'rxjs';

@Component({
  selector: 'ysh-membership-benefits',
  templateUrl: './membership-benefits.page.html',
  styleUrls: ['./membership-benefits.page.scss'],
})
export class MembershipBenefitsPage implements OnInit, OnDestroy, FlowPage {
  yoshiBenefits = [
    {
      tag: 'no delivery fees',
      detail: 'up to two deliveries every week',
    },
    {
      tag: 'automatic enrollment',
      detail: 'in the Yoshi Rewards program',
    },
    {
      tag: 'Guaranteed lowest',
      detail: 'Top-Tier™ per gallon gas price within 2 miles of your address',
    },
    {
      tag: 'no obligations',
      detail: 'cancel anytime',
    },
  ];

  onComplete: (didCancel: boolean) => void;
  onDismiss: () => void;
  unsubscribe: Subject<void> = new Subject();
  readonly title = `Are you sure you want to cancel? We'd hate for you to miss out on these incredible benefits of Yoshi membership`;

  constructor(private analytics: AnalyticsService) {
    // this.onComplete = this.navParams.get('onComplete');
    // this.onDismiss = this.navParams.get('onDismiss');
  }

  ngOnInit() {
    this.analytics.trackView('MembershipBenefitsPage');
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // actions
  didTapDismiss() {
    if (this.onDismiss) {
      this.onDismiss();
    }
  }

  didTapCancelMembership() {
    if (this.onComplete) {
      this.onComplete(true);
    }
  }

  didTapKeepMembership() {
    if (this.onComplete) {
      this.onComplete(false);
    }
  }
}
