import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { MembershipCancellationFlowPage } from './membership-cancellation-flow.page';
import { MembershipBenefitsPageModule } from '../membership-benefits/membership-benefits.module';
import { CancellationReasonsPageModule } from '../cancellation-reasons/cancellation-reasons.module';
import { MembershipDiscountOfferPageModule } from '../membership-discount-offer/membership-discount-offer.module';
import { CancellationNotesPageModule } from '../cancellation-notes/cancellation-notes.module';
import { ConfirmCancellationPageModule } from '../confirm-cancellation/confirm-cancellation.module';
import { ConfirmCancellationPage } from '../confirm-cancellation/confirm-cancellation.page';

@NgModule({
  entryComponents: [ConfirmCancellationPage],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MembershipBenefitsPageModule,
    CancellationReasonsPageModule,
    MembershipDiscountOfferPageModule,
    CancellationNotesPageModule,
    ConfirmCancellationPageModule,
  ],
  declarations: [MembershipCancellationFlowPage],
})
export class MembershipCancellationFlowPageModule {}
