import { BaseEntity, Default, MemoizeGetters, Transform } from './base';

import { emptyStringToNull } from './base/transform';

@MemoizeGetters
export class Company extends BaseEntity {
  name: string;
  shouldPromptForExternalId: boolean;
  shouldPromptForInviteCode: boolean;

  @Transform(emptyStringToNull)
  @Default('company id')
  externalIdTitle: string;

  @Transform((value) => (value ? new RegExp(value) : null))
  externalIdValidationRegex: RegExp;
}
