import * as Sentry from '@sentry/angular';

import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { reducerProvider, reducerToken } from './../state/state';

import { AddressConfirmationPageModule } from './pages/flows/webflow/address-confirmation/address-confirmation.module';
import { AddressesPageModule } from './pages/profile/addresses/addresses.module';
import { AppComponent } from './app.component';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { AppRoutingModule } from './app-routing.module';
import { ApplePay } from '@ionic-native/apple-pay/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { BrowserModule } from '@angular/platform-browser';
import { Camera } from '@ionic-native/camera/ngx';
import { CardIO } from '@ionic-native/card-io/ngx';
import { ClearAllMetaReducer } from 'src/state/reducers';
import { CollectionActions } from '../state/actions';
import { CompleteProfileFlowControllerPageModule } from './pages/flows/onboarding/complete-profile-flow-controller/complete-profile-flow-controller.module';
import { ComponentsModule } from './components/components.module';
import { ConnectOnStarPageModule } from './pages/profile/connect-on-star/connect-on-star.module';
import { ConnectToyotaPageModule } from './pages/profile/connect-toyota/connect-toyota.module';
import { Contacts } from '@ionic-native/contacts/ngx';
import { CorporatePromoPageModule } from './pages/flows/ordering/order-flow-controller/corporate-promo/corporate-promo.module';
import { CreditCardFlowPageModule } from './pages/flows/payment/credit-card-flow/credit-card-flow.module';
import { DayTimeSelectorPageModule } from './pages/flows/ordering/selectors/day-time-selector/day-time-selector.module';
import { Device } from '@ionic-native/device/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { EditAccountPageModule } from './pages/profile/edit-account/edit-account.module';
import { EditFlowControllerPageModule } from './pages/flows/ordering/edit-flow-controller/edit-flow-controller.module';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FaqModalPageModule } from './pages/flows/ordering/faq-modal/faq-modal.module';
import { FormBuilder } from '@angular/forms';
import { FuelSelectorPageModule } from './pages/flows/ordering/selectors/fuel-selector/fuel-selector.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { HttpClientModule } from '@angular/common/http';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicImageLoaderModule } from 'ionic-image-loader-v5';
import { IonicStorageModule } from '@ionic/storage';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { LoginFlowPageModule } from './pages/flows/login-flow/login-flow.module';
import { MembershipCancellationFlowPageModule } from './pages/flows/cancellation/membership-cancellation-flow/membership-cancellation-flow.module';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { NotesForFillerPageModule } from './pages/flows/ordering/notes-for-filler/notes-for-filler.module';
import { OnboardingFlowControllerPageModule } from './pages/flows/onboarding/onboarding-flow-controller/onboarding-flow-controller.module';
import { OrderFlowControllerPageModule } from './pages/flows/ordering/order-flow-controller/order-flow-controller.module';
import { PhotoStoryPageModule } from './pages/home/photo-story/photo-story.module';
import { PlacesAutocompletePageModule } from './pages/misc/places-autocomplete/places-autocomplete.module';
import { PowerUpsPageModule } from './pages/flows/ordering/power-ups/power-ups.module';
import { Push } from '@ionic-native/push/ngx';
import { RelativeTimePipe } from './pipes/relative-time/relative-time';
import { RequestReceivedModalPageModule } from './pages/flows/ordering/request-received-modal/request-received-modal.module';
import { RouteReuseStrategy } from '@angular/router';
import { SelectMembershipPageModule } from './pages/profile/membership/select-membership/select-membership.module';
import { ServiceDetailPage } from './pages/services/service-detail/service-detail.page';
import { ServiceDetailPageModule } from './pages/services/service-detail/service-detail.module';
import { ServiceSheetPageModule } from './pages/services/service-sheet/service-sheet.module';
import { SignupFlowPageModule } from './pages/flows/onboarding/signup-flow/signup-flow.module';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { Stripe } from '@ionic-native/stripe/ngx';
import { StripeService } from './services/stripe/stripe';
import { TimeSlotSelectorPageModule } from './pages/flows/ordering/selectors/time-slot-selector/time-slot-selector.module';
import { Toast } from '@ionic-native/toast/ngx';
import { TurnOffWeeklyDisclaimerPageModule } from './pages/flows/ordering/turn-off-weekly-disclaimer/turn-off-weekly-disclaimer.module';
import { UpdateUserFlowPageModule } from './pages/flows/onboarding/update-user-flow/update-user-flow.module';
import { UpsellFlowControllerPageModule } from './pages/flows/ordering/upsell-flow-controller/upsell-flow-controller.module';
import { UserLocationPageModule } from './pages/flows/onboarding/user-location/user-location.module';
import { VehicleColorPageModule } from './pages/flows/vehicle/vehicle-color/vehicle-color.module';
import { VehicleConfirmationPageModule } from './pages/flows/webflow/vehicle-confirmation/vehicle-confirmation.module';
import { VehicleFlowControllerPageModule } from './pages/flows/vehicle/vehicle-flow-controller/vehicle-flow-controller.module';
import { VehicleLicensePlatePageModule } from './pages/flows/vehicle/vehicle-license-plate/vehicle-license-plate.module';
import { VehicleMakePageModule } from './pages/flows/vehicle/vehicle-make/vehicle-make.module';
import { VehicleModelPageModule } from './pages/flows/vehicle/vehicle-model/vehicle-model.module';
import { VehicleNicknamePageModule } from './pages/flows/vehicle/vehicle-nickname/vehicle-nickname.module';
import { VehiclePhotosPageModule } from './pages/vehicle-profile/vehicle-photos/vehicle-photos.module';
import { VehicleProfilePageModule } from './pages/vehicle-profile/vehicle-profile.module';
import { VehicleYearPageModule } from './pages/flows/vehicle/vehicle-year/vehicle-year.module';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { WebflowEndPageModule } from './pages/flows/webflow/webflow-end/webflow-end.module';
import { WebflowLoginFlowControllerPageModule } from './pages/flows/webflow/webflow-login-flow-controller/webflow-login-flow-controller.module';
import { WebflowLoginPageModule } from './pages/flows/webflow/webflow-login/webflow-login.module';
import { WebflowSignupFlowControllerPageModule } from './pages/flows/webflow/webflow-signup-flow-controller/webflow-signup-flow-controller.module';
import { WebflowSignupPageModule } from './pages/flows/webflow/webflow-signup/webflow-signup.module';
import { WebflowStartPageModule } from './pages/flows/webflow/webflow-start/webflow-start.module';
import { WelcomePageModule } from './pages/flows/onboarding/welcome/welcome.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [ServiceDetailPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      rippleEffect: false,
      backButtonText: '',
    }),
    ComponentsModule,
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    StoreModule.forRoot(reducerToken, { metaReducers: [ClearAllMetaReducer] }),
    StoreDevtoolsModule.instrument({ maxAge: 5 }),
    OrderFlowControllerPageModule,
    FaqModalPageModule,
    EditFlowControllerPageModule,
    MembershipCancellationFlowPageModule,
    NotesForFillerPageModule,
    AddressesPageModule,
    LoginFlowPageModule,
    TurnOffWeeklyDisclaimerPageModule,
    UpsellFlowControllerPageModule,
    PowerUpsPageModule,
    PlacesAutocompletePageModule,
    CorporatePromoPageModule,
    CreditCardFlowPageModule,
    SelectMembershipPageModule,
    ServiceSheetPageModule,
    RequestReceivedModalPageModule,
    ServiceDetailPageModule,
    OnboardingFlowControllerPageModule,
    SignupFlowPageModule,
    FuelSelectorPageModule,
    WelcomePageModule,
    EditAccountPageModule,
    ConnectOnStarPageModule,
    ConnectToyotaPageModule,
    OnboardingFlowControllerPageModule,
    UserLocationPageModule,
    CompleteProfileFlowControllerPageModule,
    UpdateUserFlowPageModule,
    PhotoStoryPageModule,
    ConnectToyotaPageModule,
    VehicleLicensePlatePageModule,
    VehicleProfilePageModule,
    VehiclePhotosPageModule,
    IonicImageLoaderModule,
    WebflowSignupFlowControllerPageModule,
    WebflowStartPageModule,
    WebflowLoginPageModule,
    WebflowSignupPageModule,
    WebflowEndPageModule,
    WebflowLoginFlowControllerPageModule,
    VehicleFlowControllerPageModule,
    VehicleYearPageModule,
    VehicleMakePageModule,
    VehicleModelPageModule,
    VehicleColorPageModule,
    VehicleNicknamePageModule,
    AddressConfirmationPageModule,
    VehicleConfirmationPageModule,
    TimeSlotSelectorPageModule,
    DayTimeSelectorPageModule,
  ],
  providers: [
    FormBuilder,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    reducerProvider,
    CollectionActions,
    CurrencyPipe,
    DatePipe,
    PercentPipe,
    RelativeTimePipe,
    Badge,
    InAppBrowser,
    Toast,
    Facebook,
    Camera,
    Keyboard,
    Contacts,
    SocialSharing,
    NavParams,
    Stripe,
    CardIO,
    Geolocation,
    Device,
    Push,
    AppRate,
    ApplePay,
    MobileAccessibility,
    Diagnostic,
    WebView,
    StripeService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ logErrors: true }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
