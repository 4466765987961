import { ApiService, EmptySubscriber } from '../api.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { LoyaltyPerk, PerkName } from 'src/app/models/loyalty/perk';
import { filter, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LoyaltyPoints } from 'src/app/models/loyalty/points';
import { LoyaltyProgram } from 'src/app/models/loyalty/program';
import { LoyaltyTier } from 'src/app/models/loyalty/tier';
import { ServiceName } from 'src/app/models/service-type';
import { UserService } from 'src/app/services/api/user/user.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  currentProgram$: BehaviorSubject<Nullable<LoyaltyProgram>> = new BehaviorSubject(null);
  currentPerks$: Observable<LoyaltyPerk[]>;
  nextTier$: Observable<Nullable<LoyaltyTier>>;
  nextTierPerks$: Observable<LoyaltyPerk[]>;
  hasReferralPerk$: Observable<boolean>;
  pointTransactions$: BehaviorSubject<LoyaltyPoints[]> = new BehaviorSubject([]);
  levels$: Observable<LoyaltyLevel[]>;
  constructor(private api: ApiService, private userService: UserService) {
    this.currentPerks$ = this.getCurrentPerks();
    this.nextTier$ = this.getNextTier();
    this.nextTierPerks$ = this.getNextTierPerks();
    this.hasReferralPerk$ = this.getHasReferralPerk();
    this.levels$ = this.getLevels();
  }

  getLoyaltyProgram() {
    const request = this.api
      .call({
        method: 'GET',
        url: `/users/${ApiService.USER_UID}/loyalty_program`,
      })
      .pipe(
        map((res) => new LoyaltyProgram(res)),
        map((program) => {
          if (program && program.loyaltyPolicy) {
            program.loyaltyPolicy.tiers = program.loyaltyPolicy.tiers.sort(
              (a, b) => a.threshold - b.threshold
            );
          }
          return program;
        }),
        tap((program: LoyaltyProgram) => {
          const validProgram = program && program.valid;
          this.currentProgram$.next(validProgram ? program : null);
        })
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  getPointTransactions() {
    const request = this.api
      .call({
        method: 'GET',
        url: `/users/${ApiService.USER_UID}/loyalty_program/loyalty_points`,
      })
      .pipe(
        map((res) => res.map((points) => new LoyaltyPoints(points))),
        tap((points: LoyaltyPoints[]) => this.pointTransactions$.next(points))
      )
      .subscribe(new EmptySubscriber());
    return request;
  }

  pointMultiplerForService$(serviceName: ServiceName) {
    return this.currentProgram$.pipe(
      filter((program) => !!program),
      map((program) => {
        const loyaltyMultiplier = program?.loyaltyPolicy.multipliers.find((multiplier) =>
          multiplier.names.includes(serviceName)
        );
        return loyaltyMultiplier && loyaltyMultiplier.multiplier;
      })
    );
  }

  private getHasReferralPerk() {
    return this.currentPerks$.pipe(
      map((perks) => {
        return !!perks.find((perk) => perk.name === PerkName.Referrals);
      })
    );
  }

  private getCurrentPerks() {
    return this.currentProgram$.pipe(
      map((program) => {
        if (program) {
          const tierPerks: LoyaltyPerk[] = [];
          program.loyaltyPolicy.perks.forEach((perk) => {
            if (
              program &&
              program.getTier(perk.tier).threshold <= program.fullTier.threshold &&
              !tierPerks.includes(perk)
            ) {
              tierPerks.push(perk);
            }
          });
          return tierPerks;
        }
        return [];
      })
    );
  }

  private getNextTier() {
    return this.currentProgram$.pipe(
      filter((program) => !!program),
      map((program) => {
        return program?.loyaltyPolicy.tiers.find(
          (tier) => tier.threshold > program.fullTier.threshold
        );
      })
    );
  }

  private getNextTierPerks() {
    return combineLatest([this.currentProgram$, this.nextTier$]).pipe(
      map(([program, nextTier]) => {
        if (nextTier) {
          return this.filterPerks(program, nextTier);
        } else {
          return [];
        }
      })
    );
  }

  private filterPerks = (program: Nullable<LoyaltyProgram>, tier: LoyaltyTier) => {
    return (program?.loyaltyPolicy.perks ?? []).filter((perk) => {
      const perkTier = program?.getTier(perk.tier);
      const isCurrentTier = tier.threshold === perkTier?.threshold;
      const isProgressive = perkTier && perk.progressive && perkTier.threshold <= tier.threshold;
      return isCurrentTier || isProgressive;
    });
  };

  getLevels(): Observable<LoyaltyLevel[]> {
    return combineLatest([this.currentProgram$, this.nextTier$]).pipe(
      map(([currentProgram]) => {
        const levels: LoyaltyLevel[] = [];
        if (currentProgram) {
          currentProgram.loyaltyPolicy.tiers.forEach((tier) => {
            levels.push({ tier, perks: this.filterPerks(currentProgram, tier) });
          });
        }
        return levels;
      })
    );
  }

  get computeCutoffTime(): number {
    const currentUser = this.userService.currentUser$.value;
    const currentProgram = this.currentProgram$.value;
    let cutoffTime: number = 24;
    if (currentProgram && currentUser) {
      cutoffTime =
        !currentUser.converted || !currentProgram?.fullTier?.orderCutoffHour
          ? currentProgram.maxCutoffHour
          : currentProgram?.fullTier?.orderCutoffHour;
    }
    return cutoffTime;
  }

  get computeCutoffTimeString(): string {
    const hour = this.computeCutoffTime;
    return moment(hour, 'h').format('ha');
  }
}

export interface LoyaltyLevel {
  tier: LoyaltyTier;
  perks: LoyaltyPerk[];
}
