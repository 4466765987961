import { Component, NgZone } from '@angular/core';
import { Oem, OemMinYear, OemService } from 'src/app/services/oem/oem.service';
import { finalize, flatMap, map, retry, take, takeWhile } from 'rxjs/operators';
import { interval, timer } from 'rxjs';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavigationService } from 'src/app/navigation.service';
import { UserService } from 'src/app/services/api/user/user.service';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-connect-on-star',
  templateUrl: './connect-on-star.page.html',
  styleUrls: ['./connect-on-star.page.scss'],
})
export class ConnectOnStarPage {
  static readonly BACKOFF_INTERVAL = 3000;

  MIN_YEAR = OemMinYear.GM;

  isModal = false;
  gmReturnCode;

  constructor(
    private zone: NgZone,
    private vehicleService: VehiclesService,
    private userService: UserService,
    private loadingAlertCrtl: LoadingAlertService,
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private oem: OemService
  ) {
    // this.isModal = this.navParams.get('isModal');
  }

  ionViewDidLoad() {
    this.analytics.trackView('ConnectOnstarPage');
  }

  didTapBack() {
    this.dismiss();
  }

  didTapConnectOnStar() {
    this.oem
      .authenticate(Oem.GM)
      .then(
        (code) => {
          this.gmReturnCode = code;
          this.onGmSuccess();
        },
        () => {
          this.onGmReject();
        }
      )
      .catch((error) => {
        this.onGmError();
      });
  }

  didTapNotNow() {
    this.dismiss({
      success: false,
    });
  }

  onGmSuccess() {
    this.loadingAlertCrtl.showLoaderWithText('Getting your vehicle(s) from OnStar.');
    timer(ConnectOnStarPage.BACKOFF_INTERVAL)
      .pipe(
        flatMap(() => {
          return this.userService.refreshGMToken(this.gmReturnCode);
        }),
        retry(4),
        finalize(() => this.loadingAlertCrtl.dismissLoader())
      )
      .subscribe(
        (success) => {
          this.zone.run(() => {
            this.loadingAlertCrtl.showToastConfirmation(
              "Congratulations! You have connected your OnStar account to Yoshi! We've added any vehicle you've selected to your account."
            );
            this.dismiss({ success: true });
            this.pollForOnstarVehicle();
          });
        },
        (error) => {
          this.onGmError();
          console.log('error updating user', JSON.stringify(error));
        }
      );
  }

  private pollForOnstarVehicle() {
    interval(ConnectOnStarPage.BACKOFF_INTERVAL)
      .pipe(
        flatMap(() => this.vehicleService.getVehicles()),
        map((vehicles) => vehicles.find((vehicle) => vehicle.onstar)),
        take(5),
        takeWhile((vehicle) => {
          if (vehicle) {
            this.vehicleService.setSelectedVehicle(vehicle);
          }
          return !vehicle;
        })
      )
      .subscribe(() => console.log('Vehicle poll'));
  }

  onGmReject() {
    this.didTapNotNow();
  }

  onGmError() {
    this.loadingAlertCrtl.showToastAlert(
      'An error occurred while trying to authenticate with OnStar. Please add your vehicle manually.'
    );

    if (this.isModal) {
      this.dismiss({ success: false });
    }
  }

  private dismiss(data?: any) {
    this.isModal ? this.modalService.dismissModal(data) : this.navigationService.goBack();
  }
}
