import { VirtualDefaultLocation, uberAreas } from './webflow-start.variants.areas';

import { ServiceName } from 'src/app/models/service-type';
import { FaqTag } from 'src/app/pages/services/components/faq/faq';

export interface WebflowVariant {
  serviceNameSearch: string[];
  inviteCode?: string;
  introTextPromo?: string;
  introText?: string;
  imgIntroSrc?: string;
  imgMainSrc?: string;
  footerCTA?: string;
  pricingAndAvailability?: string;
  headerText?: string;
  headerCaption?: string;
  headerImgSrc?: string;
  valuePropHeading?: string;
  valuePropText?: string;
  valuePropList?: string[];
  valuePropDetailsText?: string;
  valuePropDetailsList?: string[];
  layoutAlt?: boolean;
  faqServiceName?: ServiceName;
  virtual: boolean;
  defaultLocation?: VirtualDefaultLocation;
  faqTags?: FaqTag[];
}

export const getWebflowVariant = (key: string, area?: any) => {
  const uberArea = uberAreas[area] || uberAreas.san_francisco;
  const webflowVariants: { [key: string]: WebflowVariant } = {
    uber: {
      serviceNameSearch: ['rideshare'],
      introText:
        '<b>Uber and Yoshi have partnered to help streamline vehicle inspections.</b> Get a certified inspection, provided by Yoshi, right at your parked vehicle.',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-uber.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-inspection.png',
      footerCTA: 'Enter the address for your vehicle inspection.',
      pricingAndAvailability: `Available ${uberArea.serviceTimeWindow} ${uberArea.serviceDaysWindow}<br>Service is ${uberArea.servicePrice}`,
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-uber.png',
      headerText: 'Get certified vehicle inspections at home',
      headerCaption: 'Search your address for availability',
      valuePropHeading: `Exclusive offer for Uber Earners: Mobile inspections for ${uberArea.servicePrice}`,
      valuePropText: 'Uber and Yoshi have partnered to help streamline vehicle inspections. Experience the benefits of Yoshi inspections at home:',
      valuePropList: [
        'Maximum convenience ',
        'Same day appointments in most cases',
        `${uberArea.serviceTimeWindow} availability, ${uberArea.serviceDaysWindow}`,
        'Reschedule or cancel at any time',
      ],
      layoutAlt: true,
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.UberMobile],
      virtual: false,
    },
    uber_wash: {
      serviceNameSearch: ['wash'],
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-uber.png',
      headerText: 'Get 20% off car washes delivered at home!',
      headerCaption: 'Search your address for availability',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-wash.png',
      valuePropText: '<b>Uber Earners receive 20% off car washes!</b> Uber and Yoshi have partnered to help streamline the car wash experience. See below for prices and details:',
      valuePropList: [
        `Exterior Wash: $28 <s>$35</s>`,
        `Exterior & Vacuum: $39 <s>$49</s>`,
        '20% discount is automatically applied',
      ],
      inviteCode: 'UBERWASH20',
      layoutAlt: true,
      virtual: false,
      faqServiceName: ServiceName.Wash,
    },
    uber_virtual: {
      serviceNameSearch: [uberArea.serviceName!],
      introText:
        '<b>Uber and Yoshi have partnered to help streamline vehicle inspections.</b> Get a certified inspection, provided by Yoshi, over video chat',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-uber.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-virtual.png',
      pricingAndAvailability: `Available ${uberArea.serviceTimeWindow} ${uberArea.serviceDaysWindow}<br>Service is ${uberArea.servicePrice}`,
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-uber.png',
      headerText: 'Get a certified video chat inspection',
      headerCaption: `${uberArea.headerCaption || 'Schedule your inspection today!'}`,
      valuePropHeading: `Exclusive offer for Uber Earners: Virtual inspections for ${uberArea.servicePrice}`,
      valuePropText: `Uber and Yoshi have partnered to help streamline vehicle inspections. Experience the benefits of Yoshi video chat inspections for ${uberArea.displayName} drivers:`,
      valuePropList: [
        'Maximum convenience',
        'Same day appointments in most cases',
        `${uberArea.serviceTimeWindow} availability, ${uberArea.serviceDaysWindow}`,
        'Reschedule or cancel at any time',
      ],
      layoutAlt: true,
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.UberVirtual],
      virtual: true,
      defaultLocation: uberArea.defaultLocation,
    },
    turo_virtual: {
      serviceNameSearch: ['turo'],
      imgMainSrc: '/assets/images/webflow/webflow-start-main-virtual.png',
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-turo.png',
      headerText: 'Get a Turo-approved video chat inspection',
      headerCaption: 'Schedule your inspection today!',
      valuePropHeading: 'Exclusive offer for Turo hosts: Virtual inspections for $25',
      valuePropText: 'Turo and Yoshi have partnered to help streamline vehicle inspections. Experience the benefits of Yoshi video chat inspections:',
      valuePropList: [
        'Approved for all US based Turo hosts',
        'Same day appointments in most cases',
        '10am-7pm CT availability, daily (except Sundays)',
        'Reschedule or cancel at any time',
      ],
      layoutAlt: true,
      virtual: true,
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.Turo],
      defaultLocation: {
        locationName: 'Bismarck, ND',
        lat: 46.8166927,
        lng: -100.7787809,
        zipCode: '58505',
      },
    },
    getaround_virtual: {
      serviceNameSearch: ['getaround'],
      imgMainSrc: '/assets/images/webflow/webflow-start-main-virtual.png',
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-getaround.png',
      headerText: 'Get a certified video chat inspection',
      headerCaption: 'Schedule your inspection today!',
      valuePropText: `<b>Getaround and Yoshi have partnered to help streamline vehicle inspections.</b> Experience the benefits of Yoshi video chat inspections:`,
      valuePropList: [
        'Maximum convenience',
        'Same day appointments in most cases',
        '10am-7pm CT availability, daily (except Sundays)',
        '$27.50 per video chat inspection',
      ],
      layoutAlt: true,
      virtual: true,
      faqServiceName: ServiceName.Inspection,
      faqTags: [FaqTag.GetAround],
      defaultLocation: {
        locationName: 'Bismarck, ND',
        lat: 46.8166927,
        lng: -100.7787809,
        zipCode: '58505',
      },
    },
    concierge: {
      serviceNameSearch: ['concierge'],
      imgMainSrc: '/assets/images/webflow/webflow-start-main-virtual.png',
      headerImgSrc: '/assets/images/webflow/webflow-start-logos-concierge.png',
      headerText: 'Yoshi Concierge Video Chat Inspections',
      headerCaption: 'Schedule your inspection today!',
      valuePropText: '<b>Driving without stress!</b> Get a video-chat vehicle inspection for a general checkup or advice for when problems arise.',
      valuePropList: [
        'Full Time, Certified technicians',
        'Same day appointments in most cases',
        '10pm-7pm CT availability daily (except Sundays)',
        '$25 per video-chat inspection',
      ],
      valuePropDetailsText: '<b>What’s Included?</b>',
      valuePropDetailsList: [
        'Routine checkup of key vehicle components such as tires, brakes, wipers, lights and more',
        'Optional check of fluids, belts and hoses',
        'Recommended actions based on inspection results such as necessary part replacements, inspections or rotations',
      ],
      layoutAlt: true,
      virtual: true,
    },
    wash: {
      serviceNameSearch: ['wash', 'detail'],
      inviteCode: 'wash50off',
      introTextPromo:
        '<b>Schedule today and get 50% OFF your first car wash!</b> Yoshi delivers car washes at your home or office so you can save time and money.',
      introText:
        '<b>Schedule a quality car wash today!</b> Yoshi delivers car washes and details at your home or office so you can save time and money.',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-wash.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-wash.png',
      footerCTA:
        'Have a fleet of 5+ vehicles? <a href="https://www.startyoshi.com/fleet" target="_top">Get a quote</a>',
      virtual: false,
    },
    detail: {
      serviceNameSearch: ['detail'],
      introText:
        '<b>Schedule a quality car detail today!</b> Yoshi delivers car details and washes at your home or office so you can save time and money.',
      imgIntroSrc: '/assets/images/webflow/webflow-start-intro-detail.png',
      imgMainSrc: '/assets/images/webflow/webflow-start-main-wash.png',
      footerCTA:
        'Have a fleet of 5+ vehicles? <a href="https://www.startyoshi.com/fleet" target="_top">Get a quote</a>',
      virtual: false,
    },
  };
  return webflowVariants[key];
};
