import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GasStation, GasStationSource } from 'src/app/models/gas-station';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FuelPricingAndDiscountsService } from 'src/app/services/fuel-pricing-and-discounts/fuel-pricing-and-discounts.service';
import { UserAddress } from 'src/app/models/user-address';
import { Vehicle } from 'src/app/models/vehicle';
import { YoshiMapStyle } from 'src/theme/map-style';

@Component({
  selector: 'ysh-price-per-gallon-details-modal',
  templateUrl: './price-per-gallon-details-modal.page.html',
  styleUrls: ['./price-per-gallon-details-modal.page.scss'],
})
export class PricePerGallonDetailsModalPage implements OnInit, OnDestroy {
  static PAGE_NAME = YshModals.PricePerGallonDetailsModalPage;

  readonly FIXED_DAILY_PRICING_DISCLAIMER =
    'Your gas price is competitive with other gas stations in your region. Prices are set each morning before 8am';
  readonly LOWEST_DAILY_PRICING_DISCLAIMER =
    '*Price averages are calculated daily based on the lowest Top Tier™ station price in your area! We exclude cash-only prices and stations that charge additional fees to their advertised price.';
  readonly OPIS_ATTRIBUTION = ' Data powered by OPIS.';

  @Input() address: UserAddress;
  @Input() vehicle!: Vehicle;
  fuelPrice: number;
  map: google.maps.Map;
  mapCenter: google.maps.LatLng;
  gasStations: GasStation[] = [];
  showOpisAttribution = false;
  circle: google.maps.Circle;
  closestStationOutsideRadius = false;
  hasSynergy = false;
  @Input() hasFixedPrice = false;

  constructor(
    public fuelPricingService: FuelPricingAndDiscountsService,
    private addressService: AddressService,
    private analytics: AnalyticsService,
    private modalService: ModalService
  ) {}

  // life cycle hooks

  ngOnInit() {
    this.mapCenter = new google.maps.LatLng(this.address.address.lat, this.address.address.lng);
    this.fuelPrice = this.address.getFuelPriceFor(this.vehicle.fuelGrade);
    this.hasSynergy = this.address.synergyGas || false;
    this.analytics.trackView('PricePerGallonDetailsModalPage');
  }

  ionViewDidEnter() {
    this.configureMap();
  }

  ngOnDestroy() {
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map);
    }
  }

  configureMap() {
    const options = {
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      styles: YoshiMapStyle,
    };

    const mapContainer = document.getElementById('pricing-screen-map');
    if (!mapContainer) {
      return;
    }

    this.map = new google.maps.Map(mapContainer, options);
    this.map.setCenter(this.mapCenter);
    this.drawCircle(2);
    this.drawGasStationMarkers();
  }

  private drawGasStationMarkers() {
    const PopUp = this.createPopupClass();
    this.addressService
      .getGasStationsWithinRadius(this.address.uid)
      .subscribe((result: GasStation[]) => {
        const filteredResults = result.filter((station) => {
          const grade = this.vehicle.fuelGrade;
          return grade ? station.price[grade.toLowerCase()] : false;
        });

        this.gasStations = filteredResults.sort((a, b) => {
          const aSort = Number(this.isStationLowestPrice(a));
          const bSort = Number(this.isStationLowestPrice(b));
          return aSort - bSort;
        });
        const priceMatchStation = this.gasStations.find((station) =>
          this.isStationLowestPrice(station)
        );
        this.showOpisAttribution = priceMatchStation?.source === GasStationSource.Opis;
        setTimeout(() => {
          for (let i = 0; i < this.gasStations.length; i++) {
            const ele = document.getElementById('gas-station-marker-' + i);
            if (!ele) {
              continue;
            }
            const x = new PopUp(
              new google.maps.LatLng(+this.gasStations[i].lat, +this.gasStations[i].lng),
              ele
            );
            x.setMap(this.map);
          }
          let circleRadius = this.closestGasStation(this.gasStations);
          circleRadius = circleRadius > 2 ? circleRadius : 2;
          this.closestStationOutsideRadius = circleRadius > 2;
          this.drawCircle(circleRadius);
        }, 0); // wait for html template to render first.
      });
  }

  private drawCircle(radiusInMiles) {
    if (this.circle) {
      this.circle.setMap(null);
    }
    this.circle = new google.maps.Circle({
      strokeColor: '#F37878',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#F37878',
      fillOpacity: 0.45,
      map: this.map,
      center: this.mapCenter,
      radius: 1700 * radiusInMiles, // 2 miles in meters
    });
    this.map.fitBounds(this.circle.getBounds());
    this.map.setZoom(this.map.getZoom() + 0.75);
  }

  private closestGasStation(stations: GasStation[]): number {
    const closestStation = [...stations].sort((a, b) => a.distance - b.distance)[0];
    return closestStation && closestStation.distance;
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  // Helpers

  getBasePrice() {
    return this.fuelPricingService.getFuelPrice(this.vehicle, this.address, false);
  }

  getServiceDiscounts() {
    return this.fuelPricingService.totalServiceDiscount(this.vehicle);
  }

  getOtherDiscounts() {
    return this.fuelPricingService.totalBenefitsDiscount(this.vehicle);
  }

  getLowestFixedPriceDiscount() {
    return this.fuelPricingService.bestFixedPriceDiscountValue(this.vehicle);
  }

  getDiscountedPrice() {
    return this.fuelPricingService.getFuelPrice(this.vehicle, this.address, true);
  }

  priceForStation(station: GasStation) {
    const grade = this.vehicle.fuelGrade.toLowerCase();
    const priceTier = station.price[grade];
    return station && grade && priceTier && priceTier.price;
  }

  descriptionForLowestFixedPrice() {
    return this.fuelPricingService.getBestFixedPriceDiscount(this.vehicle)?.displayName;
  }

  isStationLowestPrice(station: GasStation) {
    if (!this.vehicle || !station) {
      return false;
    }
    const grade = this.vehicle.fuelGrade.toLowerCase();
    if (this.closestStationOutsideRadius) {
      return true;
    } else if (grade === 'regular') {
      return station.lowestRegularPrice;
    } else if (grade === 'premium') {
      return station.lowestPremiumPrice;
    } else {
      return false;
    }
  }

  /**
   * This approach is described here:
   * https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
   */
  createPopupClass() {
    function Popup(position, content) {
      this.position = position;

      content.classList.add('popup-bubble');
      content.classList.remove('display-hidden');

      // This zero-height div is positioned at the bottom of the bubble.
      const bubbleAnchor = document.createElement('div');
      bubbleAnchor.classList.add('popup-bubble-anchor');
      bubbleAnchor.appendChild(content);

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement('div');
      this.containerDiv.classList.add('popup-container');
      this.containerDiv.appendChild(bubbleAnchor);
    }
    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function () {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function () {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

      // Hide the popup when it is far out of view.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

      if (display === 'block') {
        this.containerDiv.style.left = divPosition.x + 'px';
        this.containerDiv.style.top = divPosition.y + 'px';
      }
      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    };

    return Popup;
  }
}
