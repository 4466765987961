import { BaseEntity, Default, Transform, toDate } from 'src/app/models/base';

export class Mileage extends BaseEntity {
  @Transform(toDate)
  createdAt: Date;

  @Default(0)
  miles: number;

  source: MileageSource;
}

export enum MileageSource {
  User = 'user',
  OnStar = 'onstar',
  Yoshi = 'yoshi',
  Toyota = 'toyota',
}
