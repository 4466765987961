import { Injectable } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  constructor(private keyboard: Keyboard) {}
  open() {
    this.keyboard.show();
  }

  close() {
    this.keyboard.hide();
  }

  disableScroll(disable: boolean) {
    this.keyboard.disableScroll(disable);
  }

  isVisible(): boolean {
    return this.keyboard.isVisible;
  }
}
