import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MediaPlatforms } from 'src/app/services/media/media.service';
import { YshModals } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'ysh-video-player',
  templateUrl: 'ysh-video-player.html',
  styleUrls: ['./ysh-video-player.scss'],
})
export class YshVideoPlayerComponent implements OnInit {
  static PAGE_NAME = YshModals.YshVideoPlayerComponent;

  @Input() videoId: string;
  @Input() platform: MediaPlatforms;

  link: Nullable<SafeResourceUrl>;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.link = this.videoLink
      ? this.sanitizer.bypassSecurityTrustResourceUrl(this.videoLink)
      : null;
  }

  get videoLink() {
    if (this.platform === MediaPlatforms.Youtube) {
      return `https://www.youtube.com/embed/${this.videoId}?autoplay=1&mute=1`;
    }
  }
}
