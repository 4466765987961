import { Component, OnInit } from '@angular/core';
import { ModalService, YshModals } from 'src/app/services/modal/modal.service';

@Component({
  selector: 'ysh-vehicle-limit-modal',
  templateUrl: './vehicle-limit-modal.component.html',
  styleUrls: ['./vehicle-limit-modal.component.scss'],
})
export class VehicleLimitModalComponent {
  static PAGE_NAME = YshModals.VehicleLimit;

  readonly title: string = 'Vehicle Limit Reached';

  readonly smsNumber: string = '+16502854778';
  readonly customerServiceEmail = 'benefits@startyoshi.com';

  readonly description: string =
    'To add an additional vehicle please contact Yoshi customer service to upgrade your membership. If you are managing a fleet, contact our sales team to learn about our pricing options.';

  readonly emailSubject: string = encodeURI('Information request for fleet pricing');
  readonly emailBody: string = encodeURI(
    'I was trying to add an additional vehicle to my account in the Yoshi app and it told me to contact sales to learn more about your fleet pricing options. Can you help me with that?'
  );
  readonly smsBody: string = encodeURI(
    'I was trying to add an additional vehicle in the app and it told me to contact customer service to upgrade my membership. Can you help me with that?'
  );

  constructor(private modalService: ModalService) {}

  didTapCustomerServices() {
    window.open(`sms://${this.smsNumber}&body=${this.smsBody}`, '_system');
    this.dismiss();
  }

  didTapContactSales() {
    window.open(
      `mailto:${this.customerServiceEmail}?subject=${this.emailSubject}&body=${this.emailBody}`,
      '_system'
    );
    this.dismiss();
  }

  dismiss() {
    this.modalService.dismissModal();
  }
}
