import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Make } from 'src/app/models/vehicle';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'ysh-vehicle-make',
  templateUrl: './vehicle-make.page.html',
  styleUrls: ['./vehicle-make.page.scss'],
})
export class VehicleMakePage implements OnInit, OnDestroy {
  @Input() onDismiss: () => void;
  @Input() onComplete: (data: Make) => void;

  makes: Make[];
  filteredMakes: Make[] = [];
  selectedMake: Make;
  search: string;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private analytics: AnalyticsService,
    private vehicleService: VehiclesService,
    private keyboard: Keyboard,
    private platform: Platform
  ) {}

  // life cycle hooks
  ngOnInit() {
    this.analytics.trackView('VehicleMakesPage');
    this.loadData();
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // Data
  loadData() {
    this.vehicleService.getCarMakes().subscribe(
      (data) => {
        this.makes = data;
        this.filteredMakes = data;
      },
      (error) => {
        console.log('error loading car makes', error);
      }
    );
  }

  // Actions

  dismissKeyboard() {
    if (this.platform.is('cordova') && this.keyboard.isVisible) {
      this.keyboard.hide();
    }
  }

  onMakeFilterChanged() {
    this.filteredMakes = this.makes.filter((make) => {
      return make.make.toUpperCase().includes(this.search.toUpperCase());
    });
  }

  didTapDismiss() {
    this.onDismiss?.();
  }

  didSelectMake(make: Make) {
    this.selectedMake = make;
    this.onComplete?.(make);
  }
}
