import { Component, Input, OnInit } from '@angular/core';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Photo } from 'src/app/models/photo';
import { PhotoStoryPage } from '../../home/photo-story/photo-story.page';

@Component({
  selector: 'ysh-vehicle-photos',
  templateUrl: './vehicle-photos.page.html',
  styleUrls: ['./vehicle-photos.page.scss'],
})
export class VehiclePhotosPage implements OnInit {
  @Input() photoStory: Photo[];
  @Input() modal: boolean;
  @Input() photos: string[]; // used for displaying images thumbnails from string url
  constructor(private modalService: ModalService, private analytics: AnalyticsService) {}

  ngOnInit() {
    this.analytics.trackView('VehiclePhotosPage');
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapPhoto(index: number) {
    this.modalService.openPage({
      component: PhotoStoryPage,
      componentProps: {
        photoStory: this.photoStory,
        activeIndex: index,
      },
      animated: false,
    });
  }
}
