import { BaseEntity, Transform } from '../base';
import { toDate } from '../base/transform';

export class LoyaltyPeriod extends BaseEntity {
  @Transform(parseInt)
  redeemedPoints: number;
  @Transform(toDate)
  startedAt: Date;
  @Transform(toDate)
  endedAt?: Date;
}
