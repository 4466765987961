import { Component, Input } from '@angular/core';

import { MemberPolicy } from 'src/app/models/membership-policy';

@Component({
  selector: 'ysh-membership-benefits',
  templateUrl: 'membership-benefits.html',
  styleUrls: ['./membership-benefits.scss'],
})
export class MembershipBenefitsComponent {
  @Input() showFreeTrialBenefit = false;
  @Input() visitsPerMonth: number;
  @Input() policy: MemberPolicy;
}
